import React from 'react';
import { Box } from '../Layout/Box';
import styles from './colorCircle.scss';

export interface ColorCircleProps {
  color: string;
}

export const ColorCircle: React.FC<ColorCircleProps> = ({ color }) => {
  return (
    <Box
      style={{ backgroundColor: color, borderColor: color }}
      className={styles.colorCircle}
    />
  );
};
