import React from 'react';
import { type I18nItem } from '@mobble/i18n';

import Checkbox from '@src/components/Checkbox';
import { Box } from '../Layout/Box';
import { Text } from '../UI/Text';
import { HStack } from '../Layout/Stack';
import { Spacer } from '../Layout/Spacer';
import { Clickable } from './Clickable';

import styles from './inlineOption.scss';

export type LabelLike =
  | number
  | string
  | React.ReactNode
  | JSX.Element
  | I18nItem;

export interface InlineOptionProps {
  label: LabelLike;
  value: number | string;
  labelExtra?: LabelLike;
  description?: LabelLike;
  component?: React.ReactNode | JSX.Element;
  type?: 'checkbox';
  color?: string; // TODO: check used?
  selected?: boolean;
  condensed?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  className?: string;
}

export const InlineOption: React.FC<InlineOptionProps> = ({
  type,
  value,
  selected,
  label,
  description,
  component,
  color,
  labelExtra,
  condensed,
  onClick,
  disabled,
  className,
}) => {
  const renderLabelLike = (
    labelLike: string | number | React.ReactNode | I18nItem
  ): React.ReactNode => {
    if (
      typeof labelLike === 'object' &&
      ((labelLike as any)?.key || (labelLike as any)?.defaultTranslation)
    ) {
      return <Text bold i18n={labelLike as I18nItem} />;
    } else if (typeof labelLike === 'object') {
      return labelLike as React.ReactNode;
    }
    return <Text bold>{labelLike as any}</Text>;
  };

  const classNames = [
    styles.wrapper,
    styles.inlineOption,
    disabled ? styles.disabled : false,
    selected ? styles.selected : false,
  ]
    .filter(Boolean)
    .join(' ');

  return (
    <Clickable
      role="option"
      aria-selected={selected}
      data-value={value}
      fullWidth
      href={onClick}
      disabled={disabled}
      className={[styles.wrapper, className].filter(Boolean).join(' ')}
    >
      <Box flex className={classNames} spacing={condensed ? 1 : 2}>
        <HStack alignment="center">
          {type === 'checkbox' ? (
            <Box spacing={{ top: 0, right: 1, bottom: 0, left: 0 }}>
              <Checkbox
                id={label as string}
                checked={selected}
                tabIndex={-1}
                onChange={() => {}}
              />
            </Box>
          ) : null}
          {component ? (
            <Box flex>{component}</Box>
          ) : (
            <Box flex>
              <HStack>
                <HStack wrap>{renderLabelLike(label)}</HStack>
                <Spacer flex />
                {color ? (
                  <Box spacing={{ top: 0, right: 1, bottom: 0, left: 0 }}>
                    {/*  <ColorCircle color={color} /> */}
                  </Box>
                ) : null}
                <Box>{labelExtra ? renderLabelLike(labelExtra) : null}</Box>
              </HStack>
              {description ? renderLabelLike(description) : null}
            </Box>
          )}
        </HStack>
      </Box>
    </Clickable>
  );
};
