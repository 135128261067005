import React from 'react';
import { useI18n } from '@mobble/i18n';
import {
  mapAssetsTypes,
  type MapAsset,
  type MapAssetType,
  mapAssetToPoints,
  mapAssetTypeToGeometryFeatureType,
} from '@mobble/models/src/model/MapAsset';
import { FormBuilder, type FormBuilderProps } from '../Form/FormBuilder';
import { type Point } from '@mobble/models/src/model/MapGeometry';
import { mapAssetTypeToMode } from '../../Map/Plugins/Creator';

export interface MapAssetEditFormProps {
  mapAsset: MapAsset;
  error?: string;
  loading?: boolean;
  onCancel: () => void;
  onTouched: (dirty: boolean) => void;
  onSubmit: (formValues: MapAssetEditFormValues) => void;
}

export interface MapAssetEditFormValues {
  name: string;
  description?: string;
  type?: string;
  points?: Point[];
}

export const MapAssetEditForm: React.FC<MapAssetEditFormProps> = ({
  mapAsset,
  error,
  loading,
  onCancel,
  onTouched,
  onSubmit,
}) => {
  const { formatMessage } = useI18n();
  const typeToI18n = (type: MapAssetType) => ({
    id: `map_assets.type.${type}`,
    defaultMessage: [],
  });

  const typeToModeI18n = (type: MapAssetType) => {
    switch (mapAssetTypeToGeometryFeatureType(type)) {
      default:
      case 'Point':
        return { id: 'map_asset.mode.point', defaultMessage: [] };
      case 'LineString':
        return { id: 'map_asset.mode.line_string', defaultMessage: [] };
    }
  };

  const mapAssetTypeOptions = mapAssetsTypes.map((value) => ({
    label: formatMessage(typeToI18n(value)),
    labelExtra: formatMessage(typeToModeI18n(value)),
    value,
  }));

  const form: FormBuilderProps<MapAssetEditFormValues> = {
    i18nRootKey: 'map_assets.map_asset.edit.form',
    flex: true,
    fields: [
      {
        name: 'name',
        type: 'text',
        required: true,
        initialValue: mapAsset.name,
      },
      {
        name: 'description',
        type: 'textarea',
        initialValue: mapAsset.description,
      },
      {
        name: 'type',
        type: 'select',
        required: true,
        options: mapAssetTypeOptions,
        initialValue: mapAsset.map.type,
      },
      {
        name: 'points',
        type: 'map-creator',
        required: true,
        mapCreator: {
          options: {
            single: mapAssetTypeToMode(mapAsset.map.type) === 'point',
          },
        },
        initialValue: mapAssetToPoints(mapAsset),
      },
    ],
    error,
    loading,
    onSubmit,
    onCancel,
    onTouched,
  };

  return <FormBuilder {...form} />;
};
