import React from 'react';
import { type I18nItem } from '@mobble/i18n';
import { Color, colorToHex } from '@mobble/colors';
import { Box } from '@src/stories/Components/Layout/Box';
import { Spacer } from '@src/stories/Components/Layout/Spacer';
import { HStack } from '@src/stories/Components/Layout/Stack';
import { Text } from '@src/stories/Components/UI/Text';
import { Circle } from '@src/stories/Components/UI/Circle';
import styles from './pill.scss';
import { Spinner } from './Spinner';

export interface PillProps {
  size?: 'normal';
  intent?: 'primary' | 'destructive';
  elevation?: 'none' | 'low' | 'high';
  count?: number;
  label?: I18nItem;
  loading?: boolean;
}

const colorScheme = {
  primary: {
    backgroundColor: Color.Green,
    color: Color.White,
  },
  destructive: {
    backgroundColor: Color.Red,
    color: Color.White,
  },
};

export const Pill: React.FC<PillProps> = ({
  size = 'normal',
  intent = 'primary',
  elevation = 'none',
  count,
  label,
  loading,
}) => {
  const cs = colorScheme[intent];

  const className = [
    styles.pill,
    styles[`size-${size}`],
    styles[`elevation-${elevation}`],
  ]
    .filter(Boolean)
    .join(' ');

  return (
    <Box
      className={className}
      style={{ backgroundColor: colorToHex(cs.backgroundColor) }}
    >
      <HStack alignment="center">
        <Spacer x={2} />
        <Text color={cs.color} variant="body" i18n={label} />

        {loading && (
          <>
            <Spacer x={1} />
            <Spinner />
          </>
        )}

        {!loading && count ? (
          <>
            <Spacer x={1} />
            <Circle size="small" fill={cs.color}>
              <Text color={cs.backgroundColor}>{count}</Text>
            </Circle>
            <Spacer x={0.5} />
          </>
        ) : (
          <Spacer x={2} />
        )}
      </HStack>
    </Box>
  );
};
