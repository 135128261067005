import React from 'react';
import { useI18n } from '@mobble/i18n';
import { INVENTORY_TYPE } from '@mobble/models/src/model/Inventory';
import { type InventoryItem } from '@mobble/models/src/model/InventoryItem';

import { useInventory } from '@mobble/store/src/hooks/inventories';
import { useInventoryItem } from '@mobble/store/src/hooks/inventoryItems';
import { useExtStatus } from '@mobble/store/src/hooks/root';

import { useLinking } from '@src/hooks/useLinking';
import { useNavigateBack } from '@src/hooks/useNavigateBack';
import { toPath } from '@src/interfaces/Routing';

import { DeleteForm } from '@src/stories/Views/Form/DeleteForm/DeleteForm';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';
import { InventoryItemEditForm } from '@src/stories/Views/InventoryItem/InventoryItemEditForm';
import { EntitySliceFactoryPrelude } from '@src/stories/Views/Misc/EntitySliceFactoryPrelude';

import * as ROUTE_NAME from '../config/routeNames';
import { type ScreenRendererProps } from '../config/types';

export const InventoryItemEditHeader: React.FC<ScreenRendererProps> = (
  props
) => {
  const { formatMessage } = useI18n();
  const goBack = useNavigateBack();
  const { inventoryId = '', inventoryItemId = '' } = props.route.params;
  const inventory = useInventory(inventoryId);
  const inventoryItem = useInventoryItem(inventoryItemId, inventoryId);

  return (
    <ScreenHeader
      title={formatMessage(
        {
          defaultMessage: 'Edit "{INVENTORY_ITEM_NAME}"',
          description: 'screen.title.inventory_item_edit',
        },
        {
          INVENTORY_ITEM_NAME: inventoryItem.entity?.name,
        }
      )}
      breadcrumbs={[
        {
          title: formatMessage({
            description: 'screen.title.inventories',
            defaultMessage: 'Inventories',
          }),
          href: ROUTE_NAME.INVENTORIES_LIST,
        },
        {
          title: { key: inventory.entity?.name },
          href: toPath(ROUTE_NAME.INVENTORY_ITEMS, {
            inventoryId,
          }),
        },
      ]}
      onGoBack={goBack}
    />
  );
};

export const InventoryItemEdit: React.FC<ScreenRendererProps> = (props) => {
  const { inventoryItemId = '', inventoryId = '' } = props.route.params;
  const { translate } = useI18n();
  const inventoryItem = useInventoryItem(inventoryItemId, inventoryId);
  const inventory = useInventory(inventoryItem?.entity?.inventoryId || '');
  const linkTo = useLinking();
  const goBack = () =>
    linkTo(ROUTE_NAME.INVENTORY_ITEM_DETAIL, {
      inventoryId,
      inventoryItemId,
    });
  const inventoryType =
    inventory.entity?.type === 'animalHealth'
      ? INVENTORY_TYPE.ANIMAL_HEALTH
      : INVENTORY_TYPE.HERBICIDE_PESTICIDE;

  const extStatus = useExtStatus('inventoryItems', 'update', inventoryItemId);
  const formLoading = extStatus?.loading;
  const extStatusDelete = useExtStatus(
    'inventoryItems',
    'delete',
    inventoryItemId
  );
  const deleteLoading = extStatusDelete?.loading;
  const formError =
    extStatus?.error || extStatusDelete?.error
      ? translate({
          key: 'generic.form.error',
          params: {
            '%ENTITY': translate({
              key: 'screen.title.edit_x',
              params: { '%TITLE': `${inventory.entity?.name}` },
            }),
          },
        })
      : '';

  const Prelude = EntitySliceFactoryPrelude({
    preludes: [inventoryItem.prelude, inventory.prelude],
    required: [inventoryItem.entity, inventory.entity],
  });

  if (Prelude) {
    return Prelude;
  }

  const handleSubmit = (updatedInventoryItem: InventoryItem) => {
    inventoryItem.update(updatedInventoryItem).then(() => {
      inventoryItem.refresh().then(goBack);
    });
  };

  const handleDelete = () => {
    inventoryItem.remove().then(() => {
      const inventoryId = inventoryItem.entity?.inventoryId;
      linkTo(ROUTE_NAME.INVENTORY_ITEMS, { inventoryId });
    });
  };

  return (
    <>
      <InventoryItemEditForm
        inventoryItem={inventoryItem.entity}
        inventoryType={inventoryType}
        loading={formLoading}
        error={formError}
        onCancel={goBack}
        onSubmit={handleSubmit}
        onTouched={() => {}}
      />

      <DeleteForm
        i18nRootKey="inventory_item.delete"
        name={inventoryItem.entity.name}
        loading={deleteLoading}
        onDelete={handleDelete}
      />
    </>
  );
};

export default {
  name: ROUTE_NAME.INVENTORY_ITEM_EDIT,
  header: InventoryItemEditHeader,
  component: InventoryItemEdit,
};
