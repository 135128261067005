import React from 'react';
import { useAppLayoutContext } from './AppLayout';
import styles from './bottomTabBarSpacer.scss';

export const BottomTabBarSpacer: React.FC = () => {
  const { style } = useAppLayoutContext();
  if (style.modal) {
    return null;
  }

  return <div className={styles.bottomTabBarSpacer} />;
};
