import React from 'react';
import { useI18n } from '@mobble/i18n';
import { ScreenRendererProps } from '../config/types';
import * as ROUTE_NAME from '../config/routeNames';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';
import { NavigationList } from '@src/stories/Components/UX/NavigationList';
import { NavigationListItemProps } from '@src/stories/Components/UX/NavigationListItem';
import { UserRole } from '@mobble/models/src/model/User';
import { toPath } from '@src/interfaces/Routing';
import { Box } from '@src/stories/Components/Layout/Box';
import { Text } from '@src/stories/Components/UI/Text';
import { Spacer } from '@src/stories/Components/Layout/Spacer';
import { useLinking } from '@src/hooks/useLinking';

export const SettingsPropertyMobbleConnectUsersHeader: React.FC<
  ScreenRendererProps
> = () => {
  const linkTo = useLinking();
  const { formatMessage } = useI18n();

  return (
    <ScreenHeader
      title={formatMessage({
        description: 'screen.title.mobble_connect',
        defaultMessage: 'Mobble Connect',
      })}
      breadcrumbs={[
        {
          title: formatMessage({
            description: 'screen.title.settings',
            defaultMessage: 'Settings',
          }),
          href: ROUTE_NAME.SETTINGS_LIST,
        },
      ]}
      onGoBack={() => {
        linkTo(ROUTE_NAME.SETTINGS_LIST);
      }}
    />
  );
};

export const SettingsPropertyMobbleConnectUsers: React.FC<
  ScreenRendererProps
> = () => {
  const { formatMessage } = useI18n();

  const items: NavigationListItemProps[] = [
    {
      label: formatMessage({
        description: 'settings.toc.accountant',
        defaultMessage: 'Accountant',
      }),
      href: toPath(ROUTE_NAME.MOBBLE_CONNECT_USER, {
        role: UserRole.Accountant,
      }),
    },
    {
      label: formatMessage({
        description: 'settings.toc.map_view',
        defaultMessage: 'Contractor',
      }),
      href: toPath(ROUTE_NAME.MOBBLE_CONNECT_USER, {
        role: UserRole.Contractor,
      }),
    },
    {
      label: formatMessage({
        description: 'settings.toc.farm_advisor',
        defaultMessage: 'Farm Advisor',
      }),
      href: toPath(ROUTE_NAME.MOBBLE_CONNECT_USER, {
        role: UserRole.FarmAdvisor,
      }),
    },
    {
      label: formatMessage({
        description: 'settings.toc.stock_agent',
        defaultMessage: 'Stock Agent',
      }),
      href: toPath(ROUTE_NAME.MOBBLE_CONNECT_USER, {
        role: UserRole.StockAgent,
      }),
    },
    {
      label: formatMessage({
        description: 'settings.toc.auditor',
        defaultMessage: 'Auditor',
      }),
      href: toPath(ROUTE_NAME.MOBBLE_CONNECT_USER, {
        role: UserRole.Auditor,
      }),
    },
  ];

  return (
    <>
      <Box mobileSpacing={{ bottom: 2 }} />
      <Box spacing={{ bottom: 2, right: 0, left: 0 }}>
        <Box mobileSpacing={{ left: 2, right: 2 }}>
          <Text
            tagName="h2"
            variant="larger"
            bold
            i18n={formatMessage({
              description: 'settings.mobble_connect.message.title',
              defaultMessage: 'What is Mobble Connect?',
            })}
          />
          <Spacer y={2} />
          <Text
            wrap
            tagName="p"
            i18n={formatMessage({
              description: 'settings.mobble_connect.message.description.1',
              defaultMessage:
                'Mobble Connect, our third-party access feature, empowers farmers to securely share their data with essential partners: Farm Advisors, Livestock Agents, Accountants, and Contractors.',
            })}
          />
          <Spacer y={1} />
          <Text
            wrap
            tagName="p"
            i18n={formatMessage({
              description: 'settings.mobble_connect.message.description.2',
              defaultMessage:
                'We champion open communication. Our goal? To enhance collaboration between farmers and their trusted service providers, paving the way for heightened productivity and connection.',
            })}
          />
          <Spacer y={1} />
          <Text
            wrap
            tagName="p"
            i18n={formatMessage({
              description: 'settings.mobble_connect.message.description.3',
              defaultMessage:
                'Remember: Your data remains YOURS. Share it selectively with those you trust the most. Third parties are granted access only to relevant data and can only undertake authorized actions. Dive deeper below to understand the full capabilities of third-party access. Explore Mobble Connect now and elevate your farming collaboration!',
            })}
          />
        </Box>
      </Box>
      <NavigationList items={items} />
    </>
  );
};

export default {
  name: ROUTE_NAME.MOBBLE_CONNECT_USERS,
  header: SettingsPropertyMobbleConnectUsersHeader,
  component: SettingsPropertyMobbleConnectUsers,
};
