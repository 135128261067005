export enum MapStyle {
  Satellite = 'satellite',
  SatelliteMapTiler = 'satellite-maptiler',
  SatelliteHere = 'satellite-here',
  Outdoor = 'outdoor',
  Street = 'street',
  Light = 'light',
}

export const mapStyleToString = (mapStyle: MapStyle) => {
  switch (mapStyle) {
    case MapStyle.Satellite:
      return 'satellite';
    case MapStyle.SatelliteMapTiler:
      return 'satellite-maptiler';
    case MapStyle.SatelliteHere:
      return 'satellite-here';
    case MapStyle.Outdoor:
      return 'outdoor';
    case MapStyle.Street:
      return 'street';
    case MapStyle.Light:
      return 'light';
  }
};

export const mapStyleIsSatellite = (mapStyle: MapStyle) =>
  mapStyle === MapStyle.Satellite ||
  mapStyle === MapStyle.SatelliteMapTiler ||
  mapStyle === MapStyle.SatelliteHere;
