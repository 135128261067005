import React from 'react';
import { useI18n } from '@mobble/i18n';
import { Spinner } from '@src/stories/Components/UI/Spinner';
import { Color } from '@mobble/colors';

import { VStack } from '@src/stories/Components/Layout/Stack';
import {
  type InfoTileProps,
  InfoTiles,
} from '@src/stories/Components/UI/InfoTile';
import { combineTotals, RootItem } from './usePropertyReportForRange';
import { Box } from '@src/stories/Components/Layout/Box';

export interface LivestockReconciliationTilesProps {
  reportData: RootItem[] | null;
}

export const LivestockReconciliationTiles: React.FC<
  LivestockReconciliationTilesProps
> = ({ reportData }) => {
  const { formatMessage } = useI18n();

  if (!reportData) {
    return (
      <VStack alignment="center">
        <Box spacing={4}>
          <Spinner color={Color.Black} />
        </Box>
      </VStack>
    );
  }

  const totals = reportData.reduce((a, b) => combineTotals(a, b.totals), {
    startingHead: 0,
    closingHead: 0,
    naturalIncrease: 0,
    purchased: 0,
    casualties: 0,
    sold: 0,
  });

  const tiles: InfoTileProps[] = [
    {
      id: 'totals',
      label: formatMessage({
        defaultMessage: 'Livestock totals',
        description: 'reports.tile.livestock-reconciliation.totals.title',
      }),
      value: totals.closingHead,
      previousValue: totals.startingHead,
    },
    {
      id: 'sold',
      label: formatMessage({
        defaultMessage: 'Livestock sold',
        description: 'reports.tile.livestock-reconciliation.totals.title',
      }),
      value: totals.sold,
    },
    {
      id: 'casualties',
      label: formatMessage({
        defaultMessage: 'Livestock casualties',
        description: 'reports.tile.livestock-reconciliation.casualties.title',
      }),
      value: totals.casualties,
    },
    {
      id: 'purchased',
      label: formatMessage({
        defaultMessage: 'Livestock purchased',
        description: 'reports.tile.livestock-reconciliation.purchased.title',
      }),
      value: totals.purchased,
    },
    {
      id: 'natural-increase',
      label: formatMessage({
        defaultMessage: 'Livestock natural increasees',
        description:
          'reports.tile.livestock-reconciliation.natural-increase.title',
      }),
      value: totals.naturalIncrease,
    },
  ];

  return <InfoTiles items={tiles} />;
};
