import React from 'react';
import { useI18n } from '@mobble/i18n';
import { AppliedInventoryItem } from '@mobble/models/src/model/AppliedInventoryItem';
import { findMob, type Mob } from '@mobble/models/src/model/Mob';
import { MobAction } from '@mobble/models/src/model/MobAction';
import { paddockForMob } from '@mobble/models/src/model/Paddock';
import {
  ConfiguredPropertyTypeGroup,
  getOptionsFromTypesByGroup,
} from '@mobble/models/src/model/Property';
import { toISO8601 } from '@mobble/shared/src/core/Date';
import { base64decode } from '@mobble/shared/src/core/String';
import { useEntitiesRefresher } from '@mobble/shared/src/hooks/useEntitiesRefresher';
import {
  useInventories,
  useInventoryItems,
  useGetUser,
  useInventoryItemBatches,
  useMobAction,
  useMobs,
  usePaddocks,
  useProperties,
  useExtStatus,
  useRootDispatch,
} from '@mobble/store/src/hooks';
import { thunkGetAll as thunkGetInventoryBatches } from '@mobble/store/src/reducers/inventoryItemBatches';

import { useDialog } from '@src/hooks/useDialog';
import { useLinking } from '@src/hooks/useLinking';
import { useNavigateBack } from '@src/hooks/useNavigateBack';

import { EntitySliceFactoryPrelude } from '@src/stories/Views/Misc/EntitySliceFactoryPrelude';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';
import {
  MobActionCreateForm,
  type MobActionCreateFormValues,
} from '@src/stories/Views/MobAction/MobActionCreateForm';

import * as ROUTE_NAME from '../config/routeNames';
import { type ScreenRendererProps } from '../config/types';

export const MobActionCreateHeader: React.FC<ScreenRendererProps> = () => {
  const { formatMessage } = useI18n();
  const goBack = useNavigateBack();

  return (
    <ScreenHeader
      modal
      title={formatMessage({
        description: 'screen.title.mob_action',
        defaultMessage: 'Mob action',
      })}
      breadcrumbs={[
        {
          title: formatMessage({
            defaultMessage: 'Mobs',
            description: 'screen.title.mobs',
          }),
          href: ROUTE_NAME.MOBS_LIST,
        },
      ]}
      onClose={goBack}
    />
  );
};

export const MobActionCreate: React.FC<ScreenRendererProps> = (props) => {
  const dispatch = useRootDispatch();
  const { alert, close } = useDialog();
  const { formatMessage } = useI18n();
  const linkTo = useLinking();
  const goBack = useNavigateBack();

  const mobIds = props.route.params?.mobIds
    ? JSON.parse(base64decode(props.route.params.mobIds))
    : [];
  const properties = useProperties();
  const propertyId = properties.selected?.id;
  const user = useGetUser();

  const mobs = useMobs(propertyId);
  const paddocks = usePaddocks(propertyId);
  const inventories = useInventories(propertyId);
  const inventoryItems = useInventoryItems();
  const inventoryItemBatches = useInventoryItemBatches('_', propertyId);
  const { create } = useMobAction('_', propertyId);

  const extStatus = useExtStatus('mobActions', 'create', propertyId);
  const formLoading = extStatus?.loading;
  const formError = extStatus?.error
    ? formatMessage(
        {
          description: 'generic.form.error',
          defaultMessage: 'There was an error processing your request',
        },
        {
          ENTITY: formatMessage({
            defaultMessage: 'screen.title.mob_action',
          }),
        }
      )
    : '';

  useEntitiesRefresher(
    [mobs, paddocks, inventories, inventoryItemBatches],
    propertyId
  );

  const Prelude = EntitySliceFactoryPrelude({
    preludes: [inventories.prelude, properties.prelude, mobs.prelude],
    required: [properties.selected],
  });

  if (Prelude) {
    return Prelude;
  }

  const handleSubmit = (formValues: MobActionCreateFormValues) => {
    if (!user || !properties.selected) {
      return;
    }

    const usedBatches: AppliedInventoryItem[] = formValues?.inventory_items
      ? JSON.parse(formValues?.inventory_items).map(
          (item: AppliedInventoryItem) =>
            inventoryItemBatches.allEntitiesAvailable?.find(
              (e) => e?.id === item?.batchId
            )
        )
      : [];

    const count =
      formValues?.mobIds?.length === 1
        ? formValues?.count
          ? Number(formValues?.count)
          : findMob(mobs?.entities)(formValues?.mobIds[0])?.size
        : undefined;

    const newMobAction: Omit<MobAction, 'id'> = {
      propertyId: properties.selected.id,
      date: toISO8601(formValues.date),
      createdBy: user,
      type: formValues.action_type,
      title: '',
      description: '',
      note: formValues.note,
      mobIds: formValues.mobIds,
      mobs: formValues.mobIds
        .map((mobId) => {
          const mob = findMob(mobs.entities)(mobId);
          const paddock = paddockForMob(paddocks.entities)(mobId);

          return {
            ...mob,
            snapshot: paddock
              ? {
                  paddockName: paddock.name,
                }
              : undefined,
          };
        })
        .filter(Boolean) as Mob[],
      count,
      appliedInventoryItems: formValues.inventory_items
        ? JSON.parse(formValues.inventory_items)
        : [],
    };

    create(newMobAction)
      .then((mobActionRes) => {
        // TODO: replace with Toast/Alert
        console.log(`Mob Action '${mobActionRes.type}' successfully created`);

        // Fetch updated batch data for all applied chemicals
        mobActionRes.appliedInventoryItems.forEach((item) => {
          dispatch(
            thunkGetInventoryBatches({
              parentId: propertyId,
              extFilter: {
                chemicalIds: [item.inventoryItemId],
              },
            })
          );
        });

        if (mobActionRes.appliedInventoryItems.length) {
          inventories.refresh();
        }

        const finishedBatches = mobActionRes?.appliedInventoryItems?.filter(
          (item) => {
            const batch = usedBatches?.find((e) => e?.id === item?.batchId);

            return batch
              ? batch?.quantity?.value > 0 &&
                  batch?.quantity?.value -
                    item?.quantity?.value * (count ?? 1) <=
                    0
              : false;
          }
        );

        if (finishedBatches.length > 0) {
          alert(
            formatMessage({
              description: 'batches_finished.alert.title',
              defaultMessage: 'A batch has been used up',
            }),
            formatMessage({
              description: 'batches_finished.alert.message',
              defaultMessage:
                'One of your batches has been used up. You can mark it as finished on the batch detail page.',
            }),
            [
              {
                label: formatMessage({
                  description: 'batches_finished.alert.cancel',
                  defaultMessage: 'Close',
                }),
                outline: true,
                intent: 'secondary',
                onClick: () => {
                  close();
                  goBack();
                },
              },
              {
                label: formatMessage({
                  description: 'batches_finished.alert.confirm',
                  defaultMessage: 'View batch',
                }),
                onClick: () => {
                  const finishedBatch = finishedBatches[0];
                  const inventoryId = inventoryItems.allEntitiesAvailable.find(
                    (i) => i.id === finishedBatch.inventoryItemId
                  ).inventoryId;

                  close();
                  linkTo(ROUTE_NAME.INVENTORY_ITEM_BATCH_DETAIL, {
                    inventoryId,
                    inventoryItemId: finishedBatch.inventoryItemId,
                    inventoryItemBatchId: finishedBatch.batchId,
                  });
                },
              },
            ]
          );
        } else {
          goBack();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <MobActionCreateForm
      propertyTypes={properties.selected?.types || []}
      initialValues={{
        mobIds,
      }}
      mobActions={getOptionsFromTypesByGroup(properties.selected?.types || [])(
        ConfiguredPropertyTypeGroup.mobAction
      )}
      onAddMobAction={(label) => {
        if (!properties.selected) {
          return Promise.reject();
        }
        return properties.addPropertyType({
          group: ConfiguredPropertyTypeGroup.mobAction,
          label,
        });
      }}
      mobs={mobs.entities}
      paddocks={paddocks.entities}
      error={formError}
      loading={formLoading}
      onCancel={goBack}
      onTouched={() => {}}
      onSubmit={handleSubmit}
    />
  );
};

export default {
  name: ROUTE_NAME.MODAL_MOB_ACTION_CREATE,
  header: MobActionCreateHeader,
  component: MobActionCreate,
  modal: true,
};
