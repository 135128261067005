import React from 'react';
import { useProperties } from '@mobble/store/src/hooks/properties';
import {
  EntitiesViewer,
  type EntitiesViewerProps,
} from '@src/stories/Views/Entities/EntitiesViewer';
import { type Provider } from '../../Entities/types';
import { Box } from '@src/stories/Components/Layout/Box';
import { Casualty, filterCasualties } from '@mobble/models/src/model/Casualty';
import { makeCasualtiesTableColumns } from './casualtiesTableColumns';
import { usePaddocks } from '@mobble/store/src/hooks/paddocks';
import { useMobs } from '@mobble/store/src/hooks/mobs';
import { CasualtyCard } from '../CasualtyCard';
import { paddockForMob } from '@mobble/models/src/model/Paddock';
import { useLinking } from '@src/hooks/useLinking';
import * as ROUTE_NAME from '../../../../screens/config/routeNames';
import { useCasualtiesFilterItems } from '@src/hooks/useCasualtiesFilterItems';

export interface CasualtiesViewerProps {
  title?: string;
  showTitle?: boolean;
  provider: Provider<Casualty>;
  renderEntityCard?: (casualty: Casualty) => React.ReactNode;
}

export const CasualtiesViewer: React.FC<CasualtiesViewerProps> = ({
  title,
  showTitle,
  provider,
  renderEntityCard,
}) => {
  const properties = useProperties();
  const paddocks = usePaddocks(properties.selected?.id);
  const mobs = useMobs(properties.selected?.id);
  const linkTo = useLinking();
  const filterItems = useCasualtiesFilterItems();

  const onClick = (casualty: Casualty) => {
    linkTo(ROUTE_NAME.CASUALTY_DETAIL, { casualtyId: casualty.id });
  };

  const baseViewerProps: EntitiesViewerProps<Casualty> = {
    provider,
    applyFilter: filterCasualties,
    title,
    showTitle,
    onEmpty: { key: 'casualties.table.empty' },
    onRefresh: undefined,
    onClickTableRow: onClick,
    renderEntityCard:
      renderEntityCard ??
      ((casualty) => (
        <Box spacing={1}>
          <CasualtyCard
            casualty={casualty}
            paddock={paddockForMob(paddocks.entities)(casualty.id)}
            onClick={onClick}
          />
        </Box>
      )),
    tableColumns: makeCasualtiesTableColumns({
      paddocks: paddocks.entities,
      mobs: mobs.entities,
      propertyTypes: properties.selected.types,
    }),
  };

  if (Array.isArray(provider)) {
    return (
      <EntitiesViewer key={properties.selected?.id} {...baseViewerProps} />
    );
  }

  const extendedViewerProps: EntitiesViewerProps<Casualty> = {
    ...baseViewerProps,
    filterItems,
    onEmpty: { key: 'casualties.table.empty' },
    onRefresh: () => provider.refresh(),
  };

  return (
    <EntitiesViewer key={properties.selected?.id} {...extendedViewerProps} />
  );
};
