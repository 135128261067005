import React from 'react';
import { useI18n } from '@mobble/i18n';
import { useMobs, useProperties } from '@mobble/store/src/hooks';

import { useLinking } from '@src/hooks/useLinking';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';
import { EntitySliceFactoryPrelude } from '@src/stories/Views/Misc/EntitySliceFactoryPrelude';
import { MobsViewer } from '@src/stories/Views/Mob/List/MobsViewer';

import * as ROUTE_NAME from '../config/routeNames';
import { type ScreenRendererProps } from '../config/types';

export const MobsHeader: React.FC<ScreenRendererProps> = (props) => {
  const { formatMessage } = useI18n();
  const linkTo = useLinking();

  return (
    <ScreenHeader
      title={formatMessage({
        defaultMessage: 'Mobs',
        description: 'screen.title.mobs',
      })}
      actions={[
        {
          icon: 'activity',
          href: () => {
            linkTo(ROUTE_NAME.SUMMARY_EVENTS_LIST);
          },
        },
      ]}
      onToggleDrawer={props.drawer.toggle}
    />
  );
};

export const Mobs: React.FC = () => {
  const properties = useProperties();
  const mobs = useMobs(properties.selected?.id);

  const Prelude = EntitySliceFactoryPrelude({
    preludes: [properties.prelude, mobs.prelude],
    required: [properties.selected],
  });

  if (Prelude) {
    return Prelude;
  }

  return <MobsViewer provider={mobs} />;
};

export default {
  name: ROUTE_NAME.MOBS_LIST,
  header: MobsHeader,
  component: Mobs,
};
