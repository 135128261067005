import {
  LineString,
  type Point,
  type Polygon,
} from '@mobble/models/src/model/MapGeometry';
import { type Color } from '@mobble/colors';
import {
  MapAssetType,
  mapAssetTypeToRelevantMapDetail,
} from '@mobble/models/src/model/MapAsset';
import { MAP_DETAILS, MapDetail } from '@mobble/models/src/model/MapDetail';

export const MAP_ITEM_PADDOCK_BOUNDARY = 'map_item_paddock_boundary';
export const MAP_ITEM_PADDOCK_LABEL = 'map_item_paddock_label';
export const MAP_ITEM_PADDOCK_GROUP = 'map_item_paddock_group';
export const MAP_ITEM_MOBS = 'map_item_mobs';
export const MAP_ITEM_TASK = 'map_item_task';
export const MAP_ITEM_MAP_ASSET = 'map_item_map_asset';

export interface MapItemPaddockBoundary {
  type: typeof MAP_ITEM_PADDOCK_BOUNDARY;
  id: string;
  visible?: boolean;
  polygon: Polygon;
  color: Color | string;
  onClick?: () => void;
  meta: {
    isSafe: boolean;
  };
}

export interface MapItemPaddockLabel {
  type: typeof MAP_ITEM_PADDOCK_LABEL;
  id: string;
  visible?: boolean;
  polygon: Polygon;
  point: Point;
  meta: {
    title: string;
    size?: string;
  };
}

export interface MapItemPaddockGroup {
  type: typeof MAP_ITEM_PADDOCK_GROUP;
  id: string;
  visible?: boolean;
  polygon: Polygon;
}

export interface MapItemMobs {
  type: typeof MAP_ITEM_MOBS;
  id: string;
  visible?: boolean;
  point: Point;
  onClick?: () => void;
  meta: {
    ageColors: string[];
    isSafe: boolean;
    mobTypes: string[];
    amountOfMobs: number;
    size: number;
  };
}

export interface MapItemTask {
  type: typeof MAP_ITEM_TASK;
  ownedByUser: boolean;
  id: string;
  visible?: boolean;
  point: Point;
  onClick?: () => void;
}

export interface MapItemMapAsset {
  type: typeof MAP_ITEM_MAP_ASSET;
  id: string;
  name: string;
  mapAssetType: MapAssetType;
  visible?: boolean;
  geometry: Point | LineString;
  onClick?: () => void;
}

export type MapItem =
  | MapItemPaddockBoundary
  | MapItemPaddockLabel
  | MapItemPaddockGroup
  | MapItemMobs
  | MapItemTask
  | MapItemMapAsset;

export const mapItemIsIncludedInMapDetails =
  (mapDetails: MapDetail[]) => (mapItem: MapItem) => {
    switch (mapItem.type) {
      case MAP_ITEM_PADDOCK_BOUNDARY:
        return mapDetails.includes(MAP_DETAILS.PADDOCK_BOUNDARIES);
      case MAP_ITEM_PADDOCK_LABEL:
        return mapDetails.includes(MAP_DETAILS.PADDOCK_LABELS);
      case MAP_ITEM_PADDOCK_GROUP:
        return mapDetails.includes(MAP_DETAILS.PADDOCK_GROUPS);
      case MAP_ITEM_MOBS:
        return mapDetails.includes(MAP_DETAILS.MOBS);
      case MAP_ITEM_TASK: {
        if (mapItem.ownedByUser) {
          return mapDetails.includes(MAP_DETAILS.TASKS__USER);
        }
        return mapDetails.includes(MAP_DETAILS.TASKS__OTHERS);
      }
      case MAP_ITEM_MAP_ASSET:
        return mapDetails.includes(
          mapAssetTypeToRelevantMapDetail(mapItem.mapAssetType)
        );
    }
  };
