import React from 'react';
import styles from './modal.scss';
import { Portal } from '../Layout/Portal';
import { useAppLayoutContext } from '../Layout/AppLayout';
import { Color, colorToHex } from '@mobble/colors';

export interface ModalProps {
  children?: React.ReactNode;
  show?: boolean;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  onClose?: () => void;
  actAsDropdown?: boolean;
  onTop?: boolean;
  className?: string;
  backgroundColor?: Color | string;
  shouldCloseOnEsc?: boolean;
}

export const Modal: React.FC<ModalProps> = ({
  header,
  footer,
  children,
  onClose,
  show,
  actAsDropdown,
  className,
  backgroundColor,
  onTop,
  shouldCloseOnEsc = true,
}) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [isInOtherModal, setIsInOtherModal] = React.useState(false);
  // @TODO this should not be here
  const appLayout = useAppLayoutContext();

  const wrapperClassnames = [
    styles.wrapper,
    show ? styles.show : false,
    show ? styles.showRaw : false,
    onTop ? styles.onTop : false,
    actAsDropdown && !isInOtherModal ? styles.dropdown : false,
    appLayout.style.modal ? styles.noBottomPadding : false,
  ]
    .filter(Boolean)
    .join(' ');

  React.useEffect(() => {
    if (show) {
      ref.current?.focus({
        preventScroll: true,
      });
    }
  }, [show]);

  const handleKeyUp = (e: React.KeyboardEvent) => {
    if (e.key === 'Escape' && shouldCloseOnEsc) {
      onClose && onClose();
    }
  };

  const modal = (
    <div
      ref={ref}
      tabIndex={0}
      className={wrapperClassnames}
      onKeyUp={handleKeyUp}
    >
      <div className={styles.backdrop} onClick={() => onClose && onClose()} />
      <div className={[styles.modal, className].filter(Boolean).join(' ')}>
        {header ? <div className={styles.modalHeader}>{header}</div> : null}
        <div
          tabIndex={-1}
          className={styles.modalContent}
          style={
            backgroundColor
              ? { backgroundColor: colorToHex(backgroundColor) }
              : {}
          }
        >
          {children}
        </div>
        {footer ? <div className={styles.modalFooter}>{footer}</div> : null}
      </div>
    </div>
  );

  return actAsDropdown ? modal : <Portal>{modal}</Portal>;
};
