import React, { Fragment } from 'react';
import { defineMessages, FormattedDate } from 'react-intl';
import { Color } from '@mobble/colors';
import { useI18n, useMessages } from '@mobble/i18n';
import { User, UserRole } from '@mobble/models';

import { Box } from '@src/stories/Components/Layout/Box';
import { Divider } from '@src/stories/Components/Layout/Divider';
import { Spacer } from '@src/stories/Components/Layout/Spacer';
import { HStack, VStack } from '@src/stories/Components/Layout/Stack';
import { Avatar } from '@src/stories/Components/UI/Avatar';
import { Text } from '@src/stories/Components/UI/Text';
import { Button } from '@src/stories/Components/UX/Button';
import { Icon } from '@src/stories/Components/UI/Icon';
import { Clickable } from '@src/stories/Components/UX/Clickable';

import styles from './settingsPropertyMobbleConnectUserView.scss';

const messages = defineMessages({
  'farm_advisor.capabilities.1': {
    description: 'settings.property.mobble_connect.farm_advisor.capabilities.1',
    defaultMessage: 'Access to entire property',
  },
  'farm_advisor.capabilities.2': {
    description: 'settings.property.mobble_connect.farm_advisor.capabilities.2',
    defaultMessage: "Can't make edits (outside of tasks)",
  },
  'farm_advisor.capabilities.3': {
    description: 'settings.property.mobble_connect.farm_advisor.capabilities.3',
    defaultMessage: 'Can add tasks/recommendations for the farm',
  },
  'farm_advisor.capabilities.4': {
    description: 'settings.property.mobble_connect.farm_advisor.capabilities.4',
    defaultMessage: 'Can use Property Chat',
  },
  'map_view.capabilities.1': {
    description: 'settings.property.mobble_connect.map_view.capabilities.1',
    defaultMessage: 'Access to map only',
  },
  'map_view.capabilities.2': {
    description: 'settings.property.mobble_connect.map_view.capabilities.2',
    defaultMessage: "Can't make edits",
  },
  'map_view.capabilities.3': {
    description: 'settings.property.mobble_connect.map_view.capabilities.3',
    defaultMessage: 'Can view tasks assigned to them',
  },
  'map_view.capabilities.4': {
    description: 'settings.property.mobble_connect.map_view.capabilities.4',
    defaultMessage: 'Can use Property Chat',
  },
  'map_view.capabilities.5': {
    description: 'settings.property.mobble_connect.map_view.capabilities.5',
    defaultMessage: 'Role will only have access for a maximum of 30 days',
  },
  'stock_agent.capabilities.1': {
    description: 'settings.property.mobble_connect.stock_agent.capabilities.1',
    defaultMessage: 'Access to sales, purchases, livestock totals and mobs',
  },
  'stock_agent.capabilities.2': {
    description: 'settings.property.mobble_connect.stock_agent.capabilities.2',
    defaultMessage: 'Can add/edit sales and purchases',
  },
  'stock_agent.capabilities.3': {
    description: 'settings.property.mobble_connect.stock_agent.capabilities.3',
    defaultMessage: "Can't make edits (outside of sales and purchases)",
  },
  'stock_agent.capabilities.4': {
    description: 'settings.property.mobble_connect.stock_agent.capabilities.4',
    defaultMessage: 'Can use Property Chat',
  },
  'accountant.capabilities.1': {
    description: 'settings.property.mobble_connect.accountant.capabilities.1',
    defaultMessage:
      'Access to sales, purchases, livestock totals, natural increases, casualties and mobs',
  },
  'accountant.capabilities.2': {
    description: 'settings.property.mobble_connect.accountant.capabilities.2',
    defaultMessage: "Can't make edits",
  },
  'accountant.capabilities.3': {
    description: 'settings.property.mobble_connect.accountant.capabilities.3',
    defaultMessage: 'Can use Property Chat',
  },
  'auditor.capabilities.1': {
    description: 'settings.property.mobble_connect.auditor.capabilities.1',
    defaultMessage:
      'Access to mobs, paddocks, map, inventories, summaries, reports and tasks',
  },
  'auditor.capabilities.2': {
    description: 'settings.property.mobble_connect.auditor.capabilities.2',
    defaultMessage: "Can't make edits",
  },
  'auditor.capabilities.3': {
    description: 'settings.property.mobble_connect.auditor.capabilities.3',
    defaultMessage: 'Role will only have access for a maximum of 30 days',
  },
});

export const UserCard = ({
  user,
  onEditButtonClicked,
}: {
  user: User;
  onEditButtonClicked?: () => void;
}) => {
  const { formatMessage } = useI18n();

  return (
    <Box className={styles.UserCard}>
      <HStack>
        <HStack alignment="center">
          <Avatar name={user.name} />
          <Box spacing={2}>
            <VStack>
              <Text variant="larger" color={Color.DarkGrey} bold>
                {user.name}
              </Text>
              <Text variant="small" color={Color.Grey}>
                {user.email}
              </Text>
              {user.expires ? (
                <Text variant="small" color={Color.Grey}>
                  {formatMessage(
                    {
                      description:
                        'settings.property.mobble_connect.user.expires.label',
                      defaultMessage: 'End access date: {date}',
                    },
                    {
                      date: <FormattedDate value={user.expires} />,
                    }
                  )}
                </Text>
              ) : null}
            </VStack>
          </Box>
        </HStack>
        {onEditButtonClicked ? (
          <Clickable href={onEditButtonClicked}>
            <Icon name="edit" color={Color.Black} size="normal" />
          </Clickable>
        ) : null}
      </HStack>
    </Box>
  );
};

export interface MobbleConnectViewProps {
  onEditButtonClicked: (id: string) => void;
  onInviteButtonClicked: () => void;
  role: UserRole;
  usersOfRole: {
    users: (User | null)[];
    role: UserRole;
    maxUsers: number;
  } | null;
}

export const MobbleConnectUserView: React.FC<MobbleConnectViewProps> = ({
  onEditButtonClicked,
  onInviteButtonClicked,
  role,
  usersOfRole,
}) => {
  const { formatMessage } = useI18n();
  const strings = useMessages(messages);
  const roleString = formatMessage({
    id: `user_role.${role}`,
    defaultMessage: [],
  });

  const onUserCardEditButtonClicked = (user: User) => () =>
    user.id ? onEditButtonClicked(user.id) : null;

  return (
    <Box className={styles.settingsPropertyMobbleConnectUserView}>
      {usersOfRole?.users?.map((user, index) => (
        <Fragment key={index}>
          {index === 0 || usersOfRole.users[index - 1] !== null ? (
            <>
              {index > 0 ? <Spacer y={1.5} /> : null}
              {user ? (
                <UserCard
                  user={user}
                  onEditButtonClicked={onUserCardEditButtonClicked(user)}
                />
              ) : (
                <HStack>
                  <Button
                    label={formatMessage(
                      {
                        description: 'settings.property.mobble_connect.invite',
                        defaultMessage: 'Invite {VALUE}',
                      },
                      {
                        VALUE: roleString,
                      }
                    )}
                    icon="message"
                    onClick={onInviteButtonClicked}
                  />
                </HStack>
              )}
            </>
          ) : null}
        </Fragment>
      ))}
      <Spacer y={4} />
      <Divider bold />
      <Spacer y={2} />
      <Text
        i18n={formatMessage(
          {
            description: 'settings.property.mobble_connect.capabilities',
            defaultMessage: '{VALUE} capabilities',
          },
          {
            VALUE: roleString,
          }
        )}
        color={Color.Green}
        variant="card-title"
        bold
        tagName="h2"
      />
      <ul>
        {Object.keys(strings)
          .filter((key) => key.startsWith(role))
          .map((key) => (
            <Text key={key} i18n={strings[key]} variant="body" tagName="li" />
          ))}
      </ul>
    </Box>
  );
};
