import React from 'react';
import { Text } from '@src/stories/Components/UI/Text';
import { Box } from '../Layout/Box';
import { Color } from '@mobble/colors/src';
import styles from './cardedNavigationList.scss';
import {
  CardedNavigationListItem,
  CardedNavigationListItemProps,
} from './CardedNavigationListItem';

export interface CardedNavigationListGroupProps {
  title: string;
  data: CardedNavigationListItemProps[];
}

export interface CardedNavigationListProps {
  items: CardedNavigationListGroupProps[];
}

export const CardedNavigationList: React.FC<CardedNavigationListProps> = ({
  items,
}) => {
  return (
    <Box>
      {items.map((item, index) => (
        <React.Fragment key={index}>
          <Box className={styles.cardsContainer}>
            <Text bold i18n={item.title} value="larger" color={Color.Grey} />
          </Box>

          <Box className={styles.cardNavigationListGrid}>
            {item.data.map((navItem, index) => (
              <CardedNavigationListItem
                key={index}
                title={navItem.title}
                subtitle={navItem.subtitle}
                icon={navItem.icon}
                disabled={navItem.disabled}
                href={navItem.href}
                isLastElement={index === item.data.length - 1}
              />
            ))}
          </Box>
        </React.Fragment>
      ))}
    </Box>
  );
};
