import React from 'react';
import { spacingToAbsolute } from '@src/stories/Components/Layout/Spacing';
import { Box } from '@src/stories/Components/Layout/Box';
import { HStack } from '@src/stories/Components/Layout/Stack';
import { Text } from '@src/stories/Components/UI/Text';
import { Badge, type BadgeProps } from '@src/stories/Components/UI/Badge';
import { type I18nItem } from '@mobble/i18n';
import { Color } from '@mobble/colors';

export interface BadgesProps {
  label?: I18nItem;
  color?: Color;
  badges: BadgeProps[];
}

export const Badges: React.FC<BadgesProps> = ({ label, badges, color }) => {
  return (
    <HStack alignment="top">
      {label && (
        <Box
          style={{ minWidth: spacingToAbsolute(6) + spacingToAbsolute(1) }}
          spacing={{ top: 0.5, right: 0, bottom: 0, left: 0 }}
        >
          <Text variant="small" i18n={label} color={Color.DarkGrey} />
        </Box>
      )}
      <Box flex>
        <HStack wrap>
          {badges.map((badge: any) => (
            <Box
              key={JSON.stringify(badge.label)}
              spacing={{ top: 0, right: 1, bottom: 0, left: 0 }}
            >
              <Badge
                textVariant="small"
                label={badge.label}
                color={badge.color ?? color}
              />
            </Box>
          ))}
        </HStack>
      </Box>
    </HStack>
  );
};
