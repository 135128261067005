import React, { useState } from 'react';
import { useI18n } from '@mobble/i18n';
import { findMapAsset } from '@mobble/models/src/model/MapAsset';
import {
  BoundingBox,
  enlargeBoundingBox,
  pointToBoundingBox,
} from '@mobble/models/src/model/MapGeometry';
import { findPaddockBoundingBox } from '@mobble/models/src/model/PaddockGeometry';
import { findTaskGeometry } from '@mobble/models/src/model/TaskGeometry';
import { base64encode } from '@mobble/shared/src/core/String';
import { useEntitiesRefresher } from '@mobble/shared/src/hooks/useEntitiesRefresher';
import {
  useMapAssets,
  useMobs,
  usePaddockGeometries,
  usePaddockGroupGeometries,
  usePaddockGroupedStockingRates,
  usePaddockGroups,
  usePaddocks,
  useProperties,
  useTaskGeometries,
  useTasks,
} from '@mobble/store/src/hooks';

import { useAccessHelper } from '@src/hooks/useAccessHelper';
import { useLinking } from '@src/hooks/useLinking';
import { type OnClickMapItemEvent } from '@src/hooks/useMapProperties';
import { toPath } from '@src/interfaces/Routing';

import {
  makeMapItemMapAsset,
  mapItemsToBoundaryBox,
} from '@src/stories/Map/Items/helper';
import {
  MapOverview,
  type MapOverviewMode,
  type NewMapItem,
} from '@src/stories/Views/Map/MapOverview';
import { EntitySliceFactoryPrelude } from '@src/stories/Views/Misc/EntitySliceFactoryPrelude';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';

import { ActionSheetOnMobSelect } from '../Mobs/ActionSheetOnMobSelect';
import { ActionSheetOnPaddockSelect } from '../Paddocks/ActionSheetOnPaddockSelect';

import * as ROUTE_NAME from '../config/routeNames';
import { type ScreenRendererProps } from '../config/types';

export const MapHeader: React.FC<ScreenRendererProps> = (props) => {
  const { formatMessage } = useI18n();
  const { hasAccessToRoute } = useAccessHelper();
  const displayActivity = hasAccessToRoute(ROUTE_NAME.SUMMARY_EVENTS_LIST);

  return (
    <ScreenHeader
      title={formatMessage({
        defaultMessage: 'Map',
        description: 'screen.title.map',
      })}
      actions={
        displayActivity
          ? [
              {
                icon: 'activity',
                href: toPath(ROUTE_NAME.SUMMARY_EVENTS_LIST),
              },
            ]
          : []
      }
      onToggleDrawer={props.drawer.toggle}
    />
  );
};

export const Map: React.FC<ScreenRendererProps> = (props) => {
  const { params } = props.route;
  const properties = useProperties();
  const propertyId = properties.selected?.id;
  const paddocks = usePaddocks(propertyId);
  const paddockGroups = usePaddockGroups(propertyId);
  const paddockGeometries = usePaddockGeometries(propertyId);
  const paddockGroupGeometries = usePaddockGroupGeometries(propertyId);
  const paddockGroupStockingRates = usePaddockGroupedStockingRates(propertyId);
  const mobs = useMobs(propertyId);
  const tasks = useTasks(propertyId);
  const taskGeometries = useTaskGeometries(propertyId);
  const mapAssets = useMapAssets(propertyId);
  const linkTo = useLinking();

  const [selectedMapItem, setSelectedMapItem] =
    useState<null | OnClickMapItemEvent>(null);

  const onClickMapItem = (ev: OnClickMapItemEvent) => {
    switch (ev.type) {
      case 'paddock':
        setSelectedMapItem(ev);
        return;
      case 'mobs':
        setSelectedMapItem(ev);
        return;
      case 'mob':
        setSelectedMapItem(ev);
        return;
      case 'task':
        linkTo(ROUTE_NAME.TASK_DETAIL, {
          taskId: ev.task.id,
        });
        return;
      case 'map-asset':
        linkTo(ROUTE_NAME.MAP_ASSET_DETAIL, {
          mapAssetId: ev.mapAsset.id,
        });
        return;
    }
  };

  useEntitiesRefresher(
    [
      paddockGroups,
      paddocks,
      paddockGeometries,
      paddockGroupGeometries,
      paddockGroupStockingRates,
      mobs,
      tasks,
      taskGeometries,
      mapAssets,
    ],
    propertyId
  );

  const Prelude = EntitySliceFactoryPrelude({
    preludes: [
      properties.prelude,
      paddocks.prelude,
      paddockGeometries.prelude,
      paddockGroupGeometries.prelude,
      mobs.prelude,
      mapAssets.prelude,
    ],
  });

  if (Prelude) {
    return Prelude;
  }

  const centerOn = (() => {
    if (params?.goto) {
      const [type, id] = params.goto.split('--');
      switch (type) {
        case 'paddock':
          return findPaddockBoundingBox(paddockGeometries.entities)(id);
        case 'task': {
          const taskGeometry = findTaskGeometry(taskGeometries.entities)(id);
          if (taskGeometry) {
            return pointToBoundingBox(taskGeometry.point, 0.01);
          }
          return;
        }
        case 'map-asset': {
          const mapAsset = findMapAsset(mapAssets.entities)(id);
          if (mapAsset) {
            return enlargeBoundingBox(
              mapItemsToBoundaryBox([
                makeMapItemMapAsset()({ mapAsset }),
              ]) as BoundingBox,
              250
            );
          }
          return;
        }
        default:
          return;
      }
    }
  })();

  const mode: MapOverviewMode = (params?.mode as MapOverviewMode) ?? 'standard';

  const onAddMapItem = (newMapItem: NewMapItem) => {
    switch (newMapItem.type) {
      case 'task':
        return linkTo(ROUTE_NAME.MODAL_TASK_CREATE, {
          point: base64encode(JSON.stringify(newMapItem.point)),
        });

      case 'asset':
        return linkTo(ROUTE_NAME.MODAL_MAP_ASSET_CREATE, {
          mapAssetType: newMapItem.mapAssetType,
          points: base64encode(JSON.stringify(newMapItem.points)),
        });

      case 'paddock':
        return linkTo(ROUTE_NAME.MODAL_PADDOCK_CREATE, {
          points: base64encode(JSON.stringify(newMapItem.points)),
        });
    }
  };

  return (
    <>
      <MapOverview
        mode={mode}
        key={JSON.stringify(centerOn)}
        centerOn={centerOn}
        onClickMapItem={onClickMapItem}
        onAddMapItem={onAddMapItem}
        onResetMode={() => {
          linkTo(ROUTE_NAME.MAP_OVERVIEW);
        }}
      />
      {(() => {
        switch (selectedMapItem?.type) {
          case 'paddock':
            return (
              <ActionSheetOnPaddockSelect
                paddock={selectedMapItem.paddock}
                onClose={() => {
                  setSelectedMapItem(null);
                }}
              />
            );
          case 'mob':
            return (
              <ActionSheetOnMobSelect
                mob={selectedMapItem.mob}
                onClose={() => {
                  setSelectedMapItem(null);
                }}
              />
            );
          default:
            return null;
        }
      })()}
    </>
  );
};

export default {
  name: ROUTE_NAME.MAP_OVERVIEW,
  header: MapHeader,
  component: Map,
  noPadding: true,
};
