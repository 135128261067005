import { AsyncThunk } from '@reduxjs/toolkit';
import { type QueueAction } from '@mobble/models/src/model/QueueAction';

import * as Casualties from '../reducers/casualties';
import * as Chats from '../reducers/chats';
import * as Inventories from '../reducers/inventories';
import * as InventoryItemBatches from '../reducers/inventoryItemBatches';
import * as InventoryItems from '../reducers/inventoryItems';
import * as MapAssets from '../reducers/mapAssets';
import * as MobActions from '../reducers/mobActions';
import * as Mobs from '../reducers/mobs';
import * as NaturalIncreases from '../reducers/naturalIncreases';
import * as PaddockActions from '../reducers/paddockActions';
import * as PaddockGroups from '../reducers/paddockGroups';
import * as Paddocks from '../reducers/paddocks';
import * as Property from '../reducers/properties';
import * as Purchases from '../reducers/purchases';
import * as RainGaugeReadings from '../reducers/rainGaugeReadings';
import * as RainGauges from '../reducers/rainGauges';
import * as Sales from '../reducers/sales';
import * as Tasks from '../reducers/tasks';

export const replayableThunks: AsyncThunk<any, any, any>[] = [
  Casualties.thunkCreate,
  Casualties.thunkDelete,
  Casualties.thunkUpdate,
  Chats.thunkCreate,
  Inventories.thunkUpdate,
  InventoryItemBatches.thunkCreate,
  InventoryItemBatches.thunkDelete,
  InventoryItemBatches.thunkUpdate,
  InventoryItems.thunkCreate,
  InventoryItems.thunkDelete,
  InventoryItems.thunkUpdate,
  MapAssets.thunkCreate,
  MapAssets.thunkDelete,
  MapAssets.thunkUpdate,
  MobActions.thunkCreate,
  MobActions.thunkDelete,
  MobActions.thunkUpdate,
  Mobs.thunkCreate,
  Mobs.thunkDelete,
  Mobs.thunkUpdate,
  NaturalIncreases.thunkUpdate,
  NaturalIncreases.thunkDelete,
  PaddockActions.thunkCreate,
  PaddockActions.thunkDelete,
  PaddockActions.thunkUpdate,
  PaddockGroups.thunkCreate,
  PaddockGroups.thunkDelete,
  PaddockGroups.thunkUpdate,
  Paddocks.thunkCreate,
  Paddocks.thunkDelete,
  Paddocks.thunkMergeMobs,
  Paddocks.thunkMoveMobs,
  Paddocks.thunkUpdate,
  Property.thunkAddPropertyType,
  Property.thunkRemovePropertyType,
  Property.thunkUpdatePropertyType,
  Purchases.thunkUpdate,
  Purchases.thunkDelete,
  RainGaugeReadings.thunkCreate,
  RainGaugeReadings.thunkDelete,
  RainGaugeReadings.thunkUpdate,
  RainGauges.thunkCreate,
  RainGauges.thunkDelete,
  RainGauges.thunkUpdate,
  Sales.thunkCreate,
  Sales.thunkDelete,
  Sales.thunkUpdate,
  Tasks.thunkCreate,
  Tasks.thunkDelete,
  Tasks.thunkUpdate,
];

export const findReplayableThunk = (
  queueAction: QueueAction
): undefined | AsyncThunk<any, any, any> =>
  replayableThunks.find((a) => a.typePrefix === queueAction.action.type);
