import React, { useEffect, useState, useRef } from 'react';
import { useI18n } from '@mobble/i18n';
import { type Chat, type User } from '@mobble/models';
import { formatDate } from '@mobble/shared/src/core/Date';

import { Icon } from '@src/stories/Components/UI/Icon';
import { Text } from '@src/stories/Components/UI/Text';
import { List } from '@src/stories/Components/Layout/List';

import { ChatForm } from './ChatForm';
import { ChatPill } from './ChatPill';

import styles from './ChatScreen.scss';

export const ChatScreen: React.FC<{
  chats: Chat[];
  loading: boolean;
  user: User;
  hasNextPage: boolean;
  loadMore: () => Promise<void>;
  onSubmit: (_: string) => Promise<void>;
}> = ({ chats, loading, hasNextPage, loadMore, onSubmit, user }) => {
  const { formatMessage } = useI18n();
  const listRef = useRef(null);
  const [loadingMore, setLoadingMore] = useState(false);
  const isEmpty = chats.length === 0;

  useEffect(() => {
    // scroll to bottom of list on mount
    if (listRef?.current && !loading) {
      const lastMessage = listRef.current._el?.parentElement?.firstElementChild;
      window.requestAnimationFrame(() => lastMessage?.scrollIntoView());
    }
  }, [loading]);

  const handleNext = () => {
    if (!loadingMore) {
      setLoadingMore(true);

      return loadMore()
        .then(() => {
          setLoadingMore(false);
        })
        .catch(() => {
          setLoadingMore(false);
        });
    }
  };

  const renderItem = (item: Chat, index: number) => {
    const newLocal =
      formatDate(chats[index - 1]?.date) !== formatDate(item.date);

    return (
      <>
        <ChatPill
          item={item}
          isUserAuthor={item.user.id === user.id}
          className={styles.messageItem}
        />

        {newLocal && (
          <div className={styles.dateDivider}>
            <Text>{formatDate(chats[index - 1]?.date)}</Text>
          </div>
        )}
      </>
    );
  };

  return (
    <div className={styles.ChatScreen}>
      {isEmpty && (
        <div className={styles.empty}>
          <Icon name="chat" className={styles.emptyIcon} />
          <Text tagName="p" className={styles.emptyText}>
            {formatMessage({
              description: 'Property Chat empty state message',
              defaultMessage:
                'Start chatting with your farm team or added third parties here.',
            })}
          </Text>
        </div>
      )}

      <List
        ref={listRef}
        items={chats}
        loading={loadingMore}
        renderItem={renderItem}
        next={hasNextPage ? handleNext : undefined}
        className={styles.chatList}
      />
      <ChatForm onSubmit={onSubmit} className={styles.chatForm} />
    </div>
  );
};
