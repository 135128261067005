import React, { useState } from 'react';
import { useI18n } from '@mobble/i18n';
import {
  findPaddock,
  sortAnyContainingPaddocks,
  sortOptionsNameDistanceFromPaddock,
} from '@mobble/models/src/model/Paddock';
import { findPaddockCenterPoint } from '@mobble/models/src/model/PaddockGeometry';
import { type ConfiguredPropertyTypeGroupCustom } from '@mobble/models/src/model/Property';
import { getMobDisplayName } from '@mobble/models/src/model/Mob';
import { useEntitiesRefresher } from '@mobble/shared/src/hooks/useEntitiesRefresher';
import {
  useMobs,
  usePaddock,
  usePaddockGroups,
  usePaddocks,
  usePaddockGeometries,
  useProperties,
} from '@mobble/store/src/hooks';
import { base64decode } from '@mobble/shared/src/core/String';

import { usePaddockCardFactory } from '@src/hooks/usePaddockCardFactory';
import { useDialog } from '@src/hooks/useDialog';
import { useLinking } from '@src/hooks/useLinking';
import { useMoveMobs } from '@src/hooks/useMoveMobs';
import { useNavigateBack } from '@src/hooks/useNavigateBack';

import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';
import {
  PaddockMoveMobForm,
  type PaddockMoveMobFormValues,
} from '@src/stories/Views/Paddock/PaddockMoveMobForm';
import { EntitySliceFactoryPrelude } from '@src/stories/Views/Misc/EntitySliceFactoryPrelude';

import * as ROUTE_NAME from '../config/routeNames';
import { ScreenRendererProps } from '../config/types';

export const PaddockMoveMobHeader: React.FC<ScreenRendererProps> = (props) => {
  const { paddockId = '' } = props.route.params;
  const { formatMessage } = useI18n();
  const goBack = useNavigateBack();
  const paddock = usePaddock(paddockId);

  return (
    <ScreenHeader
      title={formatMessage(
        {
          defaultMessage: 'Move mobs from {PADDOCK_NAME}',
          description: 'screen.title.paddock_move_mobs',
        },
        {
          PADDOCK_NAME: paddock?.entity?.name,
        }
      )}
      onGoBack={goBack}
    />
  );
};

export const PaddockMoveMob: React.FC<ScreenRendererProps> = (props) => {
  const { paddockId = '' } = props.route.params;
  const mobIds = props.route.params?.mobIds
    ? JSON.parse(base64decode(props.route.params.mobIds))
    : [];
  const goBack = useNavigateBack();
  const linkTo = useLinking();
  const { alert, close } = useDialog();
  const { translate } = useI18n();
  const properties = useProperties();
  const propertyId = properties.selected?.id;
  const paddock = usePaddock(paddockId);
  const { moveMobs, moveToPropertyId, handleChangeProperty } =
    useMoveMobs(propertyId);
  const paddocks = usePaddocks(paddock.entity?.propertyId);
  const paddockGroups = usePaddockGroups(paddock.entity?.propertyId);
  const paddockGeometries = usePaddockGeometries(paddock.entity?.propertyId);
  const mobs = useMobs(paddock.entity?.propertyId);

  const [formLoading, setFormLoading] = useState(false);
  const [formError, setFormError] = useState('');

  const currentOrganisationId = properties.selected?.organisationId;
  const moveToPropertyTypes =
    properties.entities.find((a) => a.id === moveToPropertyId)?.types ??
    properties.selected?.types ??
    [];

  const propertyOptions = properties.entities
    .filter((p) => p.organisationId === currentOrganisationId)
    .map((property) => ({
      label: property.name,
      value: property.id,
    }));

  const paddockOrigin = findPaddockCenterPoint(paddockGeometries.entities)(
    paddockId
  );

  const makePaddockCard = usePaddockCardFactory({
    propertyId: propertyId,
    location: paddockOrigin,
  });

  const paddockSortMeta = {
    origin: paddockOrigin,
    paddockGeometries: paddockGeometries.allEntitiesAvailable,
    mobs: mobs.allEntitiesAvailable,
  };

  const sortPaddockOptions = sortAnyContainingPaddocks((o) => o.entity)(
    paddockSortMeta
  );

  const paddockOptions = paddocks.allEntitiesAvailable
    .filter((p) => p.propertyId === moveToPropertyId)
    .map((p) => ({
      label: p.name,
      value: p.id,
      entity: p,
      component: makePaddockCard(p),
    }));

  useEntitiesRefresher([paddocks, mobs], propertyId);

  const Prelude = EntitySliceFactoryPrelude({
    preludes: [
      properties.prelude,
      paddock.prelude,
      paddocks.prelude,
      mobs.prelude,
    ],
    required: [properties.selected],
    notFound: [paddock.entity],
  });

  if (Prelude) {
    return Prelude;
  }

  const handleCreateCustomField = (
    group: ConfiguredPropertyTypeGroupCustom,
    parentId: string,
    label: string
  ) => {
    if (!properties.selected) {
      return Promise.reject();
    }
    return properties.addPropertyType({
      group,
      parentId,
      label,
    });
  };

  const handleCancel = () => {
    goBack();
  };

  const handleSubmit = (formValues: PaddockMoveMobFormValues) => {
    if (
      formLoading ||
      paddocks.entities.length === 0 ||
      !paddock.entity ||
      !mobs
    ) {
      return;
    }

    const paddockTo = findPaddock(paddocks.allEntitiesAvailable)(
      formValues.paddock
    );
    if (!paddockTo) {
      return;
    }

    setFormError('');
    setFormLoading(true);

    const mobsSplit = JSON.parse(formValues.mobsSplit);
    moveMobs({
      propertyId: paddock.entity.propertyId,
      paddockFrom: paddock.entity,
      paddockTo,
      mobs: formValues.mobs,
      date: formValues.date,
      mobsSplit,
      meta: {
        mobMoveNames: formValues.mobs.map(getMobDisplayName(mobs.entities)),
        mobSplitNames: mobsSplit.length
          ? Object.keys(mobsSplit).map(getMobDisplayName(mobs.entities))
          : 'none',
      },
    })
      .then(({ mobsCanMergeOnTargetPaddock }) => {
        // TODO: replace with Toast/Alert
        console.log(`Mob successfully moved`);

        if (mobsCanMergeOnTargetPaddock) {
          setFormLoading(false);
          alert(
            translate({
              key: 'mobs_can_be_merged.alert.title',
            }) ?? '',
            translate({
              key: 'mobs_can_be_merged.alert.message',
              params: {
                '%PADDOCK': paddockTo.name,
              },
            }) ?? '',
            [
              {
                label:
                  translate({
                    key: 'mobs_can_be_merged.alert.cancel',
                  }) ?? '',
                outline: true,
                intent: 'secondary',
                onClick: () => {
                  close();
                  goBack();
                },
              },
              {
                label:
                  translate({
                    key: 'mobs_can_be_merged.alert.confirm',
                  }) ?? '',
                onClick: () => {
                  close();
                  linkTo(ROUTE_NAME.PADDOCK_DETAIL_MOBS, {
                    paddockId: paddockTo.id,
                  });
                },
              },
            ]
          );
        } else {
          goBack();
        }
      })
      .catch((err) => {
        console.log(err);
        setFormError(
          translate({
            key: 'generic.form.error',
            params: {
              '%ENTITY': translate({ key: 'mobs.mob.create.form.title.label' }),
            },
          })
        );
        setFormLoading(false);
      });
  };

  return (
    <PaddockMoveMobForm
      paddockOptions={paddockOptions}
      paddockSortOptions={sortOptionsNameDistanceFromPaddock}
      sortPaddockOptionsFunction={sortPaddockOptions}
      propertyOptions={propertyOptions}
      propertyTypes={moveToPropertyTypes}
      error={formError}
      loading={formLoading}
      paddocks={paddocks.entities}
      mobs={mobs.entities}
      selectedMobs={mobIds}
      selectedProperty={moveToPropertyId}
      selectedToPaddockId={props.route.params?.toPaddockId}
      paddock={paddock.entity}
      paddockGroups={paddockGroups.entities}
      onCancel={handleCancel}
      onTouched={() => {}}
      onSubmit={handleSubmit}
      onChangeProperty={handleChangeProperty}
      onCreateCustomField={handleCreateCustomField}
    />
  );
};

export default {
  name: ROUTE_NAME.MODAL_MOB_MOVE,
  header: PaddockMoveMobHeader,
  component: PaddockMoveMob,
  modal: true,
};
