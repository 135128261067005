import React from 'react';
import { Box } from '../../../Components/Layout/Box';
import { FormBuilder, type FormBuilderProps } from '../../Form/FormBuilder';

export interface MobCreateWizardTypeOfAdditionProps {
  formValues?: MobCreateWizardTypeOfAdditionFormValues;
  onChange: (values: MobCreateWizardTypeOfAdditionFormValues) => void;
}

export interface MobCreateWizardTypeOfAdditionFormValues {
  addition_type: 'setup' | 'natural_increase' | 'purchase';
}

export const MobCreateWizardTypeOfAddition: React.FC<
  MobCreateWizardTypeOfAdditionProps
> = ({ formValues, onChange }) => {
  const formProps: FormBuilderProps<any> = {
    i18nRootKey: 'mobs.mob.create.form',
    footer: false,
    fields: [
      {
        name: 'addition_type',
        type: 'radio-as-buttons',
        initialValue: formValues?.addition_type,
        options: [
          {
            label: 'mobs.mob.create.form.addition_type.option.setup.label',
            value: 'setup',
          },
          {
            label:
              'mobs.mob.create.form.addition_type.option.natural_increase.label',
            value: 'natural_increase',
          },
          {
            label: 'mobs.mob.create.form.addition_type.option.purchase.label',
            value: 'purchase',
          },
        ],
      },
    ],
    onSubmit: () => {},
    onChange,
  };

  return (
    <Box spacing={{ top: 2 }}>
      <FormBuilder {...formProps} />
    </Box>
  );
};
