import React from 'react';
import { Color } from '@mobble/colors';
import { getLivestockAgeColor, type Mob } from '@mobble/models/src/model/Mob';
import {
  ConfiguredPropertyTypeGroup,
  type ConfiguredPropertyTypeGroupCustom,
  type ConfiguredPropertyType,
  getLivestockAttributes,
  getLivestockParentIdForValue,
} from '@mobble/models/src/model/Property';
import { Box } from '../../../Components/Layout/Box';
import { FormBuilder, type FormBuilderProps } from '../../Form/FormBuilder';
import { Display } from '../../../Components/UX/Display';
import { Text } from '../../../Components/UI/Text';
import { HStack } from '../../../Components/Layout/Stack';
import { InputError } from '../../../Components/UX/InputError';
import styles from './styles.scss';

export interface MobSplitColumnProps {
  propertyTypes: ConfiguredPropertyType[];
  mob: Mob;
  value?: MobSplitColumnFormValues;
  remainingSize?: number;
  onChange: (value: MobSplitColumnFormValues) => void;
  onCreateCustomField: (
    type: ConfiguredPropertyTypeGroupCustom
  ) => (value: string) => Promise<void>;
}

export interface MobSplitColumnFormValues {
  classes: string[];
  ages: number[];
  gender: string;
  size: number | null;
}

export const MobSplitColumn: React.FC<MobSplitColumnProps> = ({
  propertyTypes,
  mob,
  value,
  remainingSize,
  onChange,
  onCreateCustomField,
}) => {
  const getAgeColor = getLivestockAgeColor(propertyTypes, mob.type);
  const form: FormBuilderProps<MobSplitColumnFormValues> = {
    tagName: 'section',
    containerComponent: (props) => {
      return (
        <Box spacing={{ top: 2 }}>
          <Box spacing={{ bottom: 1 }}>
            <Text
              color={Color.DarkGrey}
              variant="small"
              i18n={props.makeI18n('label')}
            />
          </Box>
          <Box className={styles.inputWrapper}>
            <HStack>
              <Box flex>{props.input}</Box>
            </HStack>
          </Box>
          <InputError spacing={0} size="small" error={props.error} />
        </Box>
      );
    },
    i18nRootKey: 'paddocks.paddock.move_mob.mob_split.form',
    fields: [
      typeof remainingSize !== 'undefined'
        ? {
            name: 'size',
            type: 'custom',
            component: () => (
              <Display
                color={Color.Grey}
                backgroundColor={Color.AlmostWhite}
                value={remainingSize}
              />
            ),
          }
        : {
            name: 'size',
            type: 'number',
            min: 0,
            initialValue: value?.size,
            transform: (val) => {
              let valNum = Number(val);
              valNum = Number.isNaN(valNum) ? 0 : valNum;
              return valNum === 0 ? '' : Math.min(valNum, mob.size - 1);
            },
            required: true,
          },
      {
        name: 'classes',
        type: 'select-multiple',
        initialValue: value?.classes,
        options: () => {
          const parentId = propertyTypes.find((a) => a.type === mob.type)?.id;
          return propertyTypes
            .filter(
              (a) =>
                a.group === ConfiguredPropertyTypeGroup.class &&
                a.parentId === parentId
            )
            .map((a) => ({
              label: a.label,
              value: a.type,
            }));
        },
        addNew: {
          onSubmit: onCreateCustomField(ConfiguredPropertyTypeGroup.class),
          placeholder: {
            key: 'mobs.mob.create.form.mob_form.classes.add_new.placeholder.label',
          },
          button: {
            key: 'mobs.mob.create.form.mob_form.classes.add_new.button.label',
          },
        },
      },
      {
        name: 'ages',
        type: 'select-multiple',
        initialValue: value?.ages,
        options: () =>
          getLivestockAttributes(propertyTypes)(
            ConfiguredPropertyTypeGroup.tag,
            getLivestockParentIdForValue(mob.type, propertyTypes)
          ).map((a) => ({
            ...a,
            value: Number(a.value),
            color: getAgeColor(a.label) || Color.White,
          })),
        sortFunction: (options) =>
          options.sort((a: any, b: any) => b.label.localeCompare(a.label)),
        required: true,
      },
      {
        name: 'gender',
        type: 'select',
        initialValue: value?.gender,
        options: () =>
          getLivestockAttributes(propertyTypes)(
            ConfiguredPropertyTypeGroup.gender,
            getLivestockParentIdForValue(mob.type, propertyTypes)
          ),
        show: () => Boolean(mob.type),
        required: true,
        addNew: {
          onSubmit: onCreateCustomField(ConfiguredPropertyTypeGroup.gender),
          placeholder: {
            key: 'mobs.mob.create.form.mob_form.gender.add_new.placeholder.label',
          },
          button: {
            key: 'mobs.mob.create.form.mob_form.gender.add_new.button.label',
          },
        },
      },
    ],
    reinitialize: true,
    onChange,
  };

  return (
    <Box>
      <FormBuilder {...form} />
    </Box>
  );
};
