import React from 'react';
import { useI18n } from '@mobble/i18n';
import { Sale } from '@mobble/models/src/model/Sale';
import { findMob } from '@mobble/models/src/model/Mob';
import { useGetUser, useExtStatus } from '@mobble/store/src/hooks';

import { useLinking } from '@src/hooks/useLinking';
import { useNavigateBack } from '@src/hooks/useNavigateBack';

import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';
import {
  SaleCreateForm,
  SaleCreateFormValues,
} from '@src/stories/Views/Sale/SaleCreateForm';

import * as ROUTE_NAME from '../config/routeNames';
import { ScreenRendererProps } from '../config/types';

import { useSaleScreen, useSaleScreenHeader } from './useSaleScreen';

export const SaleCreateHeader: React.FC<ScreenRendererProps> = () => {
  const props = useSaleScreenHeader();

  return <ScreenHeader {...props} />;
};

export const SaleCreate: React.FC<ScreenRendererProps> = ({ route }) => {
  const { mobId } = route.params;
  const { translate } = useI18n();
  const goBack = useNavigateBack();
  const linkTo = useLinking();

  const { Prelude, mobs, paddocks, properties, sale } = useSaleScreen();
  const user = useGetUser();

  const extStatus = useExtStatus('sales', 'create', properties.selected?.id);
  const formLoading = extStatus?.loading;
  const formError = extStatus?.error
    ? translate({
        key: 'generic.form.error',
        params: {
          '%ENTITY': translate({ key: 'screen.title.sale_detail' }),
        },
      })
    : '';

  if (Prelude) {
    return Prelude;
  }

  const handleSubmit = (formValues: SaleCreateFormValues) => {
    if (!user || !properties.selected) {
      return;
    }
    const mob = findMob(mobs.entities)(formValues.mob);
    if (!mob) {
      return;
    }

    const newSale: Omit<Sale, 'id'> = {
      propertyId: properties.selected.id,
      pricePerHeadCents: formValues.price_per_head
        ? Math.floor((Number(formValues.price_per_head) || 0) * 100)
        : 0,
      number: Number(formValues.number),
      date: formValues.date,
      status: 'active',
      notes: formValues.notes,
      soldToName: formValues.sold_to,
      createdBy: user,
      mob,
    };

    sale
      .create(newSale)
      .then((sale) => {
        // TODO: replace with Toast/Alert
        console.log(`Sale '${sale.id}' successfully created`);
        console.log(sale);

        if (newSale.number === sale.number) {
          linkTo(ROUTE_NAME.SALE_DETAIL, { saleId: sale.id });
          return;
        }
        goBack();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <SaleCreateForm
      paddocks={paddocks.entities}
      mobs={mobs.entities}
      propertyTypes={properties.selected.types}
      initialValues={{
        mob: mobId,
        number: findMob(mobs.entities)(mobId)?.size ?? 0,
        date: new Date().toISOString(),
      }}
      error={formError}
      loading={formLoading}
      onCancel={goBack}
      onSubmit={handleSubmit}
    />
  );
};

export default {
  name: ROUTE_NAME.MODAL_SALE_CREATE,
  header: SaleCreateHeader,
  component: SaleCreate,
};
