import React from 'react';
import { ChartWrapper } from '@src/stories/Components/Charts/ChartWrapper';
import { fromRawDate } from '@mobble/shared/src/core/Date';
import { type PropertyStockingRate } from '@mobble/service/src/ext/property-stocking-rates';
import { ChartLivestockHistoryGraph } from './ChartLivestockHistoryGraph';
import {
  useConvertStockingRatesData,
  useYearSelector,
} from './useConverStockingRatesData';

export type Mode = 'head' | 'DSE' | 'areaDSE';

const modeOptions = [
  { label: { key: 'chart.historic-livestock.option.head' }, value: 'head' },
  { label: { key: 'chart.historic-livestock.option.dse' }, value: 'DSE' },
  {
    label: { key: 'chart.historic-livestock.option.areadse' },
    value: 'areaDSE',
  },
];

export interface ChartHistoricPropertyLivestockProps {
  stockingRates: PropertyStockingRate[];
}

export const ChartHistoricPropertyLivestock: React.FC<
  ChartHistoricPropertyLivestockProps
> = ({ stockingRates }) => {
  const [mode, setMode] = React.useState<Mode>('head');

  const years = [
    ...new Set(stockingRates.map((sr) => fromRawDate(sr.dateRaw).year())),
  ];

  const { year, setYear, yearOptions } = useYearSelector(years);

  const { data, dataAverage, dataAverageByYmd, maxY } =
    useConvertStockingRatesData({
      year,
      stockingRates,
      mode,
    });

  const options = [
    {
      options: yearOptions,
      onChange: (value) => setYear(Number(value)),
    },
    {
      options: modeOptions,
      onChange: (value: Mode) => setMode(value),
    },
  ];

  return (
    <ChartWrapper
      title={{ key: 'chart.historic-livestock.title' }}
      options={options}
    >
      <div style={{ height: '400px' }}>
        <ChartLivestockHistoryGraph
          data={data}
          dataAverage={dataAverage}
          dataAverageByYmd={dataAverageByYmd}
          maxY={maxY}
        />
      </div>
    </ChartWrapper>
  );
};

export interface ChartHistoricPropertyLivestockReportsPageProps {
  stockingRates: PropertyStockingRate[];
  multiProperty?: boolean;
}

export const ChartHistoricReportsLivestock: React.FC<
  ChartHistoricPropertyLivestockReportsPageProps
> = ({ stockingRates, multiProperty }) => {
  const [mode, setMode] = React.useState<Mode>('head');

  const { data, dataAverage, dataAverageByYmd, maxY } =
    useConvertStockingRatesData({
      stockingRates,
      mode,
    });

  const options = !multiProperty && [
    {
      options: modeOptions,
      onChange: (value: Mode) => setMode(value),
    },
  ];

  return (
    <ChartWrapper
      title={{ key: 'chart.historic-livestock.title' }}
      options={options}
    >
      <div style={{ height: '400px' }}>
        <ChartLivestockHistoryGraph
          data={data}
          dataAverage={dataAverage}
          dataAverageByYmd={dataAverageByYmd}
          maxY={maxY}
        />
      </div>
    </ChartWrapper>
  );
};
