import React, { useState } from 'react';
import { useInventory } from '@mobble/store/src/hooks/inventories';
import {
  useInventoryItem,
  useInventoryItems,
} from '@mobble/store/src/hooks/inventoryItems';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';
import { type ScreenRendererProps } from '../config/types';
import * as ROUTE_NAME from '../config/routeNames';
import { toPath } from '@src/interfaces/Routing';
import { useNavigateBack } from '@src/hooks/useNavigateBack';
import { useInventoryItemBatch } from '@mobble/store/src/hooks/inventoryItemBatches';
import { InventoryItemBatch } from '@mobble/models/src/model/InventoryItemBatch';
import { parseInventoryId } from '@mobble/models/src/model/Inventory';
import { InventoryItemBatchCreateForm } from '@src/stories/Views/InventoryItem/InventoryItemBatchCreateForm';
import { useLinking } from '@src/hooks/useLinking';
import { useI18n } from '@mobble/i18n/src';
import { thunkFind } from '@mobble/store/src/reducers/inventoryItems';
import { useRootDispatch } from '@mobble/store/src/hooks/root';
import { EntitySliceFactoryPrelude } from '@src/stories/Views/Misc/EntitySliceFactoryPrelude';
import { useGetUser } from '@mobble/store/src/hooks';

export const InventoryItemCreateHeader: React.FC<ScreenRendererProps> = (
  props
) => {
  const { formatMessage } = useI18n();
  const goBack = useNavigateBack();
  const { inventoryId = '', inventoryItemId = '' } = props.route.params;
  const inventory = useInventory(inventoryId);
  const inventoryItem = useInventoryItem(inventoryItemId, inventoryId);

  const breadcrumbs = [
    {
      title: formatMessage({
        description: 'screen.title.inventories',
        defaultMessage: 'Inventories',
      }),
      href: ROUTE_NAME.INVENTORIES_LIST,
    },
    {
      title: inventory.entity?.name,
      href: toPath(ROUTE_NAME.INVENTORY_ITEMS, {
        inventoryId,
      }),
    },
  ];

  if (inventoryItem.entity?.name) {
    breadcrumbs.push({
      title: inventoryItem.entity?.name,
      href: toPath(ROUTE_NAME.INVENTORY_ITEM_DETAIL, {
        inventoryId,
        inventoryItemId,
      }),
    });
  }

  return (
    <ScreenHeader
      title={formatMessage({
        description: 'screen.title.inventory-item-batch_create',
        defaultMessage: 'Add new batch',
      })}
      breadcrumbs={breadcrumbs}
      onGoBack={goBack}
    />
  );
};

export const InventoryItemCreate: React.FC<ScreenRendererProps> = (props) => {
  const goBack = useNavigateBack();
  const linkTo = useLinking();
  const dispatch = useRootDispatch();

  const { inventoryId = '', inventoryItemId = '' } = props.route.params;

  const { propertyId } = parseInventoryId(inventoryId);
  const user = useGetUser();

  const inventoryItem = useInventoryItem(inventoryItemId, inventoryId);
  const inventoryItems = useInventoryItems(inventoryId);

  const inventoryItemBatch = useInventoryItemBatch(
    '_',
    inventoryItemId,
    propertyId
  );

  const [loading, setLoading] = useState(false);

  const Prelude = EntitySliceFactoryPrelude({
    preludes: [inventoryItems.prelude, inventoryItem.prelude],
    required: [inventoryItems.entities, inventoryItem.entity],
  });

  if (Prelude) {
    return Prelude;
  }

  const handleSubmit = (formValues: Omit<InventoryItemBatch, 'id'>) => {
    setLoading(true);
    inventoryItemBatch.create(formValues).then(() => {
      setLoading(false);

      dispatch(
        thunkFind({
          id: inventoryItemId,
          parentId: inventoryId,
        })
      ).then(() => {
        linkTo(ROUTE_NAME.INVENTORY_ITEM_DETAIL, {
          inventoryId,
          inventoryItemId,
        });
      });
    });
  };

  return (
    <InventoryItemBatchCreateForm
      inventoryItem={inventoryItem.entity}
      inventoryItems={inventoryItems.entities}
      user={user}
      onCancel={goBack}
      error={null}
      onTouched={() => {}}
      onSubmit={handleSubmit}
      loading={loading}
    />
  );
};

export default {
  name: ROUTE_NAME.MODAL_INVENTORY_ITEM_BATCH_CREATE,
  header: InventoryItemCreateHeader,
  component: InventoryItemCreate,
};
