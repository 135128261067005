import React from 'react';
import { FormBuilder, type FormBuilderProps } from '../Form/FormBuilder';

export interface PropertyCreateFormProps {
  error?: string;
  loading?: boolean;
  initialValues?: Omit<PropertyCreateFormValues, 'pic'>;
  onCancel: () => void;
  onSubmit: (formValues: PropertyCreateFormValues) => void;
}

export interface PropertyCreateFormValues {
  name: string;
  pic: string;
}

export const PropertyCreateForm: React.FC<PropertyCreateFormProps> = ({
  error,
  loading,
  initialValues,
  onCancel,
  onSubmit,
}) => {
  const form: FormBuilderProps<PropertyCreateFormValues> = {
    flex: true,
    i18nRootKey: 'properties.create.form',
    reinitialize: true,
    fields: [
      {
        initialValue: initialValues?.name || '',
        name: 'name',
        type: 'text',
        required: true,
      },
      {
        name: 'pic',
        type: 'text',
      },
    ],
    error,
    loading,
    onSubmit,
    onCancel,
  };

  return <FormBuilder {...form} />;
};
