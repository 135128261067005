import React from 'react';
import { defineMessages } from 'react-intl';
import { useMessages } from '@mobble/i18n';
import { formatMonetary } from '@mobble/shared/src/core/Monetary';
import { formatDate } from '@mobble/shared/src/core/Date';
import { Sale, Paddock, ConfiguredPropertyType } from '@mobble/models';
import { useSettings } from '@mobble/store/src/hooks/settings';
import { Box } from '../../Components/Layout/Box';
import { type InfoRowProps, InfoRow } from '../../Components/UI/InfoRow';
import { MobCard } from '../Mob/MobCard';

export interface SaleInfoTableProps {
  sale: Sale;
  paddock: Paddock;
  propertyTypes: ConfiguredPropertyType[];
}

const messages = defineMessages({
  date: {
    defaultMessage: 'Date',
    description: 'sale.info.table.row.date.label',
  },
  mob: {
    defaultMessage: 'Mob',
    description: 'sale.info.table.row.mob.label',
  },
  notes: {
    defaultMessage: 'Notes',
    description: 'sale.info.table.row.notes.label',
  },
  number: {
    defaultMessage: 'Head',
    description: 'sale.info.table.row.number.label',
  },
  price_per_head: {
    defaultMessage: 'Price per head',
    description: 'sale.info.table.row.price_per_head.label',
  },
  received: {
    defaultMessage: 'Total received',
    description: 'sale.info.table.row.received.label',
  },
  sold_to: {
    defaultMessage: 'Sold to',
    description: 'sale.info.table.row.sold_to.label',
  },
});

export const SaleInfoTable: React.FC<SaleInfoTableProps> = ({
  sale,
  paddock,
  propertyTypes,
}) => {
  const strings = useMessages(messages);
  const { settings } = useSettings();
  const { dateFormat, currencySymbol } = settings;

  const mob = {
    ...sale.mob,
    size: sale.number,
  };

  const rows: (InfoRowProps | InfoRowProps[])[] = [
    {
      label: strings.mob,
      value: () => (
        <Box flex>
          <MobCard propertyTypes={propertyTypes} paddock={paddock} mob={mob} />
        </Box>
      ),
    },
    {
      label: strings.date,
      value: sale.date ? formatDate(sale.date, dateFormat) : ' - ',
    },
    {
      label: strings.number,
      value: sale.number,
    },
    {
      label: strings.price_per_head,
      value: formatMonetary(sale.pricePerHeadCents, currencySymbol),
    },
    {
      label: strings.received,
      value: formatMonetary(
        sale.pricePerHeadCents * sale.number,
        currencySymbol
      ),
    },
    {
      label: strings.sold_to,
      value: sale.soldToName || '-',
    },
    {
      label: strings.notes,
      value: sale.notes || '-',
    },
  ];

  return (
    <>
      {rows.map((row) =>
        Array.isArray(row) ? (
          <>
            {row.map((r) => (
              <InfoRow {...r} key={r.label as string} />
            ))}
          </>
        ) : (
          <InfoRow key={row.label} {...row} />
        )
      )}
    </>
  );
};
