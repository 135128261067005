import React from 'react';
import { useI18n } from '@mobble/i18n';
import { type Mob } from '@mobble/models/src/model/Mob';
import { ChartWrapper } from '@src/stories/Components/Charts/ChartWrapper';
import { type Datum, Pie } from '@src/stories/Components/Charts/Pie';

export interface ChartLivestockTotalsProps {
  mobs: Mob[];
}

type Mode = 'size' | 'dse';

const MAX_LIVESTOCK_GROUPS = 15;

const makeData = (mobs: Mob[], toValue: (mob: Mob) => number): Datum[] => {
  const map = new Map<string, Datum>();
  const mobToDataLabel = (mob: Mob) => `${mob.breed} ${mob.gender}`;

  mobs.forEach((mob) => {
    const id = mobToDataLabel(mob);
    const value = Number(toValue(mob).toFixed(1));
    let entry = map.get(id);

    // Update existing entry
    if (entry) {
      const newValue = Number((entry.value += value).toFixed(1));
      entry.value = newValue;
      entry.label = `${newValue} ${id}`;
    } else {
      entry = {
        id,
        value,
        label: `${value} ${id}`,
      };
    }

    map.set(id, entry);
  });

  return [...map.entries()].map(([, entry]) => entry);
};

const makeOther = (data: Datum[], otherLabel: string): Datum => {
  const total = data.reduce((acc, datum) => acc + datum.value, 0);
  return {
    id: 'other',
    label: `${total} ${otherLabel}`,
    value: total,
  };
};

const makeSizeData = (mobs: Mob[]): Datum[] =>
  makeData(mobs, (mob) => mob.size);

const makeDSEData = (mobs: Mob[]): Datum[] =>
  makeData(mobs, (mob) => mob.DSE * mob.size);

export const ChartLivestockTotals: React.FC<ChartLivestockTotalsProps> = ({
  mobs,
}) => {
  const { translate } = useI18n();
  const [mode, setMode] = React.useState<Mode>('size');
  const modeOptions = [
    { value: 'size', label: { key: 'chart.livestock-totals.option.size' } },
    { value: 'dse', label: { key: 'chart.livestock-totals.option.dse' } },
  ].map((o) => ({
    ...o,
    selected: o.value === mode,
  }));

  const data = React.useMemo(() => {
    switch (mode) {
      case 'size':
        return makeSizeData(mobs);
      case 'dse':
        return makeDSEData(mobs);
    }
  }, [mode, mobs]).sort((a, b) => b.value - a.value);

  // Merge remaining into single 'Other' group
  const slicedData = data.slice(0, MAX_LIVESTOCK_GROUPS);
  const otherData = makeOther(
    data.slice(MAX_LIVESTOCK_GROUPS),
    translate({ key: 'chart.livestock-totals.legend.other' })
  );
  if (otherData.value > 0) {
    slicedData.push(otherData);
  }

  return (
    <ChartWrapper
      title={{ key: 'chart.livestock-totals.title' }}
      options={[
        {
          options: modeOptions,
          onChange: (value: Mode) => setMode(value),
        },
      ]}
    >
      <Pie data={slicedData} />
    </ChartWrapper>
  );
};
