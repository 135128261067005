import React from 'react';
import { defineMessages } from 'react-intl';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMessages } from '@mobble/i18n';
import { useProperties } from '@mobble/store/src/hooks/properties';
import { useGetUser } from '@mobble/store/src/hooks/auth';
import { groupByOrganisation } from '@mobble/models/src/model/Property';
import { usePendingOverdueTasksCount } from '@mobble/store/src/hooks/tasks';
import { PropertySelector } from '@src/stories/Views/Misc/PropertySelector';
import { useAppLayoutContext } from '@src/stories/Components/Layout/AppLayout';
import { VStack } from '@src/stories/Components/Layout/Stack';
import { Logo } from '@src/stories/Components/UI/Logo';
import { Avatar } from '@src/stories/Components/UI/Avatar';
import { Text } from '@src/stories/Components/UI/Text';
import { Clickable } from '@src/stories/Components/UX/Clickable';
import { DrawerItem } from '@src/stories/Components/UX/Drawer/DrawerItem';
import { type IconName } from '@src/stories/Components/UI/Icon';
import { toPath } from '@src/interfaces/Routing';
import {
  roleHasAccessToRoute,
  useAccessHelper,
} from '@src/hooks/useAccessHelper';
import * as ROUTE_NAME from '../config/routeNames';
import { RootStackParamList } from '../config/types';
import styles from './mainDrawer.scss';

interface MainDrawerProps {
  collapsed?: boolean;
  className?: string;
}

interface MainDrawerNavigationItem {
  routeName: keyof RootStackParamList;
  label: string;
  params?: Record<string, string | number>;
  count?: number;
  icon: IconName;
}

const messages = defineMessages({
  action_queue: {
    defaultMessage: 'Offline requests',
    description: 'main_drawer.menu.action_queue.label',
  },
  chat: {
    defaultMessage: 'Property Chat',
    description: 'main_drawer.menu.chat.label',
  },
  dashboard: {
    defaultMessage: 'Dashboard',
    description: 'main_drawer.menu.dashboard.label',
  },
  inventories: {
    defaultMessage: 'Inventories',
    description: 'main_drawer.menu.inventories.label',
  },
  invite: {
    defaultMessage: 'Refer a Farmer',
    description: 'main_drawer.menu.invite.label',
  },
  map: {
    defaultMessage: 'Map',
    description: 'main_drawer.menu.map.label',
  },
  mobs: {
    defaultMessage: 'Mobs',
    description: 'main_drawer.menu.mobs.label',
  },
  paddocks: {
    defaultMessage: 'Paddocks',
    description: 'main_drawer.menu.paddocks.label',
  },
  rain_gauges: {
    defaultMessage: 'Rain gauges',
    description: 'main_drawer.menu.rain_gauges.label',
  },
  reports: {
    defaultMessage: 'Reports',
    description: 'main_drawer.menu.reports.label',
  },
  settings: {
    defaultMessage: 'Settings',
    description: 'main_drawer.menu.settings.label',
  },
  summaries: {
    defaultMessage: 'Summaries',
    description: 'main_drawer.menu.summaries.label',
  },
  tasks: {
    defaultMessage: 'Tasks',
    description: 'main_drawer.menu.tasks.label',
  },
  user_settings: {
    defaultMessage: 'User settings',
    description: 'main_drawer.menu.user_settings.label',
  },
});

export const MainDrawer = (props: MainDrawerProps) => {
  const { collapsed, className } = props;
  const strings = useMessages(messages);
  const { showNoAccessAlert } = useAccessHelper();
  const properties = useProperties();
  const user = useGetUser();
  const navigate = useNavigate();
  const location = useLocation();
  const pendingTaskCount = usePendingOverdueTasksCount(properties.selected?.id);
  const { toggleDrawer } = useAppLayoutContext();
  const classNames = [
    styles.mainDrawer,
    collapsed ? styles.collapsed : null,
    className,
  ]
    .filter(Boolean)
    .join(' ');

  const mainDrawerNavigationItems: MainDrawerNavigationItem[] = [
    {
      routeName: ROUTE_NAME.DASHBOARD,
      label: strings.dashboard,
      icon: 'grid',
    },
    {
      routeName: ROUTE_NAME.PADDOCKS_LIST,
      label: strings.paddocks,
      icon: 'paddock',
    },
    {
      routeName: ROUTE_NAME.MOBS_LIST,
      label: strings.mobs,
      icon: 'mob',
    },
    {
      routeName: ROUTE_NAME.MAP_OVERVIEW,
      label: strings.map,
      icon: 'map',
    },
    {
      routeName: ROUTE_NAME.TASKS_LIST,
      label: strings.tasks,
      icon: 'clipboard',
      count: pendingTaskCount,
    },
    {
      routeName: ROUTE_NAME.CHATS_LIST,
      label: strings.chat,
      icon: 'chat',
    },
    {
      routeName: ROUTE_NAME.INVENTORIES_LIST,
      label: strings.inventories,
      icon: 'package',
    },
    {
      routeName: ROUTE_NAME.RAIN_GAUGES_LIST,
      label: strings.rain_gauges,
      icon: 'cloud-rain',
    },
    {
      routeName: ROUTE_NAME.SUMMARIES_LIST,
      label: strings.summaries,
      icon: 'bar-chart',
    },
    {
      routeName: ROUTE_NAME.REPORTS,
      label: strings.reports,
      icon: 'file-text',
    },
    {
      routeName: ROUTE_NAME.SETTINGS_LIST,
      label: strings.settings,
      icon: 'settings',
    },
    {
      routeName: ROUTE_NAME.REFER_FARMER,
      label: strings.invite,
      icon: 'mail',
    },
  ];

  const onSelectProperty = (propertyId: string) => {
    properties.selectProperty(propertyId);
    localStorage.removeItem(`map:viewport:map.overview`);
    navigate(toPath(ROUTE_NAME.DASHBOARD));
  };

  const handleClickProfile = () => {
    toggleDrawer(false);
  };

  const renderDrawerItem = (item: MainDrawerNavigationItem) => {
    const path = toPath(item.routeName);
    const curPath = location.pathname;
    const active =
      curPath === path ||
      curPath.startsWith(item.routeName.replace(/\/:.+/, ''));
    const disabled = !roleHasAccessToRoute(user.role)(item.routeName);
    const onClick = () => {
      if (disabled) {
        showNoAccessAlert();
        return;
      }
      toggleDrawer(false);
    };

    return (
      <li key={item.routeName}>
        <DrawerItem
          href={disabled ? undefined : path}
          icon={item.icon}
          label={item.label}
          count={item.count}
          active={active}
          collapsed={collapsed}
          disabled={disabled}
          onClick={onClick}
        />
      </li>
    );
  };

  return (
    <VStack flex className={classNames}>
      <div className={styles.navWrapper}>
        <header>
          <Logo />
          <Logo icon />
        </header>

        <PropertySelector
          collapsed={collapsed}
          groupedProperties={groupByOrganisation(properties.entities)}
          selectedPropertyId={properties.selected?.id}
          canCreate={roleHasAccessToRoute(user.role)(
            ROUTE_NAME.MODAL_PROPERTY_CREATE
          )}
          onOpen={() => {
            toggleDrawer(false);
            properties.refresh();
          }}
          onSelect={onSelectProperty}
        />

        <nav>
          <ul>{mainDrawerNavigationItems.map(renderDrawerItem)}</ul>
        </nav>
      </div>

      {user && (
        <footer className={styles.userMenu}>
          <Clickable
            href={toPath(ROUTE_NAME.SETTINGS_USER)}
            onClick={handleClickProfile}
          >
            <Avatar name={user.name} size={collapsed ? 'small' : 'normal'} />
            <VStack flex>
              <Text variant="larger" bold>
                {user.name}
              </Text>
              <Text>{strings.user_settings}</Text>
            </VStack>
          </Clickable>
        </footer>
      )}
    </VStack>
  );
};
