export const MAP_ASSETS__BORE = 'map_assets__bore';
export const MAP_ASSETS__DAM = 'map_assets__dam';
export const MAP_ASSETS__ELECTRIC_FENCE = 'map_assets__electric-fence';
export const MAP_ASSETS__ELECTRIC_FENCE_UNIT =
  'map_assets__electric-fence-unit';
export const MAP_ASSETS__FEEDER = 'map_assets__feeder';
export const MAP_ASSETS__GATE = 'map_assets__gate';
export const MAP_ASSETS__HAZARD = 'map_assets__hazard';
export const MAP_ASSETS__OTHER_LINE = 'map_assets__other-line';
export const MAP_ASSETS__OTHER_POINT = 'map_assets__other-point';
export const MAP_ASSETS__PIPELINE = 'map_assets__pipeline';
export const MAP_ASSETS__ROAD = 'map_assets__road';
export const MAP_ASSETS__SHED = 'map_assets__shed';
export const MAP_ASSETS__SILO = 'map_assets__silo';
export const MAP_ASSETS__TANK = 'map_assets__tank';
export const MAP_ASSETS__WATER_TROUGH = 'map_assets__water-trough';
export const MOBS = 'mobs';
export const PADDOCK_BOUNDARIES = 'paddock_boundaries';
export const PADDOCK_GROUPS = 'paddock_groups';
export const PADDOCK_LABELS = 'paddock_labels';
export const TASKS__OTHERS = 'tasks__others';
export const TASKS__USER = 'tasks__user';

export const mapDetails = [
  MAP_ASSETS__BORE,
  MAP_ASSETS__DAM,
  MAP_ASSETS__ELECTRIC_FENCE_UNIT,
  MAP_ASSETS__ELECTRIC_FENCE,
  MAP_ASSETS__FEEDER,
  MAP_ASSETS__GATE,
  MAP_ASSETS__HAZARD,
  MAP_ASSETS__OTHER_LINE,
  MAP_ASSETS__OTHER_POINT,
  MAP_ASSETS__PIPELINE,
  MAP_ASSETS__ROAD,
  MAP_ASSETS__SHED,
  MAP_ASSETS__SILO,
  MAP_ASSETS__TANK,
  MAP_ASSETS__WATER_TROUGH,
  MOBS,
  PADDOCK_BOUNDARIES,
  PADDOCK_GROUPS,
  PADDOCK_LABELS,
  TASKS__OTHERS,
  TASKS__USER,
] as const;

export const MAP_DETAILS: Record<string, MapDetail> = {
  MAP_ASSETS__BORE,
  MAP_ASSETS__DAM,
  MAP_ASSETS__ELECTRIC_FENCE_UNIT,
  MAP_ASSETS__ELECTRIC_FENCE,
  MAP_ASSETS__FEEDER,
  MAP_ASSETS__GATE,
  MAP_ASSETS__HAZARD,
  MAP_ASSETS__OTHER_LINE,
  MAP_ASSETS__OTHER_POINT,
  MAP_ASSETS__PIPELINE,
  MAP_ASSETS__ROAD,
  MAP_ASSETS__SHED,
  MAP_ASSETS__SILO,
  MAP_ASSETS__TANK,
  MAP_ASSETS__WATER_TROUGH,
  MOBS,
  PADDOCK_BOUNDARIES,
  PADDOCK_GROUPS,
  PADDOCK_LABELS,
  TASKS__OTHERS,
  TASKS__USER,
};

export const MAP_DETAILS_GROUPED: Record<string, MapDetail[]> = {
  PADDOCKS: [PADDOCK_BOUNDARIES, PADDOCK_LABELS, PADDOCK_GROUPS],
  MOBS: [MOBS],
  TASKS: [TASKS__OTHERS, TASKS__USER],
  MAP_ASSETS: [
    MAP_ASSETS__BORE,
    MAP_ASSETS__DAM,
    MAP_ASSETS__ELECTRIC_FENCE_UNIT,
    MAP_ASSETS__ELECTRIC_FENCE,
    MAP_ASSETS__FEEDER,
    MAP_ASSETS__GATE,
    MAP_ASSETS__HAZARD,
    MAP_ASSETS__OTHER_LINE,
    MAP_ASSETS__OTHER_POINT,
    MAP_ASSETS__PIPELINE,
    MAP_ASSETS__ROAD,
    MAP_ASSETS__SHED,
    MAP_ASSETS__SILO,
    MAP_ASSETS__TANK,
    MAP_ASSETS__WATER_TROUGH,
  ],
};

export type MapDetail = (typeof mapDetails)[number];
export type MapDetails = MapDetail[];
