import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { I18nProvider } from '@mobble/i18n';
import logger from '@mobble/shared/src/core/Logger';
import { useAuthStateListener } from '@mobble/shared/src/hooks/useAuthStateListener';
import { useGetUser } from '@mobble/store/src/hooks/auth';

import { DialogProvider } from './context/dialog';
import { useNetworkInformation } from './hooks/useNetworkInformation';
import { useVersion } from './hooks/useVersion';
import { Main } from './screens/Main';
import { LoadingScreen } from './stories/Views/Misc/LoadingScreen';
import { PrinterProvider } from './stories/Views/Misc/Printer';
import { ScrollRestore } from './stories/Views/Misc/ScrollRestore';
import { store, persistor } from './store';
import './service';

const StoredApp = () => {
  useNetworkInformation();
  useAuthStateListener({
    purge: persistor.purge,
  });
  const version = useVersion();
  logger.info('App version:', JSON.stringify(version));

  return <Main />;
};

const IntlApp = () => {
  const user = useGetUser();

  return (
    <I18nProvider
      userLocale={user?.locale || navigator.language}
      defaultRichTextElements={{
        b: (chunks) => <span style={{ fontWeight: 'bold' }}>{chunks}</span>,
      }}
    >
      <DialogProvider>
        <PersistGate
          persistor={persistor}
          loading={<LoadingScreen fullScreen />}
        >
          <PrinterProvider>
            <ScrollRestore>
              <StoredApp />
            </ScrollRestore>
          </PrinterProvider>
        </PersistGate>
      </DialogProvider>
    </I18nProvider>
  );
};

export const App = () => {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <IntlApp />
      </Provider>
    </BrowserRouter>
  );
};
