import { defineMessages } from 'react-intl';
import { type IconName } from '@mobble/icon';
import { useMessages } from '@mobble/i18n';
import { useEntitiesRefresher } from '@mobble/shared/src/hooks/useEntitiesRefresher';
import {
  useGetUser,
  usePaddocks,
  useProperties,
  useTaskGeometries,
  useTask,
} from '@mobble/store/src/hooks';

import { useNavigateBack } from '@src/hooks/useNavigateBack';
import { toPath } from '@src/interfaces/Routing';
import { ScreenHeaderProps } from '@src/stories/Views/Misc/ScreenHeader';
import { EntitySliceFactoryPrelude } from '@src/stories/Views/Misc/EntitySliceFactoryPrelude';

import * as ROUTE_NAME from '../config/routeNames';

const messages = defineMessages({
  taskDetail: {
    defaultMessage: 'Task details',
    description: 'screen.title.task_detail',
  },
  taskCreate: {
    defaultMessage: 'Add a new task',
    description: 'screen.title.task_create',
  },
  taskEdit: {
    defaultMessage: 'Edit "{TASK_TITLE}"',
    description: 'screen.title.task_edit',
  },
  tasks: {
    defaultMessage: 'Tasks',
    description: 'screen.title.tasks',
  },
});

export const useTaskScreenHeader = (
  taskId?: string,
  isEdit?: boolean
): Pick<
  ScreenHeaderProps,
  'title' | 'breadcrumbs' | 'actions' | 'onGoBack'
> => {
  const onGoBack = useNavigateBack();
  const task = useTask(taskId);
  const isNew = !taskId;

  const taskName = task.entity?.title || '';
  const strings = useMessages(messages, {
    TASK_TITLE: taskName,
  });

  const title =
    !isEdit && !isNew
      ? taskName
      : isNew
      ? strings.taskCreate
      : strings.taskEdit;

  const breadcrumbs = [
    {
      title: strings.tasks,
      href: ROUTE_NAME.TASKS_LIST,
    },
  ];

  if (isEdit) {
    breadcrumbs.push({
      title: taskName,
      href: toPath(ROUTE_NAME.TASK_DETAIL, { taskId }),
    });
  }

  const actions =
    !isEdit && !isNew
      ? [
          {
            icon: 'edit' as IconName,
            // title: strings.taskEdit, // TODO
            href: toPath(ROUTE_NAME.TASK_EDIT, {
              taskId,
            }),
          },
        ]
      : undefined;

  return { title, breadcrumbs, actions, onGoBack };
};

export const useTaskScreen = (taskId?: string) => {
  const properties = useProperties();
  const propertyId = properties.selected?.id;
  const paddocks = usePaddocks(propertyId);
  const task = useTask(taskId ?? '_', propertyId);
  const taskGeometries = useTaskGeometries(propertyId);
  const user = useGetUser();
  const isNew = !taskId;

  useEntitiesRefresher(
    [paddocks, !isNew ? task : null].filter(Boolean),
    propertyId
  );

  const prelude = EntitySliceFactoryPrelude({
    preludes: [
      properties.prelude,
      paddocks.prelude,
      !isNew ? task.prelude : null,
    ].filter(Boolean),
    required: [properties.selected],
    notFound: [!isNew ? task.entity : null].filter(Boolean),
  });

  return {
    prelude,
    task,
    paddocks,
    properties,
    taskGeometries,
    user,
  };
};
