import React from 'react';
import isEqual from 'lodash/isEqual';
import { type BaseEntity } from '@mobble/models/src/model/BaseEntity';
import { type SortOption } from '@mobble/shared/src/core/Sort';
import { InlineOption } from '@src/stories/Components/UX/InlineOption';
import { ModalFlyUp } from '@src/stories/Components/UX/ModalFlyUp';
import { EntitySliceFactoryProxyEntitiesResponse } from '@mobble/store/src/lib/entitySliceFactory';

export interface EntitiesSortProps<Entity extends BaseEntity> {
  isOpen?: boolean;
  onClose: () => void;
  items: () => SortOption[];
  provider: EntitySliceFactoryProxyEntitiesResponse<Entity>;
}

export function EntitiesSortComponent<Entity extends BaseEntity>({
  isOpen,
  onClose,
  items,
  provider,
}: EntitiesSortProps<Entity>) {
  const renderItem = (item: SortOption) => {
    return (
      <InlineOption
        type="checkbox"
        value={item.name}
        label={{ key: `entities.entities_sort.options.${item.name}.label` }}
        selected={isEqual(item.settings, provider.sort)}
        onClick={() => {
          provider.setSortSetting(item.settings);
        }}
      />
    );
  };

  return (
    <ModalFlyUp
      isOpen={isOpen}
      bottomSpacer
      onClose={() => onClose()}
      title={{ key: 'entities.entities_sort.title' }}
      listProps={{
        items: items(),
        keyExtractor: (item) => JSON.stringify(item),
        renderItem,
      }}
    />
  );
}

export const EntitiesSort = React.memo(EntitiesSortComponent, (a, b) => {
  return a.isOpen === b.isOpen && a.provider.sort === b.provider.sort;
}) as typeof EntitiesSortComponent;
