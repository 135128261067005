import React from 'react';
import { useI18n } from '@mobble/i18n';
import {
  mapAssetTypeToGeometryFeatureType,
  type MapAssetType,
  mapAssetsTypes,
} from '@mobble/models/src/model/MapAsset';
import { useTitle } from '@src/hooks/useTitle';
import { Box } from '@src/stories/Components/Layout/Box';
import { Text } from '@src/stories/Components/UI/Text';
import { ModalSelectOption } from '@src/stories/Components/UX/ModalSelect';
import { ListSelect } from '@src/stories/Components/UX/ListSelect';
import { Button } from '@src/stories/Components/UX/Button';
import { type MapStateComponentProps, type SharedState } from '../SharedTypes';
import styles from './style.scss';

export const Header: React.FC<MapStateComponentProps> = () => {
  const title = { key: 'screen.title.map_asset_create' };
  useTitle(title);

  return (
    <Box className={styles.header}>
      <Text tagName="h1" variant="larger" bold i18n={title} />
      <Text tagName="p" i18n={{ key: 'map.overview.add_asset.instructions' }} />
    </Box>
  );
};

export const AssetTypeSelect: React.FC<{
  selectedMapAssetType: SharedState['selectedMapAssetType'];
  updateState: MapStateComponentProps['updateState'];
}> = ({ selectedMapAssetType, updateState }) => {
  const { formatMessage } = useI18n();
  const typeToI18n = (type: MapAssetType) => ({
    id: `map_assets.type.${type}`,
    defaultMessage: [],
  });

  const typeToModeI18n = (type: MapAssetType) => {
    switch (mapAssetTypeToGeometryFeatureType(type)) {
      default:
      case 'Point':
        return { id: 'map_asset.mode.point', defaultMessage: [] };
      case 'LineString':
        return { id: 'map_asset.mode.line_string', defaultMessage: [] };
    }
  };

  const onChange = ([type]: [MapAssetType]) => {
    updateState({
      selectedMapAssetType: type,
    });
  };

  const options: ModalSelectOption[] = mapAssetsTypes.map((type) => ({
    label: formatMessage(typeToI18n(type)),
    value: type,
    labelExtra: formatMessage(typeToModeI18n(type)),
    selected: selectedMapAssetType === type,
  }));

  return (
    <ListSelect
      bordered
      menuPlacement="top"
      placeholder={{
        key: 'map.overview.add_asset.header.map_asset_type.placeholder',
      }}
      selectionLabel={{
        key: 'map.overview.add_asset.header.map_asset_type.selection',
      }}
      options={options}
      onChange={onChange}
    />
  );
};

export const Footer: React.FC<MapStateComponentProps> = (props) => {
  const { stateRef, onSubmit, onCancel } = props;
  const [ready, setReady] = React.useState(false);

  React.useEffect(() => {
    // const ref = mapPluginStateRef.current;
    const onUpdate = ({ points }) => {
      setReady(points.length > 0);
    };

    stateRef.current?.addListener(onUpdate);

    return () => {
      stateRef.current?.removeListener(onUpdate);
    };
  }, [stateRef]);

  return (
    <div className={styles.footer}>
      <div className={styles.typeSelect}>
        <AssetTypeSelect
          selectedMapAssetType={props.state.selectedMapAssetType}
          updateState={props.updateState}
        />
      </div>
      <div className={styles.footerButtons}>
        <Button
          flex
          intent="destructive"
          outline
          type="reset"
          onClick={onCancel}
          label={{ key: 'map.overview.add_asset.footer.cancel.label' }}
        />
        <Button
          flex
          type="submit"
          disabled={!ready}
          onClick={onSubmit}
          label={{ key: 'map.overview.add_asset.footer.submit.label' }}
        />
      </div>
    </div>
  );
};
