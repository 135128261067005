import React from 'react';
import { type FormikErrors } from 'formik';
import {
  type ConfiguredPropertyType,
  ConfiguredPropertyTypeGroup,
  type ConfiguredPropertyTypeGroupCustom,
  getLivestockParentIdForValue,
  getLivestockAttributes,
} from '@mobble/models/src/model/Property';
import { getLivestockAgeColor } from '@mobble/models/src/model/Mob';
import {
  type SortSetting,
  type SortOption,
} from '@mobble/shared/src/core//Sort';
import { Color } from '@mobble/colors';
import { FormBuilder, type FormBuilderProps } from '../../Form/FormBuilder';
import {
  type ListSelectOption,
  type LabelLike,
} from '../../../Components/UX/ListSelect';

export interface MobCreateWizardMobFormProps {
  paddockOptions: ListSelectOption[];
  paddockSortOptions: SortOption[];
  sortPaddockOptions: (
    options: ListSelectOption[],
    sortSettings: SortSetting[]
  ) => ListSelectOption[];
  propertyTypes: ConfiguredPropertyType[];
  formValues?: MobCreateWizardMobFormFormValues;
  onChange: (
    values: MobCreateWizardMobFormFormValues,
    errors: FormikErrors<MobCreateWizardMobFormFormValues>
  ) => void;
  onCreateCustomField: (
    group: ConfiguredPropertyTypeGroupCustom,
    livestockTypeId: string,
    label: string
  ) => Promise<void>;
}

export interface MobCreateWizardMobFormFormValues {
  paddock: string;
  type: string;
  breed: string;
  gender: string;
  ages: number[];
  classes: string[];
  size: number;
  dse: number;
}

export const MobCreateWizardMobForm: React.FC<MobCreateWizardMobFormProps> = ({
  paddockOptions,
  paddockSortOptions,
  sortPaddockOptions,
  propertyTypes,
  formValues,
  onChange,
  onCreateCustomField,
}) => {
  const getAgeColor = getLivestockAgeColor(propertyTypes, formValues?.type);
  const handleCreateCustomField =
    (type: ConfiguredPropertyTypeGroupCustom) =>
    (value: string): Promise<void> => {
      if (!formValues?.type) {
        return Promise.reject();
      }
      const liveStockTypeId = getLivestockParentIdForValue(
        formValues.type,
        propertyTypes
      );
      if (!liveStockTypeId) {
        return Promise.reject();
      }

      return onCreateCustomField(type, liveStockTypeId, value);
    };

  const formProps: FormBuilderProps<MobCreateWizardMobFormFormValues> = {
    i18nRootKey: 'mobs.mob.create.form.mob_form',
    footer: false,
    fields: [
      {
        name: 'paddock',
        type: 'select',
        initialValue: formValues?.paddock,
        options: paddockOptions,
        sortOptions: paddockSortOptions,
        sortFunction: sortPaddockOptions,
        required: true,
      },
      {
        name: 'type',
        type: 'select',
        initialValue: formValues?.type,
        sortFunction: (options) => {
          const fixedTypes: LabelLike[] = ['Cattle', 'Sheep', 'Goats', 'Other'];
          return options.sort((a, b) => {
            return fixedTypes.indexOf(a.label) - fixedTypes.indexOf(b.label);
          });
        },
        options: propertyTypes
          .filter((a) => a.group === ConfiguredPropertyTypeGroup.livestockType)
          .map((a) => ({
            label: a.label,
            value: a.type,
          })),
        required: true,
        onChange: (value) => {
          if (value.type === 'Cattle') {
            return { ...value, dse: 8 };
          }
          return { ...value, dse: 1 };
        },
      },
      {
        name: 'breed',
        type: 'select',
        initialValue: formValues?.breed,
        options: (values) =>
          getLivestockAttributes(propertyTypes)(
            ConfiguredPropertyTypeGroup.breed,
            getLivestockParentIdForValue(values.type, propertyTypes)
          ),
        show: (values) => Boolean(values.type),
        required: true,
        addNew: {
          onSubmit: handleCreateCustomField(ConfiguredPropertyTypeGroup.breed),
          placeholder: {
            key: 'mobs.mob.create.form.mob_form.breed.add_new.placeholder.label',
          },
          button: {
            key: 'mobs.mob.create.form.mob_form.breed.add_new.button.label',
          },
        },
      },
      {
        name: 'gender',
        type: 'select',
        initialValue: formValues?.gender,
        options: (values) =>
          getLivestockAttributes(propertyTypes)(
            ConfiguredPropertyTypeGroup.gender,
            getLivestockParentIdForValue(values.type, propertyTypes)
          ),
        show: (values) => Boolean(values.type),
        required: true,
        addNew: {
          onSubmit: handleCreateCustomField(ConfiguredPropertyTypeGroup.gender),
          placeholder: {
            key: 'mobs.mob.create.form.mob_form.gender.add_new.placeholder.label',
          },
          button: {
            key: 'mobs.mob.create.form.mob_form.gender.add_new.button.label',
          },
        },
      },
      {
        name: 'ages',
        type: 'select-multiple',
        initialValue: formValues?.ages,
        options: (values) =>
          getLivestockAttributes(propertyTypes)(
            ConfiguredPropertyTypeGroup.tag,
            getLivestockParentIdForValue(values.type, propertyTypes)
          ).map((i) => ({
            ...i,
            color: getAgeColor(i.label) || Color.White,
          })),
        sortFunction: (options) =>
          options.sort((a: any, b: any) => b.label.localeCompare(a.label)),
        show: (values) => Boolean(values.type),
        required: true,
      },
      {
        name: 'classes',
        type: 'select-multiple',
        initialValue: formValues?.classes,
        options: (values) => {
          const parentId = propertyTypes.find(
            (a) => a.type === values.type
          )?.id;
          return propertyTypes
            .filter(
              (a) =>
                a.group === ConfiguredPropertyTypeGroup.class &&
                a.parentId === parentId
            )
            .map((a) => ({
              label: a.label,
              value: a.type,
            }));
        },
        show: (values) => Boolean(values.type),
        addNew: {
          onSubmit: handleCreateCustomField(ConfiguredPropertyTypeGroup.class),
          placeholder: {
            key: 'mobs.mob.create.form.mob_form.classes.add_new.placeholder.label',
          },
          button: {
            key: 'mobs.mob.create.form.mob_form.classes.add_new.button.label',
          },
        },
      },
      {
        name: 'size',
        type: 'number',
        min: 1,
        required: true,
        show: (values) => Boolean(values.type),
        initialValue: formValues?.size,
      },
      {
        name: 'dse',
        type: 'float',
        show: (values) => Boolean(values.type),
        initialValue: formValues?.dse,
        required: true,
      },
    ],
    onSubmit: () => {},
    onChange,
  };

  return <FormBuilder {...formProps} />;
};
