import React from 'react';
import { type Color } from '@mobble/colors';
import { Box } from '../Layout/Box';
import { Icon, IconName } from '../UI/Icon';
import { type I18nItem } from '@mobble/i18n';
import { HStack } from '../Layout/Stack';
import { Text } from '../UI/Text';
import { BottomTabBarSpacer } from '../Layout/BottomTabBarSpacer';
import { Spacer } from '../Layout/Spacer';
import { List, type ListProps } from '../Layout/List';
import { Modal } from './Modal';
import { Clickable } from './Clickable';

import styles from './modalFlyUp.scss';

export type ModalFlyUpProps = React.PropsWithChildren<{
  onClose: () => void;
  title?: I18nItem | string;
  backgroundColor?: Color | string;
  isOpen?: boolean;
  fullScreen?: boolean;
  header?: React.ReactNode;
  additionalHeader?: React.ReactNode;
  footer?: React.ReactNode;
  bottomSpacer?: boolean;
  listProps?: ListProps;
  closeIcon?: IconName;
  actAsDropdown?: boolean;
  onTop?: boolean;
  hideHeader?: boolean;
  className?: string;
}>;

export const ModalFlyUp: React.FC<ModalFlyUpProps> = ({
  title,
  onClose,
  isOpen,
  children,
  fullScreen,
  header,
  additionalHeader,
  footer,
  listProps,
  bottomSpacer,
  closeIcon = 'close',
  actAsDropdown,
  backgroundColor,
  onTop,
  hideHeader,
  className,
}) => {
  const defaultBottomSpacer = bottomSpacer ?? !onTop;

  const headerComponent = (
    <Box>
      {!hideHeader && (
        <HStack>
          {header ? (
            header
          ) : (
            <Box spacing={2}>
              <Text tagName="h1" variant="card-title" i18n={title} />
            </Box>
          )}
          <Spacer flex />
          <Clickable href={onClose} className={styles.closeButton}>
            <Box spacing={2}>
              <Icon name={closeIcon} />
            </Box>
          </Clickable>
        </HStack>
      )}

      {additionalHeader}
    </Box>
  );

  const footerExtended = defaultBottomSpacer ? (
    <>
      {footer}
      <BottomTabBarSpacer />
    </>
  ) : (
    footer
  );

  const fullScreenHeight = '100%';

  return (
    <Modal
      backgroundColor={backgroundColor}
      actAsDropdown={actAsDropdown}
      show={isOpen}
      header={headerComponent}
      footer={footerExtended}
      onTop={onTop}
      onClose={onClose}
      className={className}
    >
      {listProps ? (
        <List {...listProps} />
      ) : (
        <Box style={!fullScreen ? { maxHeight: fullScreenHeight } : {}}>
          {children}
        </Box>
      )}
    </Modal>
  );
};
