import { I18nLocale } from '@mobble/i18n';
import { stripSpecialCharacters } from '@mobble/shared/src/core/String';

/* eslint-disable no-bitwise */
export interface User {
  id: string;
  name: string;
  email: string;
  role?: UserRole;
  subcriptionStatus?: SubscriptionStatus;
  expires?: string;
  tz?: string;
  meta?: UserMeta;
  locale?: I18nLocale;
  billingRegion?: BillingRegion;
}

export enum BillingRegion {
  AU = 'AU',
  NZ = 'NZ',
}

export enum UserRole {
  Owner = 'owner',
  Admin = 'admin',
  User = 'user',
  ViewOnly = 'view_only',
  FarmAdvisor = 'farm_advisor',
  StockAgent = 'stock_agent',
  Accountant = 'accountant',
  Contractor = 'map_view',
  Auditor = 'auditor',
}

export const ALL_USER_ROLES = [
  UserRole.Owner,
  UserRole.Admin,
  UserRole.User,
  UserRole.ViewOnly,
  UserRole.FarmAdvisor,
  UserRole.StockAgent,
  UserRole.Accountant,
  UserRole.Contractor,
  UserRole.Auditor,
];

export enum SubscriptionStatus {
  Trialing = 'trialing',
  Active = 'active',
  Incomplete = 'incomplete',
  IncompleteExpired = 'incomplete_expired',
  PastDue = 'past_due',
  Canceled = 'canceled',
  Unpaid = 'unpaid',
}

export interface UserMeta {
  address?: string;
  country?: string;
  farmRole?: string;
  phone?: string;
}

export const toInitials = (name: string) => {
  if (name.trim().indexOf(' ') > 0) {
    const [firstPart, ...rest] = stripSpecialCharacters(name).trim().split(' ');
    const lastPart = rest[rest.length - 1];

    if (firstPart.length > 0 && lastPart.length > 0) {
      return `${firstPart[0]}${lastPart[0]}`.toUpperCase();
    }
    return firstPart[0].toUpperCase();
  }
  return name.trim().slice(0, 2).toUpperCase();
};

export const toColor = (name: string) => {
  const firstAlphabet = name.charAt(0).toLowerCase();
  const asciiCode = firstAlphabet.charCodeAt(0);

  const colorNum =
    asciiCode.toString() + asciiCode.toString() + asciiCode.toString();

  const num = Math.round(0xffffff * parseInt(colorNum, 10));
  const r = (num >> 8) & 255;
  const g = num & 255;
  const b = (num >> 16) & 255;

  return `rgb(${r},${g},${b})`;
};

export const findUser = (users: User[]) => (id: string) => {
  return users.find((user) => user.id === id);
};

export const findUserOfRole = (users: User[]) => (role: UserRole) =>
  users?.find((roles) => roles.role === role) || null;

export const sortByName = (a: User, b: User) => {
  return a.name?.localeCompare(b?.name);
};

export const filterUsersOfUserRoles = (users: User[]) =>
  users?.filter((p) => userRoles.includes(p?.role));

export const userRoles: UserRole[] = [
  UserRole.Owner,
  UserRole.Admin,
  UserRole.User,
  UserRole.ViewOnly,
];

export const advisorRoles = [
  UserRole.FarmAdvisor,
  UserRole.StockAgent,
  UserRole.Accountant,
  UserRole.Contractor,
  UserRole.Auditor,
];

export const advisorsThatCanImmediatelyAddTasks = [
  UserRole.FarmAdvisor,
  UserRole.Contractor,
];

export const userCanAddImmediateTask = (role: UserRole) =>
  advisorsThatCanImmediatelyAddTasks.includes(role);

export const userRoleToRank = (role: UserRole): number => {
  const rankedUserRoles = [
    UserRole.Owner,
    UserRole.Admin,
    UserRole.User,
    UserRole.ViewOnly,
  ];

  const index = rankedUserRoles.indexOf(role);
  if (index < 0) {
    return -1;
  }
  return rankedUserRoles.length - index;
};

export const checkUserCanEditRole =
  (myRole: UserRole) => (theirRole: UserRole) => {
    const myRank = userRoleToRank(myRole);
    const theirRank = userRoleToRank(theirRole);

    if (myRole === UserRole.Admin) {
      return myRank >= theirRank;
    }
    return myRank > theirRank;
  };

export const checkUserCanInviteRole =
  (currentUsersRole: UserRole) => (role: UserRole) => {
    switch (currentUsersRole) {
      case UserRole.Owner:
        return true;
      case UserRole.Admin:
        return role !== UserRole.Owner;
      case UserRole.User:
        return role !== UserRole.Owner && role !== UserRole.Admin;
      default:
        return false;
    }
  };

export const checkEmailIsValid = (email: string) =>
  Boolean(
    email
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
  );
