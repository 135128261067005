import React from 'react';
import { type InventoryItem } from '@mobble/models/src/model/InventoryItem';
import {
  InventoryCategory,
  type Inventory,
} from '@mobble/models/src/model/Inventory';
import { InfoRow, type InfoRowProps } from '../../Components/UI/InfoRow';
import { formatDate } from '@mobble/shared/src/core/Date';
import { InventoryItemBatch } from '@mobble/models/src/model/InventoryItemBatch';
import { defineMessages } from 'react-intl';
import {
  makeQuantityFormatMessageItem,
  useI18n,
  useMessages,
} from '@mobble/i18n/src';
import { useSettings } from '@mobble/store/src/hooks/settings';
import { formatMonetary } from '@mobble/shared/src/core/Monetary';

export interface InventoryItemInfoTableProps {
  inventory: Inventory;
  inventoryItem: InventoryItem;
  inventoryItemBatch: InventoryItemBatch;
}

const messages = defineMessages({
  inventoryItemBatch: {
    id: 'inventory_item_batch.info.table.row.inventoryItemBatch.label',
    defaultMessage: 'Inventory Item Batch',
  },
  name: {
    id: 'inventory_item_batch.info.table.row.name.label',
    defaultMessage: 'Batch ID',
  },
  chemicalName: {
    id: 'inventory_item_batch.info.table.row.chemicalName.label',
    defaultMessage: 'Chemical Name',
  },
  inventory: {
    id: 'inventory_item_batch.info.table.row.inventory.label',
    defaultMessage: 'Inventory',
  },
  quantity: {
    id: 'inventory_item_batch.info.table.row.quantity.label',
    defaultMessage: 'Quantity',
  },
  dateReceived: {
    id: 'inventory_item_batch.info.table.row.dateReceived.label',
    defaultMessage: 'Date Received',
  },
  expiryDate: {
    id: 'inventory_item_batch.info.table.row.expiryDate.label',
    defaultMessage: 'Expiry Date',
  },
  dateOfManufacture: {
    id: 'inventory_item_batch.info.table.row.dateOfManufacture.label',
    defaultMessage: 'Date of Manufacture',
  },
  supplierName: {
    id: 'inventory_item_batch.info.table.row.supplierName.label',
    defaultMessage: 'Supplier',
  },
  pricePerUnitCents: {
    id: 'inventory_item_batch.info.table.row.pricePerUnitCents.label',
    defaultMessage: 'Price per unit',
  },
  notes: {
    id: 'inventory_item_batch.info.table.row.notes.label',
    defaultMessage: 'Notes',
  },
});

export const InventoryItemBatchInfoTable: React.FC<
  InventoryItemInfoTableProps
> = ({ inventory, inventoryItem, inventoryItemBatch }) => {
  const strings = useMessages(messages);
  const { formatMessage } = useI18n();
  const { settings } = useSettings();
  const { dateFormat, currencySymbol } = settings;

  const rows: (InfoRowProps | InfoRowProps[])[] = (() => {
    switch (inventoryItemBatch.category) {
      case InventoryCategory.Chemicals:
        return [
          {
            label: strings.name,
            value: inventoryItemBatch.name,
          },
          {
            label: strings.chemicalName,
            value: inventoryItem.name,
          },
          {
            label: strings.inventory,
            value: inventory.name,
          },
          {
            label: strings.quantity,
            value:
              inventoryItemBatch.quantity &&
              formatMessage(
                ...makeQuantityFormatMessageItem(inventoryItemBatch.quantity)
              ),
          },
          {
            label: strings.dateReceived,
            value: inventoryItemBatch.dateReceived
              ? formatDate(inventoryItemBatch.dateReceived, dateFormat)
              : '-',
          },
          {
            label: strings.expiryDate,
            value: inventoryItemBatch.dateExpiry
              ? formatDate(inventoryItemBatch.dateExpiry, dateFormat)
              : '-',
          },
          {
            label: strings.dateOfManufacture,
            value: inventoryItemBatch.dateOfManufacture
              ? formatDate(inventoryItemBatch.dateOfManufacture, dateFormat)
              : '-',
          },
          {
            label: strings.notes,
            value: inventoryItemBatch.notes,
          },
        ];
      case InventoryCategory.Feed:
        return [
          {
            label: strings.supplierName,
            value: inventoryItemBatch.supplierName,
          },
          {
            label: strings.inventory,
            value: inventory.name,
          },
          {
            label: strings.quantity,
            value:
              inventoryItemBatch.quantity &&
              formatMessage(
                ...makeQuantityFormatMessageItem(inventoryItemBatch.quantity)
              ),
          },
          {
            label: strings.dateReceived,
            value: inventoryItemBatch.dateReceived
              ? formatDate(inventoryItemBatch.dateReceived, dateFormat)
              : '-',
          },
          {
            label: strings.pricePerUnitCents,
            value: formatMonetary(
              inventoryItemBatch.pricePerUnitCents,
              currencySymbol
            ),
          },
          {
            label: strings.notes,
            value: inventoryItemBatch.notes,
          },
        ];
    }
  })();

  return (
    <>
      {rows.map((row) =>
        Array.isArray(row) ? (
          <>
            {row.map((r) => (
              <InfoRow {...r} key={r.label as string} />
            ))}
          </>
        ) : (
          <InfoRow key={row.label} {...row} />
        )
      )}
    </>
  );
};
