import React from 'react';
import { useI18n } from '@mobble/i18n';
import { formatDate, formatTime } from '@mobble/shared/src/core/Date';
import {
  areaUnits,
  lengthUnits,
  distanceUnits,
} from '@mobble/shared/src/core/Quantity';
import { type Settings } from '@mobble/models/src/model/Settings';
import {
  FormBuilder,
  type FormBuilderProps,
} from '@src/stories/Views/Form/FormBuilder';

export interface SettingsUnitsFormProps {
  settings: Settings;
  loading?: boolean;
  onSubmit: (formValues: SettingsUnitsFormValues) => void;
  onCancel: () => void;
}

export type SettingsUnitsFormValues = Settings;

export const SettingsUnitsForm: React.FC<SettingsUnitsFormProps> = ({
  settings,
  loading,
  onSubmit,
  onCancel,
}) => {
  const { translate } = useI18n();

  const areaUnitOptions = areaUnits.map((value) => ({
    value,
    label: translate({ key: `generic.quantity.unit.${value}.long` }) || value,
  }));

  const lengthUnitOptions = lengthUnits.map((value) => ({
    value,
    label: translate({ key: `generic.quantity.unit.${value}.long` }) || value,
  }));

  const distanceUnitOptions = distanceUnits.map((value) => ({
    value,
    label: translate({ key: `generic.quantity.unit.${value}.long` }) || value,
  }));

  const dateFormatOptions = [
    'DD/MM/YYYY',
    'D MMM YYYY',
    'ddd D MMM YYYY',
    'MM/DD/YYYY',
    'YYYY/MM/DD',
  ].map((value) => ({
    value,
    label: formatDate(new Date(), value),
    labelExtra: value,
  }));

  const timeFormatOptions = ['HH:mm', 'h:mm A'].map((value) => ({
    value,
    label: formatTime(new Date(), value),
    labelExtra: value,
  }));

  const form: FormBuilderProps<SettingsUnitsFormValues> = {
    flex: true,
    i18nRootKey: 'user.user_settings.appearance.form',
    fields: [
      {
        name: 'areaUnit',
        type: 'select',
        options: areaUnitOptions,
        initialValue: settings.areaUnit,
      },
      {
        name: 'lengthUnit',
        type: 'select',
        options: lengthUnitOptions,
        initialValue: settings.lengthUnit,
      },
      {
        name: 'distanceUnit',
        type: 'select',
        options: distanceUnitOptions,
        initialValue: settings.distanceUnit,
      },
      {
        name: 'dateFormat',
        type: 'select',
        options: dateFormatOptions,
        initialValue: settings.dateFormat,
      },
      {
        name: 'timeFormat',
        type: 'select',
        options: timeFormatOptions,
        initialValue: settings.timeFormat,
      },
      {
        name: 'currencySymbol',
        type: 'text',
        maxLength: 1,
        initialValue: settings.currencySymbol,
      },
    ].filter(Boolean) as FormBuilderProps['fields'],
    loading,
    onSubmit,
    onCancel,
  };

  return <FormBuilder {...form} />;
};
