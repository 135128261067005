import React from 'react';
import { Color } from '@mobble/colors';
import { Box } from '@src/stories/Components/Layout/Box';
import { type I18nItem } from '@mobble/i18n';
import { formatNumber, roundNumber } from '@mobble/shared/src/core/Number';
import { Icon } from '@src/stories/Components/UI/Icon';
import { Text } from '@src/stories/Components/UI/Text';
import styles from './infoTile.scss';

export interface InfoTileProps {
  id: string;
  label: I18nItem | string;
  value: string | number;
  previousValue?: number;
}

export const InfoTile: React.FC<InfoTileProps> = ({
  label,
  value,
  previousValue,
}) => {
  const difference = (() => {
    if (!previousValue || previousValue === value) {
      return null;
    }

    const diff = Number(value) - previousValue;
    const percentage = roundNumber(Math.abs(diff / previousValue) * 100);

    if (diff < 0) {
      return (
        <Box className={[styles.tileDifference, styles.tileDifferenceDown]}>
          <Icon color={Color.Red} name="arrow-down" />
          <span>{percentage}%</span>
        </Box>
      );
    }
    return (
      <Box className={[styles.tileDifference, styles.tileDifferenceUp]}>
        <Icon color={Color.Green} name="arrow-up" />
        <span>{percentage}%</span>
      </Box>
    );
  })();

  return (
    <Box className={styles.tile}>
      <Text className={styles.tileHeading} i18n={label} />
      <Box className={styles.tileValue}>
        {typeof value === 'number' ? formatNumber(value) : value}
      </Box>
      {difference}
    </Box>
  );
};

export interface InfoTilesProps {
  items: InfoTileProps[];
}

export const InfoTiles: React.FC<InfoTilesProps> = ({ items }) => {
  return (
    <Box className={styles.tilesContainer}>
      {items.map((props) => (
        <InfoTile key={props.id} {...props} />
      ))}
    </Box>
  );
};
