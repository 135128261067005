import Fuse from 'fuse.js';
import { Color } from '@mobble/colors';
import { arrayUnique } from '@mobble/shared/src/core/Array';

import { filterMatches, groupFilter, type FilterItem } from './Filter';
import { type Inventory } from './Inventory';
import { type Mob } from './Mob';
import { type Paddock } from './Paddock';
import { type User } from './User';

export interface HistoricEvent {
  id: string;
  propertyId: string;

  date: string;
  eventType: string;
  title: string;
  description: string;
  createdBy: User;

  related: HistoricEventRelated;
  linksTo?: {
    type: string;
    id: string;

    parentType?: string;
    parentId?: string;
  };
}

export interface HistoricEventRelated {
  paddockId?: Paddock['id'][];
  mobId?: Mob['id'][];
  inventoryId?: Inventory['id'];
}

export const mergeHistoricEvent = (
  a: HistoricEvent,
  b: HistoricEvent
): HistoricEvent => {
  return {
    ...a,
    related: {
      paddockId: arrayUnique([
        ...(a.related.paddockId || []),
        ...(b.related.paddockId || []),
      ]),
      mobId: arrayUnique([
        ...(a.related.mobId || []),
        ...(b.related.mobId || []),
      ]),
    },
  };
};

export const mergeAllHistoricEvents = (
  historicEvents: HistoricEvent[]
): HistoricEvent[] => {
  const historicEventsById = historicEvents.reduce<
    Record<string, HistoricEvent>
  >((acc, historicEvent) => {
    const existingEvent = acc[historicEvent.id];
    if (existingEvent) {
      return {
        ...acc,
        [historicEvent.id]: mergeHistoricEvent(existingEvent, historicEvent),
      };
    }
    return { ...acc, [historicEvent.id]: historicEvent };
  }, {});
  return Object.values(historicEventsById).sort(sortByDate);
};

export const eventTypeToColor = (eventType: string): undefined | Color => {
  switch (eventType) {
    case 'CasualtyAddition':
    case 'CasualtyDeletion':
    case 'CasualtyEdit':
      return Color.Black;

    case 'ChemicalAddition':
    case 'ChemicalDeletion':
    case 'ChemicalEdit':
      return Color.LightBlue;

    case 'ChemicalBatchAddition':
    case 'ChemicalBatchEdit':
      return Color.WashedRed;

    case 'CommentAddition':
      return Color.LightGreen;

    case 'FeedAddition':
    case 'FeedDeletion':
    case 'FeedEdit':
    case 'FeedFinished':
      return Color.Orange;

    // case 'FeedBatchAddition':
    // case 'FeedBatchEdit':
    //   return Color.WashedRed;

    case 'MapAssetAddition':
    case 'MapAssetEdit':
      return Color.LightGreen;

    case 'MobActionAddition':
    case 'MobActionDeletion':
    case 'MobActionEdit':
    case 'MobAddition':
    case 'MobArchiving':
    case 'MobDeletion':
    case 'MobEdit':
    case 'MobMerge':
    case 'MobMove':
    case 'MobMoveToProp':
    case 'MobSplit':
      return Color.Purple;

    case 'PaddockActionAddition':
    case 'PaddockActionDeletion':
    case 'PaddockActionEdit':
    case 'PaddockAddition':
    case 'PaddockDeletion':
    case 'PaddockEdit':
      return Color.Green;

    case 'PropertyAddition':
      return Color.Green;

    case 'RainGaugeAddition':
    case 'RainGaugeDeletion':
    case 'RainGaugeEdit':
    case 'RainGaugeReadingAddition':
    case 'RainGaugeReadingDeletion':
    case 'RainGaugeReadingEdit':
      return Color.Blue;

    case 'SaleAddition':
    case 'SaleDeletion':
    case 'SaleEdit':
      return Color.Yellow;

    case 'NaturalIncreaseAddition':
      return Color.Orange;

    case 'PurchaseAddition':
      return Color.Red;

    case 'TaskAddition':
    case 'TaskDeletion':
    case 'TaskEdit':
      return Color.Red;

    default:
      return Color.Black;
  }
};

export const sortByDate = (a: HistoricEvent, b: HistoricEvent): number => {
  return new Date(b.date).getTime() - new Date(a.date).getTime();
};

export const eventTypes = [
  'CasualtyAddition',
  'CasualtyDeletion',
  'CasualtyEdit',
  'ChemicalAddition',
  'ChemicalBatchAddition',
  'ChemicalBatchEdit',
  'ChemicalDeletion',
  'ChemicalEdit',
  'CommentAddition',
  'FeedAddition',
  // 'FeedBatchAddition',
  // 'FeedBatchEdit',
  'FeedDeletion',
  'FeedEdit',
  'FeedFinished',
  'MapAssetAddition',
  'MapAssetEdit',
  'MobActionAddition',
  'MobActionDeletion',
  'MobActionEdit',
  'MobAddition',
  'MobArchiving',
  'MobDeletion',
  'MobEdit',
  'MobMerge',
  'MobMove',
  'MobMoveToProp',
  'MobSplit',
  'NaturalIncreaseAddition',
  'PaddockActionAddition',
  'PaddockActionDeletion',
  'PaddockActionEdit',
  'PaddockAddition',
  'PaddockDeletion',
  'PaddockEdit',
  'PropertyAddition',
  'PurchaseAddition',
  'RainGaugeAddition',
  'RainGaugeDeletion',
  'RainGaugeEdit',
  'RainGaugeReadingAddition',
  'RainGaugeReadingDeletion',
  'RainGaugeReadingEdit',
  'SaleAddition',
  'SaleDeletion',
  'SaleEdit',
  'TaskAddition',
  'TaskDeletion',
  'TaskEdit',
];

export const filterHistoricEvents = (
  historicEvents: HistoricEvent[],
  filter?: FilterItem[]
): HistoricEvent[] => {
  if (!filter || filter.length === 0) {
    return historicEvents;
  }
  const grouped = [...groupFilter(filter)];

  const searchQuery = filter.find((a) => a.group === 'search')?.filter;

  const preFilteredHistoricEvents =
    searchQuery && searchQuery.type === 'search'
      ? searchHistoricEvents(historicEvents, searchQuery.value)
      : historicEvents;

  return preFilteredHistoricEvents.filter((historicEvent) =>
    grouped.every(([_, filters]) =>
      filters.some(filterItemMatchesHistoricEvent(historicEvent))
    )
  );
};

export const filterItemMatchesHistoricEvent =
  (historicEvent: HistoricEvent) => (filterItem: FilterItem) => {
    const matches = filterMatches(filterItem.filter);

    switch (filterItem.group) {
      case 'event_type':
        return matches(historicEvent.eventType);
      case 'search':
        return true;
      default:
        return true;
    }
  };

export const searchHistoricEvents = (
  historicEvents: HistoricEvent[],
  searchQuery: string
): HistoricEvent[] => {
  const fuse = new Fuse(historicEvents, {
    keys: [
      { name: 'title', weight: 2 },
      { name: 'description', weight: 3 },
      { name: 'eventType', weight: 1 },
    ],
    threshold: 0.5,
    shouldSort: true,
  });
  return fuse.search(searchQuery).map((result) => result.item);
};
