import { useLocation, useMatch, useNavigate } from 'react-router-dom';
import * as ROUTE_NAMES from '@src/screens/config/routeNames';

const LIST_ROUTES = Object.fromEntries(
  Object.entries(ROUTE_NAMES).filter(([key]) => key.endsWith('_LIST'))
);

export const useNavigateBack = () => {
  const { pathname, state } = useLocation();
  const navigate = useNavigate();

  // Use the `_LIST` route as the fallback if `state.from` not available
  const fallbackRoute = Object.entries(LIST_ROUTES).find(([, route]) => {
    return pathname.startsWith(route as string);
  }) || [];
  const fallbackPath = fallbackRoute[1] as string || ROUTE_NAMES.DASHBOARD;

  // Inventory/Map pages route structure is non-standard
  const inventoryEdit = useMatch(ROUTE_NAMES.INVENTORY_EDIT);
  const inventoryItemEdit = useMatch(ROUTE_NAMES.INVENTORY_ITEM_EDIT);
  const mapAssetCreate = useMatch(ROUTE_NAMES.MODAL_MAP_ASSET_CREATE);
  const classesTypesSettings = useMatch(ROUTE_NAMES.SETTINGS_PROPERTY_CLASSES_TYPES_EDIT);

  return () => {
    let path = fallbackPath;

    // Handle inventory items
    if (inventoryItemEdit) {
      path = pathname.replace('/edit', '')
    // Handle inventories
    } else if (inventoryEdit) {
      path = pathname.replace('/edit', '/items')
    // Handle map asset create
    } else if (mapAssetCreate) {
      path = '/map';
    // Return to the entity detail page if navigating back from entity edit
    } else if (pathname.endsWith('/edit') && !classesTypesSettings) {
      path = pathname.replace('/edit', '');
    // Return to the summary list if navigating back from summary entity detail
    } else if (pathname.startsWith(ROUTE_NAMES.SUMMARIES_LIST)) {
      path = pathname.split('/').slice(0, -1).join('/');
    } else if (state?.from) {
      path = state.from;
    }

    navigate(path);
  }
};
