import React from 'react';
import { type Paddock } from '@mobble/models';
import { toISO8601 } from '@mobble/shared/src/core/Date';
import {
  type SortOption,
  type SortSetting,
} from '@mobble/shared/src/core/Sort';
import { type ListSelectOption } from '../../Components/UX/ListSelect';
import {
  FormBuilder,
  FormBuilderFieldProps,
  type FormBuilderProps,
} from '../Form/FormBuilder';
import {
  InputInventoryItems,
  validateInputInventoryItems,
} from '../Misc/InputInventoryItems';
import { useInputInventoryItems } from './useInputInventoryItems';
import { PaddockActionEditFormValues } from './PaddockActionEditForm';

import styles from './paddockActionCreateForm.scss';

export interface PaddockActionCreateFormProps {
  paddockOptions: ListSelectOption[];
  paddockSortOptions: SortOption[];
  sortPaddockOptions: (
    options: ListSelectOption[],
    sortSettings: SortSetting[]
  ) => ListSelectOption[];
  paddockActions: { value: string; label: string }[];
  initialValues: Partial<PaddockActionCreateFormValues>;
  //
  error?: string;
  loading?: boolean;
  onAddPaddockAction: (label: string) => Promise<void>;
  onCancel: () => void;
  onTouched: (dirty: boolean) => void;
  onSubmit: (formValues: PaddockActionCreateFormValues) => void;
}

export interface PaddockActionCreateFormValues {
  date: string;
  paddock: string[];
  action_type: string;
  inventory_items: string;
  note: string;
}

const ConnectedInventoryItems = (
  props: FormBuilderFieldProps<PaddockActionEditFormValues>
) => {
  const inputProps = useInputInventoryItems(props);
  return <InputInventoryItems {...inputProps} />;
};

export const PaddockActionCreateForm: React.FC<
  PaddockActionCreateFormProps
> = ({
  paddockOptions,
  paddockSortOptions,
  sortPaddockOptions,
  paddockActions,
  initialValues,
  error,
  loading,
  onAddPaddockAction,
  onCancel,
  onTouched,
  onSubmit,
}) => {
  const form: FormBuilderProps<PaddockActionCreateFormValues> = {
    i18nRootKey: 'paddock_action.create.form',
    className: styles.paddockActionCreateForm,
    flex: true,
    fields: [
      {
        name: 'paddock',
        type: 'select-multiple',
        options: paddockOptions,
        sortOptions: paddockSortOptions,
        sortFunction: sortPaddockOptions,
        initialValue: initialValues?.paddock,
        required: true,
      },
      {
        name: 'date',
        type: 'date',
        required: true,
        initialValue: toISO8601(),
      },
      {
        name: 'action_type',
        type: 'select',
        options: paddockActions,
        required: true,
        addNew: {
          onSubmit: onAddPaddockAction,
          placeholder: {
            key: 'paddock_action.create.form.action_type.add.placeholder.label',
          },
          button: {
            key: 'paddock_action.create.form.action_type.add.button.label',
          },
        },
      },
      {
        name: 'inventory_items',
        type: 'custom',
        // validation: validateInputInventoryItems,
        component: ConnectedInventoryItems,
        containerComponent: false,
      },
      {
        name: 'note',
        type: 'textarea',
      },
    ],
    error,
    loading,
    onSubmit,
    onCancel,
    onTouched,
  };

  return <FormBuilder {...form} />;
};
