import React from 'react';
import { defineMessages } from 'react-intl';
import { useMessages, type I18nItem } from '@mobble/i18n';
import { BaseEntity, FilterItem } from '@mobble/models';
import { type SortSetting } from '@mobble/shared/src/core/Sort';
import { type EntitySliceFactoryProxyEntitiesResponse } from '@mobble/store/src/lib/entitySliceFactory';
import { Text } from '@src/stories/Components/UI/Text';
import { PullDown } from '@src/stories/Components/UX/PullDown';
import { List, type ListProps } from '@src/stories/Components/Layout/List';
import { HStack, VStack } from '@src/stories/Components/Layout/Stack';
import { Clickable } from '@src/stories/Components/UX/Clickable';
import { Checkbox } from '@src/stories/Components/UI/Checkbox';
import { Box } from '@src/stories/Components/Layout/Box';
import { EntitiesListHeader } from './EntitiesListHeader';
import { type Provider } from './types';

export interface EntitiesListProps<Entity extends BaseEntity> {
  title?: I18nItem | string;
  className?: string;
  provider: Provider<Entity> | Entity[];
  onEmpty?: I18nItem | (() => React.ReactNode) | string;
  renderEntity: (entity: Entity, meta: EntitiesListArgMeta) => React.ReactNode;
  header?: (entities: Entity[], meta: EntitiesListArgMeta) => React.ReactNode;
  showSearch?: boolean;
  showCounts?: boolean;
  hideSortAndFilter?: boolean;
  summaryCounter?: (entities: Entity[]) => I18nItem | string;
  onShowFilter?: () => void;
  onShowSort?: () => void;
  applyFilter?: (entities: Entity[], filter: FilterItem[]) => Entity[];
  applySort?: (entities: Entity[], sort: SortSetting[]) => Entity[];
  onRefresh?: () => void;
  headerButtons?: { label: I18nItem; onClick: () => void; loading?: boolean }[];
  selectedEntities?: Entity['id'][];
  onSelectEntities?: (entityIds: Entity['id'][]) => void;
  withSelection?: (entityIds: Entity['id'][]) => React.ReactElement;
}

export interface EntitiesListArgMeta {
  index: number;
  filter: FilterItem[];
  sort: SortSetting[];
}

const messages = defineMessages({
  'count.all': {
    description: 'entities.entities_list.count.all',
    defaultMessage: 'Showing <b>{count}</b> of {total}',
  },
  'count.filtered': {
    description: 'entities.entities_list.count.filtered',
    defaultMessage: 'Filtered <b>{count}</b> of {total}',
  },
});

export function EntitiesList<Entity extends BaseEntity>({
  title,
  className,
  provider,
  onEmpty,
  renderEntity,
  showSearch,
  showCounts,
  summaryCounter,
  onShowSort,
  onShowFilter,
  applyFilter,
  applySort,
  onRefresh,
  header,
  hideSortAndFilter,
  headerButtons,
  selectedEntities,
  onSelectEntities,
  withSelection,
}: EntitiesListProps<Entity>) {
  let data = [];
  const providerIsArray = Array.isArray(provider);
  const listData = providerIsArray
    ? provider
    : makeListData({
        entities: provider.entities,
        filter: provider.filter,
        sort: provider.sort,
        applyFilter,
        applySort,
      });

  const strings = useMessages(messages, {
    count: listData?.length,
    total: providerIsArray ? 0 : provider.total,
  });

  if (!providerIsArray && showCounts) {
    const key =
      applyFilter && provider.filter.length > 0
        ? 'count.filtered'
        : 'count.all';

    data = [
      {
        title: strings[key],
        right: summaryCounter
          ? summaryCounter(listData as Entity[])
          : undefined,
        data: listData,
      },
    ];
  } else {
    data = listData;
  }

  let entitiesFromData: Entity[] = [];
  if (!data || data.length === 0) {
    entitiesFromData = [];
  } else if (Array.isArray(data[0].data)) {
    entitiesFromData = data[0].data as Entity[];
  } else {
    entitiesFromData = data as Entity[];
  }

  const searchQuery = React.useMemo(() => {
    return providerIsArray
      ? undefined
      : (provider.filter.find((a) => a.group === 'search')?.filter?.value as
          | string
          | undefined);
  }, [provider.filter, providerIsArray]);

  const handleSearch = (value: string) => {
    if (providerIsArray) {
      return;
    }

    provider.setFilter({
      group: 'search',
      filter: { type: 'search', value },
    });
  };

  const additionalHeader = header
    ? header(entitiesFromData, {
        index: -1,
        filter: providerIsArray ? [] : provider.filter,
        sort: providerIsArray ? [] : provider.sort,
      })
    : null;

  const headerComponent = providerIsArray ? (
    additionalHeader
  ) : (
    <EntitiesListHeader
      title={title}
      filter={provider.filter}
      header={additionalHeader}
      onOpenFilter={
        !hideSortAndFilter && onShowFilter && applyFilter
          ? () => {
              onShowFilter();
            }
          : undefined
      }
      onClearFilter={provider.clearFilter}
      activeFilterCount={provider.filter.length}
      onOpenSort={
        !hideSortAndFilter && applySort
          ? () => {
              onShowSort();
            }
          : undefined
      }
      searchQuery={searchQuery}
      onSearch={showSearch ? handleSearch : undefined}
      headerButtons={headerButtons}
    />
  );

  const meta = {
    filter: providerIsArray ? [] : provider.filter,
    sort: providerIsArray ? [] : provider.sort,
  };

  const renderEmpty = () => {
    if (typeof onEmpty === 'function') {
      return onEmpty();
    } else if (typeof onEmpty === 'object' || typeof onEmpty === 'string') {
      return (
        <Box spacing={{ top: 8, right: 2, bottom: 8, left: 2 }}>
          <VStack alignment="center">
            <Text align="center" bold i18n={onEmpty} />
          </VStack>
        </Box>
      );
    }
    return <Box />;
  };

  const renderItem = (entity: Entity, index: number) => {
    const content = renderEntity(entity, { ...meta, index });
    if (withSelection) {
      const id = entity.id;
      const isSelected = selectedEntities.includes(id);

      const onClick = (ev) => {
        ev.stopPropagation();
        if (isSelected) {
          onSelectEntities(selectedEntities.filter((s) => s !== id));
        } else {
          onSelectEntities([...selectedEntities, id]);
        }
      };

      return (
        <HStack alignment="center">
          <Box spacing={{ left: 2, right: 1 }}>
            <Clickable href={onClick}>
              <Checkbox size="small" selected={isSelected} />
            </Clickable>
          </Box>
          <Box flex>{content}</Box>
        </HStack>
      );
    }

    return content;
  };

  const next = React.useMemo(() => {
    if (Array.isArray(provider)) {
      return null;
    }
    if (provider.hasNextPage) {
      return provider.loadMore;
    }
  }, [provider]);

  const content = (
    <>
      <List
        className={className}
        next={next}
        items={data}
        renderHeader={() => headerComponent}
        renderItem={renderItem}
        renderEmpty={renderEmpty}
      />
      {withSelection &&
        selectedEntities.length > 0 &&
        withSelection(selectedEntities)}
    </>
  );

  return onRefresh ? (
    <PullDown
      onPullDown={onRefresh}
      loading={
        Array.isArray(provider)
          ? false
          : provider.refreshing || provider.loading
      }
    >
      {content}
    </PullDown>
  ) : (
    content
  );
}

const makeListData = <Entity extends BaseEntity>({
  entities,
  filter,
  sort,
  applyFilter,
  applySort,
}: {
  entities: EntitySliceFactoryProxyEntitiesResponse<Entity>['entities'];
  filter: EntitySliceFactoryProxyEntitiesResponse<Entity>['filter'];
  sort: EntitySliceFactoryProxyEntitiesResponse<Entity>['sort'];
  applyFilter?: (entities: Entity[], filter: FilterItem[]) => Entity[];
  applySort?: (entities: Entity[], sort: SortSetting[]) => Entity[];
}): ListProps<Entity>['items'] => {
  const isSearching =
    applyFilter &&
    Boolean(filter.find((a) => a.group === 'search' && a.filter?.value !== ''));

  const filteredData = applyFilter ? applyFilter(entities, filter) : entities;

  const data =
    applySort && !isSearching ? applySort(filteredData, sort) : filteredData;

  return data;
};
