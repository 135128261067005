import React, {
  type ReactElement,
  useRef,
  useState,
  useEffect,
} from 'react';
import { Spacer } from '../../Components/Layout/Spacer';
import { HStack, VStack } from '../../Components/Layout/Stack';
import { Button } from '../../Components/UX/Button';
import { Icon } from '../../Components/UI/Icon';
import { FormWizardProgress } from './FormWizard/FormWizardProgress';
import styles from './formWizard.scss';

export interface FormWizardProps<Keys extends string> {
  steps: FormWizardSteps<Keys>;
  initialValues: FormWizardFormValues<Keys>;
  error?: string;
  loading?: boolean;
  onSubmit: (values: FormWizardFormValues<Keys>) => void;
}

export type FormWizardSteps<T extends string> = {
  [key in T]: FormWizardStep<T>;
};

export type FormWizardFormValues<T extends string> = {
  [key in T]?: any;
};

export type FormWizardFormValidity<T extends string> = {
  [key in T]?: boolean;
};

export type FormWizardFormState<T extends string> = {
  values: FormWizardFormValues<T>[T];
  allValues: FormWizardFormValues<T>;
  setValues: (values: any, stepName?: T) => void;
  valid: boolean;
  setValidity: (valid: boolean, stepName?: T) => void;
  setStep: (stepName: string) => void;
};

export interface FormWizardStep<T extends string> {
  progress: [number, number];
  alwaysValid?: true;
  component: (state: FormWizardFormState<T>) => ReactElement;
  nextStep?: (state: FormWizardFormState<T>) => null | T;
  previousStep?: (state: FormWizardFormState<T>) => null | T;
}

export const FormWizard: React.FC<FormWizardProps<string>> = ({
  steps,
  initialValues,
  error,
  loading,
  onSubmit,
}) => {
  const initialStepNameStepPair = Object.entries(steps).find(
    ([, step]) => step.progress[0] === 1
  );

  if (!initialStepNameStepPair) {
    throw new Error('No initial step found');
  }

  const scrollViewRef = useRef<HTMLDivElement>(null);
  const [currentStepName, setCurrentStepName] = useState<
    keyof typeof steps
  >(initialStepNameStepPair[0]);

  const [formValues, setFormValues] = useState<typeof initialValues>(initialValues);

  const [formValidity, setFormValidity] = useState<
    FormWizardFormValidity<keyof typeof steps>
  >({});

  const step = steps[currentStepName];

  useEffect(() => {
    scrollViewRef.current?.scrollTo(0, 0);
  }, [currentStepName, scrollViewRef]);

  const onNextClick = () => {
    const nextStepName = step.nextStep?.(formStateObject);
    if (nextStepName) {
      setCurrentStepName(nextStepName);
    }
  };

  const handlePrevClick = () => {
    const previousStepName = step.previousStep?.(formStateObject);
    if (previousStepName) {
      setCurrentStepName(previousStepName);
    }
  };

  const isValid = () => {
    if (step?.alwaysValid) {
      return true;
    }
    if (step.nextStep && step.nextStep(formStateObject)) {
      true;
    }
    return formValidity[currentStepName];
  };

  const formStateObject: FormWizardFormState<keyof typeof steps> = {
    values: formValues[currentStepName],
    allValues: formValues,
    setValues: (values, stepName = currentStepName) => {
      setFormValues((prev) => ({
        ...prev,
        [stepName]: { ...(prev[stepName] || {}), ...values },
      }));
    },
    valid: formValidity[currentStepName] ?? false,
    setValidity: (valid, stepName = currentStepName) => {
      setFormValidity((prev) => ({
        ...prev,
        [stepName]: valid,
      }));
    },
    setStep: (stepName) => {
      setCurrentStepName(stepName);
    },
  };

  const handleSubmit = () => {
    onSubmit(formValues);
  };

  return (
    <VStack className={styles.formWizard}>
      <div className={styles.formWizardContent} ref={scrollViewRef}>
        {step.component(formStateObject)}
      </div>

      <div role="alert" className={styles.error}>
        {error && <div>
          <Icon name="alert-triangle" size="large" />
          <p dangerouslySetInnerHTML={{ __html: error }} />
        </div>}
      </div>

      <div className={styles.formWizardFooter}>
        <FormWizardProgress progress={step.progress} />

        <HStack>
          <Button
            disabled={
              loading ||
              !(step?.previousStep && step.previousStep(formStateObject))
            }
            icon="chevron-left"
            label={{ key: 'generic.form.wizard.footer.previous.label' }}
            flex
            outline
            intent="secondary"
            onClick={handlePrevClick}
          />
          <Spacer x={2} />
          {step.progress[0] === step.progress[1] ? (
            <Button
              disabled={loading || !isValid()}
              iconAfter="check"
              label={{ key: 'generic.form.wizard.footer.submit.label' }}
              flex
              type="submit"
              onClick={() => {
                handleSubmit();
              }}
              loading={loading}
            />
          ) : (
            <Button
              disabled={loading || !isValid()}
              iconAfter="chevron-right"
              label={{ key: 'generic.form.wizard.footer.next.label' }}
              flex
              outline
              intent="primary"
              onClick={onNextClick}
            />
          )}
        </HStack>
      </div>
    </VStack>
  );
};
