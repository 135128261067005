import React from 'react';
import { mapDetails } from '@mobble/models/src/model/MapDetail';
import { Color } from '@mobble/colors';
import { Map } from '@src/stories/Map';
import { Box } from '@src/stories/Components/Layout/Box';
import { CircularButton } from '@src/stories/Components/UX/CircularButton';
import { makeMapItemTask } from '../../Map/Items/helper';
import styles from './taskMap.scss';
import { Task } from '@mobble/models/src/model/Task';
import { TaskGeometry } from '@mobble/models/src/model/TaskGeometry';
import { pointToBoundingBox } from '@mobble/models/src/model/MapGeometry';
import { useTaskMapView } from '@src/screens/Tasks/hooks/useTaskMapView';

export interface PaddockMapProps {
  userId: string;
  task: Task;
  taskGeometries: TaskGeometry[];
  onMaximise: () => void;
}

export const TaskMap: React.FC<PaddockMapProps> = ({
  userId,
  task,
  taskGeometries,

  onMaximise,
}) => {
  const mapItemTask = makeMapItemTask({
    taskGeometries: taskGeometries,
    userId,
  })({ task });

  const { items } = useTaskMapView();

  if (!mapItemTask?.point) {
    return null;
  }

  const boundingBox = pointToBoundingBox(mapItemTask.point, 0.0075);

  if (!boundingBox) {
    return null;
  }

  const overrideMapProperties = {
    boundingBox,
    items: [...items, mapItemTask],
    mapDetails: [...mapDetails],
  };

  return (
    <Box className={styles.container}>
      <Box className={styles.buttonContainer}>
        <CircularButton
          icon="maximise"
          fill={Color.White}
          elevation="low"
          onClick={onMaximise}
        />
      </Box>
      <Map
        static
        types="display"
        overrideMapProperties={overrideMapProperties}
      />
    </Box>
  );
};
