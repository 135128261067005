import React, { createContext, useState } from 'react';
import { Portal } from '@src/stories/Components/Layout/Portal';
import { type ButtonProps } from '@src/stories/Components/UX/Button';
import { Dialog, type DialogProps } from '@src/stories/Components/UX/Dialog';

type DialogAlertFunction = (
  title: string,
  message: string,
  buttons: ButtonProps[],
) => void;

export interface DialogContextProps {
  alert: DialogAlertFunction;
  close: () => void;
}

export const DialogContext = createContext<DialogContextProps | undefined>(undefined);

export interface DialogProviderProps {
  children: React.ReactNode;
}

export const DialogProvider: React.FC<DialogProviderProps> = ({ children }) => {
  const [dialog, setDialog] = useState<DialogProps | null>(null);

  const open: DialogAlertFunction = (title: string, message: string, buttons: ButtonProps[]) => {
    setDialog({ title, message, buttons });
  };

  const close = () => {
    setDialog(null);
  };

  return (
    <DialogContext.Provider value={{ alert: open, close }}>
      {children}
      <Portal>
        <Dialog isOpen={!!dialog} {...dialog} />
      </Portal>
    </DialogContext.Provider>
  );
};
