import React from 'react';
import { useI18n } from '@mobble/i18n';
import { MapAsset } from '@mobble/models';
import { useEntitiesRefresher } from '@mobble/shared/src/hooks/useEntitiesRefresher';
import {
  useMapAsset,
  usePaddocks,
  useProperties,
} from '@mobble/store/src/hooks';

import { useAccessHelper } from '@src/hooks/useAccessHelper';
import { useLinking } from '@src/hooks/useLinking';
import { useNavigateBack } from '@src/hooks/useNavigateBack';
import { toPath } from '@src/interfaces/Routing';
import { Box } from '@src/stories/Components/Layout/Box';
import { MapAssetInfoTable } from '@src/stories/Views/MapAsset/MapAssetInfoTable';
import { MapAssetMap } from '@src/stories/Views/MapAsset/MapAssetMap';
import { EntitySliceFactoryPrelude } from '@src/stories/Views/Misc/EntitySliceFactoryPrelude';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';

import * as ROUTE_NAME from '@src/screens/config/routeNames';
import { type ScreenRendererProps } from '@src/screens/config/types';

import styles from './mapAssetDetail.scss';

export const MapAssetDetailHeader: React.FC<ScreenRendererProps> = (props) => {
  const { mapAssetId = '' } = props.route.params;
  const { formatMessage } = useI18n();
  const { hasAccessToRoute } = useAccessHelper();
  const goBack = useNavigateBack();
  const mapAsset = useMapAsset(mapAssetId);
  const canEdit = hasAccessToRoute(ROUTE_NAME.MAP_ASSET_EDIT);

  return (
    <ScreenHeader
      title={
        mapAsset?.entity
          ? mapAsset.entity.name
          : formatMessage({
              defaultMessage: 'Map Asset details',
              description: 'screen.title.map_asset_detail',
            })
      }
      breadcrumbs={[
        {
          title: formatMessage({
            description: 'screen.title.summaries',
            defaultMessage: 'Summaries',
          }),
          href: ROUTE_NAME.SUMMARIES_LIST,
        },
        {
          title: { key: 'screen.title.map_assets' },
          href: ROUTE_NAME.MAP_ASSETS_LIST,
        },
      ]}
      actions={
        canEdit
          ? [
              {
                icon: 'edit',
                href: toPath(ROUTE_NAME.MAP_ASSET_EDIT, {
                  mapAssetId,
                }),
              },
            ]
          : []
      }
      onGoBack={goBack}
      onClose={goBack}
    />
  );
};

export const MapAssetDetail: React.FC<ScreenRendererProps> = ({ route }) => {
  const { mapAssetId } = route.params;
  const linkTo = useLinking();
  const properties = useProperties();
  const propertyId = properties.selected?.id;
  const mapAsset = useMapAsset(mapAssetId, propertyId);
  const paddocks = usePaddocks(propertyId);

  useEntitiesRefresher([mapAsset], propertyId);

  const Prelude = EntitySliceFactoryPrelude({
    preludes: [properties.prelude, paddocks.prelude, mapAsset.prelude],
    required: [properties.selected],
    notFound: [mapAsset.entity],
  });

  if (Prelude) {
    return Prelude;
  }

  const handleMaximise = () => {
    linkTo(ROUTE_NAME.MAP_OVERVIEW, {
      goto: `map-asset--${mapAssetId}`,
    });
  };

  return (
    <Box className={styles.mapAssetDetailLayout}>
      <Box className={styles.mapAssetDetailMap}>
        <MapAssetMap
          mapAsset={mapAsset.entity as MapAsset}
          onMaximise={handleMaximise}
        />
      </Box>
      <Box className={styles.mapAssetDetailInfo}>
        <MapAssetInfoTable mapAsset={mapAsset.entity as MapAsset} />
      </Box>
    </Box>
  );
};

export default {
  name: ROUTE_NAME.MAP_ASSET_DETAIL,
  header: MapAssetDetailHeader,
  component: MapAssetDetail,
};
