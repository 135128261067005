import { pickOneRandom } from '@mobble/shared/src/core/Array';
import { User, UserRole } from './User';

export interface Property {
  id: string;
  name: string;
  organisationId: string;
  organisation?: PropertyOrganisation;
  pic?: string;
  types: ConfiguredPropertyType[];
  users: User[];
  currentUserRole: UserRole;
}

export interface PropertyOrganisation {
  id: string;
  remainingUsers: number;
  remainingProperties: number;
  maxUsers: number;
  maxProperties: number;
}

export enum ConfiguredPropertyTypeGroup {
  paddockType = 'paddockType',
  livestockType = 'livestockType',
  breed = 'breed',
  gender = 'gender',
  tag = 'tag',
  class = 'class',
  paddockAction = 'paddockAction',
  mobAction = 'mobAction',
}

export interface PropertyGenderTypes {
  label: string;
  value: string;
}

export type ConfiguredPropertyTypeGroupCustom =
  | ConfiguredPropertyTypeGroup.breed
  | ConfiguredPropertyTypeGroup.gender
  | ConfiguredPropertyTypeGroup.class
  | ConfiguredPropertyTypeGroup.paddockAction
  | ConfiguredPropertyTypeGroup.mobAction;

export interface ConfiguredPropertyType {
  group: ConfiguredPropertyTypeGroup;
  id: string;
  parentId?: string;
  type: string;
  label: string;
  color?: string;
}

export const getConfiguredPropertyTypes = (
  property: Property,
  group: ConfiguredPropertyTypeGroup,
  parentId?: string
): ConfiguredPropertyType[] =>
  property.types
    .filter((a) => a.group === group && (!parentId || a.parentId === parentId))
    .sort((a, b) => a.label.localeCompare(b.label));

export const shallowCompareConfiguredPropertyTypes =
  (a: ConfiguredPropertyType) =>
  (b: ConfiguredPropertyType): boolean => {
    return a.label === b.label;
  };

export const getUniqueConfiguredPropertyTypes =
  (properties: Property[]) =>
  (group: ConfiguredPropertyTypeGroup): ConfiguredPropertyType[] => {
    return properties
      .reduce<ConfiguredPropertyType[]>((set, p) => {
        p.types.forEach((t) => {
          if (
            t.group === group &&
            !set.find(shallowCompareConfiguredPropertyTypes(t))
          ) {
            set.push(t);
          }
        });
        return set;
      }, [])
      .sort((a, b) => a.label.localeCompare(b.label));
  };

export const getOptionsFromTypesByGroupAndParentId =
  (propertyTypes: ConfiguredPropertyType[]) =>
  (group: ConfiguredPropertyTypeGroup, parentId?: string) => {
    return propertyTypes
      ?.filter((a) => a.group === group && a.parentId === parentId)
      .map((a) => ({
        label: a.label,
        value: a.type,
      }));
  };

export const getOptionsFromTypesByGroup =
  (propertyTypes: ConfiguredPropertyType[]) =>
  (group: ConfiguredPropertyTypeGroup) => {
    return propertyTypes
      ?.filter((a) => a.group === group)
      .map((a) => ({
        label: a.label,
        value: a.type,
      }));
  };

export const getLivestockParentIdForValue = (
  type: string,
  propertyTypes: ConfiguredPropertyType[]
) =>
  propertyTypes.find(
    (a) =>
      (a.group === ConfiguredPropertyTypeGroup.livestockType && a.type) === type
  )?.id;

export const getLivestockAttributes = (
  propertyTypes: ConfiguredPropertyType[]
) => getOptionsFromTypesByGroupAndParentId(propertyTypes);

export const TAG_COLORS = [
  '#FFB84E',
  '#FFFFFF',
  '#4C4C4C',
  '#7AB8FF',
  '#FF6B65',
  '#FFFF54',
  '#A47AFF',
  '#ACE7A2',
  '#FD96FF',
  '#4694EC',
  '#EB4D46',
  '#CCCCCC',
  '#B88159',
  '#6BBF6A',
];

export const MOBBLE_COLORS = [
  '#000000',
  '#8D0119',
  '#DB034D',
  '#AE4880',
  '#B40CC0',
  '#843AAA',
  '#8303E7',
  '#D759F7',
  '#F482E2',
  '#2D87BB',
  '#17BAC5',
  '#2AE2E7',
  '#55A7AA',
  '#49CBAB',
  '#64C2A6',
  '#E0A795',
  '#DF9C9B',
  '#FF685E',
  '#F1793A',
  '#E5960E',
  '#EAB026',
  '#C2C73C',
  '#C7E63A',
  '#AADEA7',
  '#8D8F01',
  '#4ECF00',
  '#54C077',
];

export const getUnselectedPaddockTypeColor = (property: Property) => {
  const colors = getUniqueConfiguredPropertyTypes([property])(
    ConfiguredPropertyTypeGroup.paddockType
  ).map((a) => (property?.types || []).find((b) => b === a)?.color || '#fff');
  const unusedColors = MOBBLE_COLORS.filter((i) => !colors.includes(i));

  return pickOneRandom(unusedColors || colors);
};

export const checkPropertyHasUserEmail =
  (property: Property) => (userEmail: string) =>
    property.users.find(
      (user) => user.email.toLowerCase() === userEmail.toLowerCase()
    );

export const checkPropertyHasUser = (property: Property) => (userId: string) =>
  property.users.find((user) => user.id === userId);

export const findProperty = (properties: Property[]) => (id: string) =>
  properties.find((p) => p.id === id);

export const findUsersOfRole = (users: User[]) => (role: UserRole) =>
  users?.filter((roles) => roles.role === role) || null;

export const findUserOfRole = (users: User[]) => (role: UserRole) =>
  users?.find((roles) => roles.role === role) || null;

export const getPropertiesWhereUserHasAdminAccess =
  (properties: Property[]) => (userId: string) =>
    properties.filter(
      (property) =>
        checkPropertyHasUser(property)(userId)?.role === UserRole.Admin ||
        checkPropertyHasUser(property)(userId)?.role === UserRole.Owner
    );

export interface MobbleConnectUserPropertyRelation {
  propertyName: string;
  propertyId: string;
  isCurrentProperty: boolean;
  isPartOfProperty: boolean;
}
export const getMobbleConnectUserStatusOfPropertiesByEmail =
  (properties: Property[]) =>
  (activeProperty: Property) =>
  (email: string): MobbleConnectUserPropertyRelation[] =>
    properties?.map((property) => ({
      propertyName: property.name,
      propertyId: property.id,
      isCurrentProperty: activeProperty?.id === property.id,
      isPartOfProperty: Boolean(checkPropertyHasUserEmail(property)(email)),
    }));

export const mobbleConnectRoles: {
  role: UserRole;
  maxUsers: number;
  users: null | User[];
}[] = [
  {
    role: UserRole.FarmAdvisor,
    maxUsers: 1,
    users: null,
  },
  {
    role: UserRole.StockAgent,
    maxUsers: 1,
    users: null,
  },
  {
    role: UserRole.Accountant,
    maxUsers: 1,
    users: null,
  },
  {
    role: UserRole.Contractor,
    maxUsers: 2,
    users: null,
  },
  {
    role: UserRole.Auditor,
    maxUsers: 1,
    users: null,
  },
];

export interface MobbleConnectRole {
  users: (User | null)[];
  role: UserRole;
  maxUsers: number;
}

export const useMobbleConnectRole = (
  property: Property
): MobbleConnectRole[] => {
  if (!property?.users) {
    return [];
  }
  const mobbleConnectUsers = findUsersOfRole(property?.users);

  const users: MobbleConnectRole[] = mobbleConnectRoles.map((role) => {
    const existingUsers = mobbleConnectUsers(role.role);
    const remainingSlots = role?.maxUsers - existingUsers?.length ?? 0;

    const additionalUsers = Array.from({ length: remainingSlots }, () => null);

    return {
      ...role,
      users: [...existingUsers, ...additionalUsers],
    };
  });

  return users;
};

export const findMobbleConnectRoleFromRole =
  (mobbleConnectRole: MobbleConnectRole[]) => (role: UserRole) =>
    mobbleConnectRole?.find((roles) => roles.role === role) || null;

export const sortByName = (properties: Property[]) =>
  [...properties].sort((a, b) => a.name.localeCompare(b.name));

export type GroupedProperties = {
  name: string;
  properties: Property[];
}[];

export const groupByOrganisation = (
  properties: Property[]
): GroupedProperties => {
  const groupedOptionsObj = sortByName(properties).reduce<
    Record<string, { name: string; properties: Property[] }>
  >((acc, property) => {
    if (!acc[property.organisationId]) {
      acc[property.organisationId] = {
        name:
          property.users.find((u) => u.role === UserRole.Owner)?.name ??
          property.organisationId,
        properties: [],
      };
    }

    acc[property.organisationId].properties.push(property);

    return acc;
  }, {});

  return Object.values(groupedOptionsObj).sort((a, b) =>
    a.name.localeCompare(b.name)
  );
};
