import React from 'react';
import { Box } from '../Layout/Box';
import styles from './colorSquare.scss';

export interface ColorSquareProps {
  color: string;
}

export const ColorSquare: React.FC<ColorSquareProps> = ({ color }) => {
  return (
    <Box style={{ backgroundColor: color }} className={styles.colorSquare} />
  );
};
