import React, { useEffect } from 'react';
import { Button, type ButtonProps } from '@src/stories/Components/UX/Button';
import { Text } from '@src/stories/Components/UI/Text';
import styles from './dialog.scss';

export interface DialogProps {
  isOpen?: boolean;
  title: string;
  message: string;
  buttons: ButtonProps[];
}

export const Dialog: React.FC<DialogProps> = (props) => {
  const { isOpen, title, message, buttons = [] } = props;
  const ref = React.useRef<HTMLDialogElement>(null);

  useEffect(() => {
    if (ref.current && isOpen) {
      ref.current.showModal();
    } else if (ref.current && !isOpen) {
      ref.current.close();
    }
  });

  return (
    <dialog ref={ref} className={styles.dialog}>
      <header>
        <Text variant="card-title">{title}</Text>
      </header>

      <section>
        <Text>{message}</Text>
      </section>

      <footer>
        {buttons.map((button, i) => (
          <Button key={i} {...button} />
        ))}
      </footer>
    </dialog>
  );
};
