import React from 'react';
import * as Yup from 'yup';
import {
  findMob,
  getMobDisplayName,
  type Mob,
} from '@mobble/models/src/model/Mob';
import { roundNumberTo } from '@mobble/shared/src/core/Number';
import { paddockForMob, type Paddock } from '@mobble/models/src/model/Paddock';
import { Color } from '@mobble/colors';
import { MobCard } from '../Mob/MobCard';
import { FormBuilder, type FormBuilderProps } from '../Form/FormBuilder';
import { Box } from '../../Components/Layout/Box';
import { useI18n } from '@mobble/i18n';
import { ConfiguredPropertyType } from '@mobble/models/src/model/Property';
import { InputContainer } from '@src/stories/Components/UX/InputContainer';

export interface SaleCreateFormProps {
  mobs: Mob[];
  paddocks: Paddock[];
  propertyTypes: ConfiguredPropertyType[];
  initialValues: Partial<SaleCreateFormValues>;
  error?: string;
  loading?: boolean;
  onCancel: () => void;
  onSubmit: (formValues: SaleCreateFormValues) => void;
}

export interface SaleCreateFormValues {
  mob: string;
  number: number;
  price_per_head: number;
  total_price: number;
  date: string;
  sold_to: string;
  notes: string;
}

export const SaleCreateForm: React.FC<SaleCreateFormProps> = ({
  mobs,
  paddocks,
  propertyTypes,
  initialValues,
  error,
  loading,
  onCancel,
  onSubmit,
}) => {
  const { translate } = useI18n();
  const getMobName = getMobDisplayName(mobs);
  const getPaddockForMob = paddockForMob(paddocks);

  const mobOptions = mobs.map((mob) => {
    const paddock = getPaddockForMob(mob.id);
    return {
      value: mob.id,
      label: getMobName(mob.id) || '',
      labelExtra: `${mob.size}`,
      description: `${paddock?.name} ${mob.classes.join(' ')} ${mob.ages.join(
        ' '
      )}}`,
      component: <MobCard propertyTypes={[]} mob={mob} paddock={paddock} />,
    };
  });

  const form: FormBuilderProps<SaleCreateFormValues> = {
    flex: true,
    i18nRootKey: 'sales.create.form',
    fields: [
      {
        name: 'mob',
        type: 'select',
        options: mobOptions,
        initialValue: initialValues?.mob,
        required: true,
        onChange: (formValues) => {
          if (!formValues.mob) {
            return formValues;
          }
          const mob = findMob(mobs)(formValues.mob) as Mob;
          return {
            ...formValues,
            mob: mob.id,
            number: mob.size,
          };
        },
      },
      {
        name: 'selected_mobs_preview',
        type: 'custom',
        containerComponent: false,
        component: ({ values }) => {
          const mob = findMob(mobs)(values.mob) as Mob;
          return (
            <InputContainer>
              <Box background={Color.LightGrey} spacing={2}>
                <MobCard
                  propertyTypes={propertyTypes}
                  paddock={getPaddockForMob(mob)}
                  mob={mob}
                />
              </Box>
            </InputContainer>
          );
        },
      },
      {
        name: 'number',
        type: 'number',
        validation: Yup.number()
          .integer(
            translate({
              key: 'mobs.sale.create.form.number.error.label',
            }) || ''
          )
          .required(),
        initialValue: initialValues.number,
        onChange: (values) => {
          return {
            ...values,
            total_price: values.price_per_head
              ? Number(
                  roundNumberTo(2)(
                    values.price_per_head * (values?.number || 1)
                  )
                )
              : 0,
          };
        },
      },
      {
        name: 'price_per_head',
        type: 'float',
        required: true,
        onChange: (values) => {
          return {
            ...values,
            total_price: values.price_per_head
              ? Number(
                  roundNumberTo(2)(
                    values.price_per_head * (values?.number || 1)
                  )
                )
              : 0,
          };
        },
      },
      {
        name: 'total_price',
        type: 'float',
        required: true,

        onChange: (values) => {
          return {
            ...values,
            price_per_head: values.total_price
              ? Number(
                  roundNumberTo(2)(
                    Number(values.total_price) / (values?.number || 1)
                  )
                )
              : 0,
          };
        },
      },
      {
        name: 'date',
        type: 'date',
        required: true,
        initialValue: initialValues.date,
      },
      {
        name: 'sold_to',
        type: 'text',
      },
      {
        name: 'notes',
        type: 'textarea',
      },
    ],
    error,
    loading,
    onSubmit,
    onCancel,
  };

  return <FormBuilder {...form} />;
};
