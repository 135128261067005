import React from 'react';
import { Color } from '@mobble/colors';
import { useI18n } from '@mobble/i18n';
import {
  pointToBoundingBox,
  type Point,
} from '@mobble/models/src/model/MapGeometry';
import { Box } from '../Layout/Box';
import { Spacer } from '../Layout/Spacer';
import { Button } from './Button';
import { FormFooter } from '../../Views/Form/FormFooter';
import { Map } from '../../Map';
import { CircularButton } from './CircularButton';
import { MAP_DETAILS } from '@mobble/models/src/model/MapDetail';
import { makeTemporaryMapItemTask } from '../../Map/Items/helper';
import { ModalFlyUp } from './ModalFlyUp';
import { type MapPluginsListenersDispatchRef } from '@src/stories/Map/Context';
import styles from './inputLocation.scss';

export interface InputLocationProps {
  value?: null | Point;
  onChange: (value: null | Point) => void;
}

export const InputLocation: React.FC<InputLocationProps> = ({
  value,
  onChange,
}) => {
  const { formatMessage } = useI18n();
  const stateRef = React.useRef<MapPluginsListenersDispatchRef>(null);

  const [modalVisible, setModalVisible] = React.useState(false);

  const mapProperties = {
    boundingBox: value ? pointToBoundingBox(value, 0.0075) : undefined,
    mapDetails: [MAP_DETAILS.PADDOCK_BOUNDARIES, MAP_DETAILS.PADDOCK_LABELS],
  };

  const currentValueDisplay = value ? (
    <Box flex className={styles.mapContainer}>
      <Box className={styles.buttonContainer}>
        <CircularButton
          icon="edit"
          fill={Color.Green}
          elevation="low"
          onClick={() => setModalVisible(true)}
        />
        <Spacer y={1} />
        <CircularButton
          icon="trash"
          fill={Color.Red}
          elevation="low"
          onClick={() => onChange(null)}
        />
      </Box>
      <Box flex>
        <Map
          key={JSON.stringify(value)}
          types="display"
          overrideMapProperties={{
            ...mapProperties,
            additionalMapItems: [makeTemporaryMapItemTask(value)],
          }}
        />
      </Box>
    </Box>
  ) : (
    <Box className={styles.inputContainer}>
      <Button
        flex
        intent="secondary"
        icon="map"
        label={formatMessage({
          defaultMessage: 'Select on map',
          description:
            'generic.input.input_location.select_on_map.button.label',
        })}
        onClick={() => setModalVisible(true)}
        outline
      />
    </Box>
  );

  return (
    <Box>
      <ModalFlyUp
        isOpen={modalVisible}
        fullScreen
        title={formatMessage({
          defaultMessage: 'Select on map',
          description: 'generic.input.input_location.select_on_map.modal.title',
        })}
        footer={
          <Box className={styles.modalButtonFooter}>
            <FormFooter
              fullWidth
              loading={false}
              onCancel={() => {
                setModalVisible(false);
              }}
              onSubmit={() => {
                const newPoint = stateRef.current.getState().points[0];
                onChange(newPoint);
                setModalVisible(false);
              }}
              submitLabel={
                value
                  ? formatMessage({
                      defaultMessage: 'Update',
                      description:
                        'generic.input.input_location.select_on_map.modal.update.label',
                    })
                  : formatMessage({
                      defaultMessage: 'Select',
                      description:
                        'generic.input.input_location.select_on_map.modal.select.label',
                    })
              }
              cancelLabel={formatMessage({
                defaultMessage: 'Cancel',
                description:
                  'generic.input.input_location.select_on_map.modal.cancel.label',
              })}
            />
          </Box>
        }
        onClose={() => {
          setModalVisible(false);
        }}
        className={styles.modal}
      >
        <Box flex className={styles.modalMapContainer}>
          <Map
            stateRef={stateRef}
            key={'input'}
            types={['creator', 'items', 'camera', 'user']}
            overrideMapProperties={mapProperties}
            pointsState={{
              enabled: true,
              points: value ? [value] : [],
              options: { single: true },
            }}
          />
        </Box>
      </ModalFlyUp>
      <Box>{currentValueDisplay}</Box>
    </Box>
  );
};
