import React from 'react';
import { type I18nItem } from '@mobble/i18n';
import { ModalFlyUp } from './ModalFlyUp';
import { InlineOption, type LabelLike } from './InlineOption';
import { Icon, type IconName } from '@mobble/icon';
import { Box } from '../Layout/Box';
import styles from './modalSelect.scss';

export interface ModalSelectProps {
  title?: I18nItem | string;
  additionalHeader?: React.ReactNode;
  options: ModalSelectOption[];
  visible?: boolean;
  onCancel: () => void;
  onChange: (value: string) => void;
}

export interface ModalSelectOption {
  label: LabelLike;
  value: string;
  disabled?: boolean;
  selected?: boolean;
  icon?: IconName;
  iconColor?: string;
}

export const ModalSelect: React.FC<ModalSelectProps> = ({
  title,
  additionalHeader,
  options,
  onChange,
  onCancel,
  visible = false,
}) => {
  const renderOption = (option: ModalSelectOption) => {
    const handleClick = () => {
      onChange(option.value.toString());
      onCancel();
    };

    const labelExtra = option.icon ? (
      <Box spacing={0.5} className={styles.labelExtra}>
        <Icon name={option.icon} color={option.iconColor} />
      </Box>
    ) : undefined;

    return (
      <InlineOption
        key={option.value}
        labelExtra={labelExtra}
        {...option}
        onClick={handleClick}
      />
    );
  };

  return (
    <ModalFlyUp
      isOpen={visible}
      title={title}
      additionalHeader={additionalHeader}
      onClose={onCancel}
      listProps={{
        items: options,
        renderItem: (item) => renderOption(item as ModalSelectOption),
      }}
    />
  );
};
