import React from 'react';
import { defineMessages } from 'react-intl';

import { Color } from '@mobble/colors';
import { useMessages, useI18n } from '@mobble/i18n';

import {
  InventoryItemBatch,
  InventoryItemBatchChemical,
  InventoryItemBatchFeed,
  getInventoryItemBatch,
} from '@mobble/models/src/model/InventoryItemBatch';
import { type Mob, findMob, getTotalHead } from '@mobble/models/src/model/Mob';
import { type MobAction } from '@mobble/models/src/model/MobAction';
import {
  findInventoryItem,
  type InventoryItem,
} from '@mobble/models/src/model/InventoryItem';
import { AppliedInventoryItem } from '@mobble/models/src/model/AppliedInventoryItem';
import { Paddock, paddockForMob } from '@mobble/models/src/model/Paddock';
import { ConfiguredPropertyType } from '@mobble/models/src/model/Property';
import { InventoryCategory } from '@mobble/models/src/model/Inventory';

import { formatDate } from '@mobble/shared/src/core/Date';
import { makeQuantity } from '@mobble/shared/src/core/Quantity';
import { useSetting } from '@mobble/store/src/hooks/settings';

import { Box } from '@src/stories/Components/Layout/Box';
import { type InfoRowProps, InfoRow } from '@src/stories/Components/UI/InfoRow';
import { HStack } from '@src/stories/Components/Layout/Stack';
import { Spacer } from '@src/stories/Components/Layout/Spacer';
import { Spinner } from '@src/stories/Components/UI/Spinner';
import { Text } from '@src/stories/Components/UI/Text';
import { makeQuantityFormatMessageItem } from '@src/stories/Components/Locale/LocaleQuantity';

import { MobCard, MobCardProps } from '../Mob/MobCard';
import { InventoryItemCard } from '../InventoryItem/InventoryItemCard';

import * as ROUTE_NAME from '@src/screens/config/routeNames';
import { toPath } from '@src/interfaces/Routing';

import styles from './mobActionInfoTable.scss';

export interface MobActionInfoTableProps {
  mobAction: MobAction;
  inventoryItems: InventoryItem[];
  inventoryItemBatches: InventoryItemBatch[];
  mobs: Mob[];
  paddocks: Paddock[];
  propertyTypes: ConfiguredPropertyType[];
}

const messages = defineMessages({
  action_type: {
    id: 'mob_action.info.table.row.action_type.label',
    defaultMessage: 'Action type',
  },
  applied_inventory_items: {
    id: 'mob_action.info.table.row.applied_inventory_items.label',
    defaultMessage: 'Product applications',
  },
  count: {
    id: 'mob_action.info.table.row.count.label',
    defaultMessage: 'Affected',
  },
  created_by: {
    id: 'mob_action.info.table.row.created_by.label',
    defaultMessage: 'Recorded by',
  },
  date: {
    id: 'mob_action.info.table.row.date.label',
    defaultMessage: 'Date',
  },
  description: {
    id: 'mob_action.info.table.row.description.label',
    defaultMessage: 'Description',
  },
  esi: {
    id: 'mob_action.info.table.row.esi.label',
    defaultMessage: 'ESI',
  },
  batch: {
    id: 'mob_action.info.table.row.batch.label',
    defaultMessage: 'Batch',
  },
  supplierName: {
    id: 'paddock_action.info.table.row.supplierName.label',
    defaultMessage: 'Supplier',
  },
  mobs: {
    id: 'mob_action.info.table.row.mobs.label',
    defaultMessage: 'Mobs',
  },
  note: {
    id: 'mob_action.info.table.row.note.label',
    defaultMessage: 'Notes',
  },
  quantity: {
    id: 'mob_action.info.table.row.quantity.label',
    defaultMessage: 'Quantity/Head used',
  },
  quantityTotal: {
    id: 'mob_action.info.table.row.quantity_total.label',
    defaultMessage: 'Total Quantity used',
  },
  type: {
    id: 'mob_action.info.table.row.type.label',
    defaultMessage: 'Type',
  },
  whp: {
    id: 'mob_action.info.table.row.whp.label',
    defaultMessage: 'WHP',
  },
});

export const MobActionInfoTable: React.FC<MobActionInfoTableProps> = ({
  mobAction,
  mobs,
  paddocks,
  propertyTypes,
  inventoryItems,
  inventoryItemBatches,
}) => {
  const strings = useMessages(messages);
  const { formatMessage } = useI18n();
  const dateFormat = useSetting('dateFormat') as string;

  const findBatch = getInventoryItemBatch(inventoryItemBatches);
  const totalMobCount = mobAction?.count
    ? mobAction?.count
    : mobAction?.mobs && mobs
    ? getTotalHead(
        mobAction?.mobs.map((m) => findMob(mobs)(m.id)).filter(Boolean) as Mob[]
      )
    : 0;

  const renderInventoryCard = (appliedInventoryItem: AppliedInventoryItem) => {
    let inventoryItem = findInventoryItem(inventoryItems)(
      appliedInventoryItem.inventoryItemId
    );

    // Fallback to nested chemical/feed data
    if (
      !inventoryItem &&
      appliedInventoryItem.category === InventoryCategory.Chemicals
    ) {
      inventoryItem = appliedInventoryItem.chemical as InventoryItem;
    } else if (
      !inventoryItem &&
      appliedInventoryItem.category === InventoryCategory.Feed
    ) {
      inventoryItem = appliedInventoryItem.feed as InventoryItem;
    }

    if (!inventoryItem) {
      return (
        <Box spacing={{ top: 1, bottom: 1 }}>
          <HStack alignment="center">
            <Spinner color={Color.Grey} />
            <Spacer x={1} />
            <Text>
              {appliedInventoryItem.category === InventoryCategory.Chemicals
                ? appliedInventoryItem.name
                : appliedInventoryItem.supplierName}
            </Text>
          </HStack>
        </Box>
      );
    }
    return (
      <InventoryItemCard
        href={toPath(ROUTE_NAME.INVENTORY_ITEM_DETAIL, {
          inventoryId: inventoryItem.inventoryId,
          inventoryItemId: inventoryItem.id,
        })}
        inventoryItem={inventoryItem}
      />
    );
  };

  const mobCardProps: MobCardProps[] = mobAction.mobs.map((mob) => {
    let mobProps: MobCardProps = {
      mob,
      propertyTypes,
      paddock: mob.snapshot?.paddockName,
    };

    const existingMob = findMob(mobs)(mob.id);

    if (existingMob) {
      const paddock = paddockForMob(paddocks)(mob);
      mobProps = {
        ...mobProps,
        paddock,
        href: toPath(ROUTE_NAME.MOB_DETAIL, {
          paddockId: paddock?.id,
          mobId: mob?.id,
        }),
      };
    }

    return mobProps;
  });

  const rows: InfoRowProps[] = [
    {
      label: strings.mobs,
      value: () => (
        <Box className={styles.mobs}>
          {mobCardProps.map((props) => (
            <Box key={props.mob.id} spacing={{ bottom: 1 }}>
              <MobCard {...props} />
            </Box>
          ))}
        </Box>
      ),
    },
    {
      label: strings.description,
      value: mobAction.description,
    },
    {
      label: strings.type,
      value: mobAction.type,
    },
    {
      label: strings.created_by,
      value: mobAction.createdBy.name,
    },
    {
      label: strings.date,
      value: formatDate(mobAction.date, dateFormat),
    },
    mobAction.count
      ? {
          label: strings.count,
          value: String(mobAction.count),
        }
      : null,
    mobAction.appliedInventoryItems.length > 0
      ? {
          label: strings.applied_inventory_items,
          value: () => (
            <Box className={styles.appliedInventoryItems}>
              {mobAction.appliedInventoryItems.map((a) => {
                return (
                  <Box key={a.id} className={styles.appliedInventoryItem}>
                    {renderInventoryCard(a)}
                    <Box spacing={{ top: 2, right: 2, left: 0, bottom: 2 }}>
                      <HStack>
                        <InfoRow
                          label={strings.quantity}
                          value={formatMessage(
                            ...makeQuantityFormatMessageItem(a.quantity)
                          )}
                          flex
                        />

                        <InfoRow
                          label={strings.quantityTotal}
                          value={formatMessage(
                            ...makeQuantityFormatMessageItem(
                              makeQuantity(
                                a.quantity.type,
                                a.quantity.unit,
                                a.quantity.value * totalMobCount || 1
                              )
                            )
                          )}
                          flex
                        />
                      </HStack>

                      {a.category === InventoryCategory.Chemicals ? (
                        <HStack>
                          <InfoRow
                            label={strings.whp}
                            value={a.whp || '-'}
                            flex
                          />
                          <InfoRow
                            label={strings.esi}
                            value={a.esi || '-'}
                            flex
                          />
                        </HStack>
                      ) : null}
                      {a.category === InventoryCategory.Chemicals ? (
                        <InfoRow
                          label={strings.batch}
                          value={
                            (
                              findBatch(
                                a?.batchId
                              ) as InventoryItemBatchChemical
                            )?.name ||
                            a.oldBatchId ||
                            '-'
                          }
                          flex
                        />
                      ) : (
                        <InfoRow
                          label={strings.supplierName}
                          value={
                            (findBatch(a?.batchId) as InventoryItemBatchFeed)
                              ?.supplierName || '-'
                          }
                          flex
                        />
                      )}
                    </Box>
                  </Box>
                );
              })}
            </Box>
          ),
        }
      : null,
    {
      label: strings.note,
      value: mobAction.note,
    },
  ].filter(Boolean) as InfoRowProps[];

  return (
    <div className={styles.mobActionInfoTable}>
      {rows.map((row) =>
        Array.isArray(row) ? (
          <>
            {row.map((r) => (
              <InfoRow {...r} key={r.label as string} />
            ))}
          </>
        ) : (
          <InfoRow key={row.label} {...row} />
        )
      )}
    </div>
  );
};
