import React from 'react';
import { type I18nItem } from '@mobble/i18n';
import { useEntitiesRefresher } from '@mobble/shared/src/hooks/useEntitiesRefresher';
import {
  useProperties,
  useHistoricEvents,
  useInventories,
} from '@mobble/store/src/hooks';
import {
  type HistoricEvent,
  filterHistoricEvents,
  eventTypes,
  eventTypeToColor,
} from '@mobble/models/src/model/HistoricEvent';
import { formatDateAndTime, fromRawDate } from '@mobble/shared/src/core/Date';

import { useLinking } from '@src/hooks/useLinking';
import { toPath } from '@src/interfaces/Routing';

import { EntitiesViewer } from '@src/stories/Views/Entities/EntitiesViewer';
import { EntitySliceFactoryPrelude } from '@src/stories/Views/Misc/EntitySliceFactoryPrelude';
import { HistoricEventCard } from '@src/stories/Views/HistoricEvent/HistoricEventCard';

import * as ROUTE_NAME from '../config/routeNames';

export interface HistoricEventsProps {
  title?: I18nItem | string;
  className?: string;
  extFilter?: any;
  asList?: boolean;
}

export const HistoricEvents: React.FC<HistoricEventsProps> = ({
  title,
  className,
  extFilter,
  asList,
}) => {
  const linkTo = useLinking();
  const properties = useProperties();
  const propertyId = properties.selected?.id;
  const historicEvents = useHistoricEvents(propertyId, extFilter);
  const { refresh } = useEntitiesRefresher([historicEvents], propertyId);

  const Prelude = EntitySliceFactoryPrelude({
    preludes: [properties.prelude, historicEvents.prelude],
    required: [properties.selected],
  });

  if (Prelude) {
    return Prelude;
  }

  const filterItems = () => [
    {
      type: 'select-multiple',
      group: 'event_type',
      title: { key: 'historic_events.filter.group.event_type.label' },
      data: eventTypes.map((value) => ({
        value,
        label: { key: `historic_events.event.event_type.${value}` },
        color: eventTypeToColor(value),
      })),
    },
  ];

  return (
    <EntitiesViewer
      headerButtons={[
        {
          loading: historicEvents.refreshing || historicEvents.loading,
          label: { key: 'generic.refresh' },
          onClick: refresh,
        },
      ]}
      title={title}
      className={className}
      asList={asList}
      provider={historicEvents}
      applyFilter={filterHistoricEvents}
      filterItems={filterItems}
      tableColumns={[
        {
          key: 'time',
          label: { key: 'time' },
          totals: false,
          toValue: (historicEvent) =>
            fromRawDate(historicEvent.date).toDate().getTime(),
          valueToString: (_, historicEvent) =>
            formatDateAndTime(fromRawDate(historicEvent.date)),
        },
        {
          key: 'event_type',
          label: { key: 'event_type' },
          totals: false,
          toValue: (historicEvent) => historicEvent.eventType,
        },
        {
          key: 'created_by',
          label: { key: 'created_by' },
          totals: false,
          toValue: (historicEvent) => historicEvent.createdBy.name,
        },
        {
          key: 'description',
          label: { key: 'description' },
          totals: false,
          toValue: (historicEvent) => historicEvent.description,
        },
      ]}
      renderEntityCard={(item) => {
        const routeAndProps = getEventRouteAndProps(item);
        const path = routeAndProps
          ? toPath(routeAndProps?.route as any, routeAndProps?.props)
          : undefined;
        return (
          <HistoricEventCard
            historicEvent={item}
            href={path}
            onClick={
              routeAndProps
                ? () => {
                    linkTo(routeAndProps.route as any, routeAndProps.props);
                  }
                : undefined
            }
          />
        );
      }}
    />
  );
};

const getEventRouteAndProps = (
  event: HistoricEvent
): null | {
  route: string;
  props: { [key: string]: string } | undefined;
} => {
  if (!event.linksTo?.id) {
    return null;
  }

  switch (event.eventType) {
    case 'CasualtyAddition':
    case 'CasualtyEdit':
      return {
        route: ROUTE_NAME.CASUALTY_DETAIL,
        props: { casualtyId: event.linksTo?.id },
      };

    case 'ChemicalAddition':
    case 'ChemicalEdit':
      return {
        route: ROUTE_NAME.INVENTORY_ITEM_DETAIL,
        props: {
          inventoryId: event.related.inventoryId,
          inventoryItemId: event.linksTo?.id,
        },
      };

    case 'ChemicalBatchAddition':
    case 'ChemicalBatchEdit': {
      if (!event.related.inventoryId || !event.linksTo.parentId) {
        return null;
      }
      return {
        route: ROUTE_NAME.INVENTORY_ITEM_BATCH_DETAIL,
        props: {
          inventoryId: event.related.inventoryId,
          inventoryItemId: event.linksTo.parentId,
          inventoryItemBatchId: event.linksTo.id,
        },
      };
    }

    case 'CommentAddition':
      return {
        route: ROUTE_NAME.CHATS_LIST,
        props: undefined,
      };

    case 'FeedAddition':
    case 'FeedEdit':
    case 'FeedFinished':
      return {
        route: ROUTE_NAME.INVENTORY_ITEM_DETAIL,
        props: {
          inventoryId: event.related.inventoryId,
          inventoryItemId: event.linksTo?.id,
        },
      };

    case 'FeedBatchAddition':
    case 'FeedBatchEdit': {
      if (!event.related.inventoryId || !event.linksTo.parentId) {
        return null;
      }
      return {
        route: ROUTE_NAME.INVENTORY_ITEM_BATCH_DETAIL,
        props: {
          inventoryId: event.related.inventoryId,
          inventoryItemId: event.linksTo.parentId,
          inventoryItemBatchId: event.linksTo.id,
        },
      };
    }

    case 'MapAssetAddition':
    case 'MapAssetEdit':
      return {
        route: ROUTE_NAME.MAP_ASSET_DETAIL,
        props: { mapAssetId: event.linksTo?.id },
      };

    case 'MobActionAddition':
    case 'MobActionEdit':
      return {
        route: ROUTE_NAME.MOB_ACTION_DETAIL,
        props: { mobActionId: event.linksTo?.id },
      };

    case 'MobAddition':
    case 'MobEdit':
      return {
        route: ROUTE_NAME.MOB_DETAIL,
        props: { mobId: event.linksTo?.id },
      };

    case 'PaddockActionAddition':
    case 'PaddockActionEdit':
      return {
        route: ROUTE_NAME.PADDOCK_ACTION_DETAIL,
        props: { paddockActionId: event.linksTo?.id },
      };

    case 'PaddockAddition':
    case 'PaddockEdit':
      return {
        route: ROUTE_NAME.PADDOCK_DETAIL_INFO,
        props: { paddockId: event.linksTo?.id },
      };
    case 'PropertyAddition':
      return null;

    case 'RainGaugeAddition':
    case 'RainGaugeEdit':
    case 'RainGaugeReadingAddition':
    case 'RainGaugeReadingEdit':
      return {
        route: ROUTE_NAME.RAIN_GAUGE_DETAIL,
        props: { rainGaugeId: event.linksTo?.id },
      };

    case 'SaleAddition':
    case 'SaleEdit':
      return {
        route: ROUTE_NAME.SALE_DETAIL,
        props: { saleId: event.linksTo?.id },
      };

    case 'NaturalIncreaseAddition':
      return {
        route: ROUTE_NAME.NATURAL_INCREASE_DETAIL,
        props: { naturalIncreaseId: event.linksTo?.id },
      };

    case 'PurchaseAddition':
      return {
        route: ROUTE_NAME.PURCHASE_DETAIL,
        props: { purchaseId: event.linksTo?.id },
      };

    case 'TaskAddition':
    case 'TaskEdit':
      return {
        route: ROUTE_NAME.TASK_DETAIL,
        props: { taskId: event.linksTo?.id },
      };

    default:
      if (event.linksTo) {
        console.warn('Event type not handled: ', event.eventType);
      }
      return null;
  }
};
