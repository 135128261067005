import React from 'react';
import { type I18nContextProps } from '@mobble/i18n';
import { arrayIntersperse } from '@mobble/shared/src/core/Array';
import {
  daysAgo,
  dateIsInThePast,
  formatDate,
} from '@mobble/shared/src/core/Date';
import { roundNumber } from '@mobble/shared/src/core/Number';
import { findMobs, getTotalHead, type Mob } from '@mobble/models/src/model/Mob';
import {
  type Paddock,
  calcTotalDSEOfPaddock,
  GrazingHistoryType,
} from '@mobble/models/src/model/Paddock';
import { type ConfiguredPropertyType } from '@mobble/models/src/model/Property';
import { type Settings } from '@mobble/models/src/model/Settings';
import {
  makeQuantityOfArea,
  QuantityOfArea,
  convertTo,
} from '@mobble/shared/src/core/Quantity';
import {
  findPaddockGeometry,
  type PaddockGeometry,
} from '@mobble/models/src/model/PaddockGeometry';
import { polygonToArea } from '@mobble/models/src/model/MapGeometry';
import {
  type PaddockGroupedStockingRate,
  findPaddockGroupedStockingRate,
  toTotals,
} from '@mobble/models/src/model/PaddockGroupedStockingRate';
import {
  type PaddockGroup,
  findPaddockGroupForPaddock,
  getPaddocksInSamePaddockGroupAsPaddock,
} from '@mobble/models/src/model/PaddockGroup';
import { HStack } from '@src/stories/Components/Layout/Stack';
import {
  TableColumn,
  TableCellValue,
} from '@src/stories/Components/Layout/Table';
import { Clickable } from '@src/stories/Components/UX/Clickable';
import { Text } from '@src/stories/Components/UI/Text';
import { toPath } from '@src/interfaces/Routing';
import * as ROUTE_NAME from '@src/screens/config/routeNames';
import { ColorCircle } from '@src/stories/Components/UI/ColorCircle';
import { Spacer } from '@src/stories/Components/Layout/Spacer';
import {
  makeQuantityFormatMessageItem,
  unitFromSettingsForQuantityType,
} from '@src/stories/Components/Locale/LocaleQuantity';
import styles from './paddockTableColumns.scss';

export const makePaddocksTableColumns = ({
  propertyTypes,
  mobs,
  paddockGeometries,
  paddockGroupedStockingRates,
  paddocks,
  paddockGroups,
  settings,
  formatMessage,
}: {
  propertyTypes: ConfiguredPropertyType[];
  mobs: Mob[];
  paddockGeometries: PaddockGeometry[];
  paddockGroupedStockingRates: PaddockGroupedStockingRate[];
  paddocks: Paddock[];
  paddockGroups: PaddockGroup[];
  settings: Settings;
  formatMessage: I18nContextProps['formatMessage'];
}): TableColumn[] => {
  const getPaddockTypeColor = (type: string) =>
    propertyTypes.find((a) => a.type === type)?.color;

  const quantityToString = (val: QuantityOfArea) => {
    const unit = unitFromSettingsForQuantityType(settings)(val.type);
    const item = makeQuantityFormatMessageItem(val, unit);
    return item.length === 2
      ? formatMessage(item[0], item[1])
      : `${val.value} ${val.unit}`;
  };

  return [
    {
      key: 'name',
      label: formatMessage({
        defaultMessage: 'Paddock name',
        description: 'Paddock table column heading',
      }),
      toValue: (paddock: Paddock) => paddock.name,
      render: (_, paddock) => {
        const isSafe = paddock.properties.safeDate
          ? dateIsInThePast(paddock.properties.safeDate)
          : true;

        return (
          <Clickable
            href={toPath(ROUTE_NAME.PADDOCK_DETAIL, { paddockId: paddock.id })}
            className={styles.paddockName}
          >
            <Text underline variant="small">
              {paddock.name}
            </Text>

            {!isSafe && (
              <div className={styles.unSafeDot}>
                <div className={styles.tooltip}>
                  <Text align="center">
                    {formatMessage({ defaultMessage: 'Safe Date' })}:{' '}
                    {formatDate(paddock.properties.safeDate)}
                  </Text>
                </div>
              </div>
            )}
          </Clickable>
        );
      },
    },
    {
      key: 'type',
      label: formatMessage({
        defaultMessage: 'Type',
        description: 'Paddock table column heading',
      }),
      totals: false,
      toValue: (paddock: Paddock) => paddock.type,
      render: (value: string) => (
        <HStack alignment="center">
          <ColorCircle color={getPaddockTypeColor(value)} />
          <Spacer x={1} />
          <Text variant="small">{value}</Text>
        </HStack>
      ),
    },
    {
      key: 'grazeable_area',
      label: formatMessage({
        defaultMessage: 'Grazeable area',
        description: 'Paddock table column heading',
      }),
      totals: true,
      toValue: (paddock: Paddock) =>
        paddock?.properties?.size ?? makeQuantityOfArea('ha', 0),
      valueToString: quantityToString,
    },
    {
      key: 'total_area',
      label: formatMessage({
        defaultMessage: 'Total area',
        description: 'Paddock table column heading',
      }),
      totals: true,
      toValue: (paddock: Paddock) => {
        const polygon = findPaddockGeometry(paddockGeometries)(
          paddock.id
        )?.polygon;

        const area = polygon
          ? (convertTo(
              settings.areaUnit,
              2
            )(polygonToArea(polygon)) as QuantityOfArea)
          : makeQuantityOfArea('ha', 0);

        return area;
      },
      valueToString: quantityToString,
    },
    {
      key: 'mobs',
      label: formatMessage({
        defaultMessage: 'Mobs',
        description: 'Paddock table column heading',
      }),
      totals: true,
      toValue: (paddock: Paddock) => paddock.mobs.length,
    },
    {
      key: 'heads',
      label: formatMessage({
        defaultMessage: 'Head',
        description: 'Paddock table column heading',
      }),
      totals: true,
      toValue: (paddock: Paddock) => getTotalHead(findMobs(mobs)(paddock.mobs)),
    },
    {
      key: 'total_dse',
      label: formatMessage({
        defaultMessage: 'Total DSE',
        description: 'Paddock table column heading',
      }),
      totals: true,
      toValue: (paddock: Paddock) => {
        const paddockGroupedStockingRate = findPaddockGroupedStockingRate(
          paddockGroupedStockingRates
        )(paddock.id);
        const paddockGroupedStockingRateTotals = paddockGroupedStockingRate
          ? toTotals(paddockGroupedStockingRate)
          : null;
        return Number(paddockGroupedStockingRateTotals?.DSE) || 0;
      },
    },
    {
      key: 'average_dse_ha',
      label: formatMessage({
        defaultMessage: 'Average DSE/Ha',
        description: 'Paddock table column heading',
      }),
      toValue: (paddock: Paddock) =>
        paddock.type === 'Yard'
          ? 0
          : paddock.properties.averageAreaStockingRate,
    },
    {
      key: 'dse_ha',
      label: formatMessage({
        defaultMessage: 'DSE/Ha',
        description: 'Paddock table column heading',
      }),
      // For 'DSE/Ha' simply do the following calculation 'Total DSE' / 'Total Grazeable Area' = 'Total DSE/Ha'
      totals: (
        _values: TableCellValue[],
        _total: TableCellValue,
        rows: any[]
      ) => {
        let totalDSE = 0;
        let totalGrazeableArea = 0;

        rows.forEach((paddock) => {
          // Calculate the total DSE for all paddocks
          const paddockGroupedStockingRate = findPaddockGroupedStockingRate(
            paddockGroupedStockingRates
          )(paddock.id);
          const paddockGroupedStockingRateTotals = paddockGroupedStockingRate
            ? toTotals(paddockGroupedStockingRate)
            : null;
          totalDSE += Number(paddockGroupedStockingRateTotals?.DSE) || 0;
          totalGrazeableArea += paddock?.properties?.size?.value ?? 0;
        });

        if (totalGrazeableArea === 0) {
          return 0;
        }

        return roundNumber(totalDSE / totalGrazeableArea);
      },
      toValue: (paddock: Paddock) => {
        const paddockGroupedStockingRate = findPaddockGroupedStockingRate(
          paddockGroupedStockingRates
        )(paddock.id);
        const paddockGroupedStockingRateTotals = paddockGroupedStockingRate
          ? toTotals(paddockGroupedStockingRate)
          : null;
        return (
          paddockGroupedStockingRateTotals?.DSE_HA ??
          roundNumber(
            calcTotalDSEOfPaddock(mobs)(paddock) / paddock.properties.size.value
          )
        );
      },
    },
    {
      key: 'days_grazed',
      label: formatMessage({
        defaultMessage: 'Days grazed/rested',
        description: 'Paddock table column heading',
      }),
      toValue: (paddock: Paddock) => [
        paddock.properties.lastGrazingHistory?.type ===
        GrazingHistoryType.paddockGrazing
          ? 1
          : paddock.properties.lastGrazingHistory?.type ===
            GrazingHistoryType.paddockEmpty
          ? 0
          : -1,
        paddock.properties.lastGrazingHistory?.date
          ? paddock.properties.lastGrazingHistory?.type ===
            GrazingHistoryType.paddockEmpty
            ? -daysAgo(paddock.properties.lastGrazingHistory.date)
            : daysAgo(paddock.properties.lastGrazingHistory.date)
          : -1,
      ],
      valueToString: (value, paddock) => {
        if (
          paddock.properties.lastGrazingHistory?.type ===
          GrazingHistoryType.paddockGrazing
        ) {
          return formatMessage(
            {
              defaultMessage: '{COUNT} days grazed',
            },
            {
              COUNT: Math.abs(value[1]),
            }
          );
        } else if (
          paddock.properties.lastGrazingHistory?.type ===
          GrazingHistoryType.paddockEmpty
        ) {
          return formatMessage(
            {
              defaultMessage: '{COUNT} days rested',
            },
            {
              COUNT: Math.abs(value[1]),
            }
          );
        }
        return '';
      },
    },
    {
      key: 'opened_with',
      label: formatMessage({
        defaultMessage: 'Opened with',
        description: 'Paddock table column heading',
      }),
      toValue: (paddock: Paddock) => {
        const paddocksInSamePaddockGroup =
          getPaddocksInSamePaddockGroupAsPaddock(
            paddockGroups,
            paddocks
          )(paddock.id).filter((p) => p.id !== paddock.id);
        return paddocksInSamePaddockGroup.map((p) => p.name).toString();
      },
      render: (_, paddock: Paddock) => {
        const paddockGroup = findPaddockGroupForPaddock(paddockGroups)(
          paddock.id
        );
        const paddocksInSamePaddockGroup =
          getPaddocksInSamePaddockGroupAsPaddock(
            paddockGroups,
            paddocks
          )(paddock.id).filter((p) => p.id !== paddock.id);
        return (
          <HStack wrap flex>
            {arrayIntersperse(
              <Text>{', '}</Text>,
              paddocksInSamePaddockGroup.map((p) => (
                <Clickable
                  key={p.id}
                  href={toPath(ROUTE_NAME.MODAL_PADDOCK_GROUP_EDIT, {
                    paddockGroupId: paddockGroup.id,
                  })}
                >
                  <Text underline>{p.name}</Text>
                </Clickable>
              ))
            )}
          </HStack>
        );
      },
    },
  ];
};
