import React from 'react';
import { useI18n } from '@mobble/i18n';
import { formatDate } from '@mobble/shared/src/core/Date';
import { useEntitiesRefresher } from '@mobble/shared/src/hooks/useEntitiesRefresher';
import {
  useMobs,
  usePaddocks,
  useProperties,
  useRainGauges,
  useTasks,
} from '@mobble/store/src/hooks';
import { useDateRange } from '@src/hooks/useDateRange';

import * as ROUTE_NAME from '@src/screens/config/routeNames';
import { ScreenRendererProps } from '@src/screens/config/types';

import { Dashboard as DashboardView } from '@src/stories/Views/Dashboard/Dashboard';
import { InfoTileProps } from '@src/stories/Components/UI/InfoTile';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';
import { usePropertyStockingRatesForRange } from '@src/stories/Views/Reports/usePropertyStockingRatesForRange';

import { useDashboardSuggestions } from './hooks/useDashboardSuggestions';
import { useDashboardTiles } from './hooks/useDashboardTiles';
import { useSubscription } from '../Settings/hooks/useBilling';

const DEFAULT_INITIAL_DATE = '2000-01-01';
const CURRENT_DATE = formatDate(new Date(), 'YYYY-MM-DD');
const DATE_RANGE = `${DEFAULT_INITIAL_DATE}--${CURRENT_DATE}`;

export const DashboardHeader: React.FC<ScreenRendererProps> = (props) => {
  const { formatMessage } = useI18n();

  return (
    <ScreenHeader
      title={formatMessage({
        defaultMessage: 'Dashboard',
        description: 'screen.title.dashboard',
      })}
      onToggleDrawer={props.drawer.toggle}
    />
  );
};

export const Dashboard: React.FC<ScreenRendererProps> = () => {
  const { formatMessage } = useI18n();
  const properties = useProperties();
  const propertyId = properties.selected?.id;
  const mobs = useMobs(propertyId);
  const paddocks = usePaddocks(propertyId);
  const tasks = useTasks(propertyId);
  const rainGauges = useRainGauges(propertyId);

  const isOwner = properties.selected.currentUserRole === 'owner';
  const subscriptionDetails = useSubscription();

  const { totalDSE, totalArea, totalDSEPerAreaUnit, activeTasks } =
    useDashboardTiles({
      mobs: mobs.entities,
      paddocks: paddocks.entities,
      tasks: tasks.entities,
    });
  const { range } = useDateRange({ rangeFromRoute: DATE_RANGE });
  const propertyStockingRatesData = usePropertyStockingRatesForRange({
    propertyId: properties.selected.id,
    range,
  });

  const suggestionDetails = useDashboardSuggestions();

  useEntitiesRefresher([mobs, paddocks, tasks, rainGauges], propertyId);

  const infoTiles: InfoTileProps[] = [
    {
      id: 'total-dse',
      label: formatMessage({
        defaultMessage: 'Total DSE',
        description: 'dashboard.tile.total-dse.title',
      }),
      value: totalDSE,
    },
    {
      id: 'total-grazeable-area',
      label: formatMessage(
        {
          defaultMessage: 'Total Grazeable <b>{UNIT}</b>',
          description: 'dashboard.tile.total-grazeable-area.title',
        },
        { UNIT: totalArea.unit }
      ),
      value: totalArea.value,
    },
    {
      id: 'total-dse-per-area',
      label: formatMessage(
        {
          defaultMessage: 'Total DSE/<b>{UNIT}</b>',
          description: 'dashboard.tile.total-dse-area.title',
        },
        { UNIT: totalArea.unit }
      ),
      value: Number(totalDSEPerAreaUnit.totalDSEPerArea.toFixed(2)),
    },
    {
      id: 'active-tasks',
      label: formatMessage({
        defaultMessage: 'Active Tasks',
        description: 'dashboard.tile.active-tasks.title',
      }),
      value: activeTasks,
    },
  ];

  const mobsData = mobs.entities.length ? mobs.entities : null;
  const rainGaugeData = rainGauges.entities.length ? rainGauges.entities : null;

  const displayTialStatus =
    subscriptionDetails.loadingStatus === 'ready' &&
    subscriptionDetails.isTrial &&
    isOwner;

  const trialCountDown = subscriptionDetails.daysUntilTrialEnd;

  return (
    <DashboardView
      infoTiles={infoTiles}
      mobs={mobsData}
      rainGauges={rainGaugeData}
      dashboardSuggestions={suggestionDetails}
      propertyStockingRatesData={propertyStockingRatesData}
      displayTialStatus={displayTialStatus}
      trialCountDown={trialCountDown}
    />
  );
};

export default {
  name: ROUTE_NAME.DASHBOARD,
  header: DashboardHeader,
  component: Dashboard,
};
