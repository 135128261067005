import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import localeData from 'dayjs/plugin/localeData';

dayjs.extend(utc);
dayjs.extend(quarterOfYear);
dayjs.extend(localeData);

export type DateObject = Dayjs;

export type RawDate = string | number | DateObject | Date;

export type DateRange = {
  start: Date;
  end: Date;
};

export const dateRangeOptions = [
  'today',
  'last-7-days',
  'last-4-weeks',
  'last-3-months',
  'last-12-months',
  'month-to-date',
  'quarter-to-date',
  'year-to-date',
] as const;

export type DateRangeOption = (typeof dateRangeOptions)[number];

export const fromRawDate = (rawDate: RawDate): DateObject => dayjs(rawDate);

export const dateObjectFromString = (dateString: string): DateObject =>
  dayjs(dateString);

export const daysAgo = (rawDate: RawDate): number => {
  return dayjs().diff(fromRawDate(rawDate).format('YYYY-MM-DD'), 'day');
};

export const formatDate = (rawDate: RawDate, format = 'DD/MM/YYYY'): string => {
  return fromRawDate(rawDate).format(format);
};

export const formatTime = (rawDate: RawDate, format = 'HH:mm'): string => {
  return fromRawDate(rawDate).format(format);
};

export const formatDateAndTime = (rawDate: RawDate): string => {
  return `${formatDate(rawDate)} ${formatTime(rawDate)}`;
};

export const toISO8601 = (rawDate: RawDate = new Date()): string => {
  return dayjs(rawDate).toISOString();
};

export const daysBetween =
  (dayStart: RawDate) =>
  (dayEnd: RawDate): number => {
    return fromRawDate(dayEnd).diff(fromRawDate(dayStart), 'day');
  };

export const dateIsInThePast = (rawDate: RawDate): boolean => {
  return fromRawDate(rawDate).isBefore(dayjs());
};

export const sortByDate = (a: RawDate, b: RawDate): number => {
  return fromRawDate(b).diff(fromRawDate(a));
};

export const dateFromString = (a: string): Date => {
  return dayjs(a).toDate();
};

export const compareDates = (a?: RawDate, b?: RawDate): -1 | 0 | 1 => {
  if (!a && !b) {
    return 0;
  }
  if (!a) {
    return -1;
  }
  if (!b) {
    return 1;
  }
  return fromRawDate(a).diff(fromRawDate(b)) > 0 ? 1 : -1;
};

export const dateIsBetween = (
  value: RawDate,
  min?: null | RawDate,
  max?: null | RawDate
): boolean => {
  if (min && !max) {
    return fromRawDate(value).isAfter(fromRawDate(min));
  } else if (!min && max) {
    return fromRawDate(value).isBefore(fromRawDate(max));
  } else if (min && max) {
    return (
      fromRawDate(value).isAfter(fromRawDate(min)) &&
      fromRawDate(value).isBefore(fromRawDate(max))
    );
  }
  return true;
};

export const startOfCurrentYear = () => {
  return dayjs().utc().startOf('year').toISOString();
};

export const endOfCurrentYear = () => {
  return dayjs().utc().endOf('year').toISOString();
};

export const startOfYear = (year: number) => {
  return dayjs().utc().year(year).startOf('year').toISOString();
};

export const endOfYear = (year: number) => {
  return dayjs().utc().year(year).endOf('year').toISOString();
};

export const addDays = (rawDate: RawDate, days: number): DateObject => {
  return fromRawDate(rawDate).add(days, 'day');
};

export const getDatesInYear = (year: number, format?: string) => {
  let date = dayjs().utc().year(year).startOf('year');
  const end = dayjs
    .utc()
    .year(year + 1)
    .startOf('year');
  const arr = [];

  let i = 0;
  while (date.isBefore(end) && i < 365) {
    arr.push(format ? date.utc().format(format) : date.utc().toISOString());
    date = date.utc().add(1, 'day');
    i++;
  }

  return arr;
};

export const getDateRange = (type: DateRangeOption): DateRange => {
  switch (type) {
    case 'today':
      return {
        start: dayjs().utc().startOf('day').subtract(1, 'day').toDate(),
        end: dayjs().utc().startOf('day').toDate(),
      };
    case 'last-7-days':
      return {
        start: dayjs().utc().startOf('day').subtract(7, 'days').toDate(),
        end: dayjs().utc().startOf('day').toDate(),
      };
    case 'last-4-weeks':
      return {
        start: dayjs().utc().startOf('day').subtract(4, 'weeks').toDate(),
        end: dayjs().utc().startOf('day').toDate(),
      };
    case 'last-3-months':
      return {
        start: dayjs().utc().startOf('day').subtract(3, 'months').toDate(),
        end: dayjs().utc().startOf('day').toDate(),
      };
    case 'last-12-months':
      return {
        start: dayjs().utc().startOf('day').subtract(12, 'months').toDate(),
        end: dayjs().utc().startOf('day').toDate(),
      };
    case 'month-to-date':
      return {
        start: dayjs().utc().startOf('day').startOf('month').toDate(),
        end: dayjs().utc().startOf('day').toDate(),
      };
    case 'quarter-to-date':
      return {
        start: dayjs().utc().startOf('quarter').toDate(),
        end: dayjs().utc().startOf('day').toDate(),
      };
    case 'year-to-date':
      return {
        start: dayjs().utc().startOf('year').toDate(),
        end: dayjs().utc().startOf('day').toDate(),
      };
  }
};

export const getMonths = ({ short }: { short?: boolean } = {}): string[] => {
  if (short) {
    return dayjs.monthsShort();
  }
  return dayjs.months();
};

export const loadDateLocale = async (locale: string): Promise<string> => {
  switch (locale) {
    case 'es-ar':
    case 'es-cl':
    case 'es-uy':
    case 'es':
      await import('dayjs/locale/es');
      dayjs.locale('es');
      break;
    case 'ja':
      await import('dayjs/locale/ja');
      dayjs.locale('ja');
      break;
    default:
      await import('dayjs/locale/en');
      dayjs.locale('en');
      break;
  }
  return dayjs.locale();
};
