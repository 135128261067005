import React from 'react';
import { Text } from '@src/stories/Components/UI/Text';
import { Box } from '../Layout/Box';
import { Color } from '@mobble/colors/src';
import { Clickable } from './Clickable';
import { Icon } from '../UI/Icon';
import { Spacer } from '../Layout/Spacer';
import { IconName } from '@mobble/icon/src';
import styles from './cardedNavigationListItem.scss';
import { RootStackParamList } from '@src/screens/config/types';

export interface CardedNavigationListItemProps {
  title: string;
  subtitle: string;
  href?: keyof RootStackParamList | (() => void);
  icon: IconName;
  disabled?: boolean;
  isLastElement?: boolean;
}

export const CardedNavigationListItem: React.FC<
  CardedNavigationListItemProps
> = (item) => (
  <Clickable href={item.href} className={styles.cardNavigationListGridItem}>
    <Box
      background={item.disabled ? Color.AlmostWhite : Color.White}
      className={[styles.card, !item.isLastElement ? styles.cardBorder : null]}
    >
      <Box className={styles.cardTitle}>
        <Icon
          name={item.icon}
          color={item.disabled ? Color.Grey : Color.Green}
        />
        <Spacer x={2} />
        <Text
          i18n={item.title}
          bold
          color={item.disabled ? Color.Grey : Color.Black}
        />
      </Box>
      <Box className={styles.cardSubtitle}>
        <Text i18n={item.subtitle} bold color={Color.Grey} />
      </Box>
    </Box>
  </Clickable>
);
