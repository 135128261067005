import React from 'react';
import { Spacer } from '../Layout/Spacer';
import { VStack } from '../Layout/Stack';
import { type I18nItem } from '@mobble/i18n';
import { Box } from '../Layout/Box';
import { reactIntersperse } from '@src/interfaces/React';
import { Button } from './Button';

export type InputRadioAsButtonProps = {
  size?: 'small' | 'normal';
  options: InputRadioAsButtonOption[];
  onChange: (value: string | number) => void;
};

export interface InputRadioAsButtonOption {
  label: string | I18nItem;
  value: number | string;
  selected?: boolean;
}

export const InputRadioAsButton: React.FC<InputRadioAsButtonProps> = ({
  size = 'normal',
  options,
  onChange,
}) => {
  const buttons = options.map((option) => {
    return (
      <Button
        key={`${option.value}`}
        size={size}
        outline={!option.selected}
        intent="secondary"
        onClick={() => {
          onChange(option.value);
        }}
        label={
          typeof option.label === 'string'
            ? { key: option.label }
            : option.label
        }
      />
    );
  });

  const children = reactIntersperse(<Spacer y={1} />, buttons);

  return (
    <Box spacing={{ left: 2, right: 2 }}>
      <VStack>{children}</VStack>
    </Box>
  );
};
