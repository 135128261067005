import { ActionType } from '@mobble/models/src/model/Actions';
import { findPaddock } from '@mobble/models/src/model/Paddock';
import {
  usePaddocks,
  useProperties,
  useRootSelector,
} from '@mobble/store/src/hooks';

import { type PaddockTotalValue } from '@src/stories/Views/Misc/InputInventoryItems';
import { type FormBuilderFieldProps } from '@src/stories/Views/Form/FormBuilder';
import { type PaddockActionCreateFormValues } from '@src/stories/Views/PaddockAction/PaddockActionCreateForm';
import { type PaddockActionEditFormValues } from '@src/stories/Views/PaddockAction/PaddockActionEditForm';

export const useInputInventoryItems = (
  props: FormBuilderFieldProps<
    PaddockActionCreateFormValues | PaddockActionEditFormValues
  >
) => {
  const properties = useProperties();
  const propertyId = properties.selected?.id;
  const { entities: paddocks } = usePaddocks(propertyId);

  const inventoriesRoot = useRootSelector((s) => s.inventories);
  const inventoryItems = useRootSelector((s) => s.inventoryItems);
  const inventoryItemBatches = useRootSelector((s) => s.inventoryItemBatches);

  const findPaddockFromId = findPaddock(paddocks);

  const inventories =
    inventoriesRoot.entities.filter(
      (inventory) => inventory.propertyId === propertyId
    ) || [];

  const paddocksAffected = props.values.paddock.map((paddockId) =>
    findPaddockFromId(paddockId)
  );

  const paddockArea = paddocksAffected.reduce(
    (acc, curr) => acc + (curr?.properties?.size?.value ?? 0),
    0
  );

  const totalValue: PaddockTotalValue = {
    type: ActionType.PaddockAction,
    paddockArea: paddockArea,
  };

  const value = (props.values as any)[props.field.name];

  return {
    label: props.makeI18n('label'),
    value: value && typeof value === 'string' ? JSON.parse(String(value)) : [],
    totalValue: totalValue,
    inventories,
    inventoryItems: inventoryItems.entities || [],
    inventoryItemBatches: inventoryItemBatches.entities || [],
    error: Array.isArray(props.error) ? props.error : [props.error],
    actionType: ActionType.PaddockAction,
    onChange: (val) => props.onChange(JSON.stringify(val)),
    onTouched: props.onTouched,
  };
};
