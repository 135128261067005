import React from 'react';
import { useI18n } from '@mobble/i18n';
import {
  checkPropertyHasUserEmail,
  getMobbleConnectUserStatusOfPropertiesByEmail,
  getPropertiesWhereUserHasAdminAccess,
  Property,
} from '@mobble/models/src/model/Property';
import * as ROUTE_NAME from '@src/screens/config/routeNames';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';
import { useProperties } from '@mobble/store/src/hooks/properties';
import { EntitySliceFactoryPrelude } from '@src/stories/Views/Misc/EntitySliceFactoryPrelude';
import { useGetUser } from '@mobble/store/src/hooks/auth';
import {
  MobbleConnectInviteUserForm,
  MobbleConnectInviteUserFormValues,
} from '@src/stories/Views/Settings/SettingsPropertyMobbleConnectInviteUserForm';
import { ListSelectOption } from '@src/stories/Components/UX/ListSelect';
import { checkEmailIsValid, UserRole } from '@mobble/models/src/model/User';
import { ScreenRendererProps } from '@src/screens/config/types';
import { useLinking } from '@src/hooks/useLinking';
import { toPath } from '@src/interfaces/Routing';

export const MobbleConnectInviteUserHeader: React.FC<ScreenRendererProps> = ({
  route,
}) => {
  const mobbleConnectRole = route.params.role as UserRole;
  const { formatMessage } = useI18n();
  const linkTo = useLinking();
  const roleString = formatMessage({
    id: `user_role.${mobbleConnectRole}`,
    defaultMessage: [],
  });

  return (
    <ScreenHeader
      title={formatMessage(
        {
          description: 'settings.property.mobble_connect.invite_user.title',
          defaultMessage: 'Invite {ROLE}',
        },
        {
          ROLE: roleString ?? mobbleConnectRole,
        }
      )}
      breadcrumbs={[
        {
          title: formatMessage({
            description: 'screen.title.settings',
            defaultMessage: 'Settings',
          }),
          href: ROUTE_NAME.SETTINGS_LIST,
        },
        {
          title: formatMessage({
            description: 'settings.property.mobble_connect.title',
            defaultMessage: 'Mobble Connect',
          }),
          href: ROUTE_NAME.MOBBLE_CONNECT_USERS,
        },
        {
          title: { key: `user_role.${mobbleConnectRole}` },
          href: toPath(ROUTE_NAME.MOBBLE_CONNECT_USER, {
            role: mobbleConnectRole,
          }),
        },
      ]}
      onGoBack={() => {
        linkTo(ROUTE_NAME.MOBBLE_CONNECT_USER, {
          role: mobbleConnectRole,
        });
      }}
    />
  );
};

export const MobbleConnectInviteUser: React.FC<ScreenRendererProps> = ({
  route,
}) => {
  const mobbleConnectRole = route.params?.role as UserRole;
  const { formatMessage } = useI18n();
  const linkTo = useLinking();

  const [emailInput, setEmailInput] = React.useState<string>('');
  const [loading, setLoading] = React.useState(false);

  const me = useGetUser();
  const properties = useProperties();

  const prelude = EntitySliceFactoryPrelude({
    preludes: [properties.prelude],
    required: [properties.selected],
  });

  const property = properties.selected as Property;

  const propertiesWithAdminAccess = getPropertiesWhereUserHasAdminAccess(
    properties.entities
  )(me?.id ?? '');

  const propertyOptions = emailInput
    ? getMobbleConnectUserStatusOfPropertiesByEmail(propertiesWithAdminAccess)(
        property
      )(emailInput)
        ?.filter((e) => !e.isPartOfProperty)
        ?.map(
          (propety): ListSelectOption => ({
            label: propety.propertyName,
            value: propety.propertyId,
          })
        )
    : [];

  if (prelude) {
    return prelude;
  }

  /*
  const hasExpiry =
    mobbleConnectRole === UserRole.Auditor ||
    mobbleConnectRole === UserRole.Contractor;
  */

  const disabled =
    !emailInput ||
    !checkEmailIsValid(emailInput) ||
    Boolean(checkPropertyHasUserEmail(property)(emailInput));

  const infoMessage =
    emailInput && checkPropertyHasUserEmail(property)(emailInput)
      ? formatMessage({
          description:
            'settings.property.mobble_connect.invite_user.form.user.exists.message',
          defaultMessage: 'User is already part of property',
        })
      : null;

  const handleCancel = () => {
    linkTo(ROUTE_NAME.MOBBLE_CONNECT_USER, {
      role: mobbleConnectRole,
    });
  };

  const handleSubmit = ({
    email,
    propertyIds,
  }: // expires,
  MobbleConnectInviteUserFormValues) => {
    setLoading(true);

    properties
      .addUser({
        propertyIds,
        organisationId: property.organisationId,
        role: mobbleConnectRole,
        email,
        // expires,
      })
      .then(() => {
        setLoading(false);
        linkTo(ROUTE_NAME.MOBBLE_CONNECT_USER, {
          role: mobbleConnectRole,
        });
      });
  };

  const disableSubmit = emailInput
    ? Boolean(checkPropertyHasUserEmail(property)(emailInput))
    : !checkEmailIsValid(emailInput);

  return (
    <MobbleConnectInviteUserForm
      loading={loading}
      properties={propertyOptions}
      currentPropertyId={property.id}
      emailErrorMessage={infoMessage}
      disabled={disabled}
      // hasExpiry={hasExpiry}
      disableSubmit={disableSubmit}
      onChange={({ email: e }) => setEmailInput(e)}
      onCancel={handleCancel}
      onSubmit={handleSubmit}
    />
  );
};

export default {
  name: ROUTE_NAME.MOBBLE_CONNECT_USER_INVITE,
  header: MobbleConnectInviteUserHeader,
  component: MobbleConnectInviteUser,
};
