import Fuse from 'fuse.js';
import { fromRawDate, RawDate } from '@mobble/shared/src/core/Date';
import { FilterItem, filterMatches, groupFilter } from './Filter';
import { type Mob } from './Mob';
import { type Paddock } from './Paddock';
import { type Property } from './Property';

export interface Purchase {
  id: string;
  propertyId: Property['id'];
  type: string;
  date: RawDate;

  mob: Mob;

  paddock?: Paddock['id'];

  notes: string | null;
  pricePerHeadCents: number;
  sellerId?: string;
  sellerName?: string;

  status?: 'active' | 'deleted';
}

export const filterPurchases = (
  purchases: Purchase[],
  filter?: FilterItem[]
): Purchase[] => {
  if (!filter || filter.length === 0) {
    return purchases;
  }
  const grouped = [...groupFilter(filter)];

  const searchQuery = filter.find((a) => a.group === 'search')?.filter;

  const preFilteredPurchases =
    searchQuery && searchQuery.type === 'search'
      ? searchPurchases(purchases, searchQuery.value)
      : purchases;

  return preFilteredPurchases.filter((purchase) =>
    grouped.every(([_, filters]) =>
      filters.some(filterItemMatchesPurchases(purchase))
    )
  );
};

export const searchPurchases = (
  purchase: Purchase[],
  searchQuery: string
): Purchase[] => {
  const fuse = new Fuse(purchase, {
    keys: [
      { name: 'mob.breed', weight: 5 },
      { name: 'mob.gender', weight: 3 },
      { name: 'mob.number', weight: 3 },
      { name: 'sellerName', weight: 5 },
      { name: 'notes', weight: 3 },
    ],
    threshold: 0.5,
    shouldSort: true,
  });
  return fuse.search(searchQuery).map((a) => a.item);
};

export const filterItemMatchesPurchases =
  (purchase: Purchase) => (filterItem: FilterItem) => {
    const matches = filterMatches(filterItem.filter);

    switch (filterItem.group) {
      case 'breed':
        return matches(purchase.mob.breed);
      case 'gender':
        return matches(purchase.mob.gender);
      case 'number':
        return matches(purchase.mob.size);
      case 'year':
        return matches(fromRawDate(purchase.date).year());
      case 'dateRange':
        return matches(purchase.date as any);
      case 'search':
        return true;
      default:
        return true;
    }
  };
