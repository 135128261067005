import React from 'react';
import isEqual from 'lodash/isEqual';
import { type ConfiguredPropertyType } from '@mobble/models/src/model/Property';
import {
  lastGrazingHistoryTypeToI18nKey,
  calcTotalDSEOfPaddock,
  paddockIsYard,
  type Paddock,
} from '@mobble/models/src/model/Paddock';
import {
  toMobDisplayName,
  summarizeAges,
  type Mob,
} from '@mobble/models/src/model/Mob';
import {
  dateIsInThePast,
  dateObjectFromString,
  daysAgo,
} from '@mobble/shared/src/core/Date';
import { roundNumber } from '@mobble/shared/src/core/Number';
import {
  distanceFromPointToPolygon,
  type Point,
} from '@mobble/models/src/model/MapGeometry';
import { type PaddockGeometry } from '@mobble/models/src/model/PaddockGeometry';
import {
  findPendingTasksForPaddock,
  type Task,
} from '@mobble/models/src/model/Task';
import {
  findPaddockGroupedStockingRate,
  toTotals,
  type PaddockGroupedStockingRate,
} from '@mobble/models/src/model/PaddockGroupedStockingRate';
import { Color } from '@mobble/colors';
import { Box } from '@src/stories/Components/Layout/Box';
import { Text } from '@src/stories/Components/UI/Text';
import { Card } from '@src/stories/Components/Layout/Card';
import { Spacer } from '@src/stories/Components/Layout/Spacer';
import { HStack } from '@src/stories/Components/Layout/Stack';
import { Badge, BadgeProps } from '@src/stories/Components/UI/Badge';
import {
  LocaleQuantity,
  quantityToLocaleI18nItem,
} from '@src/stories/Components/Locale/LocaleQuantity';
import { Icon } from '@src/stories/Components/UI/Icon';
import { Clickable } from '@src/stories/Components/UX/Clickable';
import styles from './paddockCard.scss';

interface PaddockCardProps {
  propertyTypes: ConfiguredPropertyType[];
  paddocksInSamePaddockGroup?: Paddock[];
  paddockGroupedStockingRates: PaddockGroupedStockingRate[];
  paddock: Paddock;
  mobs: Mob[];
  tasks: Task[];
  paddockGeometry?: PaddockGeometry;
  showDistanceFrom?: false | null | Point;
  onClick?: string | ((paddock: Paddock) => void);
}

export const PaddockCardComponent: React.FC<PaddockCardProps> = ({
  propertyTypes,
  paddocksInSamePaddockGroup = [],
  paddockGroupedStockingRates,
  paddock,
  paddockGeometry,
  mobs,
  tasks,
  onClick,
  showDistanceFrom,
}) => {
  const isYardPaddock = paddockIsYard(paddock);
  const connectedPaddocks = paddocksInSamePaddockGroup.filter(
    (p) => p.id !== paddock.id
  );

  const paddockColor = propertyTypes.find(
    (a) => a.type === paddock.type
  )?.color;

  const paddockGroupedStockingRate = findPaddockGroupedStockingRate(
    paddockGroupedStockingRates
  )(paddock.id);
  const paddockGroupedStockingRateTotals = paddockGroupedStockingRate
    ? toTotals(paddockGroupedStockingRate)
    : null;

  const pendingTasks = findPendingTasksForPaddock(tasks)(paddock.id);

  const renderMob = (mobId: string) => {
    const mob = mobs.find((m) => m.id === mobId);
    if (!mob) {
      return null;
    }
    return (
      <Box key={mobId} spacing={{ top: 0, right: 0, bottom: 0.5, left: 0 }}>
        <HStack>
          <Text color={Color.Black}>{toMobDisplayName(mob)}</Text>
          <Spacer flex />
          <Text color={Color.Black}>{mob.size}</Text>
        </HStack>
        {mob.classes.length > 0 || mob.ages.length > 0 ? (
          <Box spacing={{ top: 0.25, right: 4, bottom: 0.5, left: 0.5 }}>
            {mob.classes.length > 0 ? (
              <Text
                variant="small"
                color={Color.Grey}
                i18n={{
                  key: 'paddocks.list.paddock.card.classes_summary',
                  params: {
                    '%SUMMARY': mob.classes.join(', '),
                  },
                }}
              />
            ) : null}
            {mob.ages.length > 0 ? (
              <Text
                variant="small"
                color={Color.Grey}
                i18n={{
                  key: 'paddocks.list.paddock.card.ages_summary',
                  params: {
                    '%SUMMARY': summarizeAges(mob),
                  },
                }}
              />
            ) : null}
          </Box>
        ) : null}
      </Box>
    );
  };

  const badges: BadgeProps[] = [
    !isYardPaddock && paddock.properties.size
      ? {
          color: Color.LightGreen,
          label: quantityToLocaleI18nItem(paddock.properties.size),
        }
      : null,
    paddockGroupedStockingRateTotals?.head
      ? {
          color: Color.Lilac,
          label: {
            key: 'paddocks.list.paddock.card.badge.heads',
            params: {
              '%COUNT': paddockGroupedStockingRateTotals.head,
            },
          },
        }
      : null,
    !isYardPaddock &&
    paddockGroupedStockingRateTotals?.head &&
    paddock.properties.size
      ? {
          color: Color.Lilac,
          label: {
            key: 'paddocks.list.paddock.card.badge.dse_ha',
            params: {
              '%COUNT':
                paddockGroupedStockingRateTotals?.DSE_HA ??
                roundNumber(
                  calcTotalDSEOfPaddock(mobs)(paddock) /
                    paddock.properties.size.value
                ),
            },
          },
        }
      : null,
    !isYardPaddock
      ? {
          color: Color.Lilac,
          label: {
            key: 'paddocks.list.paddock.card.badge.average_dse_ha',
            params: { '%COUNT': paddock.properties.averageAreaStockingRate },
          },
        }
      : null,
    !isYardPaddock && paddock.properties.lastGrazingHistory
      ? {
          color: paddockGroupedStockingRateTotals?.head
            ? Color.Lilac
            : Color.LightGreen,
          label: {
            key: `paddocks.list.paddock.card.badge.last_grazing_history.${lastGrazingHistoryTypeToI18nKey(
              paddock.properties.lastGrazingHistory.type
            )}`,
            params: {
              '%COUNT': daysAgo(
                dateObjectFromString(paddock.properties.lastGrazingHistory.date)
              ),
            },
          },
        }
      : null,
  ].filter(Boolean) as any as BadgeProps[];

  const distance =
    showDistanceFrom && paddockGeometry
      ? distanceFromPointToPolygon(showDistanceFrom, paddockGeometry.polygon)
      : null;

  const hasPendingTasks = pendingTasks?.length > 0;

  const isSafe = paddock.properties.safeDate
    ? dateIsInThePast(paddock.properties.safeDate)
    : true;

  const topRight =
    isSafe && !hasPendingTasks ? null : (
      <Box>
        <HStack alignment="center">
          {pendingTasks?.length > 0 ? (
            <HStack>
              <Icon color={Color.Red} name="clipboard" size="small" />
              <Text color={Color.Red}>{pendingTasks.length}</Text>
            </HStack>
          ) : null}

          {!isSafe && (
            <>
              <Spacer x={0.5} />
              <Box className={styles.unSafeDot} />
            </>
          )}
        </HStack>
      </Box>
    );

  return (
    <Clickable
      fullWidth
      href={typeof onClick === 'function' ? () => onClick(paddock) : onClick}
    >
      <Card title={paddock.name} color={paddockColor} topRight={topRight}>
        {distance ? (
          <Box flex spacing={{ top: 0, right: 0, bottom: 1, left: 0 }}>
            <HStack>
              <Spacer flex />
              <Icon size="small" name="distance" color={Color.DarkGrey} />
              <Spacer x={1} />
              <LocaleQuantity
                color={Color.DarkGrey}
                variant="small"
                quantity={distance}
              />
            </HStack>
          </Box>
        ) : null}
        {connectedPaddocks.length ? (
          <Text
            bold
            color={Color.Green}
            i18n={{
              key: 'paddocks.list.paddock.card.open_with',
              params: {
                '%PADDOCK_NAMES': connectedPaddocks
                  .map((a) => a.name)
                  .join(', '),
              },
            }}
          />
        ) : null}
        <Spacer y={1} />
        <Box>{paddock.mobs.map(renderMob)}</Box>
        <Spacer y={2} />
        <HStack wrap>
          {badges.map((badge, index) => (
            <Box key={index} spacing={{ top: 0, right: 1, bottom: 1, left: 0 }}>
              <Badge {...badge} />
            </Box>
          ))}
        </HStack>
      </Card>
    </Clickable>
  );
};

export const PaddockCard = React.memo(PaddockCardComponent, (a, b) => {
  return (
    a.showDistanceFrom === b.showDistanceFrom &&
    isEqual(a.paddock, b.paddock) &&
    isEqual(a.tasks, b.tasks) &&
    isEqual(a.mobs, b.mobs) &&
    isEqual(a.paddocksInSamePaddockGroup, b.paddocksInSamePaddockGroup) &&
    isEqual(a.paddockGroupedStockingRates, b.paddockGroupedStockingRates) &&
    isEqual(a.tasks, b.tasks)
  );
});
