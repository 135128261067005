import React from 'react';
import { useI18n } from '@mobble/i18n';
import { toMobDisplayName } from '@mobble/models/src/model/Mob';
import { useEntitiesRefresher } from '@mobble/shared/src/hooks/useEntitiesRefresher';
import {
  usePaddock,
  useProperties,
  useCasualties,
  useCasualty,
} from '@mobble/store/src/hooks';

import { toPath } from '@src/interfaces/Routing';
import { useNavigateBack } from '@src/hooks/useNavigateBack';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';
import { CasualtyInfoTable } from '@src/stories/Views/Casualty/CasualtyInfoTable';
import { EntitySliceFactoryPrelude } from '@src/stories/Views/Misc/EntitySliceFactoryPrelude';

import * as ROUTE_NAME from '../config/routeNames';
import { type ScreenRendererProps } from '../config/types';

export const CasualtyDetailHeader: React.FC<ScreenRendererProps> = (props) => {
  const { casualtyId = '' } = props.route.params;
  const { formatMessage } = useI18n();
  const goBack = useNavigateBack();
  const properties = useProperties();
  const casualties = useCasualties(properties.selected?.id);
  const casualty = useCasualty(casualtyId, properties.selected?.id);

  const quickLinks = casualties.entities
    .map((p) => ({
      title: toMobDisplayName(p.mob),
      href: toPath(ROUTE_NAME.CASUALTY_DETAIL, { casualtyId: p.id }),
    }))
    .sort((a, b) => a.title.localeCompare(b.title));

  return (
    <ScreenHeader
      title={
        casualty?.entity
          ? toMobDisplayName(casualty.entity.mob)
          : formatMessage({
              defaultMessage: 'Casualty',
              description: 'screen.title.casualty_detail',
            })
      }
      breadcrumbs={[
        {
          title: formatMessage({
            description: 'screen.title.summaries',
            defaultMessage: 'Summaries',
          }),
          href: ROUTE_NAME.SUMMARIES_LIST,
        },
        {
          title: formatMessage({
            description: 'screen.title.summary_casualties',
            defaultMessage: 'Casualties',
          }),
          href: ROUTE_NAME.SUMMARY_CASUALTIES,
        },
      ]}
      quickLinks={quickLinks}
      actions={[
        {
          icon: 'edit',
          href: toPath(ROUTE_NAME.CASUALTY_EDIT, { casualtyId }),
        },
      ]}
      onGoBack={goBack}
    />
  );
};

export const CasualtyDetail: React.FC<ScreenRendererProps> = (props) => {
  const { casualtyId = '' } = props.route.params;
  const properties = useProperties();
  const propertyId = properties.selected?.id;
  const casualty = useCasualty(casualtyId, propertyId);
  const paddock = usePaddock(casualty.entity?.paddock || '');

  useEntitiesRefresher([casualty, paddock], propertyId);

  const Prelude = EntitySliceFactoryPrelude({
    preludes: [properties.prelude, casualty.prelude, paddock.prelude],
    required: [properties.selected?.types],
    notFound: [casualty.entity],
  });

  if (Prelude) {
    return Prelude;
  }

  return (
    <CasualtyInfoTable
      casualty={casualty.entity}
      paddock={paddock.entity}
      propertyTypes={properties.selected.types}
    />
  );
};

export default {
  name: ROUTE_NAME.CASUALTY_DETAIL,
  header: CasualtyDetailHeader,
  component: CasualtyDetail,
};
