import React from 'react';
import { Box } from '../Layout/Box';
import { Text } from '../UI/Text';
import { HStack } from '../Layout/Stack';
import { Spacer } from '../Layout/Spacer';
import { Color } from '@mobble/colors';
import { type I18nItem } from '@mobble/i18n';
import { MOBBLE_COLORS } from '@mobble/models/src/model/Property';
import { Clickable } from './Clickable';
import { ColorCircle } from '../UI/ColorCircle';
import styles from './inputColor.scss';
import { Icon } from '../UI/Icon';
import { Modal } from './Modal';
import { ColorSquare } from '../UI/ColorSquare';

export interface InputColorProps {
  colors?: string[];
  placeholder?: I18nItem;
  value?: string;
  onExpandChange?: (expanded: boolean) => void;
  onChange: (value: string) => void;
  onBlur?: () => void;
}

export const InputColor: React.FC<InputColorProps> = ({
  colors,
  placeholder,
  value,
  onExpandChange,
  onChange,
  onBlur,
}) => {
  const [stateExpanded, setStateExpanded] = React.useState(false);

  const onToggleExpanded = () => {
    setStateExpanded(!stateExpanded);
    if (onExpandChange) {
      onExpandChange(!stateExpanded);
    }
    if (onBlur && !stateExpanded) {
      onBlur();
    }
  };

  const options = (colors ?? MOBBLE_COLORS).map((color) => ({
    label: color,
    color,
    selected: color.toUpperCase() === value?.toUpperCase(),
  }));

  const onClose = () => {
    setStateExpanded(false);
  };

  const headerComponent = (
    <Box>
      <HStack>
        <Box spacing={2}>
          <Text
            variant="card-title"
            i18n={{ key: 'color_selector.modal.title' }}
          />
        </Box>
        <Spacer flex />
        <Clickable href={onClose}>
          <Box spacing={2}>
            <Icon name="close" />
          </Box>
        </Clickable>
      </HStack>
    </Box>
  );

  return (
    <>
      <Box flex className={styles.inputWrapper}>
        <Clickable href={onToggleExpanded} flex spacing={2}>
          <Box flex>
            <HStack>
              <ColorCircle color={value} />
              <Spacer x={1} />
              <Text
                color={Color.Black}
                i18n={
                  !value
                    ? placeholder || { key: 'color_selector.placeholder' }
                    : null
                }
              >
                {value}
              </Text>
            </HStack>
          </Box>
          <Icon
            size="small"
            name={stateExpanded ? 'chevron-up' : 'chevron-down'}
          />
        </Clickable>
      </Box>
      <Modal show={stateExpanded} header={headerComponent} onClose={onClose}>
        <Box>
          <HStack>
            <Box spacing={3}>
              {options.map((option) => (
                <Clickable
                  key={option.color}
                  href={() => {
                    onChange(option.color);
                    setStateExpanded(false);
                  }}
                  spacing={1}
                >
                  <Box flex>
                    <ColorSquare color={option.color} />
                  </Box>
                </Clickable>
              ))}
            </Box>
          </HStack>
        </Box>
        <Box mobileSpacing={{ bottom: 8 }} />
      </Modal>
    </>
  );
};
