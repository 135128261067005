import React from 'react';
import { useI18n } from '@mobble/i18n';
import {
  ConfiguredPropertyTypeGroup,
  getOptionsFromTypesByGroup,
} from '@mobble/models/src/model/Property';
import {
  findPaddock,
  sortAnyContainingPaddocks,
  sortOptionsNameDistanceFromMe,
} from '@mobble/models/src/model/Paddock';
import { AppliedInventoryItem } from '@mobble/models/src/model/AppliedInventoryItem';
import { toISO8601 } from '@mobble/shared/src/core/Date';
import { useEntitiesRefresher } from '@mobble/shared/src/hooks/useEntitiesRefresher';
import {
  usePaddocks,
  useProperties,
  useInventoryItemBatches,
  usePaddockActions,
  usePaddockAction,
  useGetUser,
  usePaddockGeometries,
  useExtStatus,
  useInventoryItems,
  useInventories,
  useRootDispatch,
} from '@mobble/store/src/hooks';
import { thunkGetAll as thunkGetInventoryBatches } from '@mobble/store/src/reducers/inventoryItemBatches';

import { useMyLocation } from '@src/hooks/useMyLocation';
import { usePaddockCardFactory } from '@src/hooks/usePaddockCardFactory';
import { useNavigateBack } from '@src/hooks/useNavigateBack';
import { useDialog } from '@src/hooks/useDialog';
import { useLinking } from '@src/hooks/useLinking';

import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';
import {
  PaddockActionCreateForm,
  type PaddockActionCreateFormValues,
} from '@src/stories/Views/PaddockAction/PaddockActionCreateForm';
import { EntitySliceFactoryPrelude } from '@src/stories/Views/Misc/EntitySliceFactoryPrelude';

import { type ScreenRendererProps } from '../config/types';
import * as ROUTE_NAME from '../config/routeNames';

export const PaddockActionCreateHeader: React.FC<ScreenRendererProps> = () => {
  const { formatMessage } = useI18n();
  const goBack = useNavigateBack();

  return (
    <ScreenHeader
      modal
      title={formatMessage({
        defaultMessage: 'Apply paddock action',
        description: 'screen.title.paddock_action_create',
      })}
      breadcrumbs={[
        {
          title: formatMessage({
            defaultMessage: 'Paddocks',
            description: 'screen.title.paddocks',
          }),
          href: ROUTE_NAME.PADDOCKS_LIST,
        },
      ]}
      onClose={goBack}
    />
  );
};

export const PaddockActionCreate: React.FC<ScreenRendererProps> = (props) => {
  const { paddockId = '' } = props.route.params;
  const dispatch = useRootDispatch();
  const { alert, close } = useDialog();
  const { formatMessage } = useI18n();
  const { location } = useMyLocation();
  const goBack = useNavigateBack();
  const linkTo = useLinking();

  const properties = useProperties();
  const propertyId = properties.selected?.id;

  const user = useGetUser();
  const paddocks = usePaddocks(propertyId);
  const batches = useInventoryItemBatches('_', propertyId);
  const inventories = useInventories(propertyId);
  const inventoryItems = useInventoryItems();
  const paddockActions = usePaddockActions(propertyId);
  const { create } = usePaddockAction('_', propertyId);
  const findPaddockFromId = findPaddock(paddocks.entities);
  const paddockGeometries = usePaddockGeometries(propertyId);
  const makePaddockCard = usePaddockCardFactory({
    propertyId: propertyId,
    location,
  });
  const extStatus = useExtStatus('paddockActions', 'create', propertyId);
  const formLoading = extStatus?.loading;
  const formError = extStatus?.error
    ? formatMessage(
        {
          description: 'generic.form.error',
          defaultMessage: 'There was an error processing your request',
        },
        {
          ENTITY: formatMessage({
            defaultMessage: 'paddock_action.create.form.title.label',
          }),
        }
      )
    : '';

  const paddockSortMeta = {
    origin: location || undefined,
    paddockGeometries: paddockGeometries.entities,
    mobs: [],
  };

  const sortPaddockOptions = sortAnyContainingPaddocks((o) => o.entity)(
    paddockSortMeta
  );

  const paddockOptions = paddocks.entities.map((p) => ({
    label: p.name,
    value: p.id,
    entity: p,
    component: makePaddockCard(p),
  }));

  useEntitiesRefresher([inventories, paddockActions, paddocks], propertyId);

  const Prelude = EntitySliceFactoryPrelude({
    preludes: [inventories.prelude, paddocks.prelude, paddockActions.prelude],
    required: [properties.selected, user],
  });

  if (Prelude) {
    return Prelude;
  }

  const handleSubmit = (formValues: PaddockActionCreateFormValues) => {
    if (!user || !properties.selected) {
      return;
    }

    const usedBatches: AppliedInventoryItem[] = (
      formValues.inventory_items.length
        ? JSON.parse(formValues.inventory_items)
        : []
    ).map((item: AppliedInventoryItem) =>
      batches.allEntitiesAvailable.find((e) => e.id === item?.batchId)
    );

    const newPaddockAction = {
      propertyId: properties.selected.id,
      date: toISO8601(formValues.date),
      createdBy: user,
      type: formValues.action_type,
      title: '',
      description: '',
      note: formValues.note,
      paddocks: formValues.paddock.map((paddockId) => ({
        name: findPaddock(paddocks.entities)(paddockId)?.name ?? '',
        paddockId,
      })),
      appliedInventoryItems: JSON.parse(formValues.inventory_items || '[]'),
    };

    create(newPaddockAction)
      .then((paddockAction) => {
        // TODO: replace with Toast/Alert
        console.log(
          `Paddock action '${paddockAction.title}' successfully created`
        );

        const paddocksAffected = paddockAction.paddocks.map((paddock) =>
          findPaddockFromId(paddock.paddockId)
        );

        const paddockArea = paddocksAffected.reduce(
          (acc, curr) => acc + (curr?.properties?.size?.value ?? 0),
          0
        );

        // Fetch updated batch data for all applied chemicals
        paddockAction.appliedInventoryItems.forEach((item) => {
          dispatch(
            thunkGetInventoryBatches({
              parentId: propertyId,
              extFilter: {
                chemicalIds: [item.inventoryItemId],
              },
            })
          );
        });

        if (paddockAction.appliedInventoryItems.length) {
          inventories.refresh();
        }

        const finishedBatches = paddockAction?.appliedInventoryItems?.filter(
          (item) => {
            const batch = usedBatches?.find((e) => e?.id === item?.batchId);

            return batch
              ? batch?.quantity?.value > 0 &&
                  batch.quantity.value -
                    (item.quantity.value * paddockArea || 1) <=
                    0
              : false;
          }
        );

        if (finishedBatches?.length > 0) {
          alert(
            formatMessage({
              description: 'batches_finished.alert.title',
              defaultMessage: 'A batch has been used up',
            }),
            formatMessage({
              description: 'batches_finished.alert.message',
              defaultMessage:
                'One of your batches has been used up. You can mark it as finished on the batch detail page.',
            }),
            [
              {
                label: formatMessage({
                  description: 'batches_finished.alert.cancel',
                  defaultMessage: 'Close',
                }),
                outline: true,
                intent: 'secondary',
                onClick: () => {
                  close();
                  goBack();
                },
              },
              {
                label: formatMessage({
                  description: 'batches_finished.alert.confirm',
                  defaultMessage: 'View batch',
                }),
                onClick: () => {
                  const finishedBatch = finishedBatches[0];
                  const inventoryId = inventoryItems.allEntitiesAvailable.find(
                    (i) => i.id === finishedBatch.inventoryItemId
                  ).inventoryId;

                  close();
                  linkTo(ROUTE_NAME.INVENTORY_ITEM_BATCH_DETAIL, {
                    inventoryId,
                    inventoryItemId: finishedBatch.inventoryItemId,
                    inventoryItemBatchId: finishedBatch.batchId,
                  });
                },
              },
            ]
          );
        } else {
          goBack();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <PaddockActionCreateForm
      paddockOptions={paddockOptions}
      paddockSortOptions={sortOptionsNameDistanceFromMe}
      sortPaddockOptions={sortPaddockOptions}
      initialValues={{
        paddock: [paddockId].filter(Boolean),
      }}
      paddockActions={getOptionsFromTypesByGroup(
        properties.selected?.types || []
      )(ConfiguredPropertyTypeGroup.paddockAction)}
      onAddPaddockAction={(label) => {
        if (!properties.selected) {
          return Promise.reject();
        }
        return properties.addPropertyType({
          group: ConfiguredPropertyTypeGroup.paddockAction,
          label,
        });
      }}
      error={formError}
      loading={formLoading}
      onCancel={goBack}
      onTouched={() => {}}
      onSubmit={handleSubmit}
    />
  );
};

export default {
  name: ROUTE_NAME.MODAL_PADDOCK_ACTION_CREATE,
  header: PaddockActionCreateHeader,
  component: PaddockActionCreate,
  modal: true,
};
