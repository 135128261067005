import React from 'react';
import { type I18nItem } from '@mobble/i18n';
import { formatDate } from '@mobble/shared/src/core/Date';
import { toInteger } from '@mobble/shared/src/core/Number';
import { Box } from '@src/stories/Components/Layout/Box';
import { HStack } from '@src/stories/Components/Layout/Stack';
import { TableColumn } from '@src/stories/Components/Layout/Table';
import { Badge } from '@src/stories/Components/UI/Badge';
import { getLivestockAgeColor } from '@mobble/models/src/model/Mob';
import { paddockForMob, type Paddock } from '@mobble/models/src/model/Paddock';
import { type ConfiguredPropertyType } from '@mobble/models/src/model/Property';
import { Clickable } from '@src/stories/Components/UX/Clickable';
import { toPath } from '@src/interfaces/Routing';
import * as ROUTE_NAME from '@src/screens/config/routeNames';
import { Text } from '@src/stories/Components/UI/Text';
import styles from './mobsTableColumns.scss';

export const makeMobsTableColumns = ({
  propertyTypes,
  paddocks,
  translate,
}: {
  propertyTypes: ConfiguredPropertyType[];
  paddocks: Paddock[];
  translate: (item: I18nItem) => string;
}): TableColumn[] => {
  const findPaddockForMob = paddockForMob(paddocks);

  return [
    {
      key: 'type',
      label: { key: 'mobs.table.heading.column.type' },
      totals: false,
      enabled: false,
      toValue: (mob) => mob.type,
    },
    {
      key: 'head',
      label: { key: 'mobs.table.heading.column.head' },
      totals: true,
      toValue: (mob) => mob.size,
      render: (_, mob) => {
        return (
          <>
            <Text variant="small" className={styles.size}>
              {mob.size}

              {mob.safeDate ? (
                <div className={styles.unSafeDot}>
                  <div className={styles.tooltip}>
                    <Text align="center">
                      {translate({
                        key: 'mobs.table.heading.column.safe_date',
                      })}
                      : {formatDate(mob.safeDate)}
                    </Text>
                  </div>
                </div>
              ) : null}
            </Text>
          </>
        );
      },
    },
    {
      key: 'breed',
      label: { key: 'mobs.table.heading.column.breed' },
      totals: false,
      toValue: (mob) => mob.breed,
    },
    {
      key: 'gender',
      label: { key: 'mobs.table.heading.column.gender' },
      totals: false,
      toValue: (mob) => mob.gender,
    },
    {
      key: 'dse',
      label: { key: 'mobs.table.heading.column.dse' },
      totals: false,
      toValue: (mob) => mob.DSE,
    },
    {
      key: 'total_dse',
      label: { key: 'mobs.table.heading.column.total_dse' },
      totals: true,
      toValue: (mob) => toInteger(mob.size * mob.DSE),
    },
    {
      key: 'ages',
      label: { key: 'mobs.table.heading.column.ages' },
      totals: false,
      toValue: (mob) => mob.ages.join(', '),
      render: (_, mob) => {
        return (
          <div style={{ marginTop: '-8px', marginBottom: '-8px' }}>
            <HStack wrap>
              {mob.ages.map((age) => (
                <Box
                  key={age}
                  spacing={{ top: 0, right: 0.5, bottom: 0.25, left: 0 }}
                >
                  <Badge
                    textVariant="small"
                    key={age}
                    label={String(age)}
                    color={getLivestockAgeColor(propertyTypes, mob.type)(age)}
                  />
                </Box>
              ))}
            </HStack>
          </div>
        );
      },
    },
    {
      key: 'classes',
      label: { key: 'mobs.table.heading.column.classes' },
      totals: false,
      toValue: (mob) => mob.classes.join(', '),
    },
    {
      key: 'paddock',
      label: { key: 'mobs.table.heading.column.paddock' },
      totals: false,
      toValue: (mob) => findPaddockForMob(mob)?.name,
      render: (_, mob) => {
        const paddock = findPaddockForMob(mob);

        if (paddock) {
          return (
            <Clickable
              href={toPath(ROUTE_NAME.PADDOCK_DETAIL, {
                paddockId: paddock.id,
              })}
            >
              <Text underline variant="small">
                {paddock.name}
              </Text>
            </Clickable>
          );
        }

        return null;
      },
    },
  ];
};
