import React, { type MouseEvent } from 'react';
import { isEqual } from 'lodash';
import {
  eventTypeToColor,
  type HistoricEvent,
} from '@mobble/models/src/model/HistoricEvent';
import { Color } from '@mobble/colors';
import { Text } from '@src/stories/Components/UI/Text';
import { TimelineEvent } from '@src/stories/Components/UI/TimelineEvent';
import { Spacer } from '@src/stories/Components/Layout/Spacer';
import { HStack } from '@src/stories/Components/Layout/Stack';
import { Avatar } from '@src/stories/Components/UI/Avatar';
import { Clickable } from '@src/stories/Components/UX/Clickable';
import styles from './historicEventCard.scss';

interface HistoricEventCardProps {
  historicEvent: HistoricEvent;
  last?: boolean;
  href?: string;
  onClick?: (historicEvent: HistoricEvent) => void;
}

export const HistoricEventCard: React.FC<HistoricEventCardProps> = ({
  historicEvent,
  last,
  href,
  onClick,
}) => {
  const card = (
    <TimelineEvent
      title={historicEvent.title}
      date={historicEvent.date}
      last={last}
      color={eventTypeToColor(historicEvent.eventType)}
    >
      <Text tagName="p" color={Color.DarkGrey}>{historicEvent.description}</Text>
      <Spacer y={1} />
      <HStack alignment="center">
        <Avatar size="tiny" name={historicEvent.createdBy.name} />
        <Spacer x={0.5} />
        <Text color={Color.Grey} variant="tiny">
          {historicEvent.createdBy.name}
        </Text>
      </HStack>
    </TimelineEvent>
  );

  if (onClick) {
    const handleClick = (ev: MouseEvent) => {
      ev.preventDefault();
      onClick(historicEvent)
    }

    return (
      <Clickable
        href={href}
        onClick={handleClick}
        className={styles.historicEventCardClickable}
      >
        {card}
      </Clickable>
    );
  }
  return card;
};

export const MemoisedHistoricEventCard = React.memo(
  HistoricEventCard,
  (prev, next) => {
    return (
      isEqual(prev.historicEvent, next.historicEvent) && prev.last === next.last
    );
  }
);
