import React, { useState } from 'react';
import { Color } from '@mobble/colors';
import { useI18n } from '@mobble/i18n';
import {
  Mob,
  findMob,
  getMobDisplayName,
  toMobDisplayName,
} from '@mobble/models/src/model/Mob';
import {
  Paddock,
  findPaddock,
  sortAnyContainingPaddocks,
  sortOptionsNameDistanceFromPaddock,
} from '@mobble/models/src/model/Paddock';
import { findPaddockCenterPoint } from '@mobble/models/src/model/PaddockGeometry';
import { toISO8601 } from '@mobble/shared/src/core/Date';
import { base64encode } from '@mobble/shared/src/core/String';
import { useMobs } from '@mobble/store/src/hooks/mobs';
import { usePaddockGeometries } from '@mobble/store/src/hooks/paddockGeometries';
import { usePaddocks } from '@mobble/store/src/hooks/paddocks';
import { useProperties } from '@mobble/store/src/hooks/properties';

import { useDialog } from '@src/hooks/useDialog';
import { useLinking } from '@src/hooks/useLinking';
import { useMoveMobs } from '@src/hooks/useMoveMobs';
import { usePaddockCardFactory } from '@src/hooks/usePaddockCardFactory';
import * as ROUTE_NAME from '@src/screens/config/routeNames';
import { Box } from '@src/stories/Components/Layout/Box';
import { Button } from '@src/stories/Components/UX/Button';
import {
  FormBuilder,
  type FormBuilderProps,
} from '@src/stories/Views/Form/FormBuilder';
import { MobCard } from '@src/stories/Views/Mob/MobCard';

import styles from './paddockMoveMobFormQuick.scss';

export interface PaddockMoveMobFormQuickProps {
  fromPropertyId: string;
  fromPaddockId: string;
  toPaddockId: string;
  onReady: () => void;
}

export interface PaddockMoveMobFormQuickValues {
  mobs: string[];
  paddockNameFrom: string;
  paddock: string;
}

export const PaddockMoveMobFormQuick: React.FC<
  PaddockMoveMobFormQuickProps
> = ({ fromPropertyId, fromPaddockId, toPaddockId, onReady }) => {
  const { alert, close } = useDialog();
  const { translate, formatMessage } = useI18n();
  const propertyId = fromPropertyId;
  const properties = useProperties();
  const mobs = useMobs(propertyId);
  const paddocks = usePaddocks(propertyId);
  const paddockGeometries = usePaddockGeometries(propertyId);
  const linkTo = useLinking();
  const [formLoading, setFormLoading] = useState(false);
  const { moveMobs } = useMoveMobs(propertyId);

  const paddockFrom = findPaddock(paddocks.entities)(fromPaddockId) as Paddock;
  const initialPaddockTo = findPaddock(paddocks.entities)(
    toPaddockId
  ) as Paddock;

  const handleSubmit = (formValues: PaddockMoveMobFormQuickValues) => {
    if (formLoading || !formValues.paddock) {
      return;
    }

    const paddockTo = findPaddock(paddocks.entities)(formValues.paddock);

    if (!paddockTo) {
      return;
    }

    setFormLoading(true);

    moveMobs({
      propertyId,
      paddockFrom,
      paddockTo,
      mobs: formValues.mobs,
      date: toISO8601(new Date()),
      mobsSplit: {},
      meta: {
        mobMoveNames: formValues.mobs.map(getMobDisplayName(mobs.entities)),
        mobSplitNames: [],
      },
    }).then(({ mobsCanMergeOnTargetPaddock }) => {
      setFormLoading(false);

      if (mobsCanMergeOnTargetPaddock) {
        alert(
          translate({
            key: 'mobs_can_be_merged.alert.title',
          }) ?? '',
          translate({
            key: 'mobs_can_be_merged.alert.message',
            params: {
              '%PADDOCK': paddockTo.name,
            },
          }) ?? '',
          [
            {
              label:
                translate({
                  key: 'mobs_can_be_merged.alert.cancel',
                }) ?? '',
              outline: true,
              intent: 'secondary',
              onClick: () => {
                close();
                onReady();
              },
            },
            {
              label:
                translate({
                  key: 'mobs_can_be_merged.alert.confirm',
                }) ?? '',
              onClick: () => {
                close();
                linkTo(ROUTE_NAME.PADDOCK_DETAIL_MOBS, {
                  paddockId: paddockTo.id,
                });
              },
            },
          ]
        );
      } else {
        onReady();
      }
    });
  };

  const mobsOnPaddock = paddockFrom.mobs
    .map(findMob(mobs.entities))
    .filter(Boolean) as Mob[];

  const mobOptions = mobsOnPaddock.map((mob) => ({
    label: toMobDisplayName(mob, false),
    value: mob.id,
    entity: mob,
    component: (
      <MobCard propertyTypes={properties.selected?.types ?? []} mob={mob} />
    ),
  }));

  const paddockOrigin = findPaddockCenterPoint(paddockGeometries.entities)(
    paddockFrom.id
  );

  const makePaddockCard = usePaddockCardFactory({
    propertyId: propertyId,
    location: paddockOrigin,
  });

  const paddockSortMeta = {
    origin: paddockOrigin,
    paddockGeometries: paddockGeometries.allEntitiesAvailable,
    mobs: mobs.allEntitiesAvailable,
  };

  const sortPaddockOptions = sortAnyContainingPaddocks((o) => o.entity)(
    paddockSortMeta
  );

  const paddockOptions = paddocks.entities.map((p) => ({
    label: p.name,
    value: p.id,
    entity: p,
    component: makePaddockCard(p),
  }));

  const form: FormBuilderProps<PaddockMoveMobFormQuickValues> = {
    i18nRootKey: 'paddocks.paddock.move_mob.form',
    className: styles.form,
    flex: true,
    fields: [
      {
        name: 'paddockNameFrom',
        type: 'display',
        initialValue: paddockFrom.name,
      },
      {
        name: 'mobs',
        type: 'select-multiple',
        options: mobOptions,
        initialValue: [...paddockFrom.mobs],
        required: true,
      },

      {
        name: 'paddock',
        type: 'select',
        options: paddockOptions,
        sortOptions: sortOptionsNameDistanceFromPaddock,
        sortFunction: sortPaddockOptions,
        required: true,
        initialValue: initialPaddockTo.id,
      },
    ],
    loading: formLoading,
    onSubmit: handleSubmit,
  };

  return (
    <Box background={Color.AlmostWhite}>
      <FormBuilder {...form} />
      <Box spacing={{ top: 0, right: 2, bottom: 2, left: 2 }}>
        <Button
          flex
          intent="secondary"
          outline
          label={formatMessage({
            defaultMessage: 'Advanced mob movement/split options',
            description: 'paddocks.paddock.move_mob.form.advanced.label',
          })}
          onClick={() => {
            linkTo(ROUTE_NAME.MODAL_MOB_MOVE, {
              paddockId: paddockFrom.id,
              mobIds: base64encode(JSON.stringify(paddockFrom.mobs)),
              toPaddockId: initialPaddockTo.id,
            });
          }}
        />
      </Box>
    </Box>
  );
};
