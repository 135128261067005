import {
  DateRange,
  DateRangeOption,
  fromRawDate,
  getDateRange,
} from '@mobble/shared/src/core/Date';
import { useState } from 'react';

export const useDateRange = ({
  rangeFromRoute,
  type = 'last-4-weeks',
  defaultRange,
}: {
  rangeFromRoute?: string;
  type?: DateRangeOption;
  defaultRange?: DateRange;
}) => {
  const initReportRange = (): DateRange => {
    if (defaultRange) {
      return defaultRange;
    }

    if (rangeFromRoute) {
      const [start, end] = rangeFromRoute.split('--');
      return {
        start: fromRawDate(start).startOf('day').toDate(),
        end: fromRawDate(end).startOf('day').toDate(),
      };
    }
    return getDateRange(type);
  };

  const [range, setRange] = useState<DateRange>(initReportRange());

  const changeRange = async (range: DateRange) => {
    return setRange(range);
  };

  return { range, changeRange };
};
