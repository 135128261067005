import React from 'react';
import { defineMessages } from 'react-intl';
import { useMessages, useI18n } from '@mobble/i18n';
import { formatDate } from '@mobble/shared/src/core/Date';
import { type Task } from '@mobble/models/src/model/Task';
import { type Paddock, findPaddock } from '@mobble/models/src/model/Paddock';
import { useSetting } from '@mobble/store/src/hooks/settings';
import * as ROUTE_NAME from '@src/screens/config/routeNames';
import { toPath } from '@src/interfaces/Routing';
import { InfoRow, type InfoRowProps } from '../../Components/UI/InfoRow';

export interface TaskInfoTableProps {
  task: Task;
  paddocks: Paddock[];
}

const messages = defineMessages({
  assigned_by: {
    id: 'task.info.table.row.assigned_by.label',
    defaultMessage: 'Assigned by',
  },
  assigned_to: {
    id: 'task.info.table.row.assigned_to.label',
    defaultMessage: 'Assigned to',
  },
  assigned_to_none: {
    id: 'task.assigned_to.none',
    defaultMessage: 'Anyone',
  },
  created_at: {
    id: 'task.info.table.row.created_at.label',
    defaultMessage: 'Created at',
  },
  description: {
    id: 'task.info.table.row.description.label',
    defaultMessage: 'Description',
  },
  due_date: {
    id: 'task.info.table.row.due_date.label',
    defaultMessage: 'Due date',
  },
  due_date_none: {
    id: 'task.due_date.none',
    defaultMessage: 'On a rainy day',
  },
  paddock: {
    id: 'task.info.table.row.paddock.label',
    defaultMessage: 'Paddock',
  },
  status: {
    id: 'task.info.table.row.status.label',
    defaultMessage: 'Status',
  },
  title: {
    id: 'task.info.table.row.title.label',
    defaultMessage: 'Title',
  },
});

export const TaskInfoTable: React.FC<TaskInfoTableProps> = ({
  task,
  paddocks,
}) => {
  const strings = useMessages(messages);
  const { formatMessage } = useI18n();
  const dateFormat = useSetting('dateFormat') as string;
  const paddock = task.paddockId
    ? findPaddock(paddocks)(task.paddockId)
    : undefined;

  const rows: (InfoRowProps | InfoRowProps[])[] = [
    {
      label: strings.title,
      value: task.title,
    },
    {
      label: strings.description,
      value: task.description,
    },
    {
      label: strings.due_date,
      value: task.dueDate
        ? formatDate(task.dueDate, dateFormat)
        : strings.due_date_none,
    },
    {
      label: strings.assigned_by,
      value: task.assignedByUser?.name,
    },
    {
      label: strings.assigned_to,
      value:
        task.assignedToUsers && task.assignedToUsers.length > 0
          ? task.assignedToUsers.map((user) => user.name).join(', ')
          : strings.assigned_to_none,
    },
    paddock?.id
      ? {
          label: strings.paddock,
          value: paddock.name,
          href: toPath(ROUTE_NAME.PADDOCK_DETAIL, { paddockId: paddock.id }),
        }
      : null,
    {
      label: strings.status,
      value: formatMessage({
        id: `task.status.${task.status}`,
        defaultMessage: [],
      }),
    },
    {
      label: strings.created_at,
      value: formatDate(task.createdAt, dateFormat),
    },
  ].filter(Boolean);

  return (
    <>
      {rows.map((row) =>
        Array.isArray(row) ? (
          <>
            {row.map((r) => (
              <InfoRow key={r.label} {...r} />
            ))}
          </>
        ) : (
          <InfoRow key={row.label} {...row} />
        )
      )}
    </>
  );
};
