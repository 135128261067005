import React from 'react';
import { RawDate, formatDate, fromRawDate } from '@mobble/shared/src/core/Date';
import { TableColumn } from '@src/stories/Components/Layout/Table';
import {
  type Mob,
  toMobDisplayName,
  findMob,
  getLivestockAgeColor,
} from '@mobble/models/src/model/Mob';
import { Clickable } from '@src/stories/Components/UX/Clickable';
import { toPath } from '@src/interfaces/Routing';
import { Text } from '@src/stories/Components/UI/Text';
import * as ROUTE_NAME from '@src/screens/config/routeNames';
import { type Paddock, findPaddock } from '@mobble/models/src/model/Paddock';
import { HStack } from '@src/stories/Components/Layout/Stack';
import { Box } from '@src/stories/Components/Layout/Box';
import { Badge } from '@src/stories/Components/UI/Badge';
import { ConfiguredPropertyType } from '@mobble/models/src/model/Property';

export const makeCasualtiesTableColumns = ({
  paddocks,
  mobs,
  propertyTypes,
}: {
  mobs: Mob[];
  paddocks: Paddock[];
  propertyTypes: ConfiguredPropertyType[];
}): TableColumn[] => {
  const handleClick = (event: any) => {
    event.stopPropagation();
  };

  return [
    {
      key: 'date',
      label: { key: 'casualties.table.heading.column.date' },
      totals: false,
      toValue: (casualty) =>
        casualty.date ? fromRawDate(casualty.date).toDate().getTime() : null,
      valueToString: (value) =>
        value ? formatDate(fromRawDate(value as RawDate)) : '',
    },
    {
      key: 'head',
      label: { key: 'casualties.table.heading.column.head' },
      totals: true,
      toValue: (casualty) => casualty.number,
      render: (_, casualty) => (
        <Text underline variant="small">
          {casualty.number}
        </Text>
      ),
    },
    {
      key: 'type',
      label: { key: 'mobs.table.heading.column.type' },
      totals: false,
      toValue: (casualty) => casualty.mob.type,
    },
    {
      key: 'mob',
      label: { key: 'casualties.table.heading.column.mob' },
      totals: false,
      toValue: (casualty) => toMobDisplayName(casualty.mob),
      render: (_, casualty) => {
        if (findMob(mobs)(casualty.mob.id)) {
          return (
            <Clickable
              href={toPath(ROUTE_NAME.MOB_DETAIL, { mobId: casualty.mob.id })}
            >
              <Text underline variant="small">
                {toMobDisplayName(casualty.mob)}
              </Text>
            </Clickable>
          );
        }
        return <Text variant="small">{toMobDisplayName(casualty.mob)}</Text>;
      },
    },
    {
      key: 'ages',
      label: { key: 'mobs.table.heading.column.ages' },
      totals: false,
      toValue: (casualty) => casualty.mob.ages.join(', '),
      render: (_, casualty) => {
        return (
          <div style={{ marginTop: '-8px', marginBottom: '-8px' }}>
            <HStack wrap>
              {casualty.mob.ages.map((age) => (
                <Box
                  key={age}
                  spacing={{ top: 0, right: 0.5, bottom: 0.25, left: 0 }}
                >
                  <Badge
                    textVariant="small"
                    key={age}
                    label={String(age)}
                    color={getLivestockAgeColor(
                      propertyTypes,
                      casualty.mob.type
                    )(age)}
                  />
                </Box>
              ))}
            </HStack>
          </div>
        );
      },
    },
    {
      key: 'classes',
      label: { key: 'mobs.table.heading.column.classes' },
      totals: false,
      toValue: (casualty) => casualty.mob.classes.join(', '),
    },
    {
      key: 'paddock',
      label: { key: 'casualties.table.heading.column.paddock' },
      totals: false,
      toValue: (casualty) => {
        return findPaddock(paddocks)(casualty.paddock)?.name ?? '[deleted]';
      },
      render: (value, casualty) => (
        <Clickable
          href={toPath(ROUTE_NAME.PADDOCK_DETAIL, {
            paddockId: casualty.paddock,
          })}
          onClick={handleClick}
        >
          <Text underline variant="small">
            {String(value)}
          </Text>
        </Clickable>
      ),
    },
    {
      key: 'reason',
      label: { key: 'casualties.table.heading.column.reason' },
      totals: false,
      toValue: (casualty) => casualty.reason,
    },
    {
      key: 'methodOfDisposal',
      label: { key: 'casualties.table.heading.column.method_of_disposal' },
      totals: false,
      toValue: (casualty) => casualty.methodOfDisposal,
    },
  ];
};
