import React from 'react';
import { useI18n } from '@mobble/i18n';
import { InventoryItem } from '@mobble/models/src/model/InventoryItem';
import { useInventory } from '@mobble/store/src/hooks/inventories';
import {
  useInventoryItem,
  useInventoryItems,
} from '@mobble/store/src/hooks/inventoryItems';
import { useGetUser } from '@mobble/store/src/hooks/auth';
import { useExtStatus } from '@mobble/store/src/hooks/root';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';
import { InventoryItemCreateForm } from '../../stories/Views/InventoryItem/InventoryItemCreateForm';
import * as ROUTE_NAME from '../config/routeNames';
import { toPath } from '@src/interfaces/Routing';
import { EntitySliceFactoryPrelude } from '../../stories/Views/Misc/EntitySliceFactoryPrelude';
import { ScreenRendererProps } from '../config/types';
import { useNavigateBack } from '@src/hooks/useNavigateBack';
import { useLinking } from '@src/hooks/useLinking';

export const InventoryItemCreateHeader: React.FC<ScreenRendererProps> = (
  props
) => {
  const { inventoryId = '' } = props.route.params;
  const inventory = useInventory(inventoryId);
  const goBack = useNavigateBack();

  let key = 'screen.title.inventory_item_create';
  if (inventory.entity?.category) {
    key = `screen.title.inventory_item.${inventory.entity.category}.create`;
  }

  return (
    <ScreenHeader
      modal
      title={{ key }}
      breadcrumbs={[
        {
          title: { key: 'screen.title.inventories' },
          href: ROUTE_NAME.INVENTORIES_LIST,
        },
        {
          title: inventory.entity?.name,
          href: toPath(ROUTE_NAME.INVENTORY_ITEMS, {
            inventoryId,
          }),
        },
      ]}
      onClose={goBack}
    />
  );
};

export const InventoryItemCreate: React.FC<ScreenRendererProps> = (props) => {
  const { translate, formatMessage } = useI18n();
  const { inventoryId = '' } = props.route.params;
  const inventory = useInventory(inventoryId);
  const inventoryItems = useInventoryItems(inventoryId);
  const { create } = useInventoryItem('_', inventoryId);
  const user = useGetUser();
  const goBack = useNavigateBack();
  const linkTo = useLinking();
  const extStatus = useExtStatus('inventoryItems', 'create', inventoryId);
  const formLoading = extStatus?.loading;
  const formError = extStatus?.error
    ? translate({
        key: 'generic.form.error',
        params: {
          '%ENTITY': formatMessage({ defaultMessage: 'item' }),
        },
      })
    : '';

  const prelude = EntitySliceFactoryPrelude({
    preludes: [inventory.prelude, inventoryItems.prelude],
    required: [user],
  });

  if (prelude) {
    return prelude;
  }

  const handleSubmit = (formValues: Omit<InventoryItem, 'id'>) => {
    create(formValues)
      .then((inventoryItem) => {
        // TODO: replace with Toast/Alert
        console.log(
          `Inventory Item '${inventoryItem.name}' successfully created`
        );

        linkTo(ROUTE_NAME.MODAL_INVENTORY_ITEM_BATCH_CREATE, {
          inventoryId: inventoryId,
          inventoryItemId: inventoryItem.id,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <InventoryItemCreateForm
      inventory={inventory.entity}
      user={user}
      error={formError}
      loading={formLoading}
      onCancel={goBack}
      onTouched={() => {}}
      onSubmit={handleSubmit}
    />
  );
};

export default {
  name: ROUTE_NAME.MODAL_INVENTORY_ITEM_CREATE,
  header: InventoryItemCreateHeader,
  component: InventoryItemCreate,
  modal: true,
};
