import React from 'react';
import { RawDate, formatDate, fromRawDate } from '@mobble/shared/src/core/Date';
import { TableColumn } from '@src/stories/Components/Layout/Table';
import {
  getLivestockAgeColor,
  toMobDisplayName,
} from '@mobble/models/src/model/Mob';
import { Clickable } from '@src/stories/Components/UX/Clickable';
import { toPath } from '@src/interfaces/Routing';
import { Text } from '@src/stories/Components/UI/Text';
import * as ROUTE_NAME from '@src/screens/config/routeNames';
import { Box } from '@src/stories/Components/Layout/Box';
import { HStack } from '@src/stories/Components/Layout/Stack';
import { Badge } from '@src/stories/Components/UI/Badge';
import { ConfiguredPropertyType } from '@mobble/models/src/model/Property';

export const makeNaturalIncreasesTableColumns = ({
  propertyTypes,
}: {
  propertyTypes: ConfiguredPropertyType[];
}): TableColumn[] => {
  const handleClick = (event: any) => {
    event.stopPropagation();
  };

  return [
    {
      key: 'date',
      label: { key: 'natural_increases.table.heading.column.date' },
      totals: false,
      toValue: (naturalIncrease) =>
        naturalIncrease.date
          ? fromRawDate(naturalIncrease.date).toDate().getTime()
          : null,
      valueToString: (value) =>
        value ? formatDate(fromRawDate(value as RawDate)) : '',
    },
    {
      key: 'head',
      label: { key: 'natural_increases.table.heading.column.head' },
      totals: true,
      toValue: (naturalIncrease) => naturalIncrease.mob.size,
      render: (_, naturalIncrease) => (
        <Text underline variant="small">
          {naturalIncrease.mob.size}
        </Text>
      ),
    },
    {
      key: 'type',
      label: { key: 'mobs.table.heading.column.type' },
      totals: false,
      toValue: (naturalIncrease) => naturalIncrease.mob.type,
    },
    {
      key: 'mob',
      label: { key: 'natural_increases.table.heading.column.mob' },
      totals: false,
      toValue: (naturalIncrease) => toMobDisplayName(naturalIncrease.mob),
      render: (_, naturalIncrease) => {
        return (
          <Text variant="small">{toMobDisplayName(naturalIncrease.mob)}</Text>
        );
      },
    },
    {
      key: 'ages',
      label: { key: 'mobs.table.heading.column.ages' },
      totals: false,
      toValue: (naturalIncrease) => naturalIncrease.mob.ages.join(', '),
      render: (_, naturalIncrease) => {
        return (
          <div style={{ marginTop: '-8px', marginBottom: '-8px' }}>
            <HStack wrap>
              {naturalIncrease.mob.ages.map((age) => (
                <Box
                  key={age}
                  spacing={{ top: 0, right: 0.5, bottom: 0.25, left: 0 }}
                >
                  <Badge
                    textVariant="small"
                    key={age}
                    label={String(age)}
                    color={getLivestockAgeColor(
                      propertyTypes,
                      naturalIncrease.mob.type
                    )(age)}
                  />
                </Box>
              ))}
            </HStack>
          </div>
        );
      },
    },
    {
      key: 'classes',
      label: { key: 'mobs.table.heading.column.classes' },
      totals: false,
      toValue: (naturalIncrease) => naturalIncrease.mob.classes.join(', '),
    },
    {
      key: 'paddock',
      label: { key: 'natural_increases.table.heading.column.paddock' },
      totals: false,
      toValue: (naturalIncrease) => {
        return naturalIncrease;
      },
      toCsvValue: (naturalIncrease) => naturalIncrease.paddock.name,
      render: (_, naturalIncrease) => (
        <Clickable
          href={toPath(ROUTE_NAME.PADDOCK_DETAIL, {
            paddockId: naturalIncrease.paddock.id,
          })}
          onClick={handleClick}
        >
          <Text underline variant="small">
            {String(naturalIncrease.paddock.name)}
          </Text>
        </Clickable>
      ),
    },
    {
      key: 'notes',
      label: { key: 'natural_increases.table.heading.column.notes' },
      totals: false,
      toValue: (natural_increase) => natural_increase.notes,
    },
  ];
};
