import Fuse from 'fuse.js';
import { type RawDate } from '@mobble/shared/src/core/Date';
import {
  ConfiguredPropertyType,
  ConfiguredPropertyTypeGroup,
  type Property,
} from './Property';
import { type Paddock, paddockForMob } from './Paddock';
import { type FilterItem, filterMatches, groupFilter } from './Filter';
import { SortDirection, type SortSetting, type SortOption } from './Sort';

export interface Mob {
  id: string;
  propertyId: Property['id'];
  breed: string;
  type: string;
  gender: string;
  size: number;
  DSE: number;
  ages: number[];
  classes: string[];
  safeDate?: RawDate;

  // used for snapshot mobs
  snapshot?: {
    paddockId?: string;
    paddockName: string;
  };

  // The following fields are only used when creating a new Mob
  paddockId?: string;
  purchaseInformation?: MobPurchaseInformation;
  naturalIncreaseInformation?: MobNaturalIncreaseInformation;
}

export interface MobPurchaseInformation {
  purchaseDate: RawDate;
  pricePerHeadCents: number;
  notes?: string;
  seller?: string;
}

export interface MobNaturalIncreaseInformation {
  naturalIncreaseDate: RawDate;
  notes?: string;
}

export const findMob =
  (mobs: Mob[]) =>
  (id: string): Mob | undefined =>
    mobs.find((mob) => mob.id === id);

export const findMobs =
  (mobs: Mob[]) =>
  (mobIds: string[]): Mob[] =>
    mobIds.map(findMob(mobs)).filter(Boolean) as Mob[];

export const mobExists =
  (mobs: Mob[]) =>
  (id: string): boolean =>
    Boolean(mobs.find((mob) => mob.id === id));

export const getMobDisplayName =
  (mobs: Mob[], includeSize?: boolean) =>
  (id: string): string | undefined => {
    const mob = findMob(mobs)(id);
    if (!mob) {
      return undefined;
    }
    return toMobDisplayName(mob, includeSize);
  };

export const getMobSize =
  (mobs: Mob[]) =>
  (id: string): number | undefined =>
    findMob(mobs)(id)?.size;

export const getMobDSE =
  (mobs: Mob[]) =>
  (id: string): number | undefined =>
    findMob(mobs)(id)?.DSE;

export const canMerge = (a?: null | Mob) => (b?: null | Mob) => {
  if (!a || !b) {
    return false;
  }
  return a.type === b.type && a.breed === b.breed && a.gender === b.gender;
};

export const someMobsCanMerge = (mobs: Mob[]) => {
  for (let i = 0; i < mobs.length; i++) {
    for (let j = i + 1; j < mobs.length; j++) {
      if (canMerge(mobs[i])(mobs[j])) {
        return true;
      }
    }
  }
  return false;
};

export const allMobsCanMerge = (mobs: Mob[]) => {
  if (mobs.length < 2) {
    return;
  }

  const canMergeWithFirstMob = canMerge(mobs[0]);
  return mobs.every(canMergeWithFirstMob);
};

export const getClasses = (mobs: Mob[]): string[] => [
  ...new Set(mobs.reduce<string[]>((acc, m) => [...acc, ...m.classes], [])),
];

export const getAges = (mobs: Mob[]): number[] => [
  ...new Set(mobs.reduce<number[]>((acc, m) => [...acc, ...m.ages], [])),
];

export const getMaxDSE = (mobs: Mob[]): number =>
  Math.max(...mobs.map((m) => m.DSE));

export const getTotalHead = (mobs: Mob[]) => {
  return mobs.reduce((acc, mob) => acc + mob.size, 0);
};

export const toMobDisplayName = (mob: Mob, includeSize?: boolean) =>
  includeSize
    ? `${mob.breed} ${mob.gender} (${mob.size})`
    : `${mob.breed} ${mob.gender}`;

export const summarizeAges = (mob: Mob, asAge = false) => {
  const curYear = new Date().getFullYear();

  //
  return [...new Set(mob.ages)]
    .sort((a, b) => (asAge ? b - a : a - b))
    .map((age) => (asAge ? curYear - age : age))
    .reduce<number[][]>((acc, age, i) => {
      if (i === 0) {
        return [[age]];
      }
      const [lastGroup, ...rest] = acc.reverse();
      if (lastGroup.reverse()[0] === age - 1) {
        return [...rest, [...lastGroup, age]];
      }
      return [...rest, lastGroup, [age]];
    }, [])
    .map((a) =>
      a.length > 1 ? [Math.min(...a), Math.max(...a)].join('-') : a[0]
    )
    .join(', ');
};

export const filterMobs =
  (allPaddocks: Paddock[]) =>
  (mobs: Mob[], filter?: FilterItem[]): Mob[] => {
    if (!filter || filter.length === 0) {
      return mobs;
    }
    const grouped = [...groupFilter(filter)];

    const searchQuery = filter.find((a) => a.group === 'search')?.filter;

    const preFilteredMobs =
      searchQuery && searchQuery.type === 'search'
        ? searchMobs(allPaddocks)(mobs, searchQuery.value)
        : mobs;

    return preFilteredMobs.filter((mob) =>
      grouped.every(([_, filters]) =>
        filters.some(mobsFilterItemMatchesMob(allPaddocks)(mob))
      )
    );
  };

export const searchMobs =
  (allPaddocks: Paddock[]) =>
  (mobs: Mob[], searchQuery: string, threshold?: number): Mob[] => {
    const fuse = new Fuse(
      mobs.map((m) => ({
        ...m,
        //
        _ages: m.ages.join(' '),
        _paddock: paddockForMob(allPaddocks)(m),
      })),
      {
        keys: [
          { name: 'type', weight: 3 },
          { name: 'breed', weight: 3 },
          { name: 'classes', weight: 6 },
          { name: 'gender', weight: 2 },
          { name: 'ages', weight: 3 },
          { name: 'DSE', weight: 1 },
          { name: 'size', weight: 1 },
          { name: '_paddock.name', weight: 3 },
          { name: '_paddock.type', weight: 2 },
          { name: '_ages', weight: 3 },
        ],
        threshold: threshold ?? 0.3,
        shouldSort: true,
      }
    );
    const result = fuse.search(searchQuery).map((a) => a.item);

    if (result.length === 0 && !threshold) {
      return searchMobs(allPaddocks)(mobs, searchQuery, 0.5);
    }

    return result;
  };

export const mobsFilterItemMatchesMob =
  (allPaddocks: Paddock[]) => (mob: Mob) => (filterItem: FilterItem) => {
    const matches = filterMatches(filterItem.filter);

    switch (filterItem.group) {
      case 'livestock_type':
        return matches(mob.type);
      case 'breed':
        return matches(mob.breed);
      case 'gender':
        return matches(mob.gender);
      case 'class':
        return mob.classes.some(matches);
      case 'age':
        return mob.ages.some(matches);
      case 'paddock_id':
        return matches(paddockForMob(allPaddocks)(mob)?.id ?? '');
      case 'search':
        return true;
      default:
        return true;
    }
  };

export const availableSortOptions: SortOption[] = [
  {
    name: 'name_asc',
    settings: [{ direction: SortDirection.Ascending, column: 'name' }],
  },
  {
    name: 'name_desc',
    settings: [{ direction: SortDirection.Descending, column: 'name' }],
  },
  {
    name: 'head_asc',
    settings: [{ direction: SortDirection.Ascending, column: 'head' }],
  },
  {
    name: 'head_desc',
    settings: [{ direction: SortDirection.Descending, column: 'head' }],
  },
  {
    name: 'paddock_name_asc',
    settings: [{ direction: SortDirection.Ascending, column: 'paddock_name' }],
  },
  {
    name: 'paddock_name_desc',
    settings: [{ direction: SortDirection.Descending, column: 'paddock_name' }],
  },
];

export interface MobSortMeta {
  // paddockGeometries?: PaddockGeometry[];
  paddocks?: Paddock[];
  // origin?: Point;
}

export const sortAscendingBy =
  (meta?: MobSortMeta) => (column: string) => (a: Mob, b: Mob) => {
    switch (column) {
      case 'name': {
        const aName = toMobDisplayName(a);
        const bName = toMobDisplayName(b);
        return aName.localeCompare(bName);
      }

      case 'head': {
        return a.size - b.size;
      }

      case 'paddock_name': {
        const aPaddock = paddockForMob(meta?.paddocks ?? [])(a);
        const bPaddock = paddockForMob(meta?.paddocks ?? [])(b);

        const aName = aPaddock?.name ?? '';
        const bName = bPaddock?.name ?? '';

        return aName.localeCompare(bName);
      }
    }
  };

export const sortAnyContainingMobs =
  <A = any>(toMob: (a: A) => Mob) =>
  (meta?: MobSortMeta) =>
  (items: A[], sortSettings: SortSetting[]): A[] => {
    return items.sort((a, b) => {
      return sortSettings.reduce<number>((result, sortSetting) => {
        if (result === 0) {
          const mobA = toMob(a);
          const mobB = toMob(b);

          const asc =
            sortAscendingBy(meta)(sortSetting.column)(mobA, mobB) ?? result;
          if (sortSetting.direction === SortDirection.Descending) {
            return asc < 0 ? 1 : asc > 0 ? -1 : 0;
          }
          return asc;
        }
        return result;
      }, 0);
    });
  };

export const sortMobs = sortAnyContainingMobs((a) => a);

export const getLivestockAgeColor = (
  propertyTypes: ConfiguredPropertyType[],
  LivestockType?: string
) => {
  const getLivestockTypeId = propertyTypes.find(
    (pt) =>
      pt.group === ConfiguredPropertyTypeGroup.livestockType &&
      pt.type === LivestockType
  )?.id;

  const colorForYear = (year: number | string) => {
    return (
      propertyTypes.find(
        (pt) =>
          pt.parentId === getLivestockTypeId &&
          pt.group === ConfiguredPropertyTypeGroup.tag &&
          pt.type === `${year}`
      )?.color || null
    );
  };
  return colorForYear;
};

export const getMissingMobs = (referenceMobs: Mob[]) => (mobs: Mob[]) => {
  const referenceMobsDoesNotContainMobId = (mob: Mob) =>
    !mobExists(referenceMobs)(mob.id);

  return mobs.filter(referenceMobsDoesNotContainMobId);
};
