import React from 'react';
import * as Yup from 'yup';
import {
  findMob,
  getMobDisplayName,
  type Mob,
} from '@mobble/models/src/model/Mob';
import { paddockForMob, type Paddock } from '@mobble/models/src/model/Paddock';
import { Color } from '@mobble/colors';
import { FormBuilder, type FormBuilderProps } from '../Form/FormBuilder';
import { MobCard } from '../Mob/MobCard';
import { Box } from '../../Components/Layout/Box';
import { useI18n } from '@mobble/i18n';
import { ConfiguredPropertyType } from '@mobble/models/src/model/Property';
import { InputContainer } from '../../Components/UX/InputContainer';

export interface CasualtyCreateFormProps {
  mobs: Mob[];
  paddocks: Paddock[];
  propertyTypes: ConfiguredPropertyType[];
  initialValues: Partial<CasualtyCreateFormValues>;
  error?: string;
  loading?: boolean;
  onCancel: () => void;
  onSubmit: (formValues: CasualtyCreateFormValues) => void;
}

export interface CasualtyCreateFormValues {
  mob: string;
  number: number;
  date: string;
  reason: string | null;
  methodOfDisposal: string | null;
}

export const CasualtyCreateForm: React.FC<CasualtyCreateFormProps> = ({
  mobs,
  paddocks,
  propertyTypes,
  initialValues,
  error,
  loading,
  onCancel,
  onSubmit,
}) => {
  const { translate } = useI18n();
  const getMobName = getMobDisplayName(mobs);
  const getPaddockForMob = paddockForMob(paddocks);

  const mobOptions = mobs.map((mob) => {
    const paddock = getPaddockForMob(mob.id);
    return {
      value: mob.id,
      label: getMobName(mob.id) || '',
      labelExtra: `${mob.size}`,
      description: `${paddock?.name} ${mob.classes.join(' ')} ${mob.ages.join(
        ' '
      )}}`,
      component: (
        <MobCard propertyTypes={propertyTypes} mob={mob} paddock={paddock} />
      ),
    };
  });

  const form: FormBuilderProps<CasualtyCreateFormValues> = {
    flex: true,
    i18nRootKey: 'casualty.create.form',
    fields: [
      {
        name: 'mob',
        type: 'select',
        options: mobOptions,
        initialValue: initialValues?.mob,
        required: true,
      },
      {
        name: 'selected_mobs_preview',
        type: 'custom',
        containerComponent: false,
        component: ({ values }) => {
          const mob = findMob(mobs)(values.mob) as Mob;
          return (
            <InputContainer>
              <Box background={Color.LightGrey} spacing={2}>
                <MobCard
                  propertyTypes={propertyTypes}
                  paddock={getPaddockForMob(mob)}
                  mob={mob}
                />
              </Box>
            </InputContainer>
          );
        },
      },
      {
        name: 'number',
        type: 'number',
        validation: Yup.number()
          .integer(
            translate({
              key: 'mob.casualty.create.form.number.error.label',
            }) || ''
          )
          .required(),
      },
      {
        name: 'date',
        type: 'date',
        required: true,
        initialValue: new Date().toISOString(),
      },
      {
        name: 'reason',
        type: 'textarea',
      },
      {
        name: 'methodOfDisposal',
        type: 'textarea',
      },
    ],
    error,
    loading,
    onSubmit,
    onCancel,
  };

  return <FormBuilder {...form} />;
};
