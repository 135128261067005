import React from 'react';
import { useI18n } from '@mobble/i18n';
import { FormBuilder, type FormBuilderProps } from '../Form/FormBuilder';
import { type Point } from '@mobble/models/src/model/MapGeometry';
import {
  MapAssetType,
  mapAssetsTypes,
  mapAssetTypeToGeometryFeatureType,
} from '@mobble/models/src/model/MapAsset';
import { mapAssetTypeToMode } from '../../Map/Plugins/Creator';

export interface MapAssetCreateFormProps {
  error?: string;
  loading?: boolean;
  initialValues?: Partial<MapAssetCreateFormValues>;

  onCancel: () => void;
  onTouched: (dirty: boolean) => void;
  onSubmit: (formValues: MapAssetCreateFormValues) => void;
}

export interface MapAssetCreateFormValues {
  name: string;
  description?: string;
  type?: MapAssetType;
  points?: Point[];
}

export const MapAssetCreateForm: React.FC<MapAssetCreateFormProps> = ({
  error,
  loading,
  initialValues,
  onCancel,
  onTouched,
  onSubmit,
}) => {
  const { formatMessage } = useI18n();
  const typeToI18n = (type: MapAssetType) => ({
    id: `map_assets.type.${type}`,
    defaultMessage: [],
  });

  const typeToModeI18n = (type: MapAssetType) => {
    switch (mapAssetTypeToGeometryFeatureType(type)) {
      default:
      case 'Point':
        return { id: 'map_asset.mode.point', defaultMessage: [] };
      case 'LineString':
        return { id: 'map_asset.mode.line_string', defaultMessage: [] };
    }
  };

  const [mapAssetType, setMapAssetType] = React.useState<MapAssetType>(
    (initialValues.type ?? MapAssetType.Bore) as MapAssetType
  );

  const mapAssetTypeOptions = mapAssetsTypes.map((value) => ({
    label: formatMessage(typeToI18n(value)),
    labelExtra: formatMessage(typeToModeI18n(value)),
    value,
  }));

  const form: FormBuilderProps<MapAssetCreateFormValues> = {
    flex: true,
    i18nRootKey: 'map_assets.map_asset.create.form',
    fields: [
      { name: 'name', type: 'text', required: true },
      { name: 'description', type: 'textarea' },
      {
        name: 'type',
        type: 'select',
        required: true,
        options: mapAssetTypeOptions,
        initialValue: initialValues?.type,
      },
      {
        name: 'points',
        type: 'map-creator',
        required: true,
        mapCreator: {
          options: {
            single: mapAssetTypeToMode(mapAssetType) === 'point',
          },
        },
        initialValue: initialValues?.points,
      },
    ],
    error,
    loading,
    onChange: (val) => {
      setMapAssetType(val.type);
    },
    onSubmit,
    onCancel,
    onTouched,
  };

  return <FormBuilder {...form} />;
};
