import React from 'react';
import { useI18n } from '@mobble/i18n';
import { MobAction } from '@mobble/models/src/model/MobAction';
import { useEntitiesRefresher } from '@mobble/shared/src/hooks/useEntitiesRefresher';
import {
  usePaddocks,
  useProperties,
  useMobAction,
  useMobs,
  useInventoryItemBatches,
  useInventoryItems,
  useInventories,
} from '@mobble/store/src/hooks';
import { toPath } from '@src/interfaces/Routing';

import { useNavigateBack } from '@src/hooks/useNavigateBack';
import { type ScreenRendererProps } from '@src/screens/config/types';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';
import { MobActionInfoTable } from '@src/stories/Views/MobAction/MobActionInfoTable';
import { EntitySliceFactoryPrelude } from '@src/stories/Views/Misc/EntitySliceFactoryPrelude';
import * as ROUTE_NAME from '@src/screens/config/routeNames';

export const MobActionDetailHeader: React.FC<ScreenRendererProps> = (props) => {
  const { formatMessage } = useI18n();
  const goBack = useNavigateBack();
  const { mobActionId = '' } = props.route.params;
  const properties = useProperties();
  const mobAction = useMobAction(mobActionId, properties.selected?.id);

  if (!mobAction.entity) {
    return null;
  }

  return (
    <ScreenHeader
      title={
        mobAction.entity?.title ??
        formatMessage({
          defaultMessage: 'Mob action',
          description: 'screen.title.mob_action',
        })
      }
      breadcrumbs={[
        {
          title: formatMessage({
            description: 'screen.title.summaries',
            defaultMessage: 'Summaries',
          }),
          href: ROUTE_NAME.SUMMARIES_LIST,
        },
        {
          title: formatMessage({
            description: 'screen.title.summary_mob_actions',
            defaultMessage: 'Mob actions',
          }),
          href: toPath(ROUTE_NAME.SUMMARY_MOB_ACTIONS_LIST),
        },
      ]}
      actions={[
        {
          icon: 'edit',
          href: toPath(ROUTE_NAME.MOB_ACTION_EDIT, { mobActionId }),
        },
      ]}
      onGoBack={goBack}
    />
  );
};

export const MobActionDetail: React.FC<ScreenRendererProps> = (props) => {
  const { mobActionId = '' } = props.route.params;
  const properties = useProperties();
  const propertyId = properties.selected?.id;

  const mobs = useMobs(propertyId);
  const paddocks = usePaddocks(propertyId);
  const inventories = useInventories(propertyId);
  const inventoryItems = useInventoryItems();
  const inventoryItemBatches = useInventoryItemBatches('_', propertyId);
  const mobAction = useMobAction(mobActionId, propertyId);

  useEntitiesRefresher(
    [mobs, mobAction, paddocks, inventories, inventoryItemBatches],
    propertyId
  );

  const Prelude = EntitySliceFactoryPrelude({
    preludes: [inventories.prelude, properties.prelude, mobAction.prelude],
    required: [properties.selected],
    notFound: [mobAction.entity],
  });

  if (Prelude) {
    return Prelude;
  }

  return (
    <MobActionInfoTable
      mobAction={mobAction.entity as MobAction}
      mobs={mobs.entities}
      paddocks={paddocks.entities}
      propertyTypes={properties.selected?.types ?? []}
      inventoryItems={inventoryItems.allEntitiesAvailable}
      inventoryItemBatches={inventoryItemBatches.allEntitiesAvailable}
    />
  );
};

export default {
  name: ROUTE_NAME.MOB_ACTION_DETAIL,
  header: MobActionDetailHeader,
  component: MobActionDetail,
};
