import Fuse from 'fuse.js';
import { isEqual } from 'lodash';
import { dateIsBetween, RawDate } from '@mobble/shared/src/core/Date';

export type FilterItem = {
  group: string;
  filter: Filter;
};

export type Filter =
  | FilterEquals
  | FilterRange
  | FilterSearch
  | FilterDateRange;

export interface FilterEquals {
  type: 'equals';
  value: string;
  caseSensitive: boolean;
}

export interface FilterRange {
  type: 'range';
  value: [null | number, null | number];
}

export interface FilterSearch {
  type: 'search';
  value: string;
}

export interface FilterDateRange {
  type: 'dateRange';
  value: [null | RawDate, null | RawDate];
}

export const filterCompare = (a: Filter) => (b: Filter) => {
  return a.type === b.type && String(a.value) === String(b.value);
};

export const makeFilterEquals = (
  value: string,
  caseSensitive = true
): FilterEquals => ({
  type: 'equals',
  value,
  caseSensitive,
});

export const makeFilterRange = (
  value: [null | number, null | number]
): FilterRange => ({
  type: 'range',
  value,
});

export const makeFilterSearch = (value: string): FilterSearch => ({
  type: 'search',
  value,
});

export const makeFilterDateRange = (
  value: [null | RawDate, null | RawDate]
): FilterDateRange => ({
  type: 'dateRange',
  value,
});

export const filterMatches =
  (filter: Filter) =>
  (value: string | number): boolean => {
    switch (filter.type) {
      case 'equals': {
        if (filter.caseSensitive) {
          return String(value) === String(filter.value);
        }
        return (
          String(value).toLowerCase() === String(filter.value).toLowerCase()
        );
      }
      case 'range': {
        const [min, max] = filter.value;
        const numberValue = Number(value);
        if (min !== null && max !== null) {
          return numberValue >= min && numberValue <= max;
        } else if (min !== null) {
          return numberValue >= min;
        } else if (max !== null) {
          return numberValue <= max;
        }
        return true;
      }

      case 'dateRange': {
        const [min, max] = filter.value;
        return dateIsBetween(value, min, max);
      }

      case 'search': {
        const fuse = new Fuse([value], { includeScore: true });
        return fuse.search(String(filter.value)).length === 1;
      }
    }
  };

export const groupFilter = (
  filter: FilterItem[]
): [string, [FilterItem, ...FilterItem[]]][] => {
  const result = new Map<string, FilterItem[]>();
  filter.forEach((filterItem) => {
    const group = filterItem.group;
    const filters = result.get(group) ?? [];

    if (isEqual(filterItem.filter.value, [null, null])) {
      return;
    }

    filters.push(filterItem);
    result.set(group, filters);
  });
  return [...result] as [string, [FilterItem, ...FilterItem[]]][];
};

export const toGroupValue = (
  filterItems: FilterItem[],
  group: string
): string[] => {
  return filterItems
    .filter((a) => a.group === group)
    .map((a) => String(a.filter.value));
};
