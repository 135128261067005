import React from 'react';
import { MobbleConnectUserPropertyRelation } from '@mobble/models/src/model/Property';
import { ListSelectOption } from '@src/stories/Components/UX/ListSelect';
import {
  FormBuilderProps,
  FormBuilder,
} from '@src/stories/Views/Form/FormBuilder';
import { UserCard } from '@src/stories/Views/Settings/SettingsPropertyMobbleConnectUserView';
import { User } from '@mobble/models/src/model/User';

export interface MobbleConnectEditUserFormProps {
  loading?: boolean;
  onSubmit: (formValues: MobbleConnectEditUserFormValues) => void;
  onCancel: () => void;

  user: User;
  propertyIdsOptions: MobbleConnectUserPropertyRelation[];
}

export interface MobbleConnectEditUserFormValues {
  email: string;
  propertyIds: string[];
}

export const MobbleConnectEditUserForm: React.FC<
  MobbleConnectEditUserFormProps
> = ({ loading, onSubmit, onCancel, user, propertyIdsOptions }) => {
  const options = propertyIdsOptions?.map(
    (propety): ListSelectOption => ({
      label: propety.propertyName,
      value: propety.propertyId,
    })
  );

  const initialValue = propertyIdsOptions
    ?.filter((e) => e.isPartOfProperty)
    ?.map((propety) => propety.propertyId);

  const form: FormBuilderProps<MobbleConnectEditUserFormValues> = {
    flex: true,
    i18nRootKey: 'settings.property.mobble_connect.edit_user.form',
    fields: [
      {
        name: 'user',
        type: 'custom',
        component: () => <UserCard user={user} />,
      },
      {
        name: 'propertyIds',
        type: 'select-multiple',
        options,
        initialValue: initialValue,
      },
    ],
    loading,
    onSubmit,
    onCancel,
  };

  return <FormBuilder {...form} />;
};
