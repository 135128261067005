import React, { ComponentPropsWithoutRef, Fragment } from 'react';
import throttle from 'lodash/throttle';
import { Spinner } from '@src/stories/Components/UI/Spinner';
import { Text } from '@src/stories/Components/UI/Text';
import { Color } from '@mobble/colors';
import { I18nItem } from '@mobble/i18n';
import { HStack } from './Stack';
import { Spacer } from './Spacer';
import { Box } from './Box';
import styles from './list.scss';

export interface ListProps<Item = any> extends ComponentPropsWithoutRef<'div'> {
  items: SectionedItems<Item>[] | Item[];
  next?: () => Promise<void>;
  renderItem: (
    item: Item,
    index: number,
    section?: SectionedItems<Item>
  ) => React.ReactNode;
  renderEmpty?: () => React.ReactNode;
  renderSectionHeader?: (section: SectionedItems<Item>) => React.ReactNode;
  renderHeader?: () => React.ReactNode;
  keyExtractor?: (item: Item, index: number) => string;
  threshold?: number; // px
  footer?: React.ReactNode;
  loading?: boolean;
}

export interface SectionedItems<Item> {
  title: I18nItem | string;
  right?: I18nItem | string;
  data: Item[];
}

const DEFAULT_THRESHOLD = 0;

export class List<Item = any> extends React.Component<ListProps<Item>> {
  private _el: HTMLDivElement | null = null;
  private _observer: IntersectionObserver | null = null;

  public static defaultProps = {
    threshold: DEFAULT_THRESHOLD,
    keyExtractor: (_: never, index: number) => index.toString(),
  };

  constructor(props: ListProps) {
    super(props);
    this.state = {};
  }

  private _setRef = (el: HTMLDivElement) => {
    this._el = el;
  };

  // -- Observer last item
  private _onIntersect = (entries: IntersectionObserverEntry[]) => {
    if (
      !this.props.next ||
      this.props.items.length === 0 ||
      !entries[0].isIntersecting
    ) {
      return;
    }
    this.props.next();
  };

  private _observeLastItem() {
    if (!this._el) {
      return;
    }

    this._observer = new IntersectionObserver(
      throttle(this._onIntersect, 100),
      {
        root: null,
        rootMargin: `${this.props.threshold}px`,
        threshold: 1.0,
      }
    );

    this._observer.observe(this._el);
  }

  private _stopObservingLastItem = () => {
    if (!this._observer) {
      return;
    }
    this._observer.disconnect();
  };

  // -- React Lifecycle

  componentDidMount(): void {
    if (typeof this.props.next === 'function') {
      this._observeLastItem();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Readonly<ListProps<Item>>): void {
    if (!this._observer && typeof nextProps.next === 'function') {
      this._observeLastItem();
    } else if (this._observer && typeof nextProps.next !== 'function') {
      this._stopObservingLastItem();
    }
  }

  componentWillUnmount(): void {
    this._stopObservingLastItem();
  }

  getSnapshotBeforeUpdate() {
    if (!this._el) {
      return null;
    }
    return this._el.scrollTop ?? null;
  }

  componentDidUpdate(a, b, snapshot): void {
    this._el.scrollTop = snapshot;
  }

  // -- Render

  private _renderItems = () => {
    const renderPlainItems = (items: Item[], section?: SectionedItems<Item>) =>
      items.map((item, index) => (
        <Fragment key={this.props.keyExtractor(item, index)}>
          {this.props.renderItem(item, index, section)}
        </Fragment>
      ));

    const renderSectionHeader = (section: SectionedItems<Item>) => {
      if (this.props.renderSectionHeader) {
        return this.props.renderSectionHeader(section);
      }

      return (
        <Box
          className={styles.sectionHeader}
          spacing={2}
          background={Color.White}
        >
          <HStack>
            <Text color={Color.DarkGrey} variant="body" i18n={section.title} />
            <Spacer flex />
            {section.right && (
              <Text
                color={Color.DarkGrey}
                variant="body"
                i18n={section.right}
              />
            )}
          </HStack>
        </Box>
      );
    };

    const renderSection = (section: SectionedItems<Item>, index: number) => {
      return (
        <React.Fragment key={String(index)}>
          {renderSectionHeader(section)}
          {renderPlainItems(section.data, section)}
        </React.Fragment>
      );
    };

    if (this.props.items.length === 0 && this.props.renderEmpty) {
      return this.props.renderEmpty();
    }

    if (this.props.items[0] && (this.props.items[0] as any)?.data) {
      return (this.props.items as SectionedItems<Item>[]).map(renderSection);
    }
    return renderPlainItems(this.props.items as Item[]);
  };

  private _renderLoading = () => {
    if (!this.props.loading) {
      return null;
    }

    return (
      <div className={styles.loadingContainer}>
        <Spinner color={Color.Black} />
      </div>
    );
  };

  private _renderHeader = () => {
    if (!this.props.renderHeader) {
      return null;
    }

    return this.props.renderHeader();
  };

  private _renderFooter = () => {
    if (!this.props.footer) {
      return null;
    }

    return <div className={styles.footer}>{this.props.footer}</div>;
  };

  render() {
    return (
      <div role={this.props.role} className={this.props.className}>
        {this._renderHeader()}
        {this._renderItems()}
        {this._renderFooter()}
        {this._renderLoading()}
        <div ref={this._setRef} className={styles.observable} />
      </div>
    );
  }
}

export default List;
