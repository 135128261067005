import React, { ComponentPropsWithoutRef } from 'react';
import classNames from 'classnames/bind';
import { Color } from '@mobble/theme';
import * as Pkg from '@mobble/icon';

import styles from './Icon.scss';
const cx = classNames.bind(styles);

export type IconName = Pkg.IconName;

export type IconSize = 'tiny' | 'small' | 'normal' | 'large';

export interface IconProps
  extends Omit<ComponentPropsWithoutRef<'div'>, 'content' | 'onClick'> {
  /**
   * Name of an icon provided by the `@mobble/icon` package
   */
  name: IconName;

  /**
   * Size of the icon
   */
  size?: IconSize;

  /**
   * Name of a colour provided by the `@mobble/theme` package
   */
  color?: Color;
}

/**
 * The Icon component displays an icon provided by the `@mobble/icon` package.
 */
export const Icon: React.FC<IconProps> = ({
  name,
  size = 'normal',
  color = 'var(--neutrals-core-default)',
  className,
  ...others
}) => {
  const rootClasses = cx(
    {
      Icon: true,
      [`size-${size}`]: true,
    },
    className
  );

  return (
    <div className={rootClasses} {...others}>
      <Pkg.Icon name={name} size={size} color={color} />
    </div>
  );
};

export default Icon;
