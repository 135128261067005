import React from 'react';
import { useI18n } from '@mobble/i18n';
import { makeRainGauge } from '@mobble/models/src/model/RainGauge';
import { useProperties } from '@mobble/store/src/hooks/properties';
import { useRainGauge, useExtStatus } from '@mobble/store/src/hooks';

import { useNavigateBack } from '@src/hooks/useNavigateBack';

import {
  RainGaugeCreateForm,
  RainGaugeCreateFormValues,
} from '@src/stories/Views/RainGauge/RainGaugeCreateForm';
import { EntitySliceFactoryPrelude } from '@src/stories/Views/Misc/EntitySliceFactoryPrelude';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';

import * as ROUTE_NAME from '../config/routeNames';
import { ScreenRendererProps } from '../config/types';

export const RainGaugeReadingCreateHeader: React.FC<
  ScreenRendererProps
> = () => {
  const { formatMessage } = useI18n();
  const goBack = useNavigateBack();

  return (
    <ScreenHeader
      modal
      title={formatMessage({
        defaultMessage: 'Add a new rain gauge',
        description: 'screen.title.rain-gauge_create',
      })}
      breadcrumbs={[
        {
          title: formatMessage({
            description: 'screen.title.rain-gauge',
            defaultMessage: 'Rain gauge',
          }),
          href: ROUTE_NAME.RAIN_GAUGES_LIST,
        },
      ]}
      onClose={goBack}
    />
  );
};

export const RainGaugeReadingCreate: React.FC<ScreenRendererProps> = () => {
  const { translate } = useI18n();
  const goBack = useNavigateBack();
  const properties = useProperties();
  const propertyId = properties.selected?.id;

  const { create } = useRainGauge('_', propertyId);
  const extStatus = useExtStatus('rainGauges', 'create', propertyId);
  const formLoading = extStatus?.loading;
  const formError = extStatus?.error
    ? translate({
        key: 'generic.form.error',
        params: {
          '%ENTITY': translate({
            key: 'rain-gauges.rain-gauge.create.form.title.label',
          }),
        },
      })
    : '';

  const Prelude = EntitySliceFactoryPrelude({
    preludes: [properties.prelude],
    required: [properties.selected],
  });

  if (Prelude) {
    return Prelude;
  }

  const handleSubmit = (formValues: RainGaugeCreateFormValues) => {
    if (!properties.selected) {
      return;
    }

    const newRainGuage = makeRainGauge({
      propertyId: properties.selected.id,
      ...formValues,
    });

    create(newRainGuage)
      .then((rainGuage) => {
        // TODO: replace with Toast/Alert
        console.log(`Rain Guage '${rainGuage.name}' successfully created`);
        console.log(rainGuage);
        goBack();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <RainGaugeCreateForm
      error={formError}
      loading={formLoading}
      onCancel={goBack}
      onSubmit={handleSubmit}
    />
  );
};

export default {
  name: ROUTE_NAME.MODAL_RAIN_GAUGE_CREATE,
  header: RainGaugeReadingCreateHeader,
  component: RainGaugeReadingCreate,
  modal: true,
};
