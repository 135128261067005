import React from 'react';
import { RawDate, formatDate, fromRawDate } from '@mobble/shared/src/core/Date';
import { TableColumn } from '@src/stories/Components/Layout/Table';
import {
  getLivestockAgeColor,
  toMobDisplayName,
} from '@mobble/models/src/model/Mob';
import { Clickable } from '@src/stories/Components/UX/Clickable';
import { toPath } from '@src/interfaces/Routing';
import { Text } from '@src/stories/Components/UI/Text';
import * as ROUTE_NAME from '@src/screens/config/routeNames';
import { Paddock, findPaddock } from '@mobble/models/src/model/Paddock';
import { formatMonetary } from '@mobble/shared/src/core/Monetary';
import { ConfiguredPropertyType } from '@mobble/models/src/model/Property';
import { Box } from '@src/stories/Components/Layout/Box';
import { HStack } from '@src/stories/Components/Layout/Stack';
import { Badge } from '@src/stories/Components/UI/Badge';
import { I18nItem } from '@mobble/i18n/src';

export const makePurchasesTableColumns = ({
  paddocks,
  currencySymbol,
  propertyTypes,
  translate,
}: {
  paddocks: Paddock[];
  currencySymbol: string;
  propertyTypes: ConfiguredPropertyType[];
  translate: (i18nItem: I18nItem) => undefined | string;
}): TableColumn[] => {
  const getPaddock = findPaddock(paddocks);
  const handleClick = (event: any) => {
    event.stopPropagation();
  };

  return [
    {
      key: 'date',
      label: { key: 'purchases.table.heading.column.date' },
      totals: false,
      toValue: (purchase) =>
        purchase.date ? fromRawDate(purchase.date).toDate().getTime() : null,
      valueToString: (value) =>
        value ? formatDate(fromRawDate(value as RawDate)) : '',
    },
    {
      key: 'head',
      label: { key: 'purchases.table.heading.column.head' },
      totals: true,
      toValue: (purchase) => purchase.mob.size,
      render: (_, purchase) => (
        <Text underline variant="small">
          {purchase.mob.size}
        </Text>
      ),
    },
    {
      key: 'type',
      label: { key: 'mobs.table.heading.column.type' },
      totals: false,
      toValue: (purchase) => purchase.mob.type,
    },
    {
      key: 'mob',
      label: { key: 'purchases.table.heading.column.mob' },
      totals: false,
      toValue: (purchase) => toMobDisplayName(purchase.mob),
      render: (_, purchase) => {
        return <Text variant="small">{toMobDisplayName(purchase.mob)}</Text>;
      },
    },
    {
      key: 'ages',
      label: { key: 'mobs.table.heading.column.ages' },
      totals: false,
      toValue: (casualty) => casualty.mob.ages.join(', '),
      render: (_, purchase) => {
        return (
          <div style={{ marginTop: '-8px', marginBottom: '-8px' }}>
            <HStack wrap>
              {purchase.mob.ages.map((age) => (
                <Box
                  key={age}
                  spacing={{ top: 0, right: 0.5, bottom: 0.25, left: 0 }}
                >
                  <Badge
                    textVariant="small"
                    key={age}
                    label={String(age)}
                    color={getLivestockAgeColor(
                      propertyTypes,
                      purchase.mob.type
                    )(age)}
                  />
                </Box>
              ))}
            </HStack>
          </div>
        );
      },
    },
    {
      key: 'classes',
      label: { key: 'purchases.table.heading.column.classes' },
      totals: false,
      toValue: (purchase) => purchase.mob.classes.join(', '),
    },
    {
      key: 'paddock',
      label: { key: 'purchases.table.heading.column.paddock' },
      totals: false,
      toValue: (purchase) => {
        return purchase;
      },
      toCsvValue: (purchase) => `"${getPaddock(purchase.paddock)?.name || ''}"`,
      render: (_, purchase) => (
        <Clickable
          href={toPath(ROUTE_NAME.PADDOCK_DETAIL, {
            paddockId: purchase.paddock,
          })}
          onClick={handleClick}
        >
          <Text underline variant="small">
            {getPaddock(purchase.paddock)?.name}
          </Text>
        </Clickable>
      ),
    },
    {
      key: 'price_per_head',
      label: { key: 'purchases.table.heading.column.price_per_head' },
      totals: (prices: number[], _, rows) => {
        const totalReceived = rows.reduce(
          (acc: number, purchase) =>
            acc + purchase.pricePerHeadCents * purchase.mob.size,
          0
        );
        const totalNumber = rows.reduce(
          (acc: number, purchase) => acc + purchase.mob.size,
          0
        );
        const avgCostPerHead = formatMonetary(
          totalReceived / totalNumber,
          currencySymbol
        );

        return translate({
          key: 'purchases.table.heading.column.price_per_head.average',
          params: { '%VALUE': avgCostPerHead },
        });
      },
      toValue: (purchase) => purchase.pricePerHeadCents / 100,
      render: (_, purchase) => (
        <Text variant="small">
          {formatMonetary(purchase.pricePerHeadCents, currencySymbol)}
        </Text>
      ),
    },
    {
      key: 'received',
      label: { key: 'purchases.table.heading.column.total' },
      totals: (_, total: number) => {
        return formatMonetary(total * 100, currencySymbol);
      },
      toValue: (purchase) =>
        (purchase.pricePerHeadCents / 100) * purchase.mob.size,
      render: (_, purchase) => (
        <Text variant="small">
          {formatMonetary(
            purchase.pricePerHeadCents * purchase.mob.size,
            currencySymbol
          )}
        </Text>
      ),
    },
    {
      key: 'sold_to',
      label: { key: 'purchases.table.heading.column.vendor' },
      totals: false,
      toValue: (purchase) => purchase.sellerName,
    },
    {
      key: 'notes',
      label: { key: 'purchases.table.heading.column.notes' },
      totals: false,
      toValue: (purchase) => purchase.notes || '',
    },
  ];
};
