import React from 'react';
import { useI18n } from '@mobble/i18n';
import { allMobsCanMerge, Mob } from '@mobble/models/src/model/Mob';
import { Button } from '@src/stories/Components/UX/Button';
import { useLinking } from '@src/hooks/useLinking';
import * as ROUTE_NAME from '@src/screens/config/routeNames';
import { base64encode } from '@mobble/shared/src/core/String';
import { usePaddocks } from '@mobble/store/src/hooks/paddocks';
import { paddockForMob } from '@mobble/models/src/model/Paddock';
import styles from './selectedMobsOptions.scss';

export interface SelectedMobsOptionsProps {
  selected?: Mob[];
}

export const SelectedMobsOptions = ({ selected }: SelectedMobsOptionsProps) => {
  const { formatMessage } = useI18n();
  const linkTo = useLinking();
  const paddocks = usePaddocks(selected[0]?.propertyId);
  const selectedIds = selected.map((m) => m.id);
  const paddockId = paddockForMob(paddocks.entities)(selected[0])?.id ?? '?';
  const selectedMobsOnSamePaddock = selected.every((mob) => paddockForMob(paddocks.entities)(mob)?.id === paddockId);

  const buttons = [
    {
      label: formatMessage({
        defaultMessage: '{count, plural, one {Move mob} other {Move mobs}}',
      }, {
        count: selected.length,
      }),
      onClick: () => {
        linkTo(ROUTE_NAME.MODAL_MOB_MOVE, {
          paddockId,
          mobIds: base64encode(JSON.stringify(selectedIds)),
        });
      },
    },
    {
      label: formatMessage({
        defaultMessage: '{count, plural, one {Merge mob} other {Merge mobs}}',
      }, {
        count: selected.length,
      }),
      disabled: !(selectedMobsOnSamePaddock && allMobsCanMerge(selected)),
      onClick: () => {
        linkTo(ROUTE_NAME.MODAL_MOB_MERGE, {
          paddockId,
          mobIds: base64encode(JSON.stringify(selectedIds)),
        });
      },
    },
    {
      label: formatMessage({
        defaultMessage: 'Apply action',
      }),
      onClick: () => {
        linkTo(ROUTE_NAME.MODAL_MOB_ACTION_CREATE, {
          paddockId,
          mobIds: base64encode(JSON.stringify(selectedIds)),
        });
      },
    },
  ];

  return (
    <div className={styles.container}>
      {buttons.map((b, i) => (
        <Button
          key={i}
          flex
          size="small"
          outline
          disabled={b.disabled ?? false}
          label={b.label}
          onClick={b.onClick}
        />
      ))}
    </div>
  );
};
