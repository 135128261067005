import React from 'react';
import dayjs from 'dayjs';
import { ListSelectOption } from '@src/stories/Components/UX/ListSelect';
import {
  FormBuilderProps,
  FormBuilder,
} from '@src/stories/Views/Form/FormBuilder';

export interface MobbleConnectInviteUserFormProps {
  error?: string;
  loading?: boolean;
  properties: ListSelectOption[];
  currentPropertyId: string;
  emailErrorMessage: string | null;
  disabled: boolean;
  disableSubmit: boolean;
  hasExpiry?: boolean;
  onSubmit: (formValues: MobbleConnectInviteUserFormValues) => void;
  onCancel: () => void;
  onChange: (params: MobbleConnectInviteUserFormValues) => void;
}

export interface MobbleConnectInviteUserFormValues {
  email: string;
  propertyIds: string[];
  expires?: string;
}

export const MobbleConnectInviteUserForm: React.FC<
  MobbleConnectInviteUserFormProps
> = ({
  error,
  loading,
  properties,
  currentPropertyId,
  emailErrorMessage,
  disabled,
  disableSubmit,
  hasExpiry,
  onChange,
  onCancel,
  onSubmit,
}) => {
  // settings.property.mobble_connect.invite_user.form.infoMessage.label

  const form: FormBuilderProps<MobbleConnectInviteUserFormValues> = {
    flex: true,
    i18nRootKey: 'settings.property.mobble_connect.invite_user.form',
    fields: [
      {
        name: 'email',
        type: 'email',
        required: true,
        error: emailErrorMessage,
      },
      {
        name: 'propertyIds',
        type: 'select-multiple',
        options: properties,
        required: true,
        disabled: () => !!currentPropertyId,
        initialValue: [currentPropertyId],
      },
    ],
    error,
    loading,
    onSubmit,
    onChange,
    onCancel,
    disabled,
  };

  if (hasExpiry) {
    const minDate = dayjs().add(1, 'day').toISOString().split('T')[0];
    const maxDate = dayjs().add(30, 'day').toISOString().split('T')[0];

    form.fields.push({
      name: 'expires',
      type: 'date',
      min: minDate,
      max: maxDate,
      required: true,
      initialValue: maxDate,
    });
  }

  return <FormBuilder {...form} />;
};
