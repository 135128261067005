import { Color, colorToHex } from '@mobble/colors';

export const chartColors: string[] = [
  '#F66D44',
  '#FEAE65',
  '#E6F69D',
  '#AADEA7',
  '#64C2A6',
  '#2D87BB',
  '#55A7AA',
  '#8303E7',
  '#DF9C9B',
  '#54C077',
  '#EAB026',
  '#8D0119',
  '#8D8F01',
  '#AE4880',
  '#D759F7',
  '#E5960E',
  '#C2C73C',
  '#E0A795',
  '#843AAA',
  '#FF685E',
  '#17BAC5',
  '#F482E2',
  '#B40CC0',
  '#DB034D',
  '#2AE2E7',
  '#4ECF00',
  '#49CBAB',
  '#C7E63A',
  '#F1793A',
];

export const chartLivestockColors: string[] = [
  '#7AD82B',
  '#35A2EB',
  '#D335EB',
  '#F66D44',
];

export const theme = {
  fontFamily: 'Ubuntu',
  fontSize: 14,
  legends: {
    text: {
      fontSize: 13,
      fontWeight: 400,
      fontFamily: 'Ubuntu',
      fill: colorToHex(Color.Black),
    },
  },
  tooltip: {
    container: {
      fontFamily: 'Ubuntu',
    },
  },
  grid: {
    line: {
      stroke: colorToHex(Color.LightGrey),
      strokeWidth: 0.5,
    },
  },
};
