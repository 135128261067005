import { defineMessages } from 'react-intl';
import { type IconName } from '@mobble/icon';
import { useMessages } from '@mobble/i18n';
import { toMobDisplayName } from '@mobble/models/src/model/Mob';
import { useEntitiesRefresher } from '@mobble/shared/src/hooks/useEntitiesRefresher';
import {
  usePaddock,
  usePaddocks,
  useProperties,
  useNaturalIncrease,
  useNaturalIncreases,
} from '@mobble/store/src/hooks';

import { useNavigateBack } from '@src/hooks/useNavigateBack';
import { toPath } from '@src/interfaces/Routing';
import { ScreenHeaderProps } from '@src/stories/Views/Misc/ScreenHeader';
import { EntitySliceFactoryPrelude } from '@src/stories/Views/Misc/EntitySliceFactoryPrelude';

import * as ROUTE_NAME from '../config/routeNames';

const messages = defineMessages({
  naturalIncreasesDetail: {
    defaultMessage: 'Natural Increase',
    description: 'screen.title.natural_increase_detail',
  },
  naturalIncreasesEdit: {
    defaultMessage: 'Edit Natural Increase',
    description: 'screen.title.naturalIncreases_edit',
  },
  summaries: {
    defaultMessage: 'Summaries',
    description: 'screen.title.summaries',
  },
  naturalIncreases: {
    defaultMessage: 'Natural Increases',
    description: 'screen.title.summary_natural_increases',
  },
});

export const useNaturalIncreasesScreenHeader = (
  naturalIncreaseId: string,
  isEdit?: boolean
): Pick<
  ScreenHeaderProps,
  'title' | 'breadcrumbs' | 'quickLinks' | 'actions' | 'onGoBack'
> => {
  const strings = useMessages(messages);
  const onGoBack = useNavigateBack();
  const properties = useProperties();
  const propertyId = properties.selected?.id;
  const naturalIncreases = useNaturalIncreases(propertyId);
  const naturalIncrease = useNaturalIncrease(naturalIncreaseId, propertyId);

  const naturalIncreasesName = naturalIncrease.entity
    ? toMobDisplayName(naturalIncrease.entity.mob)
    : strings.naturalIncreasesDetail;

  const title = !isEdit ? naturalIncreasesName : strings.naturalIncreasesEdit;

  const breadcrumbs = [
    {
      title: strings.summaries,
      href: ROUTE_NAME.SUMMARIES_LIST,
    },
    {
      title: strings.naturalIncreases,
      href: ROUTE_NAME.SUMMARY_NATURAL_INCREASES_LIST,
    },
  ];

  if (isEdit) {
    breadcrumbs.push({
      title: naturalIncreasesName,
      href: toPath(ROUTE_NAME.NATURAL_INCREASE_DETAIL, {
        naturalIncreaseId,
      }),
    });
  }

  const quickLinks = !isEdit
    ? naturalIncreases.entities
        .map((p) => ({
          title: toMobDisplayName(p.mob),
          href: toPath(ROUTE_NAME.NATURAL_INCREASE_DETAIL, {
            naturalIncreaseId: p.id,
          }),
        }))
        .sort((a, b) => a.title.localeCompare(b.title))
    : undefined;

  const actions = !isEdit
    ? [
        {
          icon: 'edit' as IconName,
          // title: strings.saleEdit, // TODO
          href: toPath(ROUTE_NAME.NATURAL_INCREASE_EDIT, {
            naturalIncreaseId,
          }),
        },
      ]
    : undefined;

  return { title, breadcrumbs, quickLinks, actions, onGoBack };
};

export const useNaturalIncreaseScreen = (naturalIncreaseId: string) => {
  const properties = useProperties();
  const propertyId = properties.selected?.id;
  const naturalIncrease = useNaturalIncrease(naturalIncreaseId, propertyId);
  const paddocks = usePaddocks(propertyId);
  const paddock = usePaddock(naturalIncrease.entity?.paddock.id);

  useEntitiesRefresher([naturalIncrease, paddocks], propertyId);

  const prelude = EntitySliceFactoryPrelude({
    preludes: [properties.prelude, naturalIncrease.prelude],
    required: [properties.selected],
    notFound: [naturalIncrease.entity],
  });

  return { prelude, naturalIncrease, paddock, paddocks, properties };
};
