import React from 'react';
import { useI18n } from '@mobble/i18n';
import {
  pointsToFeaturePolygon,
  polygonToArea,
} from '@mobble/models/src/model/MapGeometry';
import { type Paddock } from '@mobble/models/src/model/Paddock';
import {
  ConfiguredPropertyTypeGroup,
  getUniqueConfiguredPropertyTypes,
  getUnselectedPaddockTypeColor,
} from '@mobble/models/src/model/Property';
import { QuantityOfArea, convertTo } from '@mobble/shared/src/core/Quantity';
import { base64decode } from '@mobble/shared/src/core/String';
import { useEntitiesRefresher } from '@mobble/shared/src/hooks/useEntitiesRefresher';
import {
  useProperties,
  usePaddock,
  useExtStatus,
  useSettings,
} from '@mobble/store/src/hooks';

import { useNavigateBack } from '@src/hooks/useNavigateBack';

import { EntitySliceFactoryPrelude } from '@src/stories/Views/Misc/EntitySliceFactoryPrelude';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';
import {
  PaddockCreateForm,
  type PaddockCreateFormValues,
} from '@src/stories/Views/Paddock/PaddockCreateForm';

import * as ROUTE_NAME from '../config/routeNames';
import { type ScreenRendererProps } from '../config/types';

export const PaddockCreateHeader: React.FC<ScreenRendererProps> = () => {
  const goBack = useNavigateBack();
  const { formatMessage } = useI18n();

  return (
    <ScreenHeader
      modal
      title={formatMessage({
        defaultMessage: 'Add new paddock',
        description: 'screen.title.paddock_create',
      })}
      breadcrumbs={[
        {
          title: formatMessage({
            defaultMessage: 'Paddocks',
            description: 'screen.title.paddocks',
          }),
          href: ROUTE_NAME.PADDOCKS_LIST,
        },
      ]}
      onClose={goBack}
    />
  );
};

export const PaddockCreate: React.FC<ScreenRendererProps> = ({ route }) => {
  const { translate } = useI18n();
  const properties = useProperties();
  const propertyId = properties.selected?.id;
  const paddock = usePaddock('_', propertyId);
  const { settings } = useSettings();
  const goBack = useNavigateBack();

  const extStatus = useExtStatus('paddocks', 'create', propertyId || '');
  const formLoading = extStatus?.loading;
  const formError = extStatus?.error
    ? translate({
        key: 'generic.form.error',
        params: {
          '%ENTITY': translate({
            key: 'paddocks.paddock.create.form.title.label',
          }),
        },
      })
    : '';

  const points = route.params?.points
    ? JSON.parse(base64decode(route.params.points))
    : undefined;
  const initialPolygon =
    points?.length > 2 ? pointsToFeaturePolygon(points) : undefined;

  const totalArea = initialPolygon
    ? (convertTo(
        settings.areaUnit,
        2
      )(polygonToArea(initialPolygon?.geometry)) as QuantityOfArea)
    : undefined;

  const initialValues = {
    points,
    grazeable_area: totalArea,
  };

  useEntitiesRefresher([paddock], propertyId);

  const prelude = EntitySliceFactoryPrelude({
    preludes: [properties.prelude],
    required: [properties.selected],
  });

  if (prelude) {
    return prelude;
  }

  const getFilterTypesFromConfiguredPropertyTypeGroup = (
    group: ConfiguredPropertyTypeGroup
  ): string[] =>
    getUniqueConfiguredPropertyTypes(
      properties.selected ? [properties.selected] : []
    )(group).map((a) => a.type);

  const paddockTypes = getFilterTypesFromConfiguredPropertyTypeGroup(
    ConfiguredPropertyTypeGroup.paddockType
  ).map((a) => ({
    value: a,
    color:
      (properties.selected?.types || []).find((b) => b.type === a)?.color ||
      '#fff',
  }));

  const handleCreateCustomField = (label: string) => {
    if (!properties.selected) {
      return Promise.reject();
    }
    const color = getUnselectedPaddockTypeColor(properties.selected);

    return properties.addPropertyType({
      group: ConfiguredPropertyTypeGroup.paddockType,
      parentId: properties.selected.id,
      color,
      label,
    });
  };

  const handleSubmit = (formValues: PaddockCreateFormValues) => {
    const polygon = formValues.points
      ? pointsToFeaturePolygon(formValues.points)?.geometry ?? null
      : null;

    const newPaddock: Omit<Paddock, 'id'> = {
      propertyId: properties.selected.id,
      name: formValues.name,
      type: formValues.paddock_type,
      properties: {
        size: formValues.grazeable_area,
      },
      mobs: [],
      geometry: {
        polygon,
      },
    };

    paddock
      .create(newPaddock)
      .then((paddock) => {
        // TODO: replace with Toast/Alert
        console.log(`Paddock '${paddock.name}' successfully created`);
        console.log(paddock);
        goBack();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <PaddockCreateForm
      paddockTypes={paddockTypes}
      initialValues={initialValues}
      error={formError}
      loading={formLoading}
      onCancel={goBack}
      onSubmit={handleSubmit}
      onCreateCustomField={handleCreateCustomField}
    />
  );
};

export default {
  name: ROUTE_NAME.MODAL_PADDOCK_CREATE,
  header: PaddockCreateHeader,
  component: PaddockCreate,
  modal: true,
};
