import React from 'react';
import { useI18n } from '@mobble/i18n';
import { type ConfiguredPropertyTypeGroupCustom } from '@mobble/models/src/model/Property';
import { toMobDisplayName } from '@mobble/models/src/model/Mob';
import { useEntitiesRefresher } from '@mobble/shared/src/hooks/useEntitiesRefresher';
import { useExtStatus, useProperties, useMob } from '@mobble/store/src/hooks';

import { useLinking } from '@src/hooks/useLinking';
import { useNavigateBack } from '@src/hooks/useNavigateBack';
import { toPath } from '@src/interfaces/Routing';

import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';
import {
  MobEditForm,
  type MobEditFormValues,
} from '@src/stories/Views/Mob/MobEditForm';
import { DeleteForm } from '@src/stories/Views/Form/DeleteForm/DeleteForm';
import { EntitySliceFactoryPrelude } from '@src/stories/Views/Misc/EntitySliceFactoryPrelude';

import * as ROUTE_NAME from '../config/routeNames';
import { type ScreenRendererProps } from '../config/types';

export const MobEditHeader: React.FC<ScreenRendererProps> = (props) => {
  const { mobId = '' } = props.route.params;
  const { formatMessage } = useI18n();
  const mob = useMob(mobId);
  const goBack = useNavigateBack();

  return (
    <ScreenHeader
      title={formatMessage(
        {
          description: 'screen.title.edit_x',
          defaultMessage: 'Edit "{TITLE}"',
        },
        {
          TITLE: mob?.entity ? toMobDisplayName(mob.entity) : mobId,
        }
      )}
      breadcrumbs={[
        {
          title: formatMessage({
            defaultMessage: 'Mobs',
            description: 'screen.title.mobs',
          }),
          href: ROUTE_NAME.PADDOCKS_LIST,
        },
        {
          title: mob.entity ? toMobDisplayName(mob.entity) : mobId,
          href: toPath(ROUTE_NAME.MOB_DETAIL, { mobId }),
        },
      ]}
      onGoBack={goBack}
    />
  );
};

export const MobEdit: React.FC<ScreenRendererProps> = (props) => {
  const { mobId = '' } = props.route.params;
  const { translate } = useI18n();
  const linkTo = useLinking();
  const goBack = useNavigateBack();
  const properties = useProperties();
  const propertyId = properties.selected?.id;
  const mob = useMob(mobId);

  const extStatus = useExtStatus('mobs', 'update', mobId);
  const formLoading = extStatus?.loading;
  const extStatusDelete = useExtStatus('mobs', 'delete', mobId);
  const deleteLoading = extStatusDelete?.loading;
  const formError = extStatus?.error
    ? translate({
        key: 'generic.form.error',
        params: {
          '%ENTITY': translate({ key: 'mobs.mob.create.form.title.label' }),
        },
      })
    : '';

  useEntitiesRefresher([mob], propertyId);

  const Prelude = EntitySliceFactoryPrelude({
    preludes: [properties.prelude, mob.prelude],
    required: [properties.selected],
    notFound: [mob.entity],
  });

  if (Prelude) {
    return Prelude;
  }

  const handleSubmit = (formValues: MobEditFormValues) => {
    if (!mob.entity) {
      return;
    }

    const updatedMob = {
      ...mob.entity,
      ...formValues,
      ages: formValues.ages.map((age) => Number(age)),
      size: Number(formValues.size),
      DSE: Number(formValues.DSE),
    };

    mob
      .update(updatedMob)
      .then(() => {
        goBack();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDelete = () => {
    mob
      .remove()
      .then(() => {
        linkTo(ROUTE_NAME.MOBS_LIST);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCreateCustomField = (
    group: ConfiguredPropertyTypeGroupCustom,
    parentId: string,
    label: string
  ) => {
    if (!properties.selected) {
      return Promise.reject();
    }
    return properties.addPropertyType({
      group,
      parentId,
      label,
    });
  };

  return (
    <>
      <MobEditForm
        propertyTypes={properties.selected?.types ?? []}
        mob={mob.entity}
        error={formError}
        loading={formLoading}
        onCreateCustomField={handleCreateCustomField}
        onTouched={() => {}}
        onCancel={goBack}
        onSubmit={handleSubmit}
      />

      <DeleteForm
        i18nRootKey="mobs.mob.delete"
        loading={deleteLoading}
        onDelete={handleDelete}
      />
    </>
  );
};

export default {
  name: ROUTE_NAME.MOB_DETAIL_EDIT,
  header: MobEditHeader,
  component: MobEdit,
};
