import React, { useEffect, useState } from 'react';
import { useI18n } from '@mobble/i18n';
import {
  useInventories,
  useInventory,
} from '@mobble/store/src/hooks/inventories';
import { useInventoryItems } from '@mobble/store/src/hooks/inventoryItems';
import {
  InventoryItem,
  filterInventoryItems,
  InventoryItemStatus,
} from '@mobble/models/src/model/InventoryItem';
import { makeFilterEquals } from '@mobble/models/src/model/Filter';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';
import { type ScreenRendererProps } from '../config/types';
import * as ROUTE_NAME from '@src/screens/config/routeNames';
import { useLinking } from '@src/hooks/useLinking';
import { EntitySliceFactoryPrelude } from '@src/stories/Views/Misc/EntitySliceFactoryPrelude';
import { InventoryItemCard } from '@src/stories/Views/InventoryItem/InventoryItemCard';
import { useProperties } from '@mobble/store/src/hooks/properties';
import { toPath } from '@src/interfaces/Routing';
import {
  EntitiesViewer,
  EntitiesViewerProps,
} from '@src/stories/Views/Entities/EntitiesViewer';
import { makeInventoryItemsTableColumns } from '@src/stories/Views/InventoryItem/List/inventoryItemsTableColumns';
import { Box } from '@src/stories/Components/Layout/Box';
import { Provider } from '@src/stories/Views/Entities/types';
import { ActionSheetOnInventoryItemSelect } from './ActionSheetOnInventoryItemSelect';
import { useEntitiesRefresher } from '@mobble/shared/src/hooks/useEntitiesRefresher';

export const InventoryItemsHeader: React.FC<ScreenRendererProps> = (props) => {
  const { inventoryId = '' } = props.route.params;
  const { formatMessage } = useI18n();
  const linkTo = useLinking();
  const inventory = useInventory(inventoryId);
  const properties = useProperties();
  const propertyId = properties.selected?.id;
  const inventories = useInventories(inventory.entity?.propertyId);

  const quickLinks = inventories.entities
    .map((i) => ({
      title: i.name,
      href: toPath(ROUTE_NAME.INVENTORY_ITEMS, { inventoryId: i.id }),
    }))
    .sort((a, b) => a.title.localeCompare(b.title));

  const isSharedInventory = Boolean(
    inventory?.entity?.sharing.find((p) => p.propertyId === propertyId)
  );

  if (!inventory.entity) {
    return null;
  }

  return (
    <ScreenHeader
      title={inventory.entity?.name}
      breadcrumbs={[
        {
          title: formatMessage({
            description: 'screen.title.inventories',
            defaultMessage: 'Inventories',
          }),
          href: ROUTE_NAME.INVENTORIES_LIST,
        },
      ]}
      quickLinks={quickLinks}
      {...(!isSharedInventory && {
        actions: [
          {
            icon: 'edit',
            href: toPath(ROUTE_NAME.INVENTORY_EDIT, {
              inventoryId: inventory.entity?.id,
            }),
          },
        ],
      })}
      onGoBack={() => linkTo(ROUTE_NAME.INVENTORIES_LIST)}
    />
  );
};

export const InventoryItems: React.FC<ScreenRendererProps> = (props) => {
  const linkTo = useLinking();
  const { formatMessage } = useI18n();
  const properties = useProperties();
  const propertyId = properties.selected?.id;

  const { inventoryId = '' } = props.route.params;
  const inventory = useInventory(inventoryId, propertyId);
  const inventoryItems = useInventoryItems(inventoryId);

  const [selectedInventoryItem, setSelectedInventoryItem] =
    useState<InventoryItem | null>(null);

  useEffect(() => {
    if (!inventoryItems || !inventory?.entity) {
      return;
    }

    inventoryItems.setFilter({
      group: 'inventory_item_status',
      filter: makeFilterEquals(InventoryItemStatus.Active),
    });
  }, [inventoryItems.total]);

  useEntitiesRefresher([inventory], propertyId);

  const Prelude = EntitySliceFactoryPrelude({
    preludes: [inventory.prelude, inventoryItems.prelude],
    required: inventoryItems.entities,
  });

  if (Prelude) {
    return Prelude;
  }

  const activeInventoryItems = inventoryItems.entities.filter(
    (batch) => batch.status === InventoryItemStatus.Active
  );

  const inventoryItemProvider: Provider<InventoryItem> = {
    ...inventoryItems,
    entities: activeInventoryItems,
    total: activeInventoryItems.length,
  };

  const applyFilter = filterInventoryItems;
  const tableColumns = makeInventoryItemsTableColumns({
    category: inventory.entity?.category,
    type: inventory?.entity?.type,
    formatMessage,
  });

  const viewerProps: EntitiesViewerProps<InventoryItem> = {
    provider: inventoryItemProvider,
    applyFilter,
    renderEntityCard(entity) {
      return (
        <Box spacing={1}>
          <InventoryItemCard
            inventoryItem={entity}
            onClick={setSelectedInventoryItem}
          />
        </Box>
      );
    },
    tableColumns,
    onClickTableRow: setSelectedInventoryItem,
    onEmpty: { key: 'inventory_items.list.empty.add' },
  };

  return (
    <React.Fragment key={inventoryId}>
      <EntitiesViewer {...viewerProps} />
      {selectedInventoryItem ? (
        <ActionSheetOnInventoryItemSelect
          inventoryItem={selectedInventoryItem}
          onClose={() => setSelectedInventoryItem(null)}
        />
      ) : null}
    </React.Fragment>
  );
};

export default {
  name: ROUTE_NAME.INVENTORY_ITEMS,
  header: InventoryItemsHeader,
  component: InventoryItems,
};
