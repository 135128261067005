import Fuse from 'fuse.js';
import { fromRawDate } from '@mobble/shared/src/core/Date';
import { FilterItem, filterMatches, groupFilter } from './Filter';
import { Mob } from './Mob';
import { Paddock } from './Paddock';
import { Property } from './Property';
import { User } from './User';

export interface Sale {
  id: string;
  propertyId: Property['id'];
  pricePerHeadCents: number;
  number: number;
  date: any;
  deleted?: boolean;
  status: 'active' | 'deleted';
  notes: string;
  soldToId?: string;
  soldToName: string;
  createdBy: User;
  mob: Mob;
  paddock?: Paddock;
}

export const filterSales = (
  casualties: Sale[],
  filter?: FilterItem[]
): Sale[] => {
  if (!filter || filter.length === 0) {
    return casualties;
  }
  const grouped = [...groupFilter(filter)];

  const searchQuery = filter.find((a) => a.group === 'search')?.filter;

  const preFilteredCasualty =
    searchQuery && searchQuery.type === 'search'
      ? searchSales(casualties, searchQuery.value)
      : casualties;

  return preFilteredCasualty.filter((sale) =>
    grouped.every(([_, filters]) => filters.some(filterItemMatchesSales(sale)))
  );
};

export const searchSales = (sale: Sale[], searchQuery: string): Sale[] => {
  const fuse = new Fuse(sale, {
    keys: [
      { name: 'mob.breed', weight: 5 },
      { name: 'mob.gender', weight: 3 },
      { name: 'number', weight: 3 },
    ],
    threshold: 0.5,
    shouldSort: true,
  });
  return fuse.search(searchQuery).map((a) => a.item);
};

export const filterItemMatchesSales =
  (sale: Sale) => (filterItem: FilterItem) => {
    const matches = filterMatches(filterItem.filter);

    switch (filterItem.group) {
      case 'breed':
        return matches(sale.mob.breed);
      case 'gender':
        return matches(sale.mob.gender);
      case 'number':
        return matches(sale.number);
      case 'year':
        return matches(fromRawDate(sale.date).year());
      case 'dateRange':
        return matches(sale.date);
      case 'search':
        return true;
      default:
        return true;
    }
  };
