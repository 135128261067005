import { RawDate, formatDate, fromRawDate } from '@mobble/shared/src/core/Date';
import {
  ElTableValue,
  TableColumn,
} from '@src/stories/Components/Layout/Table';
import {
  I18nContextProps,
  makeQuantityFormatMessageItem,
} from '@mobble/i18n/src';
import { InventoryCategory } from '@mobble/models/src';
import { formatMonetary } from '@mobble/shared/src/core/Monetary';

export const makeInventoryItemBatchesTableColumns = ({
  category,
  currencySymbol,
  formatMessage,
}: {
  category: InventoryCategory;
  currencySymbol: string;
  formatMessage: I18nContextProps['formatMessage'];
}): TableColumn[] => {
  return [
    ...(category === InventoryCategory.Chemicals
      ? [
          {
            key: 'name',
            label: {
              key: 'inentory_item_batches.table.heading.column.name',
            },
            toValue: (entity) => entity.name,
          },
        ]
      : [
          {
            key: 'supplierName',
            label: {
              key: 'inentory_item_batches.table.heading.column.supplierName',
            },
            toValue: (entity) => entity.supplierName,
          },
        ]),
    {
      key: 'quantity',
      label: {
        key: 'inentory_item_batches.table.heading.column.quantity',
      },
      toValue: (entity) =>
        formatMessage(...makeQuantityFormatMessageItem(entity.quantity)),
    },
    {
      key: 'dateReceived',
      label: {
        key: 'inentory_item_batches.table.heading.column.dateReceived',
      },
      toValue: (entity) =>
        entity.dateReceived
          ? fromRawDate(entity.dateReceived).toDate().getTime()
          : null,
      valueToString: (value) =>
        value ? formatDate(fromRawDate(value as RawDate)) : '',
    },
    ...(category === InventoryCategory.Feed
      ? [
          {
            key: 'pricePerUnitCents',
            label: {
              key: 'inentory_item_batches.table.heading.column.pricePerUnitCents',
            },
            toValue: (entity) => entity.pricePerUnitCents,
            render: (value) => {
              return (
                <ElTableValue value={formatMonetary(value, currencySymbol)} />
              );
            },
          },
        ]
      : [
          {
            key: 'dateOfManufacture',
            label: {
              key: 'inentory_item_batches.table.heading.column.dateOfManufacture',
            },
            toValue: (batch) =>
              batch.dateOfManufacture
                ? fromRawDate(batch.dateOfManufacture).toDate().getTime()
                : null,
            valueToString: (value) =>
              value ? formatDate(fromRawDate(value as RawDate)) : '',
          },
          {
            key: 'expiryDate',
            label: {
              key: 'inentory_item_batches.table.heading.column.expiryDate',
            },
            toValue: (batch) =>
              batch.dateExpiry
                ? fromRawDate(batch.dateExpiry).toDate().getTime()
                : null,
            valueToString: (value) =>
              value ? formatDate(fromRawDate(value as RawDate)) : '',
          },
        ]),
  ];
};
