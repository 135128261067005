import React from 'react';
import * as Yup from 'yup';
import { roundNumberTo } from '@mobble/shared/src/core/Number';
import { type Sale } from '@mobble/models/src/model/Sale';
import { type Paddock, paddockForMob } from '@mobble/models/src/model/Paddock';
import { Color } from '@mobble/colors';
import { FormBuilder, type FormBuilderProps } from '../Form/FormBuilder';
import { Box } from '../../Components/Layout/Box';
import { MobCard } from '../Mob/MobCard';
import { useI18n } from '@mobble/i18n';
import { InputContainer } from '@src/stories/Components/UX/InputContainer';

export interface SaleEditFormProps {
  paddocks: Paddock[];
  sale: Sale;
  error?: string;
  loading?: boolean;
  onCancel: () => void;
  onSubmit: (formValues: SaleEditFormValues) => void;
}

export interface SaleEditFormValues {
  number: number;
  price_per_head: string | number;
  total_price: string | number;
  date: string;
  sold_to: string;
  notes: string;
}

export const SaleEditForm: React.FC<SaleEditFormProps> = ({
  paddocks,
  sale,
  error,
  loading,
  onCancel,
  onSubmit,
}) => {
  const { translate } = useI18n();
  const mob = {
    ...sale.mob,
    size: sale.number,
  };
  const form: FormBuilderProps<SaleEditFormValues> = {
    flex: true,
    i18nRootKey: 'sale.edit.form',
    fields: [
      {
        name: 'selected_mobs_preview',
        type: 'custom',
        containerComponent: false,
        component: () => {
          return (
            <InputContainer>
              <Box background={Color.LightGrey} spacing={2}>
                <MobCard
                  propertyTypes={[]}
                  paddock={paddockForMob(paddocks)(mob)}
                  mob={mob}
                />
              </Box>
            </InputContainer>
          );
        },
      },
      {
        name: 'number',
        type: 'number',
        initialValue: sale.number,
        validation: Yup.number()
          .integer(
            translate({
              key: 'mobs.sale.edit.form.number.error.label',
            }) || ''
          )
          .required(),
        onChange: (values) => {
          return {
            ...values,
            total_price: values.price_per_head
              ? Number(values.price_per_head) * (values?.number || 1)
              : '',
          };
        },
      },
      {
        name: 'price_per_head',
        type: 'float',
        initialValue: sale.pricePerHeadCents / 100,
        required: true,
        onChange: (values) => {
          return {
            ...values,
            total_price: values.price_per_head
              ? Number(values.price_per_head) * (values?.number || 1)
              : '',
          };
        },
      },
      {
        name: 'total_price',
        type: 'float',
        initialValue: Number(sale.pricePerHeadCents / 100) * sale.number,
        required: true,
        onChange: (values) => {
          return {
            ...values,
            price_per_head: values.total_price
              ? roundNumberTo(2)(
                  Number(values.total_price) / (values?.number || 1)
                )
              : '',
          };
        },
      },
      {
        name: 'date',
        type: 'date',
        initialValue: sale.date,
        required: true,
      },
      {
        name: 'sold_to',
        type: 'text',
        initialValue: sale.soldToName || '',
      },
      {
        name: 'notes',
        type: 'textarea',
        initialValue: sale.notes || '',
      },
    ],
    error,
    loading,
    onSubmit,
    onCancel,
  };

  return <FormBuilder {...form} />;
};
