import React from 'react';
import { useI18n } from '@mobble/i18n';
import { type RainGaugeReading as IRainGaugeReading } from '@mobble/models/src/model/RainGaugeReading';
import { useProperties } from '@mobble/store/src/hooks/properties';
import { useRainGauge } from '@mobble/store/src/hooks/rainGauge';
import { useRainGaugeReading } from '@mobble/store/src/hooks/rainGaugeReadings';

import { useNavigateBack } from '@src/hooks/useNavigateBack';
import { toPath } from '@src/interfaces/Routing';

import { Box } from '@src/stories/Components/Layout/Box';
import { RainGaugeReadingInfoTable } from '@src/stories/Views/RainGauge/RainGaugeReadingInfoTable';
import { EntitySliceFactoryPrelude } from '@src/stories/Views/Misc/EntitySliceFactoryPrelude';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';

import * as ROUTE_NAME from '../config/routeNames';
import { ScreenRendererProps } from '../config/types';

import styles from './rainGaugeReading.scss';
import { useEntitiesRefresher } from '@mobble/shared/src/hooks/useEntitiesRefresher';

export const RainGaugeReadingHeader: React.FC<ScreenRendererProps> = (
  props
) => {
  const { rainGaugeReadingId, rainGaugeId } = props.route.params;
  const { formatMessage } = useI18n();
  const goBack = useNavigateBack();
  const rainGauge = useRainGauge(rainGaugeId);

  return (
    <ScreenHeader
      title={formatMessage({
        defaultMessage: 'Rain gauge reading',
        description: 'screen.title.rain-gauge_reading',
      })}
      breadcrumbs={[
        {
          title: formatMessage({
            description: 'screen.title.rain-gauges',
            defaultMessage: 'Rain gauges',
          }),
          href: ROUTE_NAME.RAIN_GAUGES_LIST,
        },
        {
          title: rainGauge.entity?.name,
          href: toPath(ROUTE_NAME.RAIN_GAUGE_DETAIL, { rainGaugeId }),
        },
      ]}
      actions={[
        {
          icon: 'edit',
          href: toPath(ROUTE_NAME.MODAL_RAIN_GAUGE_READING_EDIT, {
            rainGaugeId,
            rainGaugeReadingId,
          }),
        },
      ]}
      onGoBack={goBack}
    />
  );
};

export const RainGaugeReading: React.FC<ScreenRendererProps> = (props) => {
  const { rainGaugeReadingId } = props.route.params;
  const properties = useProperties();
  const propertyId = properties.selected?.id;
  const rainGaugeReading = useRainGaugeReading(rainGaugeReadingId, propertyId);

  useEntitiesRefresher([rainGaugeReading], propertyId);

  const Prelude = EntitySliceFactoryPrelude({
    preludes: [properties.prelude, rainGaugeReading.prelude],
    required: [properties.selected],
    notFound: [rainGaugeReading.entity],
  });

  if (Prelude) {
    return Prelude;
  }

  return (
    <Box className={styles.rainGaugeReadingInfo}>
      <RainGaugeReadingInfoTable
        users={properties.selected?.users || []}
        rainGaugeReading={rainGaugeReading.entity as IRainGaugeReading}
      />
    </Box>
  );
};

export default {
  name: ROUTE_NAME.RAIN_GAUGE_READING,
  header: RainGaugeReadingHeader,
  component: RainGaugeReading,
};
