import React from 'react';
import * as Yup from 'yup';
import { getLivestockAgeColor, Mob } from '@mobble/models/src/model/Mob';
import {
  type ConfiguredPropertyType,
  ConfiguredPropertyTypeGroup,
  type ConfiguredPropertyTypeGroupCustom,
  getLivestockAttributes,
  getLivestockParentIdForValue,
} from '@mobble/models/src/model/Property';
import { Color } from '@mobble/colors';
import { FormBuilder, type FormBuilderProps } from '../Form/FormBuilder';
import { useI18n } from '@mobble/i18n';
import styles from './mobEditForm.scss';

export interface MobEditFormProps {
  propertyTypes: ConfiguredPropertyType[];

  mob: Mob;
  error?: string;
  loading: boolean;

  onTouched: (dirty: boolean) => void;
  onCancel: () => void;
  onSubmit: (formValues: MobEditFormValues) => void;
  onCreateCustomField: (
    group: ConfiguredPropertyTypeGroupCustom,
    livestockTypeId: string,
    label: string
  ) => Promise<void>;
}

export type MobEditFormValues = Omit<Mob, 'propertyId' | 'id'>;

export const MobEditForm: React.FC<MobEditFormProps> = ({
  propertyTypes,
  mob,
  error,
  loading,
  onCancel,
  onSubmit,
  onTouched,
  onCreateCustomField,
}) => {
  const { translate } = useI18n();
  const getAgeColor = getLivestockAgeColor(propertyTypes, mob?.type);
  const handleCreateCustomField =
    (type: ConfiguredPropertyTypeGroupCustom) =>
    (value: string): Promise<void> => {
      if (!mob?.type) {
        return Promise.reject();
      }
      const liveStockTypeId = getLivestockParentIdForValue(
        mob.type,
        propertyTypes
      );
      if (!liveStockTypeId) {
        return Promise.reject();
      }

      return onCreateCustomField(type, liveStockTypeId, value);
    };

  const form: FormBuilderProps<MobEditFormValues> = {
    flex: true,
    className: styles.mobEditForm,
    i18nRootKey: 'mobs.mob.edit.form.mob_form',
    fields: [
      {
        name: 'type',
        type: 'display',
        required: true,
        initialValue: mob.type,
      },
      {
        name: 'breed',
        type: 'select',
        options: (values) =>
          getLivestockAttributes(propertyTypes)(
            ConfiguredPropertyTypeGroup.breed,
            getLivestockParentIdForValue(values.type, propertyTypes)
          ),
        required: true,
        initialValue: mob.breed,
        addNew: {
          onSubmit: handleCreateCustomField(ConfiguredPropertyTypeGroup.breed),
          placeholder: {
            key: 'mobs.mob.edit.form.mob_form.breed.add_new.placeholder.label',
          },
          button: {
            key: 'mobs.mob.edit.form.mob_form.breed.add_new.button.label',
          },
        },
      },
      {
        name: 'gender',
        type: 'select',
        options: (values) =>
          getLivestockAttributes(propertyTypes)(
            ConfiguredPropertyTypeGroup.gender,
            getLivestockParentIdForValue(values.type, propertyTypes)
          ),
        required: true,
        initialValue: mob.gender,
        addNew: {
          onSubmit: handleCreateCustomField(ConfiguredPropertyTypeGroup.gender),
          placeholder: {
            key: 'mobs.mob.create.form.mob_form.gender.add_new.placeholder.label',
          },
          button: {
            key: 'mobs.mob.create.form.mob_form.gender.add_new.button.label',
          },
        },
      },
      {
        name: 'ages',
        type: 'select-multiple',
        options: (values) =>
          getLivestockAttributes(propertyTypes)(
            ConfiguredPropertyTypeGroup.tag,
            getLivestockParentIdForValue(values.type, propertyTypes)
          ).map((i) => ({
            ...i,
            color: getAgeColor(i.label) || Color.White,
          })),
        sortFunction: (options) =>
          options.sort((a: any, b: any) => b.label.localeCompare(a.label)),
        required: true,
        initialValue: mob.ages.map((num) => String(num)),
      },
      {
        name: 'classes',
        type: 'select-multiple',
        options: (values) => {
          const parentId = propertyTypes.find(
            (a) => a.type === values.type
          )?.id;

          const activeClasses = propertyTypes
            .filter(
              (a) =>
                a.group === ConfiguredPropertyTypeGroup.class &&
                a.parentId === parentId
            )
            .map((a) => ({
              label: a.label,
              value: a.type,
            }));

          return mob.classes.reduce((acc, a) => {
            const found = activeClasses.find((b) => b.value === a);
            if (found) {
              return acc;
            }
            return [...acc, { label: a, value: a }];
          }, activeClasses);
        },
        required: false,
        initialValue: mob.classes,
        addNew: {
          onSubmit: handleCreateCustomField(ConfiguredPropertyTypeGroup.class),
          placeholder: {
            key: 'mobs.mob.create.form.mob_form.classes.add_new.placeholder.label',
          },
          button: {
            key: 'mobs.mob.create.form.mob_form.classes.add_new.button.label',
          },
        },
      },
      {
        name: 'size',
        type: 'number',
        validation: Yup.number()
          .integer(
            translate({
              key: 'mobs.mob.edit.form.mob_form.size.error.label',
            }) || ''
          )
          .required(),
        initialValue: mob.size,
      },
      {
        name: 'DSE',
        type: 'float',
        required: true,
        initialValue: mob.DSE,
      },
    ],
    error,
    loading,
    onSubmit,
    onCancel,
    onTouched,
  };

  return <FormBuilder {...form} />;
};
