import React from 'react';
import { useI18n } from '@mobble/i18n';
import { findMob } from '@mobble/models/src/model/Mob';
import { paddockForMob } from '@mobble/models/src/model/Paddock';
import { type Casualty } from '@mobble/models/src/model/Casualty';
import { toISO8601 } from '@mobble/shared/src/core/Date';
import { useEntitiesRefresher } from '@mobble/shared/src/hooks/useEntitiesRefresher';
import {
  useExtStatus,
  useProperties,
  useMobs,
  useGetUser,
  useCasualty,
  usePaddocks,
} from '@mobble/store/src/hooks';

import { useNavigateBack } from '@src/hooks/useNavigateBack';
import { useLinking } from '@src/hooks/useLinking';

import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';
import { EntitySliceFactoryPrelude } from '@src/stories/Views/Misc/EntitySliceFactoryPrelude';
import {
  CasualtyCreateForm,
  CasualtyCreateFormValues,
} from '@src/stories/Views/Casualty/CasualtyCreateForm';

import * as ROUTE_NAME from '../config/routeNames';
import { ScreenRendererProps } from '../config/types';

export const CasualtyCreateHeader: React.FC<ScreenRendererProps> = () => {
  const { formatMessage } = useI18n();
  const goBack = useNavigateBack();

  return (
    <ScreenHeader
      modal
      breadcrumbs={[
        {
          title: formatMessage({
            defaultMessage: 'Mobs',
            description: 'screen.title.mobs',
          }),
          href: ROUTE_NAME.MOBS_LIST,
        },
      ]}
      title={formatMessage({
        defaultMessage: 'Record a casualty',
        description: 'screen.title.casualty',
      })}
      onClose={goBack}
    />
  );
};

export const CasualtyCreate: React.FC<ScreenRendererProps> = ({ route }) => {
  const { mobId } = route.params;
  const { translate } = useI18n();
  const properties = useProperties();
  const propertyId = properties.selected?.id;
  const user = useGetUser();
  const paddocks = usePaddocks(propertyId);
  const mobs = useMobs(propertyId);
  const { create } = useCasualty('_', propertyId);
  const goBack = useNavigateBack();
  const linkTo = useLinking();
  const extStatus = useExtStatus('casualties', 'create', propertyId);
  const formLoading = extStatus?.loading;
  const formError = extStatus?.error
    ? translate({
        key: 'generic.form.error',
        params: {
          '%ENTITY': translate({ key: 'casualty.create.form.title.label' }),
        },
      })
    : '';

  useEntitiesRefresher([paddocks], propertyId);

  const prelude = EntitySliceFactoryPrelude({
    preludes: [properties.prelude, mobs.prelude, paddocks.prelude],
    required: [properties.selected],
  });

  if (prelude) {
    return prelude;
  }

  const handleSubmit = (formValues: CasualtyCreateFormValues) => {
    if (!user || !properties.selected) {
      return;
    }

    const mob = findMob(mobs.entities)(formValues.mob);
    if (!mob) {
      return;
    }

    const paddock = paddockForMob(paddocks.entities)(mob);

    const newCasualty: Omit<Casualty, 'id'> & { [key: string]: any } = {
      propertyId: properties.selected.id,
      number: Number(formValues.number),
      methodOfDisposal: formValues.methodOfDisposal || '',
      reason: formValues.reason || '',
      date: toISO8601(formValues.date),
      deleted: false,
      createdBy: user,
      mob,
      paddock: paddock?.id ?? '',
      paddockName: paddockForMob?.name,
    };

    create(newCasualty)
      .then((casualty) => {
        // TODO: replace with Toast/Alert
        console.log(`Casualty '${casualty.id}' successfully created`);
        console.log(casualty);

        if (newCasualty.number === casualty.number) {
          linkTo(ROUTE_NAME.CASUALTY_DETAIL, { casualtyId: casualty.id });
          return;
        }
        goBack();
      })
      .catch((err) => {
        // TODO: replace with Toast/Alert
        console.log(err);
      });
  };

  return (
    <CasualtyCreateForm
      paddocks={paddocks.entities}
      mobs={mobs.entities}
      propertyTypes={properties.selected.types}
      initialValues={{
        mob: mobId,
      }}
      error={formError}
      loading={formLoading}
      onCancel={goBack}
      onSubmit={handleSubmit}
    />
  );
};

export default {
  name: ROUTE_NAME.MODAL_CASUALTY_CREATE,
  header: CasualtyCreateHeader,
  component: CasualtyCreate,
};
