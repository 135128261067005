import React from 'react';
import { type Mob } from '@mobble/models/src/model/Mob';
import { type Paddock } from '@mobble/models/src/model/Paddock';
import {
  type ConfiguredPropertyType,
  type ConfiguredPropertyTypeGroupCustom,
} from '@mobble/models/src/model/Property';
import { Color } from '@mobble/colors';
import { Box } from '@src/stories/Components/Layout/Box';
import { Spacer } from '@src/stories/Components/Layout/Spacer';
import { HStack } from '@src/stories/Components/Layout/Stack';
import { Text } from '@src/stories/Components/UI/Text';
import { Button } from '@src/stories/Components/UX/Button';
import { CircularButton } from '@src/stories/Components/UX/CircularButton';
import { MobCard } from '../../Mob/MobCard';
import { MobSplitColumn } from './MobSplitColumn';
import styles from './styles.scss';

export interface MobSplitProps {
  propertyTypes: ConfiguredPropertyType[];
  paddock?: Paddock;
  mob: Mob;
  value?: null | MobSplitFormValues;
  onChange: (value: null | MobSplitFormValues) => void;
  onCreateCustomField: (
    type: ConfiguredPropertyTypeGroupCustom
  ) => (value: string) => Promise<void>;
}

export interface MobSplitFormValues {
  remainingClasses: string[];
  remainingAges: number[];
  remainingGender: string;
  newClasses: string[];
  newAges: number[];
  newGender: string;
  newSize: number | null;
}

export const MobSplit: React.FC<MobSplitProps> = ({
  propertyTypes,
  mob,
  paddock,
  value,
  onChange,
  onCreateCustomField,
}) => {
  const onSplit = () => {
    onChange({
      remainingClasses: mob.classes,
      remainingAges: mob.ages,
      remainingGender: mob.gender,
      newClasses: mob.classes,
      newAges: mob.ages,
      newGender: mob.gender,
      newSize: null,
    });
  };

  const onCancelSplit = () => {
    onChange(null);
  };

  if (!value) {
    return (
      <Box spacing={2} className={styles.mobSplitWrapper}>
        <HStack>
          <Box flex>
            <MobCard
              paddock={paddock}
              mob={mob}
              propertyTypes={propertyTypes}
            />
          </Box>
          <Spacer x={2} />
          <CircularButton
            stroke={Color.Green}
            color={Color.Green}
            icon="split"
            onClick={onSplit}
            label={{
              key: 'paddocks.paddock.move_mob.form.mob_split.button_split.label',
            }}
          />
        </HStack>
      </Box>
    );
  }

  return (
    <Box spacing={2} className={styles.mobSplitWrapper}>
      <MobCard paddock={paddock} mob={mob} propertyTypes={propertyTypes} />
      <Box
        spacing={{ top: 2, bottom: 2 }}
        className={styles.formCancelHeadingContainer}
      >
        <HStack alignment="center">
          <Spacer flex />
          <Button
            iconAfter="close"
            onClick={onCancelSplit}
            size="small"
            outline
            intent="destructive"
            label={{
              key: 'paddocks.paddock.move_mob.form.mob_split.cancel_split.label',
            }}
          />
        </HStack>
      </Box>
      <Box className={styles.formContainer}>
        <HStack alignment="top">
          <Box className={styles.column}>
            <Text
              variant="small"
              color={Color.DarkGrey}
              bold
              i18n={{
                key: 'paddocks.paddock.move_mob.form.mob_split.remaining.title',
              }}
            />
            <MobSplitColumn
              propertyTypes={propertyTypes}
              onCreateCustomField={onCreateCustomField}
              mob={mob}
              value={{
                classes: value.remainingClasses,
                ages: value.remainingAges,
                gender: value.remainingGender,
                size: mob.size - (value.newSize || 0),
              }}
              remainingSize={mob.size - (value.newSize || 0)}
              onChange={(val) => {
                onChange({
                  ...value,
                  remainingClasses: val.classes,
                  remainingAges: val.ages,
                  remainingGender: val.gender,
                });
              }}
            />
          </Box>
          <Spacer x={2} />
          <Box className={styles.column}>
            <Text
              variant="small"
              color={Color.DarkGrey}
              bold
              i18n={{
                key: 'paddocks.paddock.move_mob.form.mob_split.new.title',
              }}
            />
            <MobSplitColumn
              propertyTypes={propertyTypes}
              onCreateCustomField={onCreateCustomField}
              mob={mob}
              value={{
                classes: value.newClasses,
                ages: value.newAges,
                gender: value.newGender,
                size: value.newSize,
              }}
              onChange={(val) => {
                onChange({
                  ...value,
                  newClasses: val.classes,
                  newAges: val.ages,
                  newGender: val.gender,
                  newSize: val.size,
                });
              }}
            />
          </Box>
        </HStack>
      </Box>
    </Box>
  );
};
