import React from 'react';
import { InventoryItemChemical } from '@mobble/models/src/model/InventoryItem';
import {
  useInventories,
  useInventory,
} from '@mobble/store/src/hooks/inventories';
import { useInventoryItem } from '@mobble/store/src/hooks/inventoryItems';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';
import { type ScreenRendererProps } from '../config/types';
import * as ROUTE_NAME from '../config/routeNames';
import { toPath } from '@src/interfaces/Routing';
import { useNavigateBack } from '@src/hooks/useNavigateBack';
import { InventoryItemBatchEditForm } from '@src/stories/Views/InventoryItem/InventoryItemBatchEditForm';
import { useInventoryItemBatch } from '@mobble/store/src/hooks/inventoryItemBatches';
import { InventoryItemBatch } from '@mobble/models/src/model/InventoryItemBatch';
import { DeleteForm } from '@src/stories/Views/Form/DeleteForm/DeleteForm';
import { useExtStatus } from '@mobble/store/src/hooks/root';
import { InventoryItemBatchStatusForm } from '@src/stories/Views/InventoryItem/InventoryItemBatchStatusForm';
import styles from './inventoryItemBatchEdit.scss';
import { Box } from '@src/stories/Components/Layout/Box';
import { parseInventoryId } from '@mobble/models/src/model/Inventory';
import { useLinking } from '@src/hooks/useLinking';
import { useI18n } from '@mobble/i18n/src';
import { EntitySliceFactoryPrelude } from '@src/stories/Views/Misc/EntitySliceFactoryPrelude';
import { useGetBatchName } from './hooks/useBatchName';

export const InventoryItemEditHeader: React.FC<ScreenRendererProps> = (
  props
) => {
  const { formatMessage } = useI18n();
  const goBack = useNavigateBack();
  const {
    inventoryId = '',
    inventoryItemId = '',
    inventoryItemBatchId = '',
  } = props.route.params;
  const inventory = useInventory(inventoryId);
  const inventoryItem = useInventoryItem(inventoryItemId, inventoryId);

  const { propertyId } = parseInventoryId(inventoryId);

  const inventoryItemBatch = useInventoryItemBatch(
    inventoryItemBatchId,
    inventoryItemId,
    propertyId
  );

  const title = useGetBatchName(inventoryItemBatch.entity);

  if (!inventoryItemBatch?.entity) {
    return null;
  }

  return (
    <ScreenHeader
      title={formatMessage(
        {
          description: 'screen.title.edit_x',
          defaultMessage: 'Edit "{TITLE}"',
        },
        {
          TITLE: title,
        }
      )}
      breadcrumbs={[
        {
          title: formatMessage({
            description: 'screen.title.inventories',
            defaultMessage: 'Inventories',
          }),
          href: ROUTE_NAME.INVENTORIES_LIST,
        },
        {
          title: inventory.entity?.name,
          href: toPath(ROUTE_NAME.INVENTORY_ITEMS, {
            inventoryId,
          }),
        },
        {
          title: inventoryItem.entity?.name,
          href: toPath(ROUTE_NAME.INVENTORY_ITEM_DETAIL, {
            inventoryId,
            inventoryItemId,
          }),
        },
      ]}
      onGoBack={goBack}
    />
  );
};

export const InventoryItemEdit: React.FC<ScreenRendererProps> = (props) => {
  const linkTo = useLinking();
  const goBack = useNavigateBack();
  const { translate } = useI18n();
  const {
    inventoryId = '',
    inventoryItemId = '',
    inventoryItemBatchId = '',
  } = props.route.params;
  const { propertyId } = parseInventoryId(inventoryId);
  const inventories = useInventories(propertyId);
  const inventoryItem = useInventoryItem(inventoryItemId, inventoryId);

  const inventoryItemBatch = useInventoryItemBatch(
    inventoryItemBatchId,
    inventoryItemId,
    propertyId
  );

  const title = useGetBatchName(inventoryItemBatch.entity);

  const extStatus = useExtStatus(
    'inventoryItemBatches',
    'update',
    inventoryItemBatchId
  );
  const extStatusDelete = useExtStatus(
    'inventoryItemBatches',
    'delete',
    inventoryItemBatchId
  );

  const formLoading = extStatus?.loading;
  const deleteLoading = extStatusDelete?.loading;

  const formError =
    extStatus?.error || extStatusDelete?.error
      ? translate({
          key: 'generic.form.error',
          params: {
            '%ENTITY': translate({
              key: 'screen.title.edit_x',
              params: {
                '%TITLE': title || '',
              },
            }),
          },
        })
      : '';

  const handleSubmit = (updatedInventoryItem: InventoryItemBatch) => {
    inventoryItemBatch.update(updatedInventoryItem).then(() => {
      inventories.refresh();
      goBack();
    });
  };

  const handleChangedStatus = (updatedInventoryItem: InventoryItemBatch) =>
    inventoryItemBatch.update(updatedInventoryItem);

  const handleDelete = () => {
    inventoryItemBatch.remove().then(() => {
      linkTo(ROUTE_NAME.INVENTORY_ITEM_DETAIL, {
        inventoryId,
        inventoryItemId,
      });
    });
  };

  const Prelude = EntitySliceFactoryPrelude({
    preludes: [inventoryItemBatch.prelude, inventoryItem.prelude],
    required: [inventoryItemBatch.entity, inventoryItem.entity],
  });

  if (Prelude) {
    return Prelude;
  }

  return (
    <>
      <InventoryItemBatchEditForm
        inventoryItemBatch={inventoryItemBatch.entity}
        inventoryItem={inventoryItem.entity as InventoryItemChemical}
        loading={formLoading}
        error={formError}
        onCancel={goBack}
        onTouched={() => {}}
        onSubmit={handleSubmit}
      />

      <Box className={styles.inventoryItemStatusForm}>
        <InventoryItemBatchStatusForm
          inventoryItemBatch={inventoryItemBatch.entity}
          loading={formLoading}
          onSubmit={handleChangedStatus}
        />
      </Box>
      <DeleteForm
        i18nRootKey="inventories.inventory-item-batch.delete"
        loading={deleteLoading}
        onDelete={handleDelete}
      />
    </>
  );
};

export default {
  name: ROUTE_NAME.INVENTORY_ITEM_BATCH_EDIT,
  header: InventoryItemEditHeader,
  component: InventoryItemEdit,
};
