import React, { HTMLAttributes } from 'react';
import { Color, colorToHex } from '@mobble/colors';
import {
  type Spacing,
  type Cardinal,
  classNamePadding,
  classNameWidth,
  classNameHeight,
} from './Spacing';
import { concatStyles, Styles } from './Styles';
import styles from './box.scss';

type PickedAttributes = Partial<
  Pick<
    HTMLAttributes<HTMLDivElement>,
    'id' | 'aria-label' | 'children' | 'role'
  >
>;

export interface BoxProps extends PickedAttributes {
  background?: Color;
  className?: string | string[];
  fill?: boolean;
  flex?: boolean;
  height?: Spacing;
  spacing?: Spacing | Cardinal<Spacing>;
  mobileSpacing?: Spacing | Cardinal<Spacing>;
  desktopSpacing?: Spacing | Cardinal<Spacing>;
  style?: Styles;
  width?: Spacing;
  scroll?: boolean;
}

export const Box: React.FC<BoxProps> = ({
  background,
  children,
  className,
  fill = false,
  flex = false,
  height,
  spacing,
  mobileSpacing,
  desktopSpacing,
  style,
  width,
  scroll,
  ...others
}) => {
  const classNames = [
    classNamePadding(spacing),
    classNamePadding(mobileSpacing, 'mobile'),
    classNamePadding(desktopSpacing, 'desktop'),

    classNameWidth(width),
    classNameHeight(height),
    //
    flex && styles.flex,
    fill && styles.fill,
    scroll && styles.scroll,
    //
    Array.isArray(className) ? className.join(' ') : className,
  ]
    .filter(Boolean)
    .join(' ');

  const styleProp = concatStyles([
    style,
    //
    background ? { backgroundColor: colorToHex(background) } : {},
  ]);

  return (
    <div className={classNames} style={styleProp} {...others}>
      {children}
    </div>
  );
};
