import React from 'react';
import { defineMessages } from 'react-intl';
import { useI18n, useMessages } from '@mobble/i18n';
import { type User } from '@mobble/models';
import { Box } from '@src/stories/Components/Layout/Box';
import { Spacer } from '@src/stories/Components/Layout/Spacer';
import { HStack } from '@src/stories/Components/Layout/Stack';
import { Avatar } from '@src/stories/Components/UI/Avatar';
import { InfoRow, type InfoRowProps } from '@src/stories/Components/UI/InfoRow';

import styles from './settingsPropertyUserInfoTable.scss';

export interface SettingsPropertyUserInfoTableProps {
  user: User;
}

const messages = defineMessages({
  email: {
    id: 'settings.property.users.user.info.email.label',
    defaultMessage: 'Email',
  },
  name: {
    id: 'settings.property.users.user.info.name.label',
    defaultMessage: 'Name',
  },
  role: {
    id: 'settings.property.users.user.info.role.label',
    defaultMessage: 'Role',
  },
});

export const SettingsPropertyUserInfoTable: React.FC<
  SettingsPropertyUserInfoTableProps
> = ({ user }) => {
  const { translate } = useI18n();
  const strings = useMessages(messages);

  const userRole =
    (user.role && translate({ key: `user_role.${user.role}` })) ?? undefined;

  const rows: InfoRowProps[] = [
    {
      // @ts-ignore
      label: strings.name,
      value: user.name,
    },
    {
      // @ts-ignore
      label: strings.email,
      value: user.email,
    },
    {
      // @ts-ignore
      label: strings.role,
      value: userRole,
    },
  ];

  return (
    <Box className={styles.settingsPropertyUserInfoTable}>
      <Box>
        <HStack>
          <Spacer flex />
          <Avatar name={user.name} />
          <Spacer flex />
        </HStack>
      </Box>
      {rows.map((row) => (
        <InfoRow key={row.label} {...row} />
      ))}
    </Box>
  );
};
