import { useState } from 'react';
import { useI18n } from '@mobble/i18n';
import { FilterItem } from '@mobble/models';
import {
  allMobsCanMerge,
  filterMobs,
  findMobs,
  Mob,
} from '@mobble/models/src/model/Mob';
import { Paddock } from '@mobble/models/src/model/Paddock';
import {
  getPaddocksInSamePaddockGroupAsPaddock,
  type PaddockGroup,
} from '@mobble/models/src/model/PaddockGroup';
import { base64encode } from '@mobble/shared/src/core/String';
import {
  useMobs,
  usePaddock,
  usePaddocks,
  usePaddockGroups,
  useProperties,
} from '@mobble/store/src/hooks';

import { useLinking } from '@src/hooks/useLinking';
import * as ROUTE_NAME from '@src/screens/config/routeNames';

import { Checkbox } from '@src/stories/Components/UI/Checkbox';
import { Toggle } from '@src/stories/Components/UI/Toggle';
import { Text } from '@src/stories/Components/UI/Text';
import { Clickable } from '@src/stories/Components/UX/Clickable';
import { ModalFlyUp } from '@src/stories/Components/UX/ModalFlyUp';
import { Spinner } from '@src/stories/Components/UI/Spinner';
import { Button } from '@src/stories/Components/UX/Button';
import { MobCard } from '@src/stories/Views/Mob/MobCard';

import styles from './mobsSelectListModal.scss';

export interface MobsSelectProps {
  title?: string;
  paddockId: Paddock['id'];
  mobsFilter: FilterItem[];
  selectedMobs?: Mob['id'][];
  onSelectMob: (mobId: Mob['id'][]) => void;
  onClickMob?: (mob: Mob) => void;
  onClose?: () => void;
}

const getMobsOnPaddockGroup =
  (mobs: Mob[], paddocks: Paddock[], paddockGroups: PaddockGroup[]) =>
  (paddock: Paddock): [Mob[], Mob[]] => {
    const mobIdsOnPaddock = paddock.mobs;

    const mobIdsOnPaddockGroup = getPaddocksInSamePaddockGroupAsPaddock(
      paddockGroups,
      paddocks
    )(paddock.id)
      .flatMap((p) => p.mobs)
      .filter((mobId) => !mobIdsOnPaddock.includes(mobId));

    const mobIdToMob = (mobId: string): null | Mob =>
      mobs.find((m) => m.id === mobId);

    const mobIdsToMobs = (mobIds: string[]): Mob[] => {
      return mobIds.map(mobIdToMob).filter(Boolean);
    };

    return [mobIdsToMobs(mobIdsOnPaddock), mobIdsToMobs(mobIdsOnPaddockGroup)];
  };

export const MobsSelectListModal = ({
  title,
  paddockId,
  mobsFilter,
  selectedMobs,
  onSelectMob,
  onClickMob,
  onClose,
}: MobsSelectProps) => {
  const { formatMessage } = useI18n();
  const properties = useProperties();
  const paddock = usePaddock(paddockId);
  const propertyId = paddock.entity?.propertyId;
  const property = properties.entities.find((p) => p.id === propertyId);
  const paddocks = usePaddocks(propertyId);
  const paddockGroups = usePaddockGroups(propertyId);
  const mobs = useMobs(propertyId);
  const linkTo = useLinking();
  const [localSelectedMobs, setLocalSelectedMobs] = useState<Mob['id'][]>(
    selectedMobs ?? []
  );
  const [filtersEnabled, setFiltersEnabled] = useState(mobsFilter.length > 0);

  if (!paddock.entity) {
    return <Spinner />;
  }

  const [allMobsOnPaddock, allMobsOnPaddockGroup] = getMobsOnPaddockGroup(
    mobs.entities,
    paddocks.entities,
    paddockGroups.entities
  )(paddock.entity);
  const [filteredMobsOnPaddock, filteredMobsOnPaddockGroup] =
    getMobsOnPaddockGroup(
      filterMobs(paddocks.entities)(allMobsOnPaddock, mobsFilter),
      paddocks.entities,
      paddockGroups.entities
    )(paddock.entity);

  const mobsOnPaddock = filtersEnabled
    ? filteredMobsOnPaddock
    : allMobsOnPaddock;
  const mobsOnPaddockGroup = filtersEnabled
    ? filteredMobsOnPaddockGroup
    : allMobsOnPaddockGroup;

  const handleToggleFilters = () => {
    setFiltersEnabled(!filtersEnabled);
  };

  const handleToggleSelection = (mobId: Mob['id']) => {
    const newSelectedMobs = localSelectedMobs.includes(mobId)
      ? localSelectedMobs.filter((id) => id !== mobId)
      : [...localSelectedMobs, mobId];
    setLocalSelectedMobs(newSelectedMobs);
    onSelectMob(newSelectedMobs);
  };

  const renderItem = (mob: Mob) => {
    return (
      <li key={mob.id} className={styles.itemContainer}>
        <Clickable href={() => handleToggleSelection(mob.id)}>
          <Checkbox selected={localSelectedMobs.includes(mob.id)} />
        </Clickable>
        <MobCard
          paddock={paddock.entity}
          propertyTypes={property.types ?? []}
          href={() => onClickMob(mob)}
          mob={mob}
        />
      </li>
    );
  };

  const buttons = [
    {
      label: formatMessage(
        {
          defaultMessage: '{count, plural, one {Move mob} other {Move mobs}}',
        },
        {
          count: selectedMobs.length,
        }
      ),
      onClick: () => {
        linkTo(ROUTE_NAME.MODAL_MOB_MOVE, {
          paddockId,
          mobIds: base64encode(JSON.stringify(localSelectedMobs)),
        });
      },
    },
    {
      label: formatMessage(
        {
          defaultMessage: '{count, plural, one {Merge mob} other {Merge mobs}}',
        },
        {
          count: selectedMobs.length,
        }
      ),
      disabled: !allMobsCanMerge(findMobs(mobs.entities)(localSelectedMobs)),
      onClick: () => {
        linkTo(ROUTE_NAME.MODAL_MOB_MERGE, {
          paddockId,
          mobIds: base64encode(JSON.stringify(localSelectedMobs)),
        });
      },
    },
    {
      label: formatMessage({
        defaultMessage: 'Apply action',
      }),
      onClick: () => {
        linkTo(ROUTE_NAME.MODAL_MOB_ACTION_CREATE, {
          paddockId,
          mobIds: base64encode(JSON.stringify(localSelectedMobs)),
        });
      },
    },
  ];

  return (
    <ModalFlyUp
      isOpen={true}
      onClose={onClose}
      title={title}
      footer={
        <div
          className={[
            styles.footer,
            localSelectedMobs.length > 0 ? styles.active : null,
          ]
            .filter(Boolean)
            .join(' ')}
        >
          {buttons.map((b, i) => (
            <Button
              key={i}
              flex
              size="small"
              outline
              disabled={b.disabled ?? false}
              label={b.label}
              onClick={b.onClick}
            />
          ))}
        </div>
      }
    >
      <div className={styles.container}>
        {allMobsOnPaddock.length > filteredMobsOnPaddock.length && (
          <div className={styles.filterContainer}>
            {filtersEnabled && (
              <Text tagName="p">
                {formatMessage(
                  {
                    description: 'Mobs Select List filtered mobs description',
                    defaultMessage:
                      '{count, plural, one {# mob is} other {# mobs are}} hidden by filters',
                  },
                  {
                    count:
                      allMobsOnPaddock.length - filteredMobsOnPaddock.length,
                  }
                )}
              </Text>
            )}
            {!filtersEnabled && (
              <Text tagName="p">
                {formatMessage(
                  {
                    description: 'Mobs Select List mobs in paddock description',
                    defaultMessage:
                      '{count, plural, one {# mob} other {# mobs}} in paddock',
                  },
                  {
                    count: allMobsOnPaddock.length,
                  }
                )}
              </Text>
            )}

            <Toggle
              id="mobs-select-list-filter-toggle"
              aria-label={formatMessage({
                description: 'Mobs select list Show All toggle label',
                defaultMessage: 'Mobs filter toggle',
              })}
              checked={filtersEnabled}
              onChange={handleToggleFilters}
            />
          </div>
        )}
        {mobsOnPaddock.length === 0 && (
          <div className={styles.mobsEmpty}>
            <Text variant="body" tagName="h2" bold>
              {formatMessage({
                defaultMessage:
                  'There are currently no mobs visible on this paddock',
                description: 'Mobs select list empty messag',
              })}
            </Text>
          </div>
        )}

        {mobsOnPaddock.length > 0 && <ul>{mobsOnPaddock.map(renderItem)}</ul>}

        {mobsOnPaddockGroup.length > 0 && (
          <div className={styles.mobsOnPaddockGroup}>
            <Text variant="body" tagName="h2" bold>
              {formatMessage({
                defaultMessage: 'Mobs from paddocks with open gate',
                description: 'mobs.list.result.paddock_group.header',
              })}
            </Text>

            <ul>
              <li>{mobsOnPaddockGroup.map(renderItem)}</li>
            </ul>
          </div>
        )}
      </div>
    </ModalFlyUp>
  );
};
