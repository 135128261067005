import React from 'react';
import styles from './formWizardProgress.scss';

export interface FormWizardProgressProps {
  progress: [number, number];
}

export const FormWizardProgress: React.FC<FormWizardProgressProps> = ({
  progress,
}) => {
  const progressPercentage = (progress[0] / progress[1]) * 100;

  return (
    <div className={styles.formWizardProgress}>
      <progress max={100} value={progressPercentage} />
    </div>
  );
};
