import React from 'react';
import { defineMessages } from 'react-intl';
import { useMessages, useI18n } from '@mobble/i18n';
import { Color } from '@mobble/colors';
import { type Paddock, findPaddock } from '@mobble/models/src/model/Paddock';
import {
  findInventoryItem,
  type InventoryItem,
} from '@mobble/models/src/model/InventoryItem';
import { type PaddockAction } from '@mobble/models/src/model/PaddockAction';
import { AppliedInventoryItem } from '@mobble/models/src/model/AppliedInventoryItem';
import { InventoryCategory } from '@mobble/models/src/model/Inventory';
import { formatDate } from '@mobble/shared/src/core/Date';
import { reactIntersperse } from '@src/interfaces/React';
import { Box } from '@src/stories/Components/Layout/Box';
import { type InfoRowProps, InfoRow } from '@src/stories/Components/UI/InfoRow';
import { HStack } from '@src/stories/Components/Layout/Stack';
import { useSetting } from '@mobble/store/src/hooks/settings';
import { Icon } from '@src/stories/Components/UI/Icon';
import { Text } from '@src/stories/Components/UI/Text';
import { Spacer } from '@src/stories/Components/Layout/Spacer';
import { InventoryItemCard } from '../InventoryItem/InventoryItemCard';
import { Spinner } from '@src/stories/Components/UI/Spinner';
import { makeQuantityFormatMessageItem } from '@src/stories/Components/Locale/LocaleQuantity';
import { Clickable } from '@src/stories/Components/UX/Clickable';
import styles from './paddockActionInfoTable.scss';
import {
  InventoryItemBatch,
  InventoryItemBatchChemical,
  InventoryItemBatchFeed,
  getInventoryItemBatch,
} from '@mobble/models/src/model/InventoryItemBatch';

import * as ROUTE_NAME from '@src/screens/config/routeNames';
import { toPath } from '@src/interfaces/Routing';

import { makeQuantity } from '@mobble/shared/src/core/Quantity';

export interface PaddockActionInfoTableProps {
  paddockAction: PaddockAction;
  inventoryItems: InventoryItem[];
  inventoryItemBatches: InventoryItemBatch[];
  paddocks: Paddock[];
  totalArea?: number;
  onOpenPaddock: (paddockId: Paddock['id']) => void;
  onOpenInventoryItem: (inventoryItem: InventoryItem) => void;
}

const messages = defineMessages({
  applied_inventory_items: {
    id: 'paddock_action.info.table.row.applied_inventory_items.label',
    defaultMessage: 'Product applications',
  },
  created_by: {
    id: 'paddock_action.info.table.row.created_by.label',
    defaultMessage: 'Recorded by',
  },
  date: {
    id: 'paddock_action.info.table.row.date.label',
    defaultMessage: 'Date',
  },
  description: {
    id: 'paddock_action.info.table.row.description.label',
    defaultMessage: 'Description',
  },
  esi: {
    id: 'paddock_action.info.table.row.esi.label',
    defaultMessage: 'ESI',
  },
  batch: {
    id: 'paddock_action.info.table.row.batch.label',
    defaultMessage: 'Batch',
  },
  supplierName: {
    id: 'paddock_action.info.table.row.supplierName.label',
    defaultMessage: 'Supplier',
  },
  note: {
    id: 'paddock_action.info.table.row.note.label',
    defaultMessage: 'Note',
  },
  paddocks: {
    id: 'paddock_action.info.table.row.paddocks.label',
    defaultMessage: 'Applied to paddocks',
  },
  quantity: {
    id: 'paddock_action.info.table.row.quantity.label',
    defaultMessage: 'Quantity/Ha used',
  },
  quantityTotal: {
    id: 'paddock_action.info.table.row.quantityTotal.label',
    defaultMessage: 'Total Quantity used',
  },
  type: {
    id: 'paddock_action.info.table.row.type.label',
    defaultMessage: 'Action type',
  },
  whp: {
    id: 'paddock_action.info.table.row.whp.label',
    defaultMessage: 'WHP',
  },
  woolWhp: {
    id: 'paddock_action.info.table.row.woolWhp.label',
    defaultMessage: 'Wool WHP',
  },
});

export const PaddockActionInfoTable: React.FC<PaddockActionInfoTableProps> = ({
  paddockAction,
  paddocks,
  totalArea,
  inventoryItems,
  inventoryItemBatches,
  onOpenPaddock,
  onOpenInventoryItem,
}) => {
  const strings = useMessages(messages);
  const { formatMessage } = useI18n();
  const dateFormat = useSetting('dateFormat') as string;

  const findBatch = getInventoryItemBatch(inventoryItemBatches);
  const appliedToPaddocks = paddockAction.paddocks.map(
    (a) =>
      findPaddock(paddocks)(a.paddockId) ?? { id: a.paddockId, name: a.name }
  );
  const renderInventoryCard = (appliedInventoryItem: AppliedInventoryItem) => {
    let inventoryItem = findInventoryItem(inventoryItems)(
      appliedInventoryItem.inventoryItemId
    );

    // Fallback to nested chemical/feed data
    if (
      !inventoryItem &&
      appliedInventoryItem.category === InventoryCategory.Chemicals
    ) {
      inventoryItem = appliedInventoryItem.chemical as InventoryItem;
    } else if (
      !inventoryItem &&
      appliedInventoryItem.category === InventoryCategory.Feed
    ) {
      inventoryItem = appliedInventoryItem.feed as InventoryItem;
    }

    if (!inventoryItem) {
      return (
        <Box spacing={{ top: 1, bottom: 1 }}>
          <HStack alignment="center">
            <Spinner color={Color.Grey} />
            <Spacer x={1} />
            <Text>
              {appliedInventoryItem.category === InventoryCategory.Chemicals
                ? appliedInventoryItem.name
                : appliedInventoryItem.supplierName}
            </Text>
          </HStack>
        </Box>
      );
    }
    return (
      <InventoryItemCard
        onClick={onOpenInventoryItem}
        href={toPath(ROUTE_NAME.INVENTORY_ITEM_DETAIL, {
          inventoryId: inventoryItem.inventoryId,
          inventoryItemId: inventoryItem.id,
        })}
        inventoryItem={inventoryItem}
      />
    );
  };

  const rows: (InfoRowProps | InfoRowProps[])[] = [
    {
      label: strings.description,
      value: paddockAction.description,
    },
    {
      label: strings.type,
      value: paddockAction.type,
    },
    {
      label: strings.created_by,
      value: paddockAction.createdBy.name,
    },
    {
      label: strings.date,
      value: formatDate(paddockAction.date, dateFormat),
    },
    {
      label: strings.applied_inventory_items,
      value: () => (
        <Box className={styles.appliedInventoryItems}>
          {!paddockAction.appliedInventoryItems.length ? <Text>-</Text> : null}
          {paddockAction.appliedInventoryItems.map((a) => {
            return (
              <Box key={a.id} className={styles.appliedInventoryItem}>
                {renderInventoryCard(a)}
                <Box spacing={{ top: 2, right: 2, left: 0, bottom: 2 }}>
                  <HStack>
                    <InfoRow
                      label={strings.quantity}
                      value={formatMessage(
                        ...makeQuantityFormatMessageItem(a.quantity)
                      )}
                      flex
                    />

                    <InfoRow
                      label={strings.quantityTotal}
                      value={formatMessage(
                        ...makeQuantityFormatMessageItem(
                          makeQuantity(
                            a.quantity.type,
                            a.quantity.unit,
                            a.quantity.value * totalArea || 1
                          )
                        )
                      )}
                      flex
                    />
                  </HStack>
                  {a.category === InventoryCategory.Chemicals ? (
                    <HStack>
                      <InfoRow label={strings.whp} value={a.whp || '-'} flex />
                      <InfoRow label={strings.esi} value={a.esi || '-'} flex />
                    </HStack>
                  ) : null}

                  {a.category === InventoryCategory.Chemicals ? (
                    <InfoRow
                      label={strings.batch}
                      value={
                        (findBatch(a?.batchId) as InventoryItemBatchChemical)
                          ?.name || '-'
                      }
                      flex
                    />
                  ) : (
                    <InfoRow
                      label={strings.supplierName}
                      value={
                        (findBatch(a?.batchId) as InventoryItemBatchFeed)
                          ?.supplierName || '-'
                      }
                      flex
                    />
                  )}
                </Box>
              </Box>
            );
          })}
        </Box>
      ),
    },
    {
      label: strings.paddocks,
      childAfter: (
        <HStack alignment="center">
          <Spacer x={1} />
          <Icon size="small" name="link" color={Color.Grey} />
        </HStack>
      ),
      value: () => (
        <Box>
          {reactIntersperse(
            <Text>{', '}</Text>,
            appliedToPaddocks.map((p) => (
              <Clickable
                key={p.id}
                href={() => {
                  onOpenPaddock(p.id);
                }}
              >
                <Text variant="normal" bold>
                  {p.name}
                </Text>
              </Clickable>
            ))
          )}
        </Box>
      ),
    },
    {
      label: strings.note,
      value: paddockAction.note,
    },
  ];

  return (
    <Box className={styles.paddockActionInfoTable}>
      {rows.map((row) =>
        Array.isArray(row) ? (
          <>
            {row.map((r) => (
              <InfoRow {...r} key={r.label as string} />
            ))}
          </>
        ) : (
          <InfoRow key={row.label} {...row} />
        )
      )}
    </Box>
  );
};
