import React from 'react';
import { useNavigate } from 'react-router';
import { useI18n } from '@mobble/i18n';
import {
  NaturalIncrease,
  filterNaturalIncreases,
} from '@mobble/models/src/model/NaturalIncrease';
import { useEntitiesRefresher } from '@mobble/shared/src/hooks/useEntitiesRefresher';
import {
  useMobs,
  useNaturalIncreases,
  usePaddocks,
  useProperties,
} from '@mobble/store/src/hooks';

import { useNaturalIncreasesFilterItems } from '@src/hooks/useNaturalIncreasesFilterItems';
import { useLinking } from '@src/hooks/useLinking';
import { toPath } from '@src/interfaces/Routing';
import { Box } from '@src/stories/Components/Layout/Box';
import {
  EntitiesViewer,
  EntitiesViewerProps,
} from '@src/stories/Views/Entities/EntitiesViewer';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';
import { makeNaturalIncreasesTableColumns } from '@src/stories/Views/NaturalIncrease/List/naturalIncreasesTableColumns';
import { MemoisedNaturalIncreaseCard } from '@src/stories/Views/NaturalIncrease/NaturalIncreaseCard';
import { EntitySliceFactoryPrelude } from '@src/stories/Views/Misc/EntitySliceFactoryPrelude';

import * as ROUTE_NAME from '../config/routeNames';
import { ScreenRendererProps } from '../config/types';

export const SummaryNaturalIncreasesHeader: React.FC<
  ScreenRendererProps
> = () => {
  const { formatMessage } = useI18n();
  const linkTo = useLinking();

  return (
    <ScreenHeader
      title={formatMessage({
        defaultMessage: 'Natural Increases',
        description: 'screen.title.summary_natural_increases',
      })}
      onGoBack={() => {
        linkTo(ROUTE_NAME.SUMMARIES_LIST);
      }}
      breadcrumbs={[
        {
          title: formatMessage({
            defaultMessage: 'Summaries',
            description: 'screen.title.summaries',
          }),
          href: ROUTE_NAME.SUMMARIES_LIST,
        },
      ]}
    />
  );
};

export const SummaryNaturalIncreases: React.FC<ScreenRendererProps> = () => {
  const { formatMessage } = useI18n();
  const navigate = useNavigate();
  const properties = useProperties();
  const propertyId = properties.selected?.id;
  const naturalIncreases = useNaturalIncreases(propertyId);
  const paddocks = usePaddocks(propertyId);
  const mobs = useMobs(propertyId);
  const filterItems = useNaturalIncreasesFilterItems();

  const { refresh } = useEntitiesRefresher(
    [mobs, naturalIncreases, paddocks],
    propertyId
  );

  const Prelude = EntitySliceFactoryPrelude({
    preludes: [
      properties.prelude,
      mobs.prelude,
      naturalIncreases.prelude,
      paddocks.prelude,
    ],
    required: [properties.selected],
  });

  if (Prelude) {
    return Prelude;
  }

  const onClick = (naturalIncrease: NaturalIncrease) => {
    navigate(
      toPath(ROUTE_NAME.NATURAL_INCREASE_DETAIL, {
        naturalIncreaseId: naturalIncrease.id,
      })
    );
  };

  const viewerProps: EntitiesViewerProps<NaturalIncrease> = {
    provider: naturalIncreases,
    onEmpty: formatMessage({
      defaultMessage: 'There are no Natural Increases to display',
      description: 'natural_increases.table.empty',
    }),
    onRefresh: refresh,
    applyFilter: filterNaturalIncreases,
    filterItems,
    onClickTableRow: onClick,
    renderEntityCard: (naturalIncrease) => {
      return (
        <Box spacing={1}>
          <MemoisedNaturalIncreaseCard
            naturalIncrease={naturalIncrease}
            onClick={() => onClick(naturalIncrease)}
          />
        </Box>
      );
    },
    tableColumns: makeNaturalIncreasesTableColumns({
      propertyTypes: properties.selected.types,
    }),
  };

  return (
    <EntitiesViewer
      title={formatMessage({
        defaultMessage: 'Natural Increases',
        description: 'screen.title.summary_natural_increases',
      })}
      {...viewerProps}
    />
  );
};

export default {
  name: ROUTE_NAME.SUMMARY_NATURAL_INCREASES_LIST,
  header: SummaryNaturalIncreasesHeader,
  component: SummaryNaturalIncreases,
};
