import React from 'react';
import { useNavigate } from 'react-router';
import { useI18n } from '@mobble/i18n';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';
import { useProperties } from '@mobble/store/src/hooks/properties';
import * as ROUTE_NAME from '../config/routeNames';
import { EntitySliceFactoryPrelude } from '../../stories/Views/Misc/EntitySliceFactoryPrelude';
import { ScreenRendererProps } from '../config/types';
import {
  PropertyCreateForm,
  PropertyCreateFormValues,
} from '@src/stories/Views/Property/PropertyCreateForm';
import { useNavigateBack } from '@src/hooks/useNavigateBack';

export const PropertyCreateHeader: React.FC<ScreenRendererProps> = () => {
  const { formatMessage } = useI18n();
  const navigate = useNavigate();

  return (
    <ScreenHeader
      modal
      onClose={() => {
        navigate(ROUTE_NAME.PADDOCKS_LIST);
      }}
      title={formatMessage({
        description: 'screen.title.property_create',
        defaultMessage: 'Create new property',
      })}
    />
  );
};

export const PropertyCreate: React.FC<ScreenRendererProps> = () => {
  const { translate } = useI18n();
  const properties = useProperties();
  const navigate = useNavigate();
  const goBack = useNavigateBack();
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const prelude = EntitySliceFactoryPrelude({
    preludes: [properties.prelude],
    required: [properties.selected],
  });

  if (prelude) {
    return prelude;
  }

  const handleSubmit = (formValues: PropertyCreateFormValues) => {
    setLoading(true);

    properties
      .create(formValues)
      .then((newProperty) => {
        console.log(`Property '${newProperty.name}' successfully created`);
        properties.selectProperty(newProperty.id);
        navigate(ROUTE_NAME.DASHBOARD);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);

        setError(
          translate({
            key: 'generic.form.error',
            params: {
              '%ENTITY': translate({ key: 'settings.toc.property' }),
            },
          })
        );
      });
  };

  return (
    <PropertyCreateForm
      error={error}
      loading={loading}
      onCancel={goBack}
      onSubmit={handleSubmit}
    />
  );
};

export default {
  name: ROUTE_NAME.MODAL_PROPERTY_CREATE,
  header: PropertyCreateHeader,
  component: PropertyCreate,
};
