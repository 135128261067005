import React, { useState } from 'react';
import { useI18n, type I18nItem } from '@mobble/i18n';
import { paddockForMob } from '@mobble/models/src/model/Paddock';
import {
  Mob,
  availableSortOptions,
  filterMobs,
  findMobs,
  getTotalHead,
  sortMobs,
} from '@mobble/models/src/model/Mob';
import { useEntitiesRefresher } from '@mobble/shared/src/hooks/useEntitiesRefresher';
import { useMobsFilterItems } from '@mobble/shared/src/hooks/useMobsFilterItems';
import { useMobs, usePaddocks, useProperties } from '@mobble/store/src/hooks';

import { ActionSheetOnMobSelect } from '@src/screens/Mobs/ActionSheetOnMobSelect';
import { Box } from '@src/stories/Components/Layout/Box';
import { Text } from '@src/stories/Components/UI/Text';
import {
  EntitiesViewer,
  type EntitiesViewerProps,
} from '@src/stories/Views/Entities/EntitiesViewer';
import { makeMobsTableColumns } from '@src/stories/Views/Mob/List/mobsTableColumns';
import { MobCard } from '@src/stories/Views/Mob/MobCard';
import { type Provider } from '@src/stories/Views/Entities/types';

import { SelectedMobsOptions } from './SelectedMobsOptions';
import styles from './mobsViewer.scss';

export interface MobsViewerProps {
  title?: I18nItem | string;
  provider: Provider<Mob>;
  showTitle?: boolean;
  renderEntityCard?: (mob: Mob) => React.ReactNode;
}

export const MobsViewer: React.FC<MobsViewerProps> = ({
  title,
  provider,
  showTitle,
  renderEntityCard,
}) => {
  const { translate, formatMessage } = useI18n();
  const properties = useProperties();
  const propertyId = properties.selected?.id;
  const paddocks = usePaddocks(properties.selected?.id);
  const mobs = useMobs(properties.selected?.id);
  const filterItems = useMobsFilterItems(mobs.entities);
  const { refresh } = useEntitiesRefresher([mobs, paddocks], propertyId);

  const [selectedMob, setSelectedMob] = useState<Mob | null>(null);

  const summaryCounter = (entities: Mob[]) =>
    formatMessage(
      {
        description: 'mobs.list.result.total_head',
        defaultMessage: '<b>{TOTAL}</b> head',
      },
      {
        TOTAL: getTotalHead(entities),
      }
    );

  const handleClick = (mob: Mob) => {
    setSelectedMob(mob);
  };

  const withSelection = (selected?: Mob['id'][]) => {
    return (
      <div className={styles.withSelectionContainer}>
        <div className={styles.inner}>
          <Text>
            {formatMessage(
              {
                defaultMessage:
                  'With selected {count, plural, one {mob} other {{count} mobs}}',
                description: 'Perform an action with selected mobs',
              },
              { count: selected.length }
            )}
          </Text>
          <SelectedMobsOptions selected={findMobs(mobs.entities)(selected)} />
        </div>
      </div>
    );
  };

  const baseViewerProps: EntitiesViewerProps<Mob> = {
    provider,
    title,
    showTitle,
    onEmpty: formatMessage({
      defaultMessage: 'There are no mobs to display',
      description: 'mobs.table.empty',
    }),
    onRefresh: undefined,
    summaryCounter,
    onClickTableRow: handleClick,
    renderEntityCard:
      renderEntityCard ??
      ((mob) => (
        <Box spacing={1} flex>
          <MobCard
            href={() => handleClick(mob)}
            propertyTypes={properties.selected?.types ?? []}
            mob={mob}
            paddock={paddockForMob(paddocks.entities)(mob)}
          />
        </Box>
      )),
    tableColumns: makeMobsTableColumns({
      propertyTypes: properties.selected?.types ?? [],
      paddocks: paddocks.entities,
      translate,
    }),
    withSelection,
  };

  const render = (props) => {
    return (
      <>
        <EntitiesViewer key={properties.selected?.id} {...props} />
        {selectedMob ? (
          <>
            <ActionSheetOnMobSelect
              mob={selectedMob}
              onClose={() => setSelectedMob(null)}
            />
          </>
        ) : null}
      </>
    );
  };

  if (Array.isArray(provider)) {
    return render(baseViewerProps);
  }

  const applyFilter = filterMobs(paddocks.entities);
  const applySort = sortMobs({
    paddocks: paddocks.entities,
  });

  const extendedViewerProps: EntitiesViewerProps<Mob> = {
    ...baseViewerProps,
    filterItems,
    applyFilter,
    sortOptions: () => availableSortOptions,
    applySort,
    onRefresh: refresh,
  };

  return render(extendedViewerProps);
};
