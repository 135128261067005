import React, { useState } from 'react';
import Fuse, { type IFuseOptions } from 'fuse.js';
import isEqual from 'lodash/isEqual';

import { Color } from '@mobble/colors';
import { useI18n, type I18nItem } from '@mobble/i18n';
import { usePreferences } from '@mobble/store/src/hooks/preferences';
import { SortOption, SortSetting } from '@mobble/shared/src/core/Sort';

import { reactIntersperse } from '../../../interfaces/React';
import { Box } from '../Layout/Box';
import { Text } from '../UI/Text';
import { HStack } from '../Layout/Stack';
import { Spacer } from '../Layout/Spacer';
import { Icon } from '../UI/Icon';
import { Input } from './Input';
import { InlineOption } from './InlineOption';
import { Button } from './Button';
import { ModalFlyUp } from './ModalFlyUp';
import { Clickable } from './Clickable';
import { CircularButton } from './CircularButton';

import styles from './listSelect.scss';

export interface ListSelectProps {
  name?: string;
  selectionLabel?: I18nItem | string;
  placeholder?: I18nItem | string;
  options: ListSelectOption[];
  sortOptions?: SortOption[];
  sortFunction?: (
    options: ListSelectOption[],
    sortSettings: SortSetting[]
  ) => ListSelectOption[];
  defaultSortSettings?: SortSetting[];
  multiple?: boolean;
  expanded?: boolean;
  showFilter?: boolean;
  fuzyOptions?: IFuseOptions<ListSelectOption>;
  onChange: (values: (string | number)[]) => void;
  onBlur?: () => void;
  addNewPlaceholder?: I18nItem;
  addNewButtonLabel?: I18nItem;
  onAddNew?: (value: string) => Promise<void>;
  allSelectable?: boolean;
  disabled?: boolean;
  bordered?: boolean;

  hideHeader?: boolean;
  menuPlacement?: 'bottom' | 'top';
}

export type LabelLike =
  | number
  | string
  | React.ReactNode
  | JSX.Element
  | I18nItem;

export interface ListSelectOption {
  label: LabelLike;
  value: number | string;
  labelExtra?: LabelLike;
  description?: LabelLike;
  component?: React.ReactNode | JSX.Element;
  type?: 'checkbox';
  color?: string;
  selected?: boolean;
  condensed?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  entity?: any;
}

export const ListSelect: React.FC<ListSelectProps> = ({
  name,
  options,
  sortOptions,
  sortFunction,
  defaultSortSettings,
  selectionLabel,
  placeholder,
  expanded,
  showFilter,
  fuzyOptions,
  multiple,
  onChange,
  onBlur,
  addNewPlaceholder,
  addNewButtonLabel,
  onAddNew,
  allSelectable = true,
  disabled = false,
  bordered,
  hideHeader,
  menuPlacement = 'bottom',
}) => {
  const { translate } = useI18n();
  const preferences = usePreferences();

  const [stateExpanded, setStateExpanded] = useState(expanded);
  const [filterText, setFilterText] = useState('');
  const [showSortOptions, setShowSortOptions] = useState(false);

  const preferredSortSettings = preferences.find(
    `list_select.sort_settings.${name}`
  );

  const [selectedSortSettings, setSelectedSortSettings] = React.useState<
    SortSetting[]
  >(
    (() => {
      if (preferredSortSettings) {
        return JSON.parse(String(preferredSortSettings)) ?? [];
      } else if (defaultSortSettings) {
        return defaultSortSettings;
      } else if (sortOptions) {
        return (sortOptions as any)[0]?.settings ?? [];
      }
      return [];
    })()
  );

  const onSelectSortSettings = (a: SortSetting[]) => {
    preferences.update({
      key: `list_select.sort_settings.${name}`,
      value: JSON.stringify(a),
    });
    setSelectedSortSettings(a);
  };

  const [addNewState, setAddNewState] = React.useState({
    value: '',
    loading: false,
  });

  const onChangeFilterText = (text: string) => {
    setFilterText(text);
  };

  React.useEffect(() => {
    setStateExpanded(expanded);
  }, [expanded]);

  const onToggleExpanded = () => {
    setStateExpanded(!stateExpanded);

    if (onBlur && !stateExpanded) {
      onBlur();
    }
  };

  const sortByLabel = (arr: ListSelectOption[]) => {
    return arr.sort((a, b) => {
      const aLabel = String(a.label ?? a.value);
      const bLabel = String(b.label ?? b.value);

      return aLabel.localeCompare(bLabel);
    });
  };

  const fuse = new Fuse(
    options,
    fuzyOptions ?? {
      keys: [
        { name: 'label', weight: 3 },
        { name: 'description', weight: 1 },
        { name: 'labelExtra', weight: 1 },
      ],
    }
  );

  const processedOptions = filterText
    ? [...fuse.search(filterText).map((a) => a.item)]
    : sortFunction
    ? sortFunction(options, selectedSortSettings)
    : sortByLabel(options);

  const allSelected = processedOptions.every((o) => o.selected);
  const selectedOptions = processedOptions.filter((option) => option.selected);

  const renderOption = (option: ListSelectOption) => {
    const onClick = () => {
      const newOptions = multiple
        ? options.map((o) =>
            o.value === option.value ? { ...o, selected: !o.selected } : o
          )
        : options.map((o) =>
            o.value === option.value
              ? { ...o, selected: true }
              : { ...o, selected: false }
          );
      onChange(newOptions.filter((o) => o.selected).map((o) => o.value));
      if (!multiple) {
        onToggleExpanded();
      }
    };
    return (
      <InlineOption
        key={option.value}
        type={multiple ? 'checkbox' : undefined}
        {...option}
        onClick={onClick}
      />
    );
  };

  const handleAddNewInputKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && addNewState.value) {
      e.preventDefault();
      handleAddNew(addNewState.value);
    }
  };

  const handleAddNew = (value: string) => {
    if (!onAddNew) {
      return;
    }
    setAddNewState({ value, loading: true });
    onAddNew(value).then(() => {
      setAddNewState({ value: '', loading: false });

      if (!multiple) {
        onChange([value]);
        onToggleExpanded();
      } else {
        onChange([...selectedOptions.map((o) => o.value), value]);
      }
    });
  };

  const makeLabel = () => {
    let placeholderText = '';
    if (typeof placeholder === 'string') {
      placeholderText = placeholder;
    } else if (typeof placeholder === 'object') {
      placeholderText = translate(placeholder);
    }

    return (
      selectedOptions
        .map((o) => {
          if (!o.label) {
            return String(o.value);
          } else if (typeof o.label === 'string') {
            return o.label;
          }
          return translate(o.label as I18nItem);
        })
        .join(', ') || placeholderText
    );
  };

  const onSelectAll = () => {
    if (allSelected) {
      onChange([]);
    } else {
      onChange(processedOptions.map((o) => o.value));
    }
  };

  const headerFilterInput = showFilter ? (
    <Box flex className={styles.filterTextWrapper}>
      <Input
        showClear
        onChange={onChangeFilterText}
        value={filterText}
        placeholder={{
          key: 'generic.components.ux.inline_select.filter.placeholder',
        }}
      />
    </Box>
  ) : null;

  const headerSortButton = sortOptions ? (
    <CircularButton
      fill={Color.Green}
      icon="sort-asc"
      onClick={() => {
        setShowSortOptions(true);
      }}
    />
  ) : null;

  const headerItems = [headerFilterInput, headerSortButton].filter(
    (a) => a !== null
  ) as JSX.Element[];

  const additionalHeader =
    headerItems.length > 0 ? (
      <Box spacing={{ top: 1, right: 2, bottom: 1, left: 2 }}>
        <HStack>{reactIntersperse(<Spacer x={2} />, headerItems)}</HStack>
      </Box>
    ) : null;

  const renderSortOption = (item: SortOption) => {
    return (
      <InlineOption
        type="checkbox"
        value={item.name}
        label={{ key: `entities.entities_sort.options.${item.name}.label` }}
        selected={isEqual(item.settings, selectedSortSettings)}
        onClick={() => {
          onSelectSortSettings(item.settings);
        }}
      />
    );
  };

  return (
    <Box className={styles.wrapper}>
      <ListSelectButton
        disabled={disabled}
        stateExpanded={stateExpanded}
        bordered={bordered}
        hasSelectedOptions={selectedOptions.length > 0}
        selectionLabel={selectionLabel}
        options={options}
        makeLabel={makeLabel}
        onToggleExpanded={onToggleExpanded}
      />

      <ModalFlyUp
        actAsDropdown
        isOpen={stateExpanded}
        title={hideHeader ? null : placeholder}
        fullScreen
        onClose={() => {
          setFilterText('');
          setStateExpanded(false);
        }}
        additionalHeader={additionalHeader}
        footer={
          onAddNew ? (
            <Box
              spacing={{ top: 1, right: 2, bottom: 1, left: 2 }}
              className={styles.addNewWrapper}
            >
              <HStack>
                <Input
                  type="text"
                  value={addNewState.value}
                  disabled={addNewState.loading}
                  onChange={(value) =>
                    setAddNewState({ ...addNewState, value })
                  }
                  placeholder={
                    addNewPlaceholder ?? {
                      key: 'generic.input.inline_select.add_new.placeholder.label',
                    }
                  }
                  onKeyUp={handleAddNewInputKeyUp}
                >
                  <Button
                    type="submit"
                    disabled={!addNewState.value}
                    loading={addNewState.loading}
                    label={
                      addNewButtonLabel ?? {
                        key: 'generic.input.inline_select.add_new.button.label',
                      }
                    }
                    onClick={() => handleAddNew(addNewState.value)}
                  />
                </Input>
              </HStack>
            </Box>
          ) : null
        }
        listProps={{
          items: processedOptions,
          renderItem: (item) => {
            return renderOption(item as ListSelectOption);
          },
          ...(allSelectable
            ? {
                ListHeaderComponent: !multiple
                  ? undefined
                  : () => {
                      return (
                        <HStack
                        // spacing={{ top: 1, right: 2, bottom: 1, left: 1 }}
                        >
                          <Spacer flex />
                          <div>TextButton</div>
                          {/* <TextButton
                            size="small"
                            outline
                            label={{
                              key: allSelected
                                ? 'generic.input.inline_select.add_new.select_none.button.label'
                                : filterText
                                ? 'generic.input.inline_select.add_new.select_filtered.button.label'
                                : 'generic.input.inline_select.add_new.select_all.button.label',
                              params: {
                                '%COUNT': processedOptions.length,
                              },
                            }}
                            onClick={onSelectAll}
                          /> */}
                          <Spacer flex />
                        </HStack>
                      );
                    },
              }
            : {}),
        }}
        className={menuPlacement === 'top' ? styles.modalTop : null}
      />

      {sortOptions ? (
        <ModalFlyUp
          isOpen={showSortOptions}
          onClose={() => setShowSortOptions(false)}
          title={{ key: 'entities.entities_sort.title' }}
          listProps={{
            items: sortOptions ?? [],
            keyExtractor: (item) => JSON.stringify(item),
            renderItem: (item) => {
              return renderSortOption(item as any);
            },
          }}
          className={menuPlacement === 'top' ? styles.modalTop : null}
        />
      ) : null}
    </Box>
  );
};

export const ListSelectButton: React.FC<{
  disabled?: boolean;
  stateExpanded?: boolean;
  bordered?: boolean;
  hasSelectedOptions?: boolean;
  selectionLabel?: I18nItem | string;
  options?: ListSelectOption[];
  makeLabel: () => string;
  onToggleExpanded: () => void;
}> = ({
  disabled,
  stateExpanded,
  bordered,
  hasSelectedOptions,
  selectionLabel,
  options = [],
  makeLabel,
  onToggleExpanded,
}) => {
  const buttonContent = (
    <Box
      className={[
        styles.buttonExpand,
        disabled ? styles.buttonExpandDisabled : null,
        stateExpanded ? styles.buttonExpandExpanded : null,
        bordered ? styles.buttonBordered : null,
      ]}
      spacing={2}
    >
      <HStack>
        <Text
          color={hasSelectedOptions ? Color.Black : Color.Grey}
          i18n={
            typeof selectionLabel === 'object'
              ? {
                  ...selectionLabel,
                  pluralise: options.filter((a) => a.selected).length,
                  params: {
                    ...(selectionLabel?.params ?? {}),
                    '%VALUE': makeLabel(),
                  },
                }
              : selectionLabel || makeLabel()
          }
        />
        <Spacer flex />
        {disabled ? null : (
          <Icon
            size="small"
            name={stateExpanded ? 'chevron-up' : 'chevron-down'}
          />
        )}
      </HStack>
    </Box>
  );

  if (disabled) {
    return buttonContent;
  }

  return (
    <Clickable className={styles.buttonExpandClickable} href={onToggleExpanded}>
      {buttonContent}
    </Clickable>
  );
};
