import React, { Fragment } from 'react';
import { defineMessages } from 'react-intl';
import { useMessages, useI18n } from '@mobble/i18n';
import { Color } from '@mobble/colors';
import {
  type Paddock,
  GrazingHistoryType,
  calcTotalDSEOfPaddock,
  getMobTotalHead,
  paddockIsYard,
  isSafe,
} from '@mobble/models/src/model/Paddock';
import { type Mob } from '@mobble/models/src/model/Mob';
import { daysAgo, formatDate } from '@mobble/shared/src/core/Date';
import { ConfiguredPropertyType } from '@mobble/models/src/model/Property';
import { type PaddockGroupedStockingRateTotals } from '@mobble/models/src/model/PaddockGroupedStockingRate';
import { roundNumber } from '@mobble/shared/src/core/Number';
import { arrayIntersperse } from '@mobble/shared/src/core/Array';
import * as Quantity from '@mobble/shared/src/core/Quantity';
import { useSetting } from '@mobble/store/src/hooks/settings';
import { toPath } from '@src/interfaces/Routing';
import * as ROUTE_NAME from '@src/screens/config/routeNames';
import { type InfoRowProps, InfoRow } from '../../Components/UI/InfoRow';
import { HStack } from '../../Components/Layout/Stack';
import { makeQuantityFormatMessageItem } from '../../Components/Locale/LocaleQuantity';
import { Icon } from '../../Components/UI/Icon';
import { Text } from '../../Components/UI/Text';
import { Clickable } from '@src/stories/Components/UX/Clickable';
import styles from './paddockInfoTable.scss';

export interface PaddockInfoTableProps {
  propertyTypes: ConfiguredPropertyType[];
  paddock: Paddock;
  mobs: Mob[];
  paddocksInSamePaddockGroup: Paddock[];
  paddockGroupedStockingRateTotals?: null | PaddockGroupedStockingRateTotals;
  paddockTotalArea: Quantity.Quantities | undefined;
}

const messages = defineMessages({
  average_dse_per_ha: {
    id: 'paddock.info.table.row.average_dse_per_ha.label',
    defaultMessage: 'Average DSE/ha',
  },
  average_dse_per_ha_note: {
    id: 'paddock.info.table.row.average_dse_per_ha.note',
    defaultMessage: '(last 12 months)',
  },
  days_grazed: {
    id: 'paddock.info.table.row.days_grazed.label',
    defaultMessage: 'Days grazed',
  },
  days_rested: {
    id: 'paddock.info.table.row.days_rested.label',
    defaultMessage: 'Days rested',
  },
  dse_per_ha: {
    id: 'paddock.info.table.row.dse_per_ha.label',
    defaultMessage: 'DSE/Ha',
  },
  grazeable_area: {
    id: 'paddock.info.table.row.grazeable_area.label',
    defaultMessage: 'Grazeable area',
  },
  number_of_head: {
    id: 'paddock.info.table.row.number_of_head.label',
    defaultMessage: 'Number of head',
  },
  paddock_group: {
    id: 'paddock.info.table.row.paddock_group.label',
    defaultMessage: 'Opened with',
  },
  paddock_type: {
    id: 'paddock.info.table.row.paddock_type.label',
    defaultMessage: 'Paddock type',
  },
  paddock_type_note_yard: {
    id: 'paddock.info.table.row.paddock_type.note.yard',
    defaultMessage:
      'A yard is a special paddock type that can be used for the bulk processing of livestock such as, scanning, weaning, merging, reclassing and more.',
  },
  total_area: {
    id: 'paddock.info.table.row.total_area.label',
    defaultMessage: 'Total area',
  },
  total_dse: {
    id: 'paddock.info.table.row.total_dse.label',
    defaultMessage: 'Total DSE',
  },
  whp_is_safe: {
    id: 'paddock.info.table.row.whp.is_safe',
    defaultMessage: 'Safe',
  },
  whp: {
    id: 'paddock.info.table.row.whp.label',
    defaultMessage: 'WHP',
  },
});

export const PaddockInfoTable: React.FC<PaddockInfoTableProps> = ({
  paddock,
  mobs,
  propertyTypes,
  paddocksInSamePaddockGroup,
  paddockGroupedStockingRateTotals,
  paddockTotalArea,
}) => {
  const strings = useMessages(messages);
  const { formatMessage } = useI18n();
  const dateFormat = useSetting('dateFormat') as string;
  const areaUnit = useSetting('areaUnit') as Quantity.AreaUnits;
  const isYardPaddock = paddockIsYard(paddock);

  const rows: (InfoRowProps | InfoRowProps[])[] = [
    // paddock type
    {
      label: strings.paddock_type,
      value: paddock.type,
      note:
        paddock.type === 'Yard'
          ? { key: 'paddock.info.table.row.paddock_type.note.yard' }
          : undefined,
      color: propertyTypes.find((a) => a.type === paddock.type)?.color,
    },
    // paddock group
    paddocksInSamePaddockGroup?.length && {
      label: strings.paddock_group,
      value: () => (
        <HStack>
          {arrayIntersperse(
            <Text bold>{', '}</Text>,
            paddocksInSamePaddockGroup.map((p) => (
              <Clickable
                key={p.id}
                href={toPath(ROUTE_NAME.PADDOCK_DETAIL, { paddockId: p.id })}
                className={styles.paddockLink}
              >
                <Text bold>{p.name}</Text>
                <Icon
                  size="small"
                  name="link"
                  color={Color.Grey}
                  className={styles.linkIcon}
                />
              </Clickable>
            ))
          )}
        </HStack>
      ),
    },
    // paddock sizes
    [
      ...(paddock.properties.size
        ? [
            {
              label: strings.grazeable_area,
              value: formatMessage(
                ...makeQuantityFormatMessageItem(
                  paddock.properties.size,
                  areaUnit
                )
              ),
            },
          ]
        : []),

      ...(paddockTotalArea
        ? [
            {
              label: strings.total_area,
              value: formatMessage(
                ...makeQuantityFormatMessageItem(paddockTotalArea, areaUnit)
              ),
            },
          ]
        : []),
    ],
    // days x
    paddock.properties.lastGrazingHistory && {
      label:
        paddock.properties.lastGrazingHistory.type ===
        GrazingHistoryType.paddockEmpty
          ? strings.days_rested
          : strings.days_grazed,
      value: daysAgo(paddock.properties.lastGrazingHistory.date),
      note: formatDate(paddock.properties.lastGrazingHistory.date, dateFormat),
    },
    // head
    getMobTotalHead(mobs)(paddock) > 0 && {
      label: strings.number_of_head,
      value:
        paddockGroupedStockingRateTotals?.head ??
        getMobTotalHead(mobs)(paddock),
    },
    // Total DSE
    [
      {
        label: strings.total_dse,
        value:
          paddockGroupedStockingRateTotals?.DSE ??
          calcTotalDSEOfPaddock(mobs)(paddock),
      },
      // DSE/Ha
      !isYardPaddock
        ? {
            label: strings.dse_per_ha,
            value:
              paddockGroupedStockingRateTotals?.DSE_HA ??
              (paddock.properties.size?.value
                ? roundNumber(
                    calcTotalDSEOfPaddock(mobs)(paddock) /
                      paddock.properties.size.value
                  )
                : undefined),
          }
        : null,
      // average DSE/Ha
      !isYardPaddock &&
        paddock.properties.averageAreaStockingRate && {
          label: strings.average_dse_per_ha,
          note: strings.average_dse_per_ha_note,
          value: paddock.properties.averageAreaStockingRate,
        },
    ].filter((a) => a && Boolean(a?.value)),
    // Safe Date
    {
      label: strings.whp,
      value: isSafe(paddock)
        ? strings.whp_is_safe
        : formatDate(paddock.properties.safeDate as string, dateFormat),
    },
  ].filter(Boolean) as InfoRowProps[];

  return (
    <>
      {rows.map((row, i) =>
        Array.isArray(row) ? (
          <Fragment key={i}>
            {row.map((r) => (
              <InfoRow {...r} key={r.label as string} />
            ))}
          </Fragment>
        ) : (
          <InfoRow key={row.label} {...row} />
        )
      )}
    </>
  );
};
