import React from 'react';
import { useI18n } from '@mobble/i18n';
import { findPaddock } from '@mobble/models/src/model/Paddock';
import { PaddockAction } from '@mobble/models/src/model/PaddockAction';
import { useEntitiesRefresher } from '@mobble/shared/src/hooks/useEntitiesRefresher';
import {
  useInventoryItemBatches,
  useInventoryItems,
  usePaddockAction,
  usePaddocks,
  useProperties,
  useInventories,
} from '@mobble/store/src/hooks';
import { useNavigateBack } from '@src/hooks/useNavigateBack';
import { useLinking } from '@src/hooks/useLinking';
import { toPath } from '@src/interfaces/Routing';

import { EntitySliceFactoryPrelude } from '@src/stories/Views/Misc/EntitySliceFactoryPrelude';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';
import { PaddockActionInfoTable } from '@src/stories/Views/PaddockAction/PaddockActionInfoTable';

import * as ROUTE_NAME from '../config/routeNames';
import { type ScreenRendererProps } from '../config/types';

export const PaddockActionDetailHeader: React.FC<ScreenRendererProps> = (
  props
) => {
  const { formatMessage } = useI18n();
  const { paddockActionId = '' } = props.route.params;
  const properties = useProperties();
  const paddockAction = usePaddockAction(
    paddockActionId,
    properties.selected?.id
  );
  const goBack = useNavigateBack();

  if (!paddockAction.entity) {
    return null;
  }

  return (
    <ScreenHeader
      title={
        paddockAction.entity?.title ||
        formatMessage({
          defaultMessage: 'Paddock action',
          description: 'screen.title.paddock_action_detail',
        })
      }
      breadcrumbs={[
        {
          title: formatMessage({
            defaultMessage: 'Summaries',
            description: 'screen.title.summaries',
          }),
          href: ROUTE_NAME.SUMMARIES_LIST,
        },
        {
          title: formatMessage({
            defaultMessage: 'Paddock actions',
            description: 'screen.title.summary_paddock_actions',
          }),
          href: toPath(ROUTE_NAME.SUMMARY_PADDOCK_ACTIONS_LIST),
        },
      ]}
      actions={[
        {
          icon: 'edit',
          href: toPath(ROUTE_NAME.PADDOCK_ACTION_EDIT, { paddockActionId }),
        },
      ]}
      onGoBack={goBack}
    />
  );
};

export const PaddockActionDetail: React.FC<ScreenRendererProps> = (props) => {
  const { paddockActionId = '' } = props.route.params;
  const linkTo = useLinking();
  const properties = useProperties();
  const propertyId = properties.selected?.id;

  const inventories = useInventories(propertyId);
  const inventoryItems = useInventoryItems();
  const inventoryItemBatches = useInventoryItemBatches('_', propertyId);
  const paddocks = usePaddocks(propertyId);
  const paddockAction = usePaddockAction(paddockActionId, propertyId);
  const findPaddockFromId = findPaddock(paddocks.entities);

  useEntitiesRefresher(
    [paddockAction, paddocks, inventories, inventoryItemBatches],
    propertyId
  );

  const Prelude = EntitySliceFactoryPrelude({
    preludes: [inventories.prelude, paddocks.prelude, paddockAction.prelude],
    required: [properties.selected],
    notFound: [paddockAction.entity],
  });

  if (Prelude) {
    return Prelude;
  }

  const totalArea =
    paddockAction?.entity?.paddocks.reduce((acc, paddock) => {
      const paddockAffected = findPaddockFromId(paddock.paddockId);
      return acc + (paddockAffected?.properties?.size?.value || 0);
    }, 0) ?? 0;

  return (
    <PaddockActionInfoTable
      paddockAction={paddockAction.entity as PaddockAction}
      paddocks={paddocks.entities}
      totalArea={totalArea}
      inventoryItems={inventoryItems.allEntitiesAvailable}
      inventoryItemBatches={inventoryItemBatches.allEntitiesAvailable}
      onOpenPaddock={(paddockId) => {
        linkTo(ROUTE_NAME.PADDOCK_DETAIL, { paddockId: paddockId });
      }}
      onOpenInventoryItem={(inventoryItem) => {
        linkTo(ROUTE_NAME.INVENTORY_ITEM_DETAIL, {
          inventoryId: inventoryItem.inventoryId,
          inventoryItemId: inventoryItem.id,
        });
      }}
    />
  );
};

export default {
  name: ROUTE_NAME.PADDOCK_ACTION_DETAIL,
  header: PaddockActionDetailHeader,
  component: PaddockActionDetail,
};
