import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Color } from '@mobble/colors';
import { useI18n } from '@mobble/i18n';
import {
  RainGauge,
  getRainGaugeYearsAvailable,
} from '@mobble/models/src/model/RainGauge';
import { RainGaugeReading } from '@mobble/models/src/model/RainGaugeReading';
import { useEntitiesRefresher } from '@mobble/shared/src/hooks/useEntitiesRefresher';
import {
  useProperties,
  useRainGauge,
  useRainGaugeReadings,
  useRainGauges,
} from '@mobble/store/src/hooks';
import { useNavigateBack } from '@src/hooks/useNavigateBack';
import { toPath } from '@src/interfaces/Routing';
import { Box } from '@src/stories/Components/Layout/Box';
import { ResponsiveMobileOrDesktop } from '@src/stories/Components/Layout/Responsive';
import { EntitySliceFactoryPrelude } from '@src/stories/Views/Misc/EntitySliceFactoryPrelude';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';
import { TabBar } from '@src/stories/Views/Misc/TabBar';
import { ChartRainGaugeMonthlyRainfall } from '@src/stories/Views/RainGauge/Charts/ChartRainGaugeMonthlyRainfall';
import { RainGaugeYearSelector } from '@src/stories/Views/RainGauge/RainGaugeYearSelector';
import { RainGaugeInfoTable } from '@src/stories/Views/RainGauge/RainGaugeInfoTable';

import * as ROUTE_NAME from '../config/routeNames';
import { type ScreenRendererProps } from '../config/types';

import { RainGaugeReadings } from './RainGaugeReadings';
import styles from './rainGaugeDetail.scss';

export interface RainGaugeDetail {
  type: 'info';
}

export interface RainGaugeReadings {
  type: 'history';
  userId: RainGauge['id'];
}

type RainGaugeDetailMobileTab = 'info' | 'history';

export const RainGaugeDetailHeader: React.FC<ScreenRendererProps> = (props) => {
  const { rainGaugeId = '' } = props.route.params;
  const { formatMessage } = useI18n();
  const properties = useProperties();
  const rainGauge = useRainGauge(rainGaugeId);
  const rainGauges = useRainGauges(properties.selected?.id);
  const goBack = useNavigateBack();

  const quickLinks = rainGauges.entities
    .map((g) => ({
      title: g.name,
      href: toPath(ROUTE_NAME.RAIN_GAUGE_DETAIL, { rainGaugeId: g.id }),
    }))
    .sort((a, b) => a.title.localeCompare(b.title));

  const title = rainGauge.entity
    ? rainGauge.entity.name
    : formatMessage({
        description: 'screen.title.rain-gauge',
        defaultMessage: 'Rain gauge',
      });

  return (
    <ScreenHeader
      title={title}
      breadcrumbs={[
        {
          title: formatMessage({
            description: 'screen.title.rain-gauge',
            defaultMessage: 'Rain gauge',
          }),
          href: ROUTE_NAME.RAIN_GAUGES_LIST,
        },
      ]}
      quickLinks={quickLinks}
      actions={[
        {
          icon: 'edit',
          href: toPath(ROUTE_NAME.RAIN_GAUGE_DETAIL_EDIT, { rainGaugeId }),
        },
      ]}
      onGoBack={goBack}
    />
  );
};

export const RainGaugeDetail: React.FC<ScreenRendererProps> = (props) => {
  const { rainGaugeId = '' } = props.route.params;
  const { formatMessage } = useI18n();
  const { hash } = useLocation();
  const navigate = useNavigate();
  const properties = useProperties();
  const propertyId = properties.selected?.id;
  const rainGauge = useRainGauge(rainGaugeId);
  const rainGaugeReadings = useRainGaugeReadings(propertyId, rainGaugeId);

  const curYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState<number>(curYear);
  const fetched = useRef<number[]>([curYear]);
  const availableYears = getRainGaugeYearsAvailable(rainGauge.entity);

  const [activeTab, setActiveTab] = useState<RainGaugeDetailMobileTab>(
    (hash.slice(1) as RainGaugeDetailMobileTab) || 'info'
  );

  useEffect(() => {
    if (rainGauge.entity) {
      availableYears.forEach((y) => {
        if (!fetched.current.includes(y)) {
          fetched.current.push(y);
          return rainGauge.getYearTotals(y).catch(() => {});
        }
      });
    }
  }, [rainGauge]);

  useEntitiesRefresher([rainGaugeReadings, rainGauge], propertyId);

  const Prelude = EntitySliceFactoryPrelude({
    preludes: [
      properties.prelude,
      rainGauge.prelude,
      rainGaugeReadings.prelude,
    ],
    required: [properties.selected],
    notFound: [rainGauge.entity],
  });

  if (Prelude) {
    return Prelude;
  }

  const tabs = [
    {
      label: formatMessage({
        description: 'screen.title.rain-gauge_detail_info',
        defaultMessage: 'Info',
      }),
      value: 'info',
    },
    {
      label: formatMessage({
        description: 'screen.title.rain-gauge_detail_history',
        defaultMessage: 'History',
      }),
      value: 'history',
    },
  ].map((t) => ({
    ...t,
    isActive: t.value === activeTab,
    onClick: (tab) => {
      setActiveTab(tab);
      window.location.hash = `#${tab}`;
    },
  }));

  const handleRainGaugeReadingClick = (rainGaugeReading: RainGaugeReading) => {
    navigate(
      toPath(ROUTE_NAME.RAIN_GAUGE_READING, {
        rainGaugeReadingId: rainGaugeReading.id,
        rainGaugeId,
      })
    );
  };

  return (
    <>
      <TabBar
        className={styles.tabBar}
        backgroundColor={Color.Blue}
        tabs={tabs}
      />

      <Box className={styles.rainGaugeDetailLayout}>
        <Box
          className={[
            styles.rainGaugeTabChart,
            styles.tab,
            activeTab === 'info' ? styles.active : null,
          ]}
        >
          <Box className={styles.rainGaugeTabChartBar}>
            <ResponsiveMobileOrDesktop
              style={{ height: '100%' }}
              mobile={
                <>
                  <RainGaugeYearSelector
                    year={selectedYear}
                    availableYears={availableYears}
                    onChange={setSelectedYear}
                  />
                  <ChartRainGaugeMonthlyRainfall
                    height="300px"
                    year={selectedYear}
                    rainGauge={rainGauge.entity}
                  />
                </>
              }
              desktop={
                <ChartRainGaugeMonthlyRainfall
                  height="100%"
                  rainGauge={rainGauge.entity}
                />
              }
            />
          </Box>
        </Box>

        <Box
          className={[
            styles.rainGaugeTabInfo,
            styles.tab,
            activeTab === 'info' ? styles.active : null,
          ]}
        >
          <Box className={styles.rainGaugeInfoTabTable}>
            <RainGaugeInfoTable
              rainGauge={rainGauge.entity}
              rainGaugeReadings={rainGaugeReadings.entities}
              year={curYear}
            />
          </Box>
        </Box>

        <Box
          className={[
            styles.rainGaugeTabHistory,
            styles.tab,
            activeTab === 'history' ? styles.active : null,
          ]}
        >
          <Box className={styles.rainGaugeTabHistoryTimeline}>
            <RainGaugeReadings
              rainGaugeReadings={rainGaugeReadings}
              properties={properties}
              onClick={handleRainGaugeReadingClick}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default {
  name: ROUTE_NAME.RAIN_GAUGE_DETAIL,
  header: RainGaugeDetailHeader,
  component: RainGaugeDetail,
};
