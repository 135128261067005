import React, { Fragment } from 'react';
import { Color } from '@mobble/colors';
import { Box, type BoxProps } from '../Layout/Box';
import { Icon } from './Icon';
import { Text } from './Text';
import { Spacer } from '../Layout/Spacer';
import { HStack } from '../Layout/Stack';
import { ColorSquare } from './ColorSquare';
import { Clickable } from '../UX/Clickable';
import styles from './infoRow.scss';

export type InfoRowProps = BoxProps & {
  label: string;
  value?: number | string | (() => JSX.Element);
  note?: string;
  color?: string;
  childAfter?: React.ReactElement;
  href?: string | (() => void);
};

export const InfoRow: React.FC<InfoRowProps> = ({
  label,
  value,
  note,
  color,
  childAfter,
  href,
  style,
  ...delegated
}) => {
  const renderValue = () => {
    switch (typeof value) {
      case 'function':
        return value();
      case 'object':
        return <Text variant="normal" bold i18n={value as object} />;
      default:
        return (
          <>
            <Text variant="body" bold>
              {value || '\u00A0'}
            </Text>
            {Boolean(href) && (
              <Icon
                size="small"
                name="link"
                color={Color.Grey}
                className={styles.linkIcon}
              />
            )}
          </>
        );
    }
  };

  const WrapperComponent = href ? Clickable : Fragment;
  const wrapperProps = href ? { href } : {};

  return (
    <Box className={styles.infoRow} style={style} {...delegated}>
      <Text tagName="h3" color={Color.Green} variant="small" i18n={label} />

      <Spacer y={1} />

      <WrapperComponent {...wrapperProps}>
        <HStack>
          {renderValue()}

          {childAfter}
          {color && (
            <>
              <Spacer x={2} />
              <ColorSquare color={color} />
            </>
          )}
        </HStack>
      </WrapperComponent>

      {note ? (
        <Box spacing={{ top: 1, right: 2, bottom: 0, left: 0 }}>
          <Text i18n={note} variant="small" color={Color.Grey} tagName="p" />
        </Box>
      ) : null}
    </Box>
  );
};
