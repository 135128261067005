import React from 'react';
import { useI18n } from '@mobble/i18n';
import { findMob, findMobs } from '@mobble/models/src/model/Mob';
import { type MobAction } from '@mobble/models/src/model/MobAction';
import { paddockForMob } from '@mobble/models/src/model/Paddock';
import {
  ConfiguredPropertyTypeGroup,
  getOptionsFromTypesByGroup,
} from '@mobble/models/src/model/Property';
import { toISO8601 } from '@mobble/shared/src/core/Date';
import { useEntitiesRefresher } from '@mobble/shared/src/hooks/useEntitiesRefresher';
import {
  useInventories,
  useMobAction,
  useMobs,
  usePaddocks,
  useProperties,
  useExtStatus,
  useRootDispatch,
} from '@mobble/store/src/hooks';
import { thunkGetAll as thunkGetInventoryBatches } from '@mobble/store/src/reducers/inventoryItemBatches';

import { useLinking } from '@src/hooks/useLinking';
import { useNavigateBack } from '@src/hooks/useNavigateBack';
import { toPath } from '@src/interfaces/Routing';

import { DeleteForm } from '@src/stories/Views/Form/DeleteForm/DeleteForm';
import { EntitySliceFactoryPrelude } from '@src/stories/Views/Misc/EntitySliceFactoryPrelude';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';
import {
  MobActionEditForm,
  type MobActionEditFormValues,
} from '@src/stories/Views/MobAction/MobActionEditForm';
import * as ROUTE_NAME from '../config/routeNames';
import { type ScreenRendererProps } from '../config/types';

export const MobActionEditHeader: React.FC<ScreenRendererProps> = (props) => {
  const { formatMessage } = useI18n();
  const { mobActionId = '' } = props.route.params;
  const goBack = useNavigateBack();
  const properties = useProperties();
  const mobAction = useMobAction(mobActionId, properties.selected?.id);

  return (
    <ScreenHeader
      title={formatMessage(
        {
          description: 'screen.title.edit_x',
          defaultMessage: 'Edit "{TITLE}"',
        },
        {
          TITLE: mobAction.entity?.title,
        }
      )}
      breadcrumbs={[
        {
          title: formatMessage({
            description: 'screen.title.summaries',
            defaultMessage: 'Summaries',
          }),
          href: ROUTE_NAME.SUMMARIES_LIST,
        },
        {
          title: formatMessage({
            description: 'screen.title.summary_mob_actions',
            defaultMessage: 'Mob actions',
          }),
          href: toPath(ROUTE_NAME.SUMMARY_MOB_ACTIONS_LIST),
        },
        {
          title: mobAction.entity?.title,
          href: toPath(ROUTE_NAME.MOB_ACTION_DETAIL, { mobActionId }),
        },
      ]}
      onGoBack={goBack}
    />
  );
};

export const MobActionEdit: React.FC<ScreenRendererProps> = (props) => {
  const { mobActionId = '' } = props.route.params;
  const dispatch = useRootDispatch();
  const { translate } = useI18n();
  const linkTo = useLinking();
  const goBack = useNavigateBack();
  const properties = useProperties();
  const propertyId = properties.selected?.id;

  const inventories = useInventories(propertyId);

  const mobs = useMobs(propertyId);
  const paddocks = usePaddocks(propertyId);
  const mobAction = useMobAction(mobActionId, propertyId);
  const extStatus = useExtStatus('mobActions', 'update', mobActionId);
  const formLoading = extStatus?.loading;
  const extStatusDelete = useExtStatus('mobActions', 'delete', mobActionId);
  const deleteLoading = extStatusDelete?.loading;
  const formError = extStatus?.error
    ? translate({
        key: 'generic.form.error',
        params: {
          '%ENTITY': translate({ key: 'screen.title.mob_action' }),
        },
      })
    : '';

  useEntitiesRefresher([inventories, mobs, paddocks], propertyId);

  const Prelude = EntitySliceFactoryPrelude({
    preludes: [inventories.prelude, properties.prelude, mobAction.prelude],
    required: [properties.selected],
    notFound: [mobAction.entity],
  });

  if (Prelude) {
    return Prelude;
  }

  const handleSubmit = (formValues: MobActionEditFormValues) => {
    if (!mobAction.entity || !properties.selected) {
      return;
    }

    const allMobs = [
      ...mobs.entities,
      ...(mobAction.entity.mobs ?? []).filter(
        (m) => !findMob(mobs.entities)(m.id)
      ),
    ];

    const count =
      formValues.mobIds.length === 1
        ? formValues.count
          ? Number(formValues.count)
          : findMob(allMobs)(formValues.mobIds[0])?.size
        : undefined;

    const formMobs = findMobs(allMobs)(formValues.mobIds).map((mob) => {
      const paddock = paddockForMob(paddocks.entities)(mob);
      return {
        ...mob,
        snapshot: paddock ? { paddockName: paddock.name } : undefined,
      };
    });

    const updatedMobAction: MobAction = {
      ...mobAction.entity,
      date: toISO8601(formValues.date),
      type: formValues.action_type,
      note: formValues.note,
      mobIds: formValues.mobIds,
      mobs: formMobs,
      count,
      appliedInventoryItems: JSON.parse(formValues.inventory_items),
    };

    mobAction
      .update(updatedMobAction)
      .then((mobActionRes) => {
        // Fetch updated batch data for all applied chemicals
        mobActionRes.appliedInventoryItems.forEach((item) => {
          dispatch(
            thunkGetInventoryBatches({
              parentId: propertyId,
              extFilter: {
                chemicalIds: [item.inventoryItemId],
              },
            })
          );
        });

        if (mobActionRes.appliedInventoryItems.length) {
          inventories.refresh();
        }

        goBack();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDelete = () => {
    mobAction
      .remove()
      .then(() => {
        linkTo(ROUTE_NAME.SUMMARY_MOB_ACTIONS_LIST);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <MobActionEditForm
        propertyTypes={properties.selected?.types || []}
        mobAction={mobAction.entity}
        mobActions={getOptionsFromTypesByGroup(
          properties.selected?.types || []
        )(ConfiguredPropertyTypeGroup.mobAction)}
        onAddMobAction={(label) => {
          if (!properties.selected) {
            return Promise.reject();
          }
          return properties.addPropertyType({
            group: ConfiguredPropertyTypeGroup.mobAction,
            label,
          });
        }}
        paddocks={paddocks.entities}
        mobs={mobs.entities}
        error={formError}
        loading={formLoading}
        onCancel={goBack}
        onTouched={() => {}}
        onSubmit={handleSubmit}
      />

      <DeleteForm
        i18nRootKey="mobActions.mobAction.delete"
        loading={deleteLoading}
        onDelete={handleDelete}
      />
    </>
  );
};

export default {
  name: ROUTE_NAME.MOB_ACTION_EDIT,
  header: MobActionEditHeader,
  component: MobActionEdit,
};
