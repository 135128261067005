import Area from './area.svg';
import Average from './average.svg';
import CheckFat from './check-fat.svg';
import Distance from './distance.svg';
import LineString from './line-string.svg';
import MagnetOff from './magnet-off.svg';
import Magnet from './magnet.svg';
import Paddock from './paddock.svg';
import Path from './path.svg';
import Point from './point.svg';
import Polygon from './polygon.svg';
import Ruler from './ruler.svg';
import SortAsc from './sort-asc.svg';
import Split from './split.svg';

export default {
  Area,
  Average,
  CheckFat,
  Distance,
  LineString,
  MagnetOff,
  Magnet,
  Paddock,
  Path,
  Point,
  Polygon,
  Ruler,
  SortAsc,
  Split,
};
