import React, { useEffect, useState } from 'react';
import { defineMessages } from 'react-intl';

import { Color } from '@mobble/colors';
import { useI18n, useMessages } from '@mobble/i18n';
import {
  InventoryCategory,
  inventoryCategoryToColor,
} from '@mobble/models/src/model/Inventory';
import { InventoryItemStatus } from '@mobble/models/src/model/InventoryItem';
import { InventoryItemBatch } from '@mobble/models/src/model/InventoryItemBatch';
import { ProductApplication } from '@mobble/models/src/model/ProductApplication';

import {
  useSettings,
  useInventory,
  useInventoryItemUsage,
  useInventoryItemBatches,
  useInventoryItem,
  useInventoryItems,
  useProductApplications,
  useProperties,
} from '@mobble/store/src/hooks';

import { fromRawDate } from '@mobble/shared/src/core/Date';
import { useEntitiesRefresher } from '@mobble/shared/src/hooks/useEntitiesRefresher';

import { Alert } from '@src/components';

import { useDateRange } from '@src/hooks/useDateRange';
import { useLinking } from '@src/hooks/useLinking';
import { MediaQuerySize, useMediaQuery } from '@src/hooks/useMediaQuery';
import { toPath } from '@src/interfaces/Routing';

import { Box } from '@src/stories/Components/Layout/Box';
import { Spacer } from '@src/stories/Components/Layout/Spacer';
import {
  EntitiesViewer,
  EntitiesViewerProps,
} from '@src/stories/Views/Entities/EntitiesViewer';
import { Provider } from '@src/stories/Views/Entities/types';
import { InventoryItemApplicationCard } from '@src/stories/Views/InventoryItem/InventoryItemApplicationCard';
import { InventoryItemBatchCard } from '@src/stories/Views/InventoryItem/InventoryItemBatchCard';
import { InventoryItemInfoTable } from '@src/stories/Views/InventoryItem/InventoryItemInfoTable';
import { EntitySliceFactoryPrelude } from '@src/stories/Views/Misc/EntitySliceFactoryPrelude';
import {
  ScreenHeader,
  type ScreenHeaderProps,
} from '@src/stories/Views/Misc/ScreenHeader';
import { TabBar } from '@src/stories/Views/Misc/TabBar';

import { ChartInventoryItemUsageGraph } from '@src/stories/Components/Charts/ChartInventoryItemUsageGraph/ChartInventoryItemUsageGraph';
import { ChartWrapper } from '@src/stories/Components/Charts/ChartWrapper';
import { DateRangeSelector } from '@src/stories/Views/Reports/DateRangeSelector';
import { convertInventoryItemUsageData } from '@src/stories/Components/Charts/ChartInventoryItemUsageGraph/convertInventoryItemUsageData';
import { makeInventoryItemBatchesTableColumns } from '@src/stories/Views/InventoryItem/List/inventoryItemBatchesTableColumns';

import * as ROUTE_NAME from '../config/routeNames';
import { type ScreenRendererProps } from '../config/types';

import styles from './inventoryItemDetail.scss';

const messages = defineMessages({
  batches: {
    defaultMessage: 'Batches',
    description: 'screen.title.inventory_item_detail_batches',
  },
  info: {
    defaultMessage: 'Info',
    description: 'screen.title.inventory_item_detail_info',
  },
  history: {
    defaultMessage: 'History',
    description: 'screen.title.inventory_item_detail_history',
  },
  active: {
    defaultMessage: 'Active',
    description: 'screen.title.inventory_item_detail_active',
  },
  finished: {
    defaultMessage: 'Finished',
    description: 'screen.title.inventory_item_detail_finished',
  },
  chemicalUsageOverTime: {
    defaultMessage: 'Chemical Usage Over Time',
    description: 'inventories.inventory_item_detail.chemical.chart.title',
  },
  feedUsageOverTime: {
    defaultMessage: 'Feed Usage Over Time',
    description: 'inventories.inventory_item_detail.feed.chart.title',
  },

  applicationTableEmptyMessage: {
    defaultMessage: 'No applications to display',
    description: 'inventory_item_applications.table.empty',
  },
  batchesTableEmptyMessage: {
    defaultMessage: 'There are no batches to display',
    description: 'inventory_item_batches.table.empty',
  },
  statusDeletedHeading: {
    defaultMessage: 'Inventory item deleted',
    description: 'Deleted Inventory item status heading',
  },
  statusDeletedMessage: {
    defaultMessage: 'You are unable to edit deleted inventory items.',
    description: 'Deleted Inventory item status message',
  },
});

export const InventoryItemDetailHeader: React.FC<ScreenRendererProps> = (
  props
) => {
  const { formatMessage } = useI18n();
  const linkTo = useLinking();
  const { inventoryId = '', inventoryItemId = '' } = props.route.params;
  const properties = useProperties();
  const propertyId = properties.selected?.id;
  const inventory = useInventory(inventoryId, propertyId);
  const inventoryItem = useInventoryItem(inventoryItemId, inventoryId);
  const inventoryItems = useInventoryItems(inventoryId);

  if (!inventoryItem?.entity) {
    return null;
  }

  const isDeleted =
    inventoryItem.entity?.status === InventoryItemStatus.Deleted;

  const goBack = () => {
    if (inventory.entity) {
      linkTo(ROUTE_NAME.INVENTORY_ITEMS, {
        inventoryId: inventory.entity.id,
      });
    } else {
      linkTo(ROUTE_NAME.INVENTORIES_LIST);
    }
  };

  const quickLinks = inventoryItems.entities
    .filter(
      (ii) =>
        ii.status === inventoryItem.entity.status ?? InventoryItemStatus.Active
    )
    .map((ii) => ({
      title: ii.name,
      href: toPath(ROUTE_NAME.INVENTORY_ITEM_DETAIL, {
        inventoryId,
        inventoryItemId: ii.id,
      }),
    }))
    .sort((a, b) => a.title.localeCompare(b.title));

  const breadcrumbs: ScreenHeaderProps['breadcrumbs'] = [
    {
      title: formatMessage({
        description: 'screen.title.inventories',
        defaultMessage: 'Inventories',
      }),
      href: ROUTE_NAME.INVENTORIES_LIST,
    },
  ];

  // :inventoryId not available when navigating from /summaries/event
  if (inventory.entity?.name) {
    breadcrumbs.push({
      title: inventory.entity?.name,
      href: toPath(ROUTE_NAME.INVENTORY_ITEMS, {
        inventoryId,
      }),
    });
  }

  const title =
    inventoryItem.entity?.name ||
    formatMessage({
      description: 'screen.title.inventory_item_detail',
      defaultMessage: 'Inventory Item Detail',
    });

  return (
    <ScreenHeader
      title={title}
      breadcrumbs={breadcrumbs}
      quickLinks={quickLinks}
      actions={
        !isDeleted
          ? [
              {
                icon: 'edit',
                href: toPath(ROUTE_NAME.INVENTORY_ITEM_EDIT, {
                  inventoryId,
                  inventoryItemId,
                }),
              },
            ]
          : []
      }
      onGoBack={goBack}
    />
  );
};

type InventoryItemDetailMobileTab = 'info' | 'batches' | 'history';
type InventoryItemDetailMobileTabChemical = 'active' | 'finished';

export const InventoryItemDetail: React.FC<ScreenRendererProps> = (props) => {
  const size = useMediaQuery();
  const strings = useMessages(messages);
  const { formatMessage } = useI18n();
  const { settings } = useSettings();
  const linkTo = useLinking();

  const { inventoryItemId = '', inventoryId } = props.route.params;
  const properties = useProperties();
  const propertyId = properties.selected?.id;

  const inventoryItem = useInventoryItem(inventoryItemId, inventoryId);
  const inventory = useInventory(
    inventoryId,
    inventoryItem.entity?.propertyId || propertyId
  );
  const inventoryItemBatches = useInventoryItemBatches(
    inventoryItemId,
    inventoryItem?.entity?.propertyId
  );

  const isDeleted =
    inventoryItem.entity?.status === InventoryItemStatus.Deleted;

  const isChemical =
    inventoryItem?.entity?.category === InventoryCategory.Chemicals;

  const getUserFromId = (userId: string) =>
    properties.selected?.users.find((u) => u.id === userId);

  const productApplications = useProductApplications(
    propertyId,
    inventoryItemId ?? '_',
    isChemical ? 'chemical' : 'feed'
  );

  const inventoryItemUsage = useInventoryItemUsage({
    id: inventoryItemId,
    propertyId,
    type: inventory.entity?.category,
  });

  const onProductApplicationClick = (productApplication: ProductApplication) =>
    productApplication.class === 'mob'
      ? linkTo(ROUTE_NAME.MOB_ACTION_DETAIL, {
          mobActionId: productApplication.mobActionId,
        })
      : linkTo(ROUTE_NAME.PADDOCK_ACTION_DETAIL, {
          paddockActionId: productApplication.paddockActionId,
        });

  const isMobileWidth =
    size === MediaQuerySize.Small || size === MediaQuerySize.Medium;

  const [activeTab, setActiveTab] =
    useState<InventoryItemDetailMobileTab>('info');

  const tabs = ['info', 'batches', 'history'].map((value) => ({
    onClick: (tab) => {
      setActiveTab(tab);
      window.location.hash = `#${tab}`;
    },
    label: strings[value],
    value,
    isActive: value === activeTab,
  }));

  const [chemicalStatus, setChemicalStatus] =
    useState<InventoryItemDetailMobileTabChemical>('active');

  const chemicalStatusTabs = ['active', 'finished'].map((value) => ({
    onClick: (tab) => {
      setChemicalStatus(tab);
    },
    label: strings[value],
    value,
    isActive: value === chemicalStatus,
  }));

  const batches: Provider<InventoryItemBatch> = {
    ...inventoryItemBatches,
    entities: inventoryItemBatches.entities.filter(
      (batch) => batch.status === chemicalStatus
    ),
    total: inventoryItemBatches.entities.filter(
      (batch) => batch.status === chemicalStatus
    ).length,
  };

  const { range, changeRange } = useDateRange({
    rangeFromRoute: props.route.params?.range,
    type: 'last-12-months',
  });

  const { data, maxY } = convertInventoryItemUsageData(
    inventoryItemId,
    inventoryItemUsage?.entity,
    range,
    inventoryItem?.entity?.quantity?.unit,
    inventory?.entity?.category
  );

  useEffect(() => {
    if (range && inventoryItemId) {
      inventoryItemUsage.getDateRange(
        fromRawDate(range.start).startOf('day').format(),
        fromRawDate(range.end).endOf('day').format()
      );
    }
  }, [range]);

  useEntitiesRefresher(
    [inventory, inventoryItem, inventoryItemBatches, productApplications],
    propertyId
  );

  const Prelude = EntitySliceFactoryPrelude({
    preludes: [inventory.prelude, inventoryItem.prelude],
    required: [properties.selected],
    notFound: [inventoryItem.entity],
  });

  const chemicalApplicationsPrelude = EntitySliceFactoryPrelude({
    preludes: [productApplications.prelude],
  });

  if (Prelude) {
    return Prelude;
  }

  const tableColumns = makeInventoryItemBatchesTableColumns({
    category: inventory.entity?.category ?? InventoryCategory.Feed,
    currencySymbol: settings.currencySymbol,
    formatMessage,
  });

  const viewerProps: EntitiesViewerProps<ProductApplication> = {
    provider: productApplications,
    asList: true,
    onEmpty: strings.applicationTableEmptyMessage,
    renderEntityCard(entity) {
      return (
        <Box spacing={1}>
          <InventoryItemApplicationCard
            inventoryItemApplication={entity}
            category={inventory.entity?.category}
            user={getUserFromId(entity.createdById)}
            onClick={onProductApplicationClick}
          />
        </Box>
      );
    },
    tableColumns: [],
  };

  return (
    <>
      <TabBar
        backgroundColor={inventoryCategoryToColor(
          inventoryItem.entity.category
        )}
        className={styles.tabBar}
        tabs={tabs}
      />

      <Alert
        open={isDeleted}
        variant="warning"
        title={strings.statusDeletedHeading}
        message={strings.statusDeletedMessage}
        className={styles.deletedAlert}
      />

      <Box className={styles.inventoryItemDetailLayout}>
        <Box
          className={[styles.tab, activeTab === 'info' ? styles.active : null]}
        >
          <Box className={styles.inventoryItemInfoTabTable}>
            <InventoryItemInfoTable
              inventory={inventory.entity}
              inventoryItem={inventoryItem.entity}
              onClickInventory={({ id }) => {
                linkTo(ROUTE_NAME.INVENTORY_ITEMS, {
                  inventoryId: id,
                });
              }}
            />
          </Box>
        </Box>

        <Box
          className={[
            styles.tab,
            activeTab === 'history' ? styles.active : null,
          ]}
        >
          {isMobileWidth ? (
            <Box className={styles.inventoryItemTabHistoryTimeline}>
              {chemicalApplicationsPrelude ? (
                chemicalApplicationsPrelude
              ) : (
                <EntitiesViewer {...viewerProps} />
              )}
            </Box>
          ) : (
            <ChartWrapper
              title={
                isChemical
                  ? strings.chemicalUsageOverTime
                  : strings.feedUsageOverTime
              }
            >
              <Box className={styles.inventoryItemTabHistoryTimeline}>
                {chemicalApplicationsPrelude ? (
                  chemicalApplicationsPrelude
                ) : (
                  <EntitiesViewer {...viewerProps} />
                )}
              </Box>
            </ChartWrapper>
          )}
        </Box>
      </Box>
      <Box
        className={[styles.tab, activeTab === 'batches' ? styles.active : null]}
      >
        <Spacer y={isMobileWidth ? 0 : 4} />
        <TabBar
          backgroundColor={
            isMobileWidth
              ? Color.LightGrey
              : inventoryCategoryToColor(inventoryItem.entity.category)
          }
          indicatorColor={isMobileWidth ? Color.Grey : Color.AlmostWhite}
          className={styles.chemicalStatusTabBar}
          tabs={chemicalStatusTabs}
        />

        <EntitiesViewer
          provider={batches || []}
          renderEntityCard={(entity) => {
            return (
              <Box spacing={1} flex>
                <InventoryItemBatchCard
                  href={toPath(ROUTE_NAME.INVENTORY_ITEM_BATCH_DETAIL, {
                    inventoryId: inventoryItem.entity.inventoryId,
                    inventoryItemId: inventoryItem.entity?.id,
                    inventoryItemBatchId: entity.id,
                  })}
                  inventoryItemBatch={entity}
                />
              </Box>
            );
          }}
          onClickTableRow={(entity) => {
            linkTo(ROUTE_NAME.INVENTORY_ITEM_BATCH_DETAIL, {
              inventoryId: inventoryItem.entity.inventoryId,
              inventoryItemId: inventoryItem.entity?.id,
              inventoryItemBatchId: entity.id,
            });
          }}
          title={!isMobileWidth && strings.batches}
          onEmpty={strings.batchesTableEmptyMessage}
          showTitle={!isMobileWidth}
          tableColumns={tableColumns}
        />
      </Box>

      {activeTab === 'info' ? (
        <ChartWrapper
          title={
            isChemical
              ? strings.chemicalUsageOverTime
              : strings.feedUsageOverTime
          }
        >
          <Spacer y={1} />
          <DateRangeSelector range={range} onChange={changeRange} />
          <Spacer y={1} />
          <div style={{ height: '400px' }}>
            {data ? (
              <ChartInventoryItemUsageGraph
                data={data}
                maxY={maxY}
                unit={inventoryItem.entity.quantity.unit}
              />
            ) : null}
          </div>
        </ChartWrapper>
      ) : null}
    </>
  );
};

export default {
  name: ROUTE_NAME.INVENTORY_ITEM_DETAIL,
  header: InventoryItemDetailHeader,
  component: InventoryItemDetail,
};
