import React from 'react';
import * as Yup from 'yup';
import { NaturalIncrease } from '@mobble/models/src/model/NaturalIncrease';
import { Paddock, paddockForMob } from '@mobble/models/src/model/Paddock';
import { Color } from '@mobble/colors';
import { Box } from '../../Components/Layout/Box';
import { FormBuilder, type FormBuilderProps } from '../Form/FormBuilder';
import { MobCard } from '../Mob/MobCard';
import { useI18n } from '@mobble/i18n';
import { ConfiguredPropertyType } from '@mobble/models/src/model/Property';
import { InputContainer } from '@src/stories/Components/UX/InputContainer';

export interface NaturalIncreaseEditFormProps {
  paddocks: Paddock[];
  naturalIncrease: NaturalIncrease;
  propertyTypes: ConfiguredPropertyType[];
  error?: string;
  loading?: boolean;
  onCancel: () => void;
  onSubmit: (formValues: NaturalIncreaseEditFormValues) => void;
}

export interface NaturalIncreaseEditFormValues {
  date: string;
  number: number;
  note: string;
}

export const NaturalIncreaseEditForm: React.FC<
  NaturalIncreaseEditFormProps
> = ({
  paddocks,
  naturalIncrease,
  propertyTypes,
  error,
  loading,
  onCancel,
  onSubmit,
}) => {
  const { translate } = useI18n();
  const form: FormBuilderProps<NaturalIncreaseEditFormValues> = {
    flex: true,
    i18nRootKey: 'naturalIncrease.edit.form',
    fields: [
      {
        name: 'selected_mobs_preview',
        type: 'custom',
        containerComponent: false,
        component: () => {
          return (
            <InputContainer>
              <Box background={Color.LightGrey} spacing={2}>
                <MobCard
                  propertyTypes={propertyTypes}
                  paddock={paddockForMob(paddocks)(naturalIncrease.mob)}
                  mob={naturalIncrease.mob}
                />
              </Box>
            </InputContainer>
          );
        },
      },
      {
        name: 'date',
        type: 'date',
        initialValue: naturalIncrease.date,
        required: true,
      },
      {
        name: 'number',
        type: 'number',
        initialValue: naturalIncrease.mob.size,
        validation: Yup.number()
          .integer(
            translate({
              key: 'summaries.natural_increase.edit.form.number.error.label',
            }) || ''
          )
          .required(),
      },
      {
        name: 'note',
        initialValue: naturalIncrease.notes || '',
        type: 'textarea',
      },
    ],
    error,
    loading,
    onSubmit,
    onCancel,
  };

  return <FormBuilder {...form} />;
};
